<template>
  <div>
    <v-overlay
      :z-index="zIndex"
      :value="show"
      @click="close()"
      class="text-center"
    >
    </v-overlay>
    <!-- панель шаблонизатора -->
    <div class="l-panel" :class="{ 'l-panel-open': show }">
      <v-card elevation="0">
        <v-app-bar dark color="primary" height="48px" class="rounded-0">
          <v-toolbar-title>Шаблоны</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text>
          <v-tabs>
            <v-tab v-for="(header, idx) in headers" :key="idx">{{
              header
            }}</v-tab>
            <v-tab-item v-for="(items, idx) in templatesData" :key="idx">
              <div class="template">
                <v-data-table
                  :items="items"
                  :headers="dictHeader"
                  hide-default-footer
                  hide-default-header
                  disable-pagination
                >
                  <template #[`item.template`]="{ value }">
                    <OverlayItem
                      :template="value"
                      @add="v => addComment(idx, v)"
                    />
                  </template>
                </v-data-table>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import OverlayItem from '@/components/expSummary/templates/OverlayItem'

export default {
  name: 'OverlayTemplate',
  components: { OverlayItem },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    /**Массив [{header: String, templates: String []}]**/
    config: {
      type: [Array],
      default: () => [],
    },
  },
  data: () => ({
    zIndex: 99,
    dictHeader: [{ text: 'Текст', value: 'template' }],
  }),
  methods: {
    close() {
      this.$emit('close')
    },
    addComment(idx, template) {
      this.$emit('add', idx, template)
    },
  },
  computed: {
    headers() {
      return this.config.map(
        ({ header }, index) => header || `Шаблон ${index + 1}`
      )
    },
    templatesData() {
      return this.config.map(({ templates }) =>
        templates.map(template => ({
          template,
        }))
      )
    },
  },
}
</script>

<style scoped>
.l-panel-open {
  transform: translateX(0%) !important;
}
.l-panel {
  position: fixed;
  width: 45%;
  height: 100vh;
  background-color: white;
  z-index: 100;
  top: 0;
  right: 0;
  border-left: 1px solid gray;
  transform: translateX(100%);
  transition: 0.2s;
}
.template {
  overflow-y: scroll;
  height: calc(100vh - 120px);
}
</style>
