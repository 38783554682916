<template>
  <div>
    <v-tabs
      class="mb-4"
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab>Клинические рекомендаций</v-tab>
      <v-tab>Список аналогов</v-tab>
      <v-tab>Данные об аналогах</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <CrList :value="value" log-view readonly />
      </v-tab-item>
      <v-tab-item>
        <AnalogList :value="value" log-view readonly />
      </v-tab-item>
      <v-tab-item>
        <AdList :value="value" log-view readonly />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'AnalogForLog',
  components: {
    CrList: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expAnalogs/crList'
      ),
    AdList: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expAnalogs/adList'
      ),
    AnalogList: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expAnalogs/analogList'
      ),
  },

  props: ['value'],
  data: () => ({
    tab: null,
  }),
}
</script>
