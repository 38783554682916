<template>
  <VersionSelect v-if="plp_task_id" fluid>
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <analog-list
        :value="data"
        :readonly="readonly"
        :onCreate="onCreate"
        @change="onChange"
        @blur="onBlur"
      />
    </template>
  </VersionSelect>
</template>

<script>
import { mapGetters } from 'vuex'
import VersionSelect from '@/components/VersionSelect'
import analogList from '@/components/expAnalogs/analogList'

export default {
  components: { VersionSelect, analogList },
  computed: {
    ...mapGetters(['plp_task_id']),
  },
}
</script>

<style scoped>
.container {
  width: 100%;
}
</style>
