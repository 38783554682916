<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="blue--text text-decoration-underline">
        {{ message }}
      </span>
    </template>
    <template #detail="{ logInfo, data }">
      <v-list subheader flat>
        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title> Экспертиза </v-list-item-title>
          </template>
          <v-card flat>
            <ExpertiseTabsList
              v-if="expData || expDataExist || subexData(logInfo, 0)"
              :value="expData"
              readonly
              log-view
              dictType="'kei'"
            />
          </v-card>
        </v-list-group>
      </v-list>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import ExpertiseTabsList from '@/components/expAvbKei/ExpertiseTabsList'
import { sub_exp_migration } from '@/store/const/expertise'

export default {
  name: 'SubexMergedLog',
  components: { DefaultLog, ExpertiseTabsList },
  data: () => ({
    tab: null,
    expData: null,
    expDataExist: 0,
  }),
  inheritAttrs: false,
  methods: {
    subexData(logInfo, idx) {
      this.expDataExist = 1
      const { data, ex_type_name, data_version } = logInfo.data.data[idx] || {}
      sub_exp_migration(ex_type_name, data_version, data).then(({ data }) => {
        this.expData = data
      })
    },
  },
}
</script>
