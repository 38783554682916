<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="orange--text font-weight-bold">
        {{ message }}
      </span>
    </template>
    <template #detail="{ logInfo }">
      <ul v-if="showLogInfo(logInfo)">
        <li>
          <span class="font-weight-bold">Отправлено в МЗ: </span
          >{{ mzTask ? 'да' : 'нет' }}
        </li>
        <li>
          <span class="font-weight-bold">Проверено: </span
          >{{ checkedTask ? 'да' : 'нет' }}
        </li>
        <li>
          <span class="font-weight-bold">Выполнено: </span
          >{{ completeTask ? 'да' : 'нет' }}
        </li>
      </ul>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'

export default {
  name: 'SentToReworkLog',
  mixins: [logInfoMixin],
  components: { DefaultLog },
  inheritAttrs: false,
  computed: {
    plpTask() {
      return (
        this.logData?.data?.data?.data?.plp ?? this.logData?.data?.data?.plp
      )
    },
    mzTask() {
      return this.plpTask?.was_sent_to_mz
    },
    checkedTask() {
      return this.plpTask?.is_checked
    },
    completeTask() {
      return this.plpTask?.is_completed
    },
  },
}
</script>
