<template>
  <div class="ma-2 pa-2">
    <v-switch v-model="isCombine" @change="doChange(true)" @blur="doBlur()">
      <template #label>
        Используется ли комбинация препаратов?
        <span class="font-weight-bold ml-3">
          {{ isCombine ? 'Да' : 'Нет' }}</span
        ></template
      >
    </v-switch>
    <v-card class="mb-4">
      <v-card-text>
        <v-tabs v-model="activeTab">
          <v-tab
            v-for="(item, tabIndex) in calculationCost"
            :key="tabIndex"
            class="font-weight-bold"
            ><span>
              <span v-if="tabIndex === 0">Предлагаемый ЛП</span>
              <span v-else>
                <span>аналогичный ЛП {{ tabIndex }}</span>
                <btn-with-confirm
                  v-if="editMode"
                  class="mb-1"
                  :disabled="!editMode"
                  icon
                  x-small
                  icon-main="mdi-close"
                  title="Удалить рассчёт"
                  @click="deleteManual(item.id)"
                />
              </span>
            </span>
          </v-tab>
          <v-btn
            class="mt-2"
            small
            @click="addManual"
            v-if="editMode && isCombine"
            icon
            ><v-icon> mdi-plus </v-icon></v-btn
          >

          <v-tab-item
            v-for="(item, itemIndex) in calculationCost"
            :key="itemIndex"
            class="pa-2"
            eager
          >
            <CostManual
              v-if="calculationCost[itemIndex]"
              :item="item"
              :editMode="editMode"
              :itemIndex="itemIndex"
              :mainName="name"
              @change="
                (data, force) => {
                  doChangeCalculation(data, itemIndex, force)
                }
              "
              @blur="doBlur()"
            />
            <v-alert v-else type="error" outlined
              >Информация для рассчета {{ itemIndex + 1 }} отсутствует</v-alert
            >
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <v-text-field
      v-if="isCombine"
      v-model="combinePrice"
      :outlined="editMode"
      @input="doChange(false)"
      @blur="doBlur"
      persistent-hint
      label="Стоимость комбинаций"
      dense
    >
    </v-text-field>
    <btn-with-confirm
      icon
      v-if="editMode && id !== 0"
      text
      icon-main="mdi-delete-forever-outline"
      title="Удалить"
      color="red"
      @click="deleteCombination"
    />
  </div>
</template>

<script>
import { copyObject } from '@/lib/objects'
import CostManual from './CostManual.vue'
import { DEFAULT_CALCULATION_COST } from '../const'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'

export default {
  name: 'CostEditor',
  components: { CostManual, btnWithConfirm },
  data: () => ({
    item: null,
    isCombine: false,
    calculationCost: [],
    activeTab: 0,
    combinePrice: 0,
    id: 0,
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  watch: {
    value: 'init',
    isCombine: 'addFirstCombination',
  },
  methods: {
    init() {
      this.item = copyObject(this.value)
      this.isCombine = this.item?.isCombine || false
      this.calculationCost = this.item?.costs || []
      this.combinePrice = this.item?.combinePrice || 0
      this.id = this.item?.id || 0
    },
    doChange(force = false) {
      this.$emit(
        'change',
        {
          ...this.item,
          isCombine: this.isCombine,
          combinePrice: this.combinePrice,
          costs: this.calculationCost.map((item, index) => ({
            id: index,
            ...item,
          })),
        },
        force
      )
    },
    doChangeCalculation(data, itemIndex, force = false) {
      this.calculationCost[itemIndex] = data
      this.doChange(force)
    },
    doBlur() {
      this.$emit('blur')
    },
    addManual() {
      this.calculationCost.push(DEFAULT_CALCULATION_COST())
      this.activeTab = this.calculationCost?.length - 1 || 0
      this.doChange(this.calculationCost, true)
    },
    deleteManual(id) {
      const idx = this.calculationCost.findIndex(item => item.id === id)
      this.calculationCost.splice(idx, 1)
      this.doChange(this.calculationCost, true)
    },
    deleteCombination() {
      this.$emit('delete')
    },
    addFirstCombination(val) {
      if (val && this.calculationCost.length === 1) {
        this.addManual()
      }
    },
  },
  created() {
    this.init()
  },
}
</script>
