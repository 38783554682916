var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.plp_task_id)?_c('VersionSelect',{scopedSlots:_vm._u([{key:"content",fn:function(ref){
var data = ref.data;
var readonly = ref.readonly;
var onChange = ref.onChange;
var onBlur = ref.onBlur;
var onCreate = ref.onCreate;
return [_c('ad-list',{attrs:{"value":data,"readonly":readonly,"onCreate":onCreate},on:{"change":onChange,"blur":onBlur}})]}},{key:"prepend-bottom",fn:function(ref){
var readonly = ref.readonly;
var is_analog_completed = ref.is_analog_completed;
return [(is_analog_completed)?_c('btn-with-confirm',{directives:[{name:"show",rawName:"v-show",value:(!readonly),expression:"!readonly"}],attrs:{"loading":_vm.loading,"left":"","iconMain":"mdi-check-bold","color":"primary","title":"Этап экспертизы аналоги не готов к проверки - требуется доработка"},on:{"click":_vm.analogToRework}},[_vm._v("Аналоги - не готово!")]):_c('btn-with-confirm',{directives:[{name:"show",rawName:"v-show",value:(!readonly),expression:"!readonly"}],attrs:{"loading":_vm.loading,"left":"","iconMain":"mdi-arrow-up-left-bold","color":"success","title":"Этап экспертизы аналоги готовы к проверки"},on:{"click":_vm.analogComplete}},[_vm._v("Аналоги - готово")])]}}],null,false,2147415417)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }