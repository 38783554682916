<template>
  <div>
    <v-progress-linear v-if="loading" indeterminate color="secondary" />
    <v-alert v-else-if="error" type="error" outlined dense>
      Ошибка получения экспертизы: {{ error }}
    </v-alert>
    <v-form v-else :readonly="readonly">
      <v-row>
        <v-col>
          <!-- Табы  -->
          <v-tabs v-model="activeTab">
            <v-tab
              v-for="(expertise, tabIndex) in data.ka"
              :key="tabIndex"
              :disabled="disabledTab"
              :title="expertise.shortName"
              class="font-weight-bold"
              >{{ expertise.shortName }}
            </v-tab>
            <v-tab
              :disabled="disabledTab || readonly"
              class="font-weight-regular"
              >Добавить</v-tab
            >

            <v-tab-item
              v-for="(expertise, itemIndex) in data.ka"
              :key="itemIndex"
              class="pa-2"
            >
              <ExpertiseTabItems
                v-if="renderComponent"
                :kei="dt === 'KEI-MOD'"
                :value="expertise"
                :readonly="readonly"
                :initData="expElements[itemIndex]"
                @change="(force, lock) => doChange(force, lock)"
                @blur="doBlur()"
                @clickTemplate="clickTemplate"
                @clickTemplateAdd="clickTemplateAdd"
                @removeAvbKeiExpertise="removeAvbKeiExpertise(itemIndex)"
              />
            </v-tab-item>

            <!-- Последняя вкладка с добавлением -->
            <v-tab-item>
              <v-form
                @submit.prevent="addExpertise"
                class="my-5 pt-0 d-flex align-center"
                :readonly="readonly"
              >
                <v-text-field
                  :label="`Короткое название (до ${shortNameLength} символов)`"
                  v-model="newExpertiseName"
                  class="mr-4"
                  style="max-width: 480px"
                  :maxlength="shortNameLength"
                  autofocus
                ></v-text-field>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!newExpertiseName"
                  >Добавить</v-btn
                >
              </v-form>
            </v-tab-item>
          </v-tabs>
        </v-col>
      </v-row>
    </v-form>
    <OverlayDictCriteria
      :show="template.show"
      :correspond="template.correspond"
      :tabIndex="template.tabIndex"
      :dict="`${dictType}-mod`"
      @select="onSelectTemplate"
      @close="onCloseTemplate"
      autoclose
      :year.sync="answerYear"
      @update:year="updateAnswerYear"
    />
    <OverlayDictCriteria
      :show="templateAdd.show"
      :correspond="templateAdd.correspond"
      :tabIndex="templateAdd.tabIndex"
      :dict="`${dictType}-add`"
      @select="onSelectTemplateAdd"
      @close="onCloseTemplate"
      autoclose
      hide-answers
    />
  </div>
</template>

<script>
import ExpertiseTabItems from '@/components/expModAvbKei/ExpertiseTabItems'
import OverlayDictCriteria from '@/components/overlay/OverlayDictCriteria'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { SHORT_NAME_LENGTH } from '@/store/const/expDefaults'
import {
  EX_KEI_DEFAULT,
  EX_KEI_DEFAULT_DATA,
  EX_AVB_DEFAULT,
  EX_AVB_DEFAULT_DATA,
} from '@/components/expModAvbKei/const'
import { copyObject } from '@/lib/objects'
import ls from '@/lib/localStorageUtils'
import {
  SUB_EX_AVB,
  SUB_EX_AVB_MOD,
  SUB_EX_KEI,
  SUB_EX_KEI_MOD,
} from '@/store/const/expertise'

export default {
  components: { ExpertiseTabItems, OverlayDictCriteria },
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
    dictType: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    renderComponent: true,
    loading: false,
    error: '',
    initData: null,
    data: null,
    dt: undefined, //dictType
    newExpertiseName: '',
    activeTab: 0,
    disabledTab: false,
    template: {
      show: false,
      correspond: null,
      element: null,
      tabIndex: 0,
    },
    templateAdd: {
      show: false,
      correspond: null,
      element: null,
      key: '',
    },
    answerYear: ls.load('answerYear'),
  }),
  mounted() {
    this.dt = this.dictType.toUpperCase()
    if (this.readonly) this.data = this.value
    else this.data = copyObject(this.value)

    if (!this.data && this.onCreate) {
      this.data = this.onCreate(this.initNewData())
    }
    this.LOAD_ADD_KEI_CRITERIA()
    this.LOAD_ADD_KEI_TEMPLATE()
  },
  async created() {
    this.loading = true
    try {
      const { expertise } = await this.GET_PLP_REPORT([
        SUB_EX_KEI_MOD,
        SUB_EX_AVB_MOD,
        SUB_EX_KEI,
        SUB_EX_AVB,
      ])
      this.initData = expertise
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['storedPlpVersion']),
    shortNameLength() {
      return SHORT_NAME_LENGTH
    },
    expElements() {
      return this.dictType === 'kei-mod'
        ? this.initData?.kei?.ka
        : this.initData?.avb?.ka
    },
  },
  methods: {
    ...mapActions([
      'LOAD_KEI_CRITERIA',
      'LOAD_AVB_CRITERIA',
      'LOAD_ANSWERS',
      'LOAD_ADD_KEI_CRITERIA',
      'LOAD_ADD_KEI_TEMPLATE',
      'GET_PLP_REPORT',
    ]),
    ...mapMutations(['SET_TEMPLATE_CRITERION']),
    removeAvbKeiExpertise(itemIndex) {
      this.data.ka.splice(itemIndex, 1)
      this.forceRender()
      this.doChange(true)
    },
    forceRender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    initNewData() {
      return { ka: [] }
    },
    doBlur() {
      this.$emit('blur')
    },
    doChange(force = false, lock = false) {
      this.disabledTab = lock
      if (!this.readonly)
        this.$emit('change', this.data, force, this.disabledTab)
    },
    async addExpertise() {
      const ln = this.data?.ka?.length || 0
      const expertiseDefault = copyObject(
        this.dt === 'KEI-MOD'
          ? copyObject(EX_KEI_DEFAULT)
          : copyObject(EX_AVB_DEFAULT)
      )
      const elements =
        this.dt === 'KEI-MOD'
          ? copyObject(await this.LOAD_KEI_CRITERIA())
          : copyObject(await this.LOAD_AVB_CRITERIA())

      //Костыль для старых КЭИ/АВБ, чтобы при добавлений новой КЭИ/АВБ не было 5 критерия
      if (this.storedPlpVersion === 1) {
        elements.splice(
          elements.findIndex(item => item.order === 5),
          1
        )
        elements.forEach(item => {
          if (item.order >= 6) item.order -= 1
        })
      }

      for (const key in elements) {
        let { name, order, id, tags, weight } = {
          ...copyObject(elements[key]),
        }
        expertiseDefault.elements.push({
          name,
          order,
          id,
          tags,
          weight,
          ...(this.dt === 'KEI-MOD'
            ? copyObject(EX_KEI_DEFAULT_DATA)
            : copyObject(EX_AVB_DEFAULT_DATA)),
        })
      }

      expertiseDefault.shortName = this.newExpertiseName
      this.data.ka.push(expertiseDefault)
      this.doChange(true, this.disabledTab)
      this.newExpertiseName = ''
      this.activeTab = ln + 1
    },
    async clickTemplate(element, tabIndex) {
      const { id, correspond, tagsSelected } = element

      const tags = []
      for (const item of tagsSelected) {
        const arr = [item?.values].flat().filter(v => v)
        arr.forEach(value => tags.push({ tag: item.name, value }))
      }

      const preparedData = {
        dict_criterion_id: id,
        is_matched: correspond,
        year: this.answerYear || null,
        tags,
      }

      this.SET_TEMPLATE_CRITERION({
        id,
        correspond,
        tagsSelected: copyObject(tagsSelected),
      })

      this.LOAD_ANSWERS(preparedData)
      this.template = { show: true, element, tabIndex, correspond }
    },
    updateAnswerYear() {
      const { element, tabIndex, correspond } = this.template

      if (tabIndex !== null && correspond !== null) {
        this.clickTemplate(element, tabIndex)
        ls.save('answerYear', this.answerYear)
      }
    },

    clickTemplateAdd({ id, element, key, index }) {
      this.SET_TEMPLATE_CRITERION({
        id,
      })
      this.templateAdd = { show: true, element, key, index }
    },
    onSelectTemplate(template) {
      // записали шаблон
      if (this.template.element) {
        this.template.element.criteria = template
        this.doChange(true, this.disabledTab)
      }
    },
    onSelectTemplateAdd(template) {
      const { element, key, index } = this.templateAdd || {}
      // записали шаблон
      if (element) {
        this.$set(element[key], index, template)
        this.doChange(true, this.disabledTab)
      }
    },
    onCloseTemplate() {
      // закрыли шаблонизатор
      this.template = { show: false, tabIndex: 0, element: null }
      this.templateAdd = { show: false, tabIndex: 0, element: null }
      // чистим жёлтый??
      const elem = this.$root.$el.querySelector('.active-field')
      if (elem) elem.classList.remove('active-field')
    },
  },
}
</script>

<style scoped></style>
