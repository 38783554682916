<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="light-green--text text-decoration-underline">
        {{ message }}
      </span>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
export default {
  name: 'DictExportedLog',
  components: { DefaultLog },
}
</script>
