import {
  EX_KEI_ANNOTATIONS_DEFAULT,
  EX_KEI_METHOD_DEFAULT,
} from '@/components/expAvbKei/const'

export const DEFAULT_KEI = () => ({
  shortName: '',
  qLevels: '',
  condition: '',
  method: {
    items: EX_KEI_METHOD_DEFAULT(),
    annotations: EX_KEI_ANNOTATIONS_DEFAULT(),
  },
  analogsCompare: '',
  clinicalAdvantages: '',
  econAdvantages: '',
  results: '',
})

export const DEFAULT_AVB = () => ({
  shortName: '',
  qLevels: '',
  condition: '',
  epidemiology: '',
  currentState: '',
  expectedState: '',
  results: '',
})

export const DEFAULT_CR_EFFECT = () => ({
  crScience: '',
  analogCompare: '',
  population: '',
  result: '',
})

export const DEFAULT_ADDITIONAL_INFO = () => ({
  crEffects: [DEFAULT_CR_EFFECT()],
  kei: [],
  avb: [],
})
