<script>
import { copyObject } from '@/lib/objects'
import { mapActions } from 'vuex'
import { copySummaryExp } from './const'

export default {
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    logView: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
    initData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    data: null,
  }),
  watch: {
    value: 'reInit',
    readonly: 'reInit',
    initData: 'reInit',
  },
  computed: {
    editMode() {
      return this.data && !this.readonly && !this.logView
    },
    needRefresh() {
      return !this.logView && !this.loading && this.showAlert
    },
    showAlert() {
      return false // по-умолчанию
    },
    editIcon() {
      return !this.readonly ? 'mdi-pencil' : null
    },
  },
  async created() {
    this.loading = true

    try {
      if (this.readonly) this.data = this.value
      else this.data = copyObject(this.value)

      // для проверки сущестоввания данных по экспертизам about, kei, avb
      if (!this.data && this.onCreate && this.initData) {
        console.log('need create expertise - summary')
        this.data = await this.onCreate(copySummaryExp(null, this.initData))
      }
    } catch (err) {
      this.SET_ERROR({
        head: 'Сводное заключение',
        text: 'Ошибка загрузки данных для создания представления о ЛП',
        err,
      })
      throw err
    } finally {
      this.loading = false
    }
  },
  mounted() {
    this.reInit()
  },
  methods: {
    ...mapActions(['SET_ERROR', 'COPY_EXPERTISE']),
    //обновляем данные
    reInit() {
      if (this.readonly && this.data !== this.value) {
        this.data = this.value
      }
      if (!this.readonly && this.initData) {
        this.$nextTick()
          .then(() => this.$refs.form?.validate())
          .catch(() => {})
      }
    },
    async refreshData() {
      if (!this.initData) return
      this.loading = true
      try {
        // создаём новую версию с обновлёнными данными
        await this.COPY_EXPERTISE(copySummaryExp(this.data, this.initData))
        this.errorArrNames = []
      } catch (err) {
        this.SET_ERROR({
          head: 'Представление о ЛП',
          text: 'Ошибка обновления данных представления о ЛП ',
          err,
        })
        throw err
      } finally {
        this.loading = false
      }
    },
    // данные изменились
    doChange(force = false) {
      if (!this.readonly) {
        this.$emit('change', this.data, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>
