<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="blue--text">
        {{ message }}
      </span>
    </template>
    <template #detail="{ logInfo }">
      <v-list-group :value="false">
        <template v-slot:activator>
          <v-list-item-subtitle> Типы экспертиз: </v-list-item-subtitle>
        </template>

        <v-list-item v-for="(i, idx) in logInfo.data.ex_types" :key="i" link>
          <v-list-item-subtitle>{{ idx }}:{{ i }}</v-list-item-subtitle>
        </v-list-item>
      </v-list-group>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'

export default {
  name: 'PlpReportLog',
  components: { DefaultLog },
  inheritAttrs: false,
}
</script>
