<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-data-table
          :items="safety"
          :headers="assessmentHeaders"
          show-expand
          single-select
          single-expand
          item-key="idx"
          :expanded.sync="expandedAssessments"
          disable-pagination
          hide-default-footer
          no-data-text="Информация о assessmentsData отсутствует"
        >
          <template #[`item.idx`]="{ item }">
            <span class="text--disabled">{{ item.idx }}.</span>
          </template>
          <template #[`item.sideEffect`]="{ value }">
            <span v-if="value" class="font-weight-medium">{{ value }}</span>
            <span v-else class="warning--text"> ( пусто ) </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="td-expanded pa-2">
              <SafetyEditor
                :value="item"
                @change="doChangeItem"
                @blur="doBlur"
                @delete="deleteAssessments(item.idx)"
                :readonly="readonly"
              />
            </td>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
            <td class="text-right">
              <v-btn
                icon
                @click="expand(!isExpanded)"
                class="v-data-table__expand-icon"
                :class="{ 'v-data-table__expand-icon--active': isExpanded }"
              >
                <v-icon>mdi-pencil-circle-outline</v-icon>
              </v-btn>
            </td>
          </template>
          <template #footer v-if="!readonly">
            <div class="d-flex pt-3">
              <div class="text-body-1">
                <span>Суммарная оценка: </span>
                <span class="font-weight-bold">{{ averageValue }}</span>
              </div>
              <v-spacer />
              <v-btn
                color="accent"
                fab
                small
                :disabled="!!readonly"
                @click="addAssessment()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="10" sm="6" md="3">
        <v-text-field
          label="Баллы ЭО"
          v-model="scoreEO"
          :outlined="!readonly"
          @input="doChangeEO()"
          @blur="doBlur()"
          hide-details
          type="number"
          :append-icon="!readonly ? 'mdi-pencil' : null"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <HelpCard
          caption="СПРАВОЧНАЯ ИНФОРМАЦИЯ – ШКАЛА БЕЗОПАСНОСТИ"
          class="my-2"
          outlined
          border
        >
          <SafetyAssessment />
        </HelpCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { DEFAULT_SAFETY } from '@/components/expSummary/summaryScore/assessmentConst'
import SafetyEditor from '@/components/expSummary/summaryScore/comp/SafetyEditor'
import HelpCard from '@/components/HelpCard'
import SafetyAssessment from '@/components/expSummary/scales/SafetyAssessment'
import ReferenceTip from '@/components/ReferenceTip'

export default {
  name: 'SafetyTable',
  components: { ReferenceTip, SafetyAssessment, HelpCard, SafetyEditor },
  data: function () {
    return {
      safety: null,
      expandedAssessments: [],
      scoreEO: '',
      scoreSafety: '',
      isCalculate: false,
      scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      assessmentHeaders: [
        {
          text: '№',
          value: 'idx',
          width: '10px',
          sortable: false,
        },
        {
          text: 'Название побочного эффекта',
          value: 'sideEffect',
          sortable: false,
        },
        {
          text: 'Оценка в баллах',
          value: 'pointsScore',
          sortable: false,
          width: '70px',
        },
        {
          text: 'Весовой коэффициент',
          value: 'weightFactor',
          sortable: false,
          width: '70px',
        },
        {
          text: 'Итоговая оценка',
          value: 'resultScore',
          sortable: false,
          width: '70px',
          cellClass: 'text--primary font-weight-bold',
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    averageValue() {
      return this.getAverageValue()
    },
  },
  watch: {
    value: 'init',
    averageValue(val) {
      if (val) this.$emit('update')
    },
  },
  created() {
    this.init(this.value)
  },
  mounted() {
    const firstItem = this.safety?.[0]
    if (!firstItem?.sideEffect) {
      this.expand(firstItem)
    }
  },
  methods: {
    doChangeEO() {
      this.$emit('update')
      this.doChange()
    },
    getAverageValue() {
      if (!this.safety?.length) return 0
      const sum = this.safety.reduce((acc, current) => {
        return Number(acc) + Number(current.resultScore)
      }, 0)
      return sum
    },
    init(value) {
      this.safety = copyObject(value?.safety || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
      this.scoreEO = value.scoreEO || 0
      this.scoreSafety = value.scoreSafety || 0
      this.isCalculate = value.isCalculate || false
    },
    doChangeItem(item, force = false) {
      const idx = item.idx - 1
      this.safety[idx] = item
      this.doChange(force)
    },
    doChange(force = false) {
      if (!this.readonly) {
        const outObj = {
          ...this.value,
          safety: this.safety,
          averageValue: this.getAverageValue(),
          scoreEO: this.scoreEO,
          scoreSafety: this.scoreSafety,
          isCalculate: this.isCalculate,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments(idx) {
      this.safety.splice(idx - 1, 1)
      this.$set(this, 'safety', this.safety)
      this.expand(null)
      this.doChange(true)
    },
    expand(item = null) {
      this.expandedAssessments = item ? [item] : []
    },
    addAssessment() {
      this.safety.push(DEFAULT_SAFETY())
      this.doChange(true)

      this.$nextTick(() => {
        const lg = this.safety?.length || 0
        this.expand(this.safety[lg - 1])
      })
    },
  },
}
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
