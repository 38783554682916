<template>
  <VersionSelect v-if="plp_task_id" fluid>
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <ExpertiseKEITabs
        :value="data"
        :readonly="readonly"
        :onCreate="onCreate"
        :dictType="dict"
        @change="onChange"
        @blur="onBlur"
      />
    </template>
  </VersionSelect>
</template>

<script>
import VersionSelect from '@/components/VersionSelect'
import ExpertiseKEITabs from '@/components/expAvbKei/ExpertiseTabsList'
import { mapGetters } from 'vuex'

export default {
  components: {
    VersionSelect,
    ExpertiseKEITabs,
  },
  data: () => ({
    dict: 'avb',
  }),
  computed: {
    ...mapGetters(['plp_task_id']),
  },
}
</script>
