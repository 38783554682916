<template>
  <v-container>
    <v-row>Критерий соответствия</v-row>
    <v-row dense class="d-flex rowParent mb-2">
      <v-col>
        <v-radio-group
          disabled
          hide-details
          row
          dense
          readonly
          v-model="compare_init.correspond"
          class="mb-4"
        >
          <v-radio color="green" :value="true" :label="EX_CORRESPOND" />
          <v-radio
            color="red"
            :value="false"
            readonly
            :label="EX_NOT_CORRESPOND"
          />
        </v-radio-group>
        <v-btn
          :disabled="sameCorrespond"
          text
          class="font-weight-bold pa-0 mt-4 eqBtn"
          x-small
          @click="equalyCorrespond"
          ><v-icon dense small left />Использовать<v-icon
            dense
            small
            right
            v-text="`mdi-chevron-right`"
            :color="color"
        /></v-btn>
        <same-icon class="iconRadioStyle" :same="sameCorrespond" />
      </v-col>
      <v-col
        ><v-radio-group
          hide-details
          row
          dense
          :readonly="readonly"
          v-model="compare_exp.correspond"
          @change="updateCorrespond()"
        >
          <v-radio color="green" :value="true" :label="EX_CORRESPOND" />
          <v-radio color="red" :value="false" :label="EX_NOT_CORRESPOND" />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="mt-2">Обоснование</v-row>
    <v-row dense class="d-flex rowParent">
      <v-col>
        <AutoGrowTextEditor
          full-width
          dense
          class="d-flex flex-column mr-2 pb-4"
          label="Обоснование"
          disabled
          v-model="compare_init.criteria"
          hide-details
          readonly
        ></AutoGrowTextEditor>
        <v-btn
          :disabled="sameCriteria"
          text
          class="font-weight-bold pa-0 mt-2 eqBtn"
          x-small
          @click="equalyCriteria"
          ><v-icon dense small left />Использовать<v-icon
            dense
            small
            right
            v-text="`mdi-chevron-right`"
            :color="color"
        /></v-btn>
        <same-icon :same="sameCriteria" class="iconCorStyle" />
      </v-col>
      <v-col>
        <AutoGrowTextEditor
          full-width
          dense
          label="Обоснование"
          v-model="compare_exp.criteria"
          class="d-flex flex-column pb-4 ml-2"
          hide-details
          ref="commentRef"
          @input="doChange()"
          @blur="doBlur()"
        ></AutoGrowTextEditor>
      </v-col>
    </v-row>
    <v-divider class="mt-2" />
    <v-row class="mt-2">Комментарий</v-row>

    <v-row dense class="rowParent">
      <v-col>
        <AutoGrowTextEditor
          full-width
          dense
          disabled
          rows="2"
          v-model="compare_init.comment"
          readonly
          hide-details
          class="d-flex flex-column mr-2 pb-4"
        ></AutoGrowTextEditor>
        <v-btn
          :disabled="sameComment"
          text
          class="font-weight-bold pa-0 ma-0 eqBtn"
          x-small
          @click="equalyComment"
          ><v-icon dense small left />Использовать<v-icon
            dense
            small
            right
            v-text="`mdi-chevron-right`"
            :color="color"
        /></v-btn>
        <same-icon :same="sameComment" class="iconCommentStyle" />
      </v-col>
      <v-col>
        <AutoGrowTextEditor
          rows="2"
          class="pb-4 ml-2"
          v-model="compare_exp.comment"
          hide-details
          dense
          @input="doChange()"
          @blur="doBlur()"
        ></AutoGrowTextEditor>
      </v-col>
    </v-row>
    <v-divider class="mt-2" />
  </v-container>
</template>

<script>
import SameIcon from '@/components/compare/SameIcon.vue'
import { copyObject, sameObject } from '@/lib/objects'
import { EX_CORRESPOND, EX_NOT_CORRESPOND } from '@/components/expAvbKei/const'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor.vue'

export default {
  name: 'CriteriaCompare',
  methods: {
    doChange(force = false) {
      this.$emit('change', this.compare_exp, force)
    },
    doBlur() {
      this.$emit('blur')
    },
    updateCorrespond() {
      this.$emit('update', this.compare_exp)
    },
    equaly() {
      this.compare_exp = copyObject(this.compare_init)
      this.doChange(true)
    },
    equalyCorrespond() {
      this.compare_exp.correspond = copyObject(this.compare_init.correspond)
      this.doChange(true)
    },
    equalyCriteria() {
      this.compare_exp.criteria = copyObject(this.compare_init.criteria)
      this.doChange(true)
    },
    equalyComment() {
      this.compare_exp.comment = copyObject(this.compare_init.comment)
      this.doChange(true)
    },
    init() {
      this.compare_init = copyObject(this.initObj || {})
      this.compare_exp = copyObject(this.expObj)
    },
  },
  watch: {
    initObj() {
      this.init()
    },
  },
  computed: {
    EX_CORRESPOND: () => EX_CORRESPOND,
    EX_NOT_CORRESPOND: () => EX_NOT_CORRESPOND,
    same() {
      return sameObject(this.compare_exp, this.compare_init)
    },
    sameCorrespond() {
      return this.compare_exp.correspond === this.compare_init.correspond
    },
    sameCriteria() {
      return this.compare_exp.criteria === this.compare_init.criteria
    },
    sameComment() {
      return this.compare_exp.comment === this.compare_init.comment
    },
    color() {
      return !this.same ? 'warning' : 'primary'
    },
  },
  components: { AutoGrowTextEditor, SameIcon },
  data: () => ({
    compare_init: null,
    compare_exp: null,
  }),
  created() {
    this.init()
  },
  props: {
    initObj: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    expObj: {
      type: Object,
      required: true,
    },
    elementIndex: {
      type: Number,
    },
    readonly: {
      type: Boolean,
    },
  },
}
</script>

<style scoped>
.iconStyle {
  position: absolute;
  left: 49%;
}
.iconCorStyle {
  position: absolute;
  left: 48%;
  bottom: 55%;
}
.iconCommentStyle {
  position: absolute;
  left: 48%;
  bottom: 50%;
}
.iconRadioStyle {
  position: absolute;
  left: 48%;
  bottom: 30%;
}
.rowParent {
  position: relative;
}
.eqBtn {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: -5px;
}
</style>
