<template>
  <v-card flat>
    <v-data-table
      dense
      :headers="safetyHeaders"
      :items="SAFETY"
      :group-by="['criteria']"
      hide-default-footer
      :items-per-page="100"
    >
      <template #[`group.header`]="{ headers, items }">
        <td :colspan="headers.length">
          <span v-if="items" class="text-overline"
            >{{ items[0].criteria }}.
          </span>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
const SAFETY = [
  {
    criteria:
      'Наличие или целесообразность включения лекарственного препарата в клинические рекомендации или стандарты оказания медицинской помощи',
    result: 'Да, включен/ Нет, включение целесообразно',
    scale: 3,
  },
  {
    criteria:
      'Наличие или целесообразность включения лекарственного препарата в клинические рекомендации или стандарты оказания медицинской помощи',
    result: 'Нет',
    scale: 0,
  },
]
export default {
  name: 'NewFeasibility',
  data: () => ({
    SAFETY,
    safetyHeaders: [
      {
        text: 'Критерий',
        value: 'criteria',
        sortable: false,
      },
      {
        text: 'Результат',
        value: 'result',
        sortable: false,
      },
      {
        text: 'Шкала',
        value: 'scale',
        sortable: false,
      },
    ],
  }),
}
</script>

<style scoped></style>
