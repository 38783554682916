// Установили текущую версию данных
import { copyObject } from '@/lib/objects'

import { SUB_EX_SUMMARY, SUB_VERSION_MIGRATION } from '@/store/const/expertise'

import { ASSESSMENT_ORGANIZATION } from './summaryScore/assessmentConst'
import {
  DEFAULT_RELEASE_FORM,
  EX_PRICE_CHARACTERISTICS,
} from './summaryPurchase/purchaseConst'
import { DEFAULT_GVS_FORM } from './summaryGVS/gvsConst'
import { DEFAULT_ADDITIONAL_INFO } from './summaryAdditional/addConst'
import { createSummaryKeiCopy } from './summaryAdditional/keiUtils'
import { createSummaryAvbCopy } from './summaryAdditional/avbUtils'
import { collectCRList } from '@/components/expSummary/summaryFunctions'

// версия набора данных экспертизы
export const EX_SUMMARY_TEMPLATE_VERSION = 1

// ГЛАВНЫЙ ШАБЛОН ЭКСПЕРТИЗЫ
export const EX_SUMMARY_TEMPLATE = {
  mnn: '',
  gnvlp: false,
  vzn14: false,
  lForma: '',
  ruDate: '',
  isOrphan: false,
  crNames: '',
  condition: '',
  // company: '',
  purchaseData: {
    year: '', //Год
    subjects: '', //Субъекты
    //Формы выпуска
    releaseForms: [DEFAULT_RELEASE_FORM()],
  }, //Данные по закупке
  priceCharacteristics: [EX_PRICE_CHARACTERISTICS()], //Ценовая характеристика ЛП
  assessmentOrganization: ASSESSMENT_ORGANIZATION(), //Оценка экспертной организаций
  gvs: [DEFAULT_GVS_FORM()], //оценка ГВС
  additionalInfo: DEFAULT_ADDITIONAL_INFO(), //Дополнительная информация.
}

/** Копируем данные initData - поверх oldData */
export function copySummaryExp(
  oldData,
  { about, other, kei, avb, analog } = {}
) {
  const data = copyObject({
    ...EX_SUMMARY_TEMPLATE,
    ...(oldData || EX_SUMMARY_TEMPLATE),
  })

  data.mnn = about?.mnn || ''
  data.gnvlp = about?.gnvlp || false
  data.vzn14 = about?.vzn14 || false
  data.lForma = about?.lForma || ''
  data.ruDate = about?.ruDate || ''
  data.isOrphan = about?.isOrphan || false
  data.condition = about?.condition || ''
  data.crNames = collectCRList(analog?.CR, analog?.AL)
  if (!data.additionalInfo) data.additionalInfo = DEFAULT_ADDITIONAL_INFO()
  data.additionalInfo.kei = createSummaryKeiCopy(data.additionalInfo.kei, kei)
  data.additionalInfo.avb = createSummaryAvbCopy(data.additionalInfo.avb, avb)

  data.gvs.forEach(item => (item.scoreFGBU = other?.sum || 0))

  return data
}

// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_SUMMARY].v = EX_SUMMARY_TEMPLATE_VERSION
