<template>
  <v-container>
    <v-row dense v-for="dl in dts" :key="dl.id">
      <v-col cols="12" class="text=subtitle">
        ● {{ dl.deadlineType.title }}
      </v-col>
      <v-col cols="12" lg="6">
        <SelectDate
          v-model="dl.first_date"
          :readlonly="readlonly"
          :disabled="!isManager"
          :minDate="minDate"
          label="Крайний срок"
          ref="date"
          @input="value => changeDate(value, dl)"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <SelectDate
          v-model="dl.last_date"
          :readlonly="readlonly"
          :disabled="!isManager"
          :minDate="minDate"
          label="Крайний срок отправки в МЗ"
          ref="date"
          @input="value => changeDate(value, dl)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectDate from '@/components/editors/SelectDate.vue'
import DateForPicker from '@/lib/datetime'
import { copyObject } from '@/lib/objects'

export default {
  name: 'DeadlinesList',
  components: {
    SelectDate,
  },
  data: () => ({
    dts: [],
    minDate: new DateForPicker().toISO8601date,
  }),
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    readlonly: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    this.dts = copyObject(this.data)
  },

  computed: {
    ...mapGetters(['isManager']),
  },
  methods: {
    changeDate() {
      this.$emit('change', this.dts)
    },
  },
}
</script>
