<template>
  <v-dialog v-model="showing" width="650" persistent>
    <v-card>
      <v-card-title v-if="item">Редактировать КР</v-card-title>
      <v-card-title v-else>Добавить новый КР</v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Краткое название"
                dense
                v-model="formData.shortName"
                class="mb-0"
                :maxlength="maxShortName"
                :rules="[checkShortName]"
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Название"
                v-model="formData.name"
                :rules="rulesName"
                class="mb-4"
              >
                <template #append-outer>
                  <v-btn icon @click="dialogCrShow = true">
                    <v-icon>mdi-text-search</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-checkbox
                label="Размещена на рубрикаторе"
                dense
                v-model="formData.isOnRubricator"
                hide-details
                class="ma-0 pa-0 mb-2"
              />
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-checkbox
                label="Одобрена НПС"
                dense
                v-model="formData.isApprovedNPS"
                hide-details
                class="ma-0 pa-0 mb-2"
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-textarea
                label="Ассоциации"
                dense
                v-model="formData.associations"
                hide-details
                class="mb-4"
                rows="3"
                no-resize
              />
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-text-field
                label="№ КР"
                dense
                v-model="formData.crNumber"
                hide-details
                class="mb-4"
              />
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-select
                :items="filterYears"
                label="год"
                dense
                clearable
                type="number"
                v-model="formData.year"
                hide-details
                class="mb-8"
              />
            </v-col>
          </v-row>

          <v-row v-show="!deleteRequest">
            <v-col cols="6">
              <v-btn color="primary" text @click="updateItem">
                СОХРАНИТЬ
              </v-btn>
              <v-btn color="primary" text @click="closeDialog"> ЗАКРЫТЬ </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn
                v-if="canDelete"
                color="error"
                text
                @click="deleteRequest = !deleteRequest"
              >
                УДАЛИТЬ
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-show="deleteRequest">
            <v-col cols="6" class="delete-request">
              Внимание! Удалить КР?
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn text @click="deleteRequest = !deleteRequest">
                ОТМЕНА
              </v-btn>
              <v-btn color="error" text @click="removeItem"> УДАЛИТЬ </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog scrollable width="1000" v-model="dialogCrShow">
      <CrTable select-mode @close="dialogCrShow = false" @save="saveCr" />
    </v-dialog>
  </v-dialog>
</template>

<script>
import { copyObject } from '@/lib/objects'
import {
  ANALOG_CR_DATA,
  ANALOG_CR_YEAR_START,
  SHORT_NAME_LENGTH,
} from './const'
import CrTable from '@/components/dict/cr/CrTable.vue'

export default {
  components: { CrTable },
  props: {
    item: {
      type: Object,
      default: null,
    },
    CR: {
      type: Array,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    deleteRequest: false,
    dialogShow: false,
    formData: null,
    confirmDelete: false,
    rulesName: [v => v?.length > 0 || 'Это поле должно быть заполнено'],
    maxShortName: SHORT_NAME_LENGTH,
    dialogCrShow: false,
    dialogCrItem: null,
  }),
  created() {
    this.formData = copyObject(this.item) || ANALOG_CR_DATA()
    this.dialogShow = this.show
  },
  watch: {
    item(val) {
      this.formData = copyObject(val) || ANALOG_CR_DATA()
    },
    show(val) {
      this.dialogShow = val
    },
  },
  computed: {
    filterYears() {
      const year = new Date().getFullYear()
      const filledArray = []

      for (let i = year; i >= ANALOG_CR_YEAR_START; i--) {
        filledArray.push(i)
      }

      if (this.formData?.year && !filledArray.includes(this.formData.year))
        filledArray.push(this.formData.year)

      return filledArray
    },
    showing: {
      get() {
        return this.dialogShow
      },
      set(value) {
        this.dialogShow = value
        this.closeDialog()
      },
    },
    canDelete() {
      return !!this.item
    },
  },
  methods: {
    checkShortName(value) {
      const f = this.CR.findIndex(
        (item, index) =>
          item.shortName === value && index !== parseInt(this.formData.key)
      )
      if (value.length === 0) return 'Это поле должно быть заполнено'
      if (f > -1) return 'Такое наименование уже есть'
      return true
    },
    removeItem() {
      this.deleteRequest = !this.deleteRequest
      this.$refs.form.resetValidation()
      this.$emit('removeItem', this.formData)
    },
    updateItem() {
      if (!this.$refs.form.validate()) return
      this.$refs.form.resetValidation()
      this.$emit('updateItem', this.formData)
    },
    closeDialog() {
      this.$refs.form.resetValidation()
      this.$emit('closeDialog')
    },
    saveCr(item) {
      this.dialogCrItem = item
      this.formData.shortName = item.shortName
      this.formData.name = item.title
      this.formData.isOnRubricator = !!item.onRubricator
      this.formData.isApprovedNPS = !!item.npc
      this.formData.associations = item.association
      this.formData.crNumber = item.number
      this.formData.year = item.year
    },
  },
}
</script>

<style scoped>
.delete-request {
  color: red;
  font-size: x-large;
}
</style>
