<template>
  <v-card class="my-4">
    <v-tabs v-model="activeTab">
      <v-tab
        v-for="(item, tabIndex) in count"
        :key="tabIndex"
        class="font-weight-bold"
        ><span v-if="tabIndex < costs.length">
          {{ costs[tabIndex].name }}
        </span>
        <span class="error--text" v-else> Показание {{ tabIndex + 1 }} </span>
      </v-tab>
      <v-tab-item
        v-for="(item, itemIndex) in count"
        :key="itemIndex"
        class="pa-2"
        eager
      >
        <CostTable
          v-if="costs[itemIndex]"
          :value="costs[itemIndex]"
          :calculations="calculations[itemIndex]"
          :editMode="editMode"
          :readonly="readonly"
          :mnn="mnn"
          :condition="condition"
          @change="(data, force) => doChange(data, itemIndex, force)"
          @blur="doBlur"
        />
        <v-alert v-else type="error" outlined
          >Информация для показания {{ itemIndex + 1 }} у
          {{ costLabel }} отсутствует в документе</v-alert
        >
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import CostTable from '@/components/expCost/costTable/CostTable'

export default {
  name: 'CostTabs',
  components: { CostTable },
  data: () => ({
    activeTab: 0,
    costs: [],
    newPricingName: '',
  }),
  props: {
    value: { type: Array, default: () => [] },
    calculations: { type: Array, default: () => [] },
    editMode: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    vzn: {
      type: Boolean,
      default: false,
    },
    mnn: { type: String },
    condition: { type: String },
    count: { type: Number, default: 0 },
  },
  created() {
    this.init(this.value)
  },
  watch: {
    value: 'init',
  },
  computed: {
    typeCost() {
      return this.vzn ? 'VznTable' : 'GnvlpTable'
    },
    costLabel() {
      return this.vzn ? '14ВЗН' : 'ЖНВЛП'
    },
  },
  methods: {
    init(value) {
      this.costs = value
    },
    doChange(data, idx, force = false) {
      this.$emit('change', data, idx, force)
    },
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>
