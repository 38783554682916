<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          v-model="crScience"
          :outlined="!readonly"
          label="Клиническое исследование"
          hint="Например, РКИ Shapiro S. et al., 2020"
          placeholder="Дизайн, ФИО, год"
          prepend-icon="mdi-text-box-search-outline"
          @input="doChange()"
          @blur="doBlur()"
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="analogCompare"
          :items="scores"
          :outlined="!readonly"
          label="Препарат сравнения"
          prepend-icon="mdi-book-open-page-variant-outline"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
      /></v-col>
      <v-col cols="12">
        <v-text-field
          v-model="population"
          :items="scores"
          :outlined="!readonly"
          label="Популяция"
          prepend-icon="mdi-book-open-blank-variant"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="result"
          :outlined="!readonly"
          rows="4"
          no-resize
          auto-grow
          label="Результат"
          prepend-icon="mdi-book-open-variant"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
        />
      </v-col>
    </v-row>
    <v-row v-if="!readonly">
      <v-col>
        <v-btn color="primary" text @click="showDlg = !showDlg">Шаблоны</v-btn>
      </v-col>
      <v-col class="text-right">
        <btn-with-confirm
          text
          icon-main="mdi-delete-forever-outline"
          title="Удалить препарат"
          @click="deleteAssessments"
          :readonly="readonly"
        >
          Удалить
        </btn-with-confirm>
      </v-col>
    </v-row>
    <OverlayResults
      :show="showDlg"
      @close="showDlg = false"
      @add="changeResult"
    />
  </v-container>
</template>

<script>
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import OverlayResults from '@/components/expSummary/templates/OverlayResults'

export default {
  name: 'AdditionalEditor',
  components: { OverlayResults, BtnWithConfirm },
  data: () => ({
    showDlg: false,
    crScience: '',
    analogCompare: '',
    population: '',
    result: '',
    scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) {
        const { crScience, analogCompare, population, result } = this
        const outObj = {
          ...this.value,
          crScience,
          analogCompare,
          population,
          result,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.doChange(true)
    },
    deleteAssessments() {
      this.$emit('delete')
    },
    init() {
      this.crScience = this.value?.crScience || ''
      this.analogCompare = this.value?.analogCompare || ''
      this.population = this.value?.population || ''
      this.result = this.value?.result || ''
    },
    onCloseResult() {
      this.showDlg = false
    },
    changeResult(result) {
      this.result = String(this.result?.trimRight() + '\n' + result).trim()
      this.doChange(true)
    },
  },
}
</script>
