var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Оценка АВБ ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.avbHeaders,"items":_vm.AVB,"hide-default-footer":"","items-per-page":100},scopedSlots:_vm._u([{key:"item.percent",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(item)+" "),_c('hr')])})}},{key:"item.score",fn:function(ref){
var value = ref.value;
return _vm._l((value),function(item){return _c('div',{key:item.id},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item))]),_c('hr')])})}},{key:"item.result",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.percent.length || item.score.length)?_c('span',[_vm._v(" "+_vm._s(value)+" ")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }