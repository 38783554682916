<template>
  <v-dialog width="750px" persistent :value="true">
    <v-card class="pa-4">
      <v-form @submit.prevent="submitHandler" ref="form" v-model="valid">
        <v-card-title>{{ actionTitle }}</v-card-title>
        <v-card-text>
          <AtcTextEditor
            v-model.trim="formData.ath"
            title="ATH"
            @blur="onBlur"
            @input="athExists = true"
            :maxlength="5"
            :loading="checkInProgress"
            :error-messages="
              !athExists
                ? `Внимание! Кода АТХ ${formData.ath} не существует`
                : ''
            "
            :messages="
              athFromMnn && athFromMnn !== formData.ath
                ? `Внимание! Для мнн: '${formData.mnn}', атх: ${athFromMnn}`
                : ''
            "
            clearable
          />
          <ComboboxMNN
            :isOpenForAdd="isOpenForAdd"
            :value="formData"
            :items="dictMedicineAtc5Mnn"
            :loading="dictMedicineAtc5MnnLoading"
            @updateMNN="updateMNN"
            clearable
            hint="Данные из справочника ЛП (только ЖВНЛП)"
            persistent-hint
            @click:clear="formData.ath = ''"
          />
          <v-alert
            v-if="error_message"
            dismissible
            type="error"
            outlined
            class="mb-0"
          >
            <span v-html="error_message" />
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            color="primary"
            class="mr-4"
            small
            :loading="saveInProgress"
            :disabled="saveDisabled"
            >Сохранить</v-btn
          >
          <v-btn @click="closeDialog" class="mr-4" small>Отменить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { copyObject } from '@/lib/objects'
import AtcTextEditor from '@/components/editors/AtcTextEditor'
import { mapActions, mapGetters } from 'vuex'
import ComboboxMNN from '@/components/expAnalogs/ComboboxMNN'

const TEMPLATE_FORM_DATA = () => ({
  id: '',
  ath: '',
  mnn: '',
})

export default {
  name: 'MedicineIndicationsForm',
  components: { ComboboxMNN, AtcTextEditor },
  props: {
    isOpenForAdd: {
      type: Boolean,
      default: false,
    },
    gnvlp: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      formData: TEMPLATE_FORM_DATA(),
      athFromMnn: '',
      saveInProgress: false,
      error_message: '',
      valid: false, // v-model для самой формы, чтобы валидировать всю форму
      athExists: true, // признак проверки, что АТХ правильный
      checkInProgress: false, // признак проверки АТХ, что она в процессе
      dictMedicineAtc5MnnLoading: false, // загрузка справочника АТХ - МНН
    }
  },
  computed: {
    ...mapGetters(['dictMedicineAtc5Mnn']),
    actionTitle() {
      return this.isOpenForAdd
        ? 'Добавить лекарственный препарат'
        : 'Редактировать лекарственный препарат'
    },
    saveDisabled() {
      return (
        this.saveInProgress ||
        !this.valid ||
        !this.athExists ||
        this.checkInProgress
      )
    },
  },
  methods: {
    ...mapActions([
      'EDIT_GNVLP_DICT',
      'ADD_MED_TO_GVNLP_DICT',
      'GET_MEDICINE_DICT',
      'CHECK_ATH_EXISTS',
    ]),
    submitHandler() {
      if (this.isOpenForAdd) this.add()
      else this.save()
    },
    async save() {
      if (!this.formData.ath || !this.formData.mnn) return
      this.error_message = ''
      this.saveInProgress = true
      try {
        const medication = this.formData
        await this.EDIT_GNVLP_DICT({ medication })
        this.closeDialog()
      } catch (err) {
        if (err.message.includes('Duplicate entry'))
          this.error_message =
            'Ошибка сохранения препарата, т.к. он уже существует в справочнике показаний!'
        else this.error_message = err
      } finally {
        this.saveInProgress = false
      }
    },
    async add() {
      if (!this.formData.ath || !this.formData.mnn) return
      this.error_message = ''
      this.saveInProgress = true
      try {
        await this.ADD_MED_TO_GVNLP_DICT(this.formData)
        this.closeDialog()
      } catch (err) {
        if (err.message.includes('Duplicate entry'))
          this.error_message =
            'Ошибка добавления нового препарата, т.к. он уже существует в справочнике показаний!'
        else this.error_message = err
      } finally {
        this.saveInProgress = false
      }
    },
    closeDialog() {
      this.formData.ath = ''
      this.$emit('close-form')
    },
    updateMNN(data) {
      this.athFromMnn = data?.ath ?? ''
      if (!this.formData.ath) {
        this.formData.ath = data?.ath ?? ''
      }
      this.formData.mnn = data?.mnn ? data.mnn : data
      this.error_message = ''
      this.onBlur()
    },
    async onBlur() {
      this.athExists = true
      if (!this.formData.ath) return

      this.checkInProgress = true
      try {
        await this.CHECK_ATH_EXISTS(this.formData.ath)
      } catch (err) {
        this.athExists = false
      } finally {
        this.checkInProgress = false
      }
    },
  },
  async created() {
    this.dictMedicineAtc5MnnLoading = true
    try {
      if (!this.gnvlp) {
        await this.GET_MEDICINE_DICT()
        return
      }
      this.formData = copyObject(this.gnvlp)
      await this.GET_MEDICINE_DICT()
    } finally {
      this.dictMedicineAtc5MnnLoading = false
    }
  },
  mounted() {
    if (!this.isOpenForAdd) this.onBlur()
  },
}
</script>
