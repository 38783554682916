<template>
  <div v-if="docReady">
    <v-overlay
      :z-index="zIndex"
      :value="show"
      @click="close()"
      class="text-center"
    >
      <v-progress-circular v-if="!docReady" indeterminate size="64" />
    </v-overlay>
    <!-- панель шаблонизатора -->
    <div class="l-panel" :class="{ 'l-panel-open': show }">
      <v-card elevation="0">
        <v-app-bar dark color="primary" height="48px" class="rounded-0">
          <v-toolbar-title>Справочники</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text>
          <v-tabs :value="tabIndex">
            <v-tab v-if="!hideTemplates">Шаблонные фразы</v-tab>
            <v-tab v-if="!hideAnswers">Ответы</v-tab>

            <v-tab-item v-if="!hideTemplates">
              <div class="template">
                <v-container>
                  <v-row>
                    <v-col cols="12" v-if="questions.length">
                      <span class="text-caption">Фильтр </span>
                      <v-combobox
                        v-for="(question, idx) in questions"
                        :key="idx"
                        :label="question"
                        v-model="answers[idx]"
                        :items="answersList[idx]"
                        @change="showQuestion(idx + 1)"
                        clearable
                        @click:clear="showQuestion(idx)"
                      ></v-combobox>
                      <v-divider />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="dictSearch"
                        label="Поиск"
                        hide-details
                        class="mb-4"
                        append-outer-icon="mdi-magnify"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col />
                    <v-col
                      :class="matched === false ? 'red--text' : 'success--text'"
                      class="text-right font-weight-bold"
                      v-if="matched !== null"
                    >
                      Критерий
                      <label v-if="matched === false">не</label> соответствует
                    </v-col>
                  </v-row>
                </v-container>
                <v-data-table
                  :items="selectTemplate"
                  :headers="dictHeader"
                  :search="dictSearch"
                  hide-default-footer
                  hide-default-header
                  disable-pagination
                >
                  <template #[`item.template`]="{ item }">
                    <div class="text-justify pt-2 red-line text-pre-wrap">
                      {{ item.template }}
                    </div>
                    <div class="text-right" v-if="!hideSelectBtn">
                      <v-btn
                        text
                        x-small
                        color="success"
                        class="mb-1"
                        @click="selectCriteriaTemplate(null, { item })"
                        >выбрать</v-btn
                      >
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-tab-item>
            <v-tab-item v-if="!hideAnswers">
              <v-container>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="answerSearch"
                      label="Поиск"
                      hide-details
                      class="mb-4"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="selectedYear"
                      :items="filterYears"
                      label="Фильтр по году"
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col />
                  <v-col
                    :class="matched === false ? 'red--text' : 'success--text'"
                    class="text-right font-weight-bold"
                    v-if="matched !== null"
                  >
                    Критерий
                    <label v-if="matched === false">не</label> соответствует
                  </v-col>
                </v-row>
              </v-container>
              <v-data-table
                :items="preparedAnswer"
                :headers="answerHeader"
                :search="answerSearch"
                group-by="year"
                hide-default-header
                hide-default-footer
                disable-pagination
                height="calc(100vh - 230px)"
                :loading="loadingAnswers"
                loading-text="Ищу ответы..."
              >
                <template v-slot:[`group.header`]="{ headers, group }">
                  <td :colspan="headers.length">
                    <div class="font-weight-bold pa-2">Год: {{ group }}</div>
                  </td>
                </template>

                <template #[`item.mnn`]><!-- пустышка --></template>

                <template #[`item.answer`]="{ item }">
                  <div class="py-3 font-weight-medium">
                    <span
                      :inner-html.prop="item.mnn | highlight(answerSearch)"
                    />
                    <div class="float-right">
                      {{ item.year }} / {{ item.quarter }}
                    </div>
                  </div>

                  <div class="text-justify red-line text-pre-wrap">
                    <span
                      :inner-html.prop="item.answer | highlight(answerSearch)"
                    />
                  </div>

                  <div class="py-3 font-weight-bold">
                    Теги:
                    <span
                      class="font-weight-light"
                      v-for="(item, index) of formatTags(item.tags)"
                      :key="index"
                    >
                      <span class="font-weight-medium">{{ index }} </span>
                      <span
                        class="font-weight-bold"
                        style="color: lightseagreen"
                        >[{{ item }}]
                      </span>
                    </span>
                  </div>

                  <div class="text-right" v-if="!hideSelectBtn">
                    <v-btn
                      text
                      x-small
                      color="success"
                      class="mb-1"
                      @click="selectCriteriaAnswer(null, { item })"
                      >выбрать</v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EX_ANSWER_YEAR_QUANTITY } from '@/store/const/expDefaults'
import { copyObject } from '@/lib/objects'

export default {
  name: 'Over-lay',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    correspond: {
      default: null,
    },
    dict: {
      type: String,
      default: '',
      required: true,
    },
    autoclose: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    hideAnswers: {
      type: Boolean,
      default: false,
    },
    hideTemplates: {
      type: Boolean,
      default: false,
    },
    hideSelectBtn: {
      type: Boolean,
      default: false,
    },
    year: {
      default: null,
    },
  },
  data: () => ({
    matched: null,
    zIndex: 99,
    selectAnswer: '',
    questions: [],
    answersList: [],
    answers: [],
    dictSearch: '',
    answerSearch: '',
    dictHeader: [{ text: 'Текст', value: 'template' }],
    answerHeader: [
      { text: 'Текст', value: 'answer' },
      { value: 'year', groupable: true },
      { value: 'mnn', width: '0px', cellClass: 'pa-0' },
    ],
    docReady: false,
    dictType: null,
    selectedYear: null,
  }),
  created() {
    this.dictType = this.dict.toUpperCase()
    this.selectedYear = Number(this.year)
    this.load_dict()
  },
  computed: {
    ...mapGetters([
      'getAvbTemplate',
      'getKeiTemplate',
      'getAddKeiTemplate',
      'storedAnswersData',
      'loadingAnswers',
    ]),
    filterYears() {
      const year = new Date().getFullYear()
      const filledArray = []

      for (let i = 0; i < EX_ANSWER_YEAR_QUANTITY; i++) {
        filledArray.push(year - i)
      }

      return filledArray
    },

    preparedAnswer() {
      const answer = this.storedAnswersData
      return this.selectedYear
        ? answer.filter(i => i.year === this.selectedYear)
        : answer
    },

    selectTemplate() {
      let selectTemplate = this.getAddKeiTemplate // if KEI-add
      if (this.dictType === 'AVB') selectTemplate = this.getAvbTemplate
      if (this.dictType === 'KEI') selectTemplate = this.getKeiTemplate

      const filterTemplates = []
      selectTemplate?.forEach(template => {
        for (let i = 0; i < this.questions.length; i++) {
          if (
            this.answers[i] &&
            template?.data?.filter[i]?.answer !== this.answers[i]
          ) {
            return
          }
        }
        filterTemplates.push(template)
      })
      return filterTemplates
    },
  },
  watch: {
    correspond(val) {
      this.matched = val
    },
    show(val) {
      if (val) {
        this.showDrawer()
        this.showQuestion()
      } else {
        this.clearQuestion()
        this.hideDrawer()
      }
    },
    year(newYear) {
      this.selectedYear = Number(newYear)
    },
    selectedYear(newYear) {
      this.$emit('update:year', newYear)
    },
  },
  methods: {
    ...mapActions(['LOAD_AVB_TEMPLATE', 'LOAD_KEI_TEMPLATE']),
    showQuestion(i = 0) {
      for (let j = 0; j < i; j++) {
        if (!this.answers[j]) return
      }
      if (this.selectTemplate?.length) {
        const ques = this.selectTemplate[0].data?.filter?.[i]?.question
        if (!ques) return

        this.answers = [...this.answers.slice(0, i), null]

        const answersItems = this.filteredAnswersTemplate(i)
        this.answersList = [...this.answersList.slice(0, i), answersItems]
        this.questions = [...this.questions.slice(0, i), ques]
      }
    },
    clearQuestion() {
      this.questions = []
      this.answersList = []
      this.answers = []
    },
    filteredAnswersTemplate(i) {
      const answers = new Set()
      this.selectTemplate?.forEach(template => {
        const answer = template.data?.filter?.[i]?.answer
        if (answer) answers.add(answer)
      })

      return [...answers.values()]
    },
    formatTags(tags) {
      if (!tags) return 'Нет тегов'
      tags = copyObject(tags).sort((a, b) => {
        return a.tag?.localeCompare(b.tag)
      })
      return tags.reduce((tt, item) => {
        tt[item.tag] = (tt[item.tag] ? tt[item.tag] + ', ' : '') + item.value
        return tt
      }, {})
    },

    async showDrawer() {
      let html = document.getElementsByTagName('html')[0]
      if (html) html.style.overflow = 'hidden'
    },

    hideDrawer() {
      let html = document.getElementsByTagName('html')[0]
      if (html) html.style.overflow = null
    },

    close() {
      this.hideDrawer()
      this.$emit('close')
    },

    selectCriteriaTemplate(_, { item }) {
      // что то выбрали кидаем событие
      this.$emit('select', item.template)

      if (this.autoclose) this.close()
    },

    selectCriteriaAnswer(_, { item }) {
      // что то выбрали кидаем событие
      this.$emit('select', item.answer)

      if (this.autoclose) this.close()
    },

    async load_dict() {
      if (this.dictType === 'AVB') await this.LOAD_AVB_TEMPLATE()
      if (this.dictType === 'KEI') await this.LOAD_KEI_TEMPLATE()
      this.docReady = true
    },
  },
}
</script>

<style scoped>
.l-panel-open {
  transform: translateX(0%) !important;
}
.l-panel {
  position: fixed;
  width: 45%;
  height: 100vh;
  background-color: white;
  z-index: 100;
  top: 0;
  right: 0;
  border-left: 1px solid gray;
  transform: translateX(100%);
  transition: 0.2s;
}
.template {
  overflow-y: scroll;
  height: calc(100vh - 120px);
}
</style>
