<template>
  <OverlayTemplate
    v-bind="$attrs"
    :config="templates"
    @add="addResult"
    @close="$emit('close', $event)"
  />
</template>

<script>
import OverlayTemplate from './OverlayTemplate'
import {
  SCORE_KEIAVB_TEMPLATES,
  SCORE_KI_TEMPLATES,
  SCORE_OTHER_TEMPLATES,
  SCORE_SAFETY_TEMPLATES,
} from '@/components/expSummary/templates/summaryTemplates'

export default {
  name: 'OverlayResults',
  components: { OverlayTemplate },
  data: () => ({
    templates: [
      { header: 'Качество КИ', templates: SCORE_KI_TEMPLATES },
      { header: 'Оценка безопасности', templates: SCORE_SAFETY_TEMPLATES },
      { header: 'КЭИ_АВБ', templates: SCORE_KEIAVB_TEMPLATES },
      { header: 'Прочие данные', templates: SCORE_OTHER_TEMPLATES },
    ],
  }),
  methods: {
    addResult(idx, template) {
      // const out = this.result.trimRight() + '\n' + template
      this.$emit('add', template)
    },
  },
}
</script>
