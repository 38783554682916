<template>
  <v-container fluid>
    <v-row dense>
      <v-col>
        <v-data-table
          :items="quality"
          :headers="assessmentHeaders"
          show-expand
          single-select
          single-expand
          item-key="idx"
          :expanded.sync="expandedAssessments"
          disable-pagination
          hide-default-footer
          no-data-text="Информация о assessmentsData отсутствует"
        >
          <template #[`item.idx`]="{ item }">
            <span class="text--disabled">{{ item.idx }}.</span>
          </template>
          <template #[`item.nameKi`]="{ value }">
            <span v-if="value" class="font-weight-medium">{{ value }}</span>
            <span v-else class="warning--text"> ( пусто ) </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td v-if="item" :colspan="headers.length" class="td-expanded pa-2">
              <quality-editor
                :value="item"
                :readonly="readonly"
                @change="doChangeItem"
                @delete="deleteAssessments(item.idx)"
                @blur="doBlur"
              />
            </td>
          </template>
          <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
            <td class="text-right">
              <v-btn
                class="v-data-table__expand-icon"
                :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                @click="expand(!isExpanded)"
                icon
              >
                <v-icon>mdi-pencil-circle-outline</v-icon>
              </v-btn>
            </td>
          </template>
          <template #footer v-if="!readonly">
            <div class="d-flex pt-3">
              <div class="text-body-1">
                <span>Среднее значение: </span>
                <span class="font-weight-bold">{{ averageValue }}</span>
              </div>
              <v-spacer />
              <v-btn
                color="accent"
                fab
                small
                :disabled="!!readonly"
                @click="addAssessment()"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="10" sm="6" md="3">
        <v-text-field
          label="Баллы ЭО"
          v-model="scoreEO"
          :outlined="!readonly"
          @input="doChangeEO()"
          @blur="doBlur()"
          hide-details
          type="number"
          :append-icon="!readonly ? 'mdi-pencil' : null"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <HelpCard
          caption="СПРАВОЧНАЯ ИНФОРМАЦИЯ– ШКАЛА ОЦЕНКИ КАЧЕСТВА КИ"
          class="my-2"
          outlined
          border
        >
          <ClinicalTrials class="mb-3" />
          <LevelEvidence />
          <ClinicalAddTrials />
        </HelpCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { DEFAULT_QUALITY } from '@/components/expSummary/summaryScore/assessmentConst'
import QualityEditor from '@/components/expSummary/summaryScore/comp/QualityEditor'
import HelpCard from '@/components/HelpCard'
import ClinicalTrials from '@/components/expSummary/scales/ClinicalTrials'
import LevelEvidence from '@/components/expSummary/scales/LevelEvidence'
import ClinicalAddTrials from '@/components/expSummary/scales/ClinicalAddTrials'

export default {
  name: 'QualityTable',
  components: {
    LevelEvidence,
    ClinicalTrials,
    HelpCard,
    QualityEditor,
    ClinicalAddTrials,
  },
  data: function () {
    return {
      quality: [],
      scoreEO: '',
      scoreQuality: '',
      expandedAssessments: [],
      scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      assessmentHeaders: [
        {
          text: '№',
          value: 'idx',
          width: '10px',
          sortable: false,
        },
        {
          text: 'Название КИ (дизайн + автор + год) ',
          value: 'nameKi',
          sortable: false,
        },
        {
          text: 'Оценка по уровню доказательности',
          value: 'evidencesScore',
          sortable: false,
          width: '70px',
        },
        {
          text: 'Оценка по уровню убедительности',
          value: 'convictionScore',
          sortable: false,
          width: '70px',
        },
        {
          text: 'Итоговая оценка',
          value: 'resultScore',
          sortable: false,
          width: '70px',
          cellClass: 'text--primary font-weight-bold',
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
    },
  },
  watch: {
    value: 'init',
    averageValue(val) {
      if (val) this.$emit('update')
    },
  },
  created() {
    this.init(this.value)
  },
  mounted() {
    const firstItem = this.quality?.[0]
    if (!firstItem?.nameKi) {
      this.expandItem(firstItem)
    }
  },
  computed: {
    averageValue() {
      return this.getAverageValue()
    },
  },
  methods: {
    getAverageValue() {
      if (!this.quality?.length) return 0
      const sum = this.quality.reduce((acc, current) => {
        return Number(acc) + Number(current.resultScore)
      }, 0)
      return (sum / this.quality?.length).toFixed(2)
    },
    init(value) {
      this.quality = copyObject(value?.quality || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
      this.scoreEO = value.scoreEO || 0
      this.scoreQuality = value.scoreQuality || 0
    },
    doChangeItem(item, force = false) {
      const idx = item.idx - 1
      this.quality[idx] = item
      this.doChange(force)
    },
    doChangeEO() {
      this.$emit('update')
      this.doChange()
    },
    doChange(force = false) {
      if (!this.readonly) {
        const outObj = {
          ...this.value,
          quality: this.quality,
          averageValue: this.getAverageValue(),
          scoreEO: this.scoreEO,
          scoreQuality: this.scoreQuality,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments(idx) {
      this.quality.splice(idx - 1, 1)
      // this.$set(this, 'quality', this.quality)
      this.expandItem(null)
      this.doChange(true)
    },
    addAssessment() {
      this.quality.push(DEFAULT_QUALITY())
      this.doChange(true)

      this.$nextTick(() => {
        const lg = this.quality?.length || 0
        this.expandItem(this.quality[lg - 1])
      })
    },
    expandItem(item = null) {
      this.expandedAssessments = item ? [item] : []
    },
  },
}
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
