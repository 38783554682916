var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.about.error)?_c('v-alert',{attrs:{"type":"error","dismissible":""}},[_vm._v(_vm._s(_vm.about.error))]):_vm._e(),_c('check-alert',{attrs:{"label":"Показание","loading":_vm.about.loading,"check-value":_vm.initCondition,"hide-check":_vm.about.loading || !_vm.editMode,"readonly":_vm.readonly,"pre":""},on:{"change":function($event){return _vm.doChange(true)}},model:{value:(_vm.data.AL[0].condition),callback:function ($$v) {_vm.$set(_vm.data.AL[0], "condition", $$v)},expression:"data.AL[0].condition"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.crItems,"hide-default-footer":"","items-per-page":30,"sort-by":"name"},on:{"click:row":_vm.openForEdit},scopedSlots:_vm._u([(!_vm.logView)?{key:"top",fn:function(){return [_c('v-btn',{attrs:{"color":"success","small":"","disabled":_vm.readonly},on:{"click":_vm.openForAdd}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" mdi-plus ")]),_vm._v("Добавить КР")],1)]},proxy:true}:null,{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-badge',{staticClass:"cursor-help",attrs:{"value":_vm.crItemsUsedCount[item.key],"color":"grey lighten-1","content":_vm.crItemsUsedCount[item.key],"title":_vm.crItemsUsedHint(_vm.crItemsUsedCount[item.key]),"offset-x":"-5px"}},[_vm._v(_vm._s(index + 1)+".")])]}},{key:"item.isOnRubricator",fn:function(ref){
var item = ref.item;
return [(item.isOnRubricator)?_c('span',[_vm._v(" Да ")]):_c('span',[_vm._v("Нет")])]}},{key:"item.isApprovedNPS",fn:function(ref){
var item = ref.item;
return [(item.isApprovedNPS)?_c('span',[_vm._v(" Да ")]):_c('span',[_vm._v("Нет")])]}}],null,true)})],1)],1),(_vm.show)?_c('editCRForm',{attrs:{"CR":_vm.data.CR,"item":_vm.item},on:{"updateItem":_vm.updateItem,"removeItem":_vm.removeItem,"closeDialog":_vm.closeDialog}}):_vm._e()],1):(_vm.createError)?_c('div',[_c('v-alert',{attrs:{"type":"error","outlined":""}},[_vm._v(_vm._s(_vm.createError))])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }