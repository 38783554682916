<template>
  <v-dialog v-model="dialogState" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="editMode" fab x-small v-bind="attrs" icon v-on="on">
        <v-icon>mdi-pencil</v-icon></v-btn
      >
      <v-btn
        v-else
        title="Добавить референтную страну"
        color="accent"
        fab
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialogStateKiller">
      <v-card-title
        >{{ editMode ? 'Редактирование' : 'Добавление' }} валюты</v-card-title
      >
      <v-card-text class="px-4 ma-0">
        <v-text-field
          v-model="nameCurrency"
          maxLength="3"
          prepend-icon="mdi-key-variant"
          placeholder="3-х значный код валюты"
          dense
          label="Код валюты"
        ></v-text-field>
        <v-text-field
          v-model="fullNameCurrency"
          prepend-icon="mdi-subtitles"
          dense
          label="Название валюты"
        ></v-text-field>
        <v-text-field
          v-model.number="valueCurrency"
          type="number"
          prepend-icon="mdi-numeric"
          dense
          label="Значение"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <btn-with-confirm
          v-if="editMode"
          color="red lighten-3"
          title="Удалить валюту"
          @click="deleteCurrency"
          icon
          icon-main="mdi-delete-forever-outline"
        >
        </btn-with-confirm>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="closeDialog">Отмена </v-btn>
        <v-btn color="green" text @click="closeAndAction">
          {{ editMode ? 'Изменить' : 'Добавить' }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { DEFAULT_ORIGIN } from '@/components/expPricing/const'
import { getFlag } from '@/components/expPricing/const'
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
export default {
  name: 'OriginDialog',
  components: { BtnWithConfirm },
  data: () => ({
    dialogState: false,
    dialogStateKiller: false,
    nameCurrency: '',
    fullNameCurrency: '',
    valueCurrency: 0,
    killTimer: 0,
  }),

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.value && this.editMode) {
      this.nameCurrency = this.value?.nameCurrency || ''
      this.fullNameCurrency = this.value?.fullNameCurrency || ''
      this.valueCurrency = this.value?.valueCurrency || 0
    }
  },
  watch: {
    dialogState(val) {
      if (val) {
        clearTimeout(this.killTimer)
        this.dialogStateKiller = false
        this.$nextTick(() => {
          this.dialogStateKiller = true
        })
      } else
        this.killTimer = setTimeout(() => {
          this.dialogStateKiller = false
        }, 1000)
    },
    dialogStateKiller(val) {
      if (val) {
        this.nameCurrency = this.value?.nameCurrency || ''
        this.fullNameCurrency = this.value?.fullNameCurrency || ''
        this.valueCurrency = this.value?.valueCurrency || 0
      } else {
        this.nameCurrency = ''
        this.fullNameCurrency = ''
        this.valueCurrency = 0
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.killTimer)
  },
  methods: {
    getFlag,
    closeDialog() {
      this.dialogState = false
      this.$emit('onClose')
    },
    closeAndAction() {
      const { nameCurrency, fullNameCurrency, valueCurrency } = this
      this.$emit('change', {
        ...DEFAULT_ORIGIN(),
        nameCurrency,
        fullNameCurrency,
        valueCurrency,
      })
      this.dialogState = false
    },
    deleteCurrency() {
      this.$emit('delete')
    },
  },
}
</script>
