var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-log',_vm._b({attrs:{"diff-keys":_vm.diffKeys,"diff-keys-exclude":_vm.excludeKeys},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var message = ref.message;
return [_c('span',{staticClass:"text-decoration-underline font-weight-bold"},[_vm._v(" "+_vm._s(message.toUpperCase())+" ")])]}},{key:"diff.object",fn:function(ref){
var obj = ref.value;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.expTypeById(obj.expertise_type_id))+"_"+_vm._s(obj.section + 1)+" ")]),_vm._v(" - "+_vm._s(_vm.userById(obj.user_id))+" ")]}},{key:"diff.user_signer_id",fn:function(ref){
var value = ref.value;
var textClass = ref.textClass;
return [(value)?_c('span',{class:textClass},[_vm._v(_vm._s(_vm.userById(value)))]):_vm._e()]}},{key:"diff.user_id",fn:function(ref){
var value = ref.value;
var textClass = ref.textClass;
return [(value)?_c('span',{class:textClass},[_vm._v(_vm._s(_vm.userById(value)))]):_vm._e()]}}],null,true)},'default-log',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }