<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="blue--text">
        {{ message }}
      </span>
    </template>
    <template #detail="{ data }">
      <PlpDataForm
        v-if="showExpertise(data) && plpData"
        :value="plpData"
        readonly
      />
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import PlpDataForm from '@/components/plp/PlpDataForm'
export default {
  name: 'PlpCreatedLog',
  inheritAttrs: false,
  components: { PlpDataForm, DefaultLog },
  data: () => ({
    logData: null,
  }),
  computed: {
    plpData() {
      return this.logData?.data?.data
    },
  },
  methods: {
    showExpertise(data) {
      if (data) this.logData = data
      return !!data
    },
  },
}
</script>
