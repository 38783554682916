<template>
  <v-container fluid class="pb-4">
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="lf"
          :outlined="!readonly"
          :readonly="readonly"
          label="Форма выпуска"
          placeholder="Введите или выберите лекарственную форму из справочника"
          prepend-icon="mdi-text-box-search-outline"
          @input="doChange()"
          @blur="doBlur()"
          hint="Например, капсулы 10 мг № 10 "
          :append-outer-icon="!readonly ? 'mdi-store-search-outline' : ''"
          @click:append-outer="getLF()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.number="min"
          :outlined="!readonly"
          :readonly="readonly"
          label="Минимальный объем закупок, уп."
          prepend-icon="mdi-arrow-bottom-right-bold-outline"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="max"
          :outlined="!readonly"
          :readonly="readonly"
          label="Максимальный объем закупок, уп."
          prepend-icon="mdi-arrow-top-right-bold-outline"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
    </v-row>
    <v-row v-if="!readonly">
      <v-col class="text-right">
        <btn-with-confirm
          text
          icon-main="mdi-delete-forever-outline"
          title="Удалить лекарственную форму"
          @click="deleteAssessments"
        >
          Удалить
        </btn-with-confirm>
      </v-col>
    </v-row>
    <AnalogLFList
      v-if="show"
      :value="aboutLf"
      :readonly="readonly"
      @closeDialog="closeDialog"
      @updateLF="updateLF"
      hide-details
      only-one-selected
    />
  </v-container>
</template>

<script>
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import AnalogLFList from '@/components/expAnalogs/AnalogLFList'
import ReferenceTip from '@/components/ReferenceTip'

export default {
  name: 'ReleaseEditor',
  components: { ReferenceTip, AnalogLFList, BtnWithConfirm },
  data: () => ({
    lf: '',
    min: 0,
    max: 0,
    show: false,
    scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    about: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  computed: {
    aboutLf() {
      return { ...this.about, lForma: this.lf }
    },
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) {
        const { lf, min, max } = this
        const outObj = {
          ...this.value,
          lf,
          min,
          max,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments() {
      this.$emit('delete')
    },
    init() {
      this.lf = this.value?.lf || ''
      this.min = this.value?.min || 0
      this.max = this.value?.max || 0
    },
    updateLF(lf) {
      this.lf = lf.innInternal ?? ''
      this.doChange(true)
      this.closeDialog()
    },
    getLF() {
      this.openDialog()
    },
    closeDialog() {
      this.show = false
    },
    openDialog() {
      this.show = true
    },
  },
}
</script>

<style scoped></style>
