<template>
  <default-log v-bind="$attrs" :diff-keys="diffKeys">
    <template #default="{ message }">
      <span class="grey--text text-decoration-underline">
        {{ message }}
      </span>
    </template>
    <template #detail="{ data }">
      <DeadlinesList v-if="showLogInfo(data)" :data="deadlines"></DeadlinesList>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'
import DeadlinesList from '../../deadlines/DeadlinesList.vue'

export default {
  name: 'DictImportedLog',
  inheritAttrs: false,
  components: { DefaultLog, DeadlinesList },
  mixins: [logInfoMixin],
  data: () => ({
    diffKeys: {
      first_date: 'Дата отправки',
      last_date: 'Дата отправки в МЗ',
    },
  }),
  mounted() {},
  computed: {
    deadlines() {
      return this.logData.data
    },
  },
}
</script>
