<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="purple--text text-decoration-underline">
        {{ message }}
      </span>
    </template>
    <template #detail="{ data }">
      <ul v-if="showLogInfo(data)" class="ml-3">
        <li class="mb-1">
          <span class="font-weight-bold">Комментарий: </span>{{ dictComment }}
        </li>
        <li class="mb-1">
          <span class="font-weight-bold">Название справочника: </span
          >{{ dictName }}
        </li>
        <li class="mb-1">
          <span class="font-weight-bold">Дата и время: </span
          >{{ filteredDatetime(dateDict) }}
        </li>
      </ul>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'
export default {
  name: 'DictImportedLog',
  inheritAttrs: false,
  components: { DefaultLog },
  mixins: [logInfoMixin],
  computed: {
    dictComment() {
      return this.logData?.dict?.comment || '--пусто--'
    },
    dictName() {
      return this.logData?.dict?.dict_name || '--пусто--'
    },
    dateDict() {
      return this.logData?.dict?.time_edit
    },
  },
}
</script>
