<template>
  <v-container fill-height fluid>
    <v-row v-if="showAlert" dense>
      <v-col>
        <ReferenceTip>
          Обновить курсы валют до актуальных значений можно кнопкой "обновить" (
          <v-icon color="green" small>mdi-sync</v-icon> )
        </ReferenceTip>
      </v-col>
    </v-row>
    <v-row v-if="showAlert" dense>
      <v-col>
        <v-alert type="warning" dense text>
          Внимание! Информация о текущих курсах валют неактуальна, рекомендуется
          обновить данные
          <template #append>
            <v-btn
              v-if="editMode"
              text
              small
              :color="showValues ? 'green' : 'secondary'"
              title="Показать изменения"
              @click="showValues = !showValues"
              :disabled="!needRefresh"
              icon
              ><v-icon
                v-text="showValues ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
              ></v-icon
            ></v-btn>
            <v-btn
              v-if="editMode"
              text
              color="green"
              small
              title="Обновить значения ЦБ"
              @click="setCurrencyPrice"
              :disabled="!needRefresh"
              ><v-icon>mdi-sync</v-icon></v-btn
            >
          </template>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" lg="8" offset-lg="2">
        <v-data-table
          :items="originCurrency"
          :headers="originHeaders"
          disable-pagination
          hide-default-footer
        >
          <template #[`item.nameCurrency`]="{ item }">
            <v-badge
              v-if="item.isEdit"
              :value="item.isEdit"
              color="blue lighten-3"
              content="Редактируемая валюта"
              :offset-x="180"
              left
            >
              <v-text-field
                dense
                readonly
                maxLength="3"
                v-model="item.nameCurrency"
                @input="doChange()"
                @blur="doBlur()"
                hide-details
                class="ma-0 pa-0"
              ></v-text-field>
            </v-badge>
            <span class="font-weight-bold" v-else>
              {{ item.nameCurrency }}
            </span>
          </template>
          <template #[`item.fullNameCurrency`]="{ item, index }">
            <v-text-field
              v-if="item.isEdit"
              dense
              readonly
              :value="item.fullNameCurrency"
              @input="doChange()"
              @blur="doBlur()"
              hide-details
            ></v-text-field>
            <span v-else>{{ item.fullNameCurrency }}</span>
          </template>
          <template #[`item.valueCurrency`]="{ item, index }">
            <span v-if="item.isEdit" class="font-weight-bold">{{
              item.valueCurrency | toMoney
            }}</span>
            <span v-else class="font-weight-bold"
              >{{ item.valueCurrency | toMoney }}
              <span v-if="showValues && editMode">
                <span
                  v-if="item.valueCurrency < getInitValue(item, index)"
                  class="green--text"
                >
                  ➚
                  {{ getInitValue(item, index) | toMoney }}
                </span>
                <span
                  v-else-if="item.valueCurrency > getInitValue(item, index)"
                  class="error--text"
                >
                  ➘{{ getInitValue(item, index) | toMoney }}
                </span>
              </span>
            </span>
          </template>
          <template
            v-slot:[`item.data-table-expand`]="{ item, isExpanded, index }"
          >
            <td class="text-right">
              <div
                class="action"
                :class="{ 'v-data-table__expand-icon--active': isExpanded }"
              >
                <OriginDialog
                  v-if="item.isEdit && editMode"
                  :value="item"
                  :edit-mode="editMode"
                  @change="item => editCurrency(item, index)"
                  @delete="deleteCurrency(index)"
                />
              </div>
            </td>
          </template>
          <template #footer="{ item, index }">
            <div v-if="editMode" class="d-flex">
              <v-spacer />
              <OriginDialog @change="addCurrency" />
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { copyObject, sameObject } from '@/lib/objects'
import { Api } from '@/lib/axiosApi'
import ReferenceTip from '@/components/ReferenceTip'
import { mapMutations } from 'vuex'
import OriginDialog from '@/components/expPricing/comp/origin/OriginDialog'
import { toMoney } from '@/lib/strUtils'

export default {
  name: 'OriginTable',
  components: { OriginDialog, ReferenceTip },
  data: () => ({
    originCurrency: [],
    initOrigin: [],
    // dateCurrency: '',
    loadCurrency: false,
    showValues: false,
    originHeaders: [
      {
        text: 'Код валюты',
        value: 'nameCurrency',
        width: '190px',
        sortable: false,
      },
      {
        text: 'Название валюты',
        value: 'fullNameCurrency',
        sortable: false,
      },
      {
        text: 'Значение',
        value: 'valueCurrency',
        sortable: false,
      },
      { text: '', value: 'data-table-expand', width: '70px' },
    ],
  }),
  filters: {
    toMoney,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    datePricing: {
      type: String,
    },
  },
  watch: {
    value: 'init',
    datePricing: 'getCurrencyPrice',
  },
  methods: {
    ...mapMutations(['SET_ERROR']),
    doChange(force = false) {
      this.$emit('change', this.originCurrency, force)
    },
    doBlur() {
      this.$emit('blur')
    },
    init(value) {
      this.originCurrency = copyObject(value || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
    },
    async setEmptyCurrency() {
      // Если значения валют пусты, то первый раз подгружаем их
      await this.getCurrencyPrice()
      if (this.originCurrency.find(item => !item.valueCurrency)) {
        this.setCurrencyPrice()
      }
    },
    async getCurrencyPrice() {
      //Подгружаем значения валют с сайта ЦБ
      this.loadCurrency = true
      try {
        const origin = copyObject(this.originCurrency)
        const curs = await Api.post('cbr/middle', {
          currencies: origin
            .filter(item => !item.isEdit)
            .map(item => item.nameCurrency),
          date: this.datePricing,
        })
        origin.forEach(item => {
          if (!item.isEdit) {
            if (item.nameCurrency in curs.valute) {
              const valute = curs.valute[item.nameCurrency]
              item.valueCurrency = Number(
                (Number(valute.value) / Number(valute.nominal)).toFixed(4)
              )
              //Захардкодил пока для одной валюты, т.к захотели чтобы отображалось
              // в именительном падеже (Чешская крона). Если будет больше валют,
              // сделаю чек-проверку с ключом-значением
              if (valute.name === 'Чешских крон')
                item.fullNameCurrency = 'Чешская крона'
              else item.fullNameCurrency = valute.name
            } else {
              item.fullNameCurrency = ''
              item.valueCurrency = 0
            }
          }
        })

        this.initOrigin = origin
      } catch (error) {
        console.log(error)
        this.SET_ERROR({
          head: 'Текущие курсы валют (ЦБ)',
          text: 'Ошибка загрузки текущих курсов валют',
          error,
        })
        throw error
      } finally {
        this.loadCurrency = false
      }
    },
    setCurrencyPrice() {
      //Обновляем значения валют до актуальных
      this.originCurrency = this.initOrigin
      this.doChange(true)
    },
    getInitValue(item, idx) {
      return !item.isEdit ? this.initOrigin[idx]?.valueCurrency : 0
    },
    addCurrency(item) {
      this.originCurrency.push(item)
      this.doChange(true)
    },
    editCurrency(item, index) {
      this.originCurrency[index] = item
      this.doChange(true)
    },
    deleteCurrency(idx) {
      this.originCurrency.splice(idx, 1)
      this.doChange(true)
    },
  },
  async created() {
    this.init(this.value)
    await this.setEmptyCurrency()
  },
  computed: {
    needRefresh() {
      //Сравниваем значения валют с экспертизы со значениями валют с сайта ЦБ
      return !sameObject(
        this.originCurrency
          .filter(item => !item.isEdit)
          .map(item => item.valueCurrency),
        this.initOrigin
          .filter(item => !item.isEdit)
          .map(item => item.valueCurrency)
      )
    },
    showAlert() {
      return this.editMode && this.needRefresh && !this.loadCurrency
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr .action {
  display: none !important;
}
.v-data-table >>> tr:hover .action {
  display: flex !important;
}
</style>
