<template>
  <v-form v-if="data" :readonly="readonly" ref="form">
    <ScoreEditor
      :exp.sync="assessmentOrganization.nameExpOrg"
      :readonly="readonly"
      @change="
        (exp, force) => {
          doChangeExp(exp, force)
        }
      "
      @blur="doBlur()"
    />
    <!--    Оценка качества КИ-->
    <v-card class="mb-2">
      <v-card-title>Оценка качества КИ</v-card-title>
      <v-card-text>
        <QualityTable
          :value="assessmentOrganization.qualityAssessment"
          :readonly="readonly"
          @change="doChangeQuality"
          @update="doUpdate"
          @blur="doBlur"
      /></v-card-text>
    </v-card>
    <!--    Оценка эффективности-->
    <v-card class="mb-2">
      <v-card-title>Оценка эффективности</v-card-title>
      <v-card-text>
        <LevelQualityCard
          :value="assessmentOrganization.levelQuality"
          :readonly="readonly"
          @change="doChangeScoreQuality"
          @update="doUpdate"
          @blur="doBlur"
        />
      </v-card-text>
    </v-card>
    <!--    Оценка безопасности-->
    <v-card class="mb-2">
      <v-card-title>Оценка безопасности</v-card-title>
      <v-card-text>
        <SafetyTable
          :value="assessmentOrganization.safetyAssessment"
          :readonly="readonly"
          @change="doChangeSafety"
          @update="doUpdate"
          @blur="doBlur"
      /></v-card-text>
    </v-card>
    <!--    Оценка дополнительной терапевтической ценности-->
    <v-card class="mb-2">
      <v-card-title
        >Оценка дополнительной терапевтической ценности
      </v-card-title>
      <v-card-text>
        <LevelPriceCard
          :value="assessmentOrganization.levelPrice"
          :readonly="readonly"
          @change="doChangeScorePrice"
          @update="doUpdate"
          @blur="doBlur"
        />
      </v-card-text>
    </v-card>

    <CompareScores
      :value="assessmentOrganization"
      :edit-mode="editMode"
      @change="doChangeOwnScores"
      @blur="doBlur"
    />

    <v-radio-group
      class="mt-0 mb-4 pa-0"
      row
      v-model="assessmentOrganization.conclusionExpediency"
      @change="doChange(true)"
    >
      <v-radio
        color="green"
        :value="true"
        label="ЭО считает ЦЕЛЕСООБРАЗНЫМ включение ЛП"
      />
      <v-radio
        color="red"
        :value="false"
        label="ЭО считает НЕЦЕЛЕСООБРАЗНЫМ включение ЛП"
      />
    </v-radio-group>

    <v-textarea
      label="Комментарий"
      v-model="assessmentOrganization.comment"
      :outlined="!readonly"
      @input="doChange()"
      @blur="doBlur()"
      hide-details
      prepend-inner-icon="mdi-comment-outline"
      :append-icon="editIcon"
      rows="4"
      auto-grow
      no-resize
    />
    <v-btn
      v-if="editMode"
      color="primary"
      class="mt-2"
      text
      @click="showDlg = !showDlg"
      >Шаблонные ответы</v-btn
    >
    <OverlayQuestions
      :show="showDlg"
      @close="showDlg = false"
      @add="changeComment"
    />
  </v-form>
</template>

<script>
import { ECONOMIC, QUALITY, SAFETY } from './assessmentConst'
import ExpSummaryMixin from '../ExpSummaryMixin'
import QualityTable from './comp/QualityTable'
import SafetyTable from './comp/SafetyTable'
import ScoreEditor from '@/components/expSummary/summaryScore/ScoreEditor'
import OverlayQuestions from '@/components/expSummary/templates/OverlayQuestions'
import LevelQualityCard from '@/components/expSummary/summaryScore/comp/LevelQualityCard'
import LevelPriceCard from '@/components/expSummary/summaryScore/comp/LevelPriceCard'
import CompareScores from '@/components/expSummary/summaryScore/comp/scores/CompareScores'
import { copyObject } from '@/lib/objects'

export default {
  name: 'SummaryScore',
  components: {
    CompareScores,
    LevelPriceCard,
    LevelQualityCard,
    OverlayQuestions,
    ScoreEditor,
    SafetyTable,
    QualityTable,
  },
  data: () => ({
    QUALITY,
    SAFETY,
    ECONOMIC,
    needUpdate: false,
    showDlg: false,
    timer: 0,
  }),
  mixins: [ExpSummaryMixin],
  computed: {
    assessmentOrganization() {
      return this.data?.assessmentOrganization || {}
    },
    quality() {
      return this.data?.assessmentOrganization?.qualityAssessment
    },
  },
  watch: {
    assessmentOrganization: {
      handler() {
        this.reInit()
      },
    },
    needUpdate(val) {
      if (val)
        this.timer = setTimeout(() => {
          this.needUpdate = false
          this.data = copyObject(this.value)
        }, 1000)
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  methods: {
    doChangeQuality(data, force = false) {
      this.assessmentOrganization.qualityAssessment = data
      this.doChange(force)
    },
    doChangeSafety(data, force = false) {
      this.assessmentOrganization.safetyAssessment = data
      this.doChange(force)
    },
    doUpdate() {
      this.needUpdate = true
    },
    doChangeExp(exp, force) {
      this.assessmentOrganization.nameExpOrg = exp
      this.doChange(force)
    },
    doChangeScoreQuality(data, force) {
      this.assessmentOrganization.levelQuality = data
      this.doChange(force)
    },
    doChangeScorePrice(data, force) {
      this.assessmentOrganization.levelPrice = data
      this.doChange(force)
    },
    doChange(force = false) {
      if (!this.readonly) {
        this.$emit('change', this.data, force)
      }
    },
    changeComment(comment) {
      this.assessmentOrganization.comment = String(
        this.assessmentOrganization.comment?.trimRight() + '\n' + comment
      ).trim()
      this.doChange(true)
    },
    doChangeOwnScores(data) {
      this.assessmentOrganization.ownScores = data
      this.doChange(false)
    },
    onCloseTemplate() {
      this.showDlg = false
    },
  },
}
</script>
