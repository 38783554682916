<template>
  <v-data-table
    :items="priceCharacteristics"
    :headers="priceCharacterHeaders"
    show-expand
    single-select
    item-key="idx"
    single-expand
    :expanded.sync="expandedPurchase"
    disable-pagination
    hide-default-footer
    no-data-text="Информация о ценовой характеристики ЛП отсутствует"
  >
    <template #[`item.idx`]="{ item }">
      <span class="text--disabled">{{ item.idx }}.</span>
    </template>
    <template #[`item.releaseForm`]="{ value }">
      <span v-if="value" class="font-weight-medium">{{ value }}</span>
      <span v-else class="warning--text"> ( пусто ) </span>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="td-expanded pa-2">
        <PriceEditor
          :value="item"
          :readonly="readonly"
          @change="doChangeItem"
          @blur="doBlur"
          @delete="deleteAssessments(item.idx)"
        />
      </td>
    </template>
    <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
      <td class="text-start">
        <v-btn
          icon
          @click="expand(!isExpanded)"
          class="v-data-table__expand-icon"
          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
        >
          <v-icon>mdi-pencil-circle-outline</v-icon>
        </v-btn>
      </td>
    </template>
    <template #footer v-if="!readonly">
      <div class="d-flex pt-3">
        <v-spacer />
        <v-btn
          :disabled="!!readonly"
          @click="addPurchase()"
          title="Добавить форму выпуска"
          color="accent"
          fab
          small
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { EX_PRICE_CHARACTERISTICS } from '../purchaseConst'
import PriceEditor from './PriceEditor'

export default {
  name: 'PriceTable',
  components: { PriceEditor },
  data: function () {
    return {
      priceCharacteristics: [],
      scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      expandedPurchase: [],
      priceCharacterHeaders: [
        {
          text: '№',
          value: 'idx',
          width: '10px',
          sortable: false,
        },
        {
          text: 'Форма выпуска',
          value: 'releaseForm',
          sortable: false,
        },
        {
          text: 'Цена планируемая к регистрирации, руб.',
          value: 'registerPrice',
          sortable: false,
          width: '100px',
          align: 'end',
        },
        {
          text: 'Цена гос. закупок, руб.',
          value: 'pricePurchase',
          sortable: false,
          width: '100px',
          align: 'end',
        },
        {
          text: 'Розничная цена, руб.',
          value: 'retailPrice',
          sortable: false,
          width: '100px',
          align: 'end',
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  props: {
    value: {
      type: Array,
    },
    readonly: {
      type: Boolean,
    },
  },
  created() {
    this.init(this.value)
  },
  mounted() {
    const firstItem = this.priceCharacteristics?.[0]
    if (!firstItem?.releaseForm) {
      this.expandItem(firstItem)
    }
  },
  watch: {
    value: 'init',
  },
  methods: {
    init(value) {
      this.priceCharacteristics = copyObject(value || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
    },
    doChangeItem(item, force = false) {
      const idx = item.idx - 1
      this.priceCharacteristics[idx] = item
      this.doChange(force)
    },
    doChange(force = false) {
      if (!this.readonly) {
        this.$emit('change', this.priceCharacteristics, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments(idx) {
      this.priceCharacteristics.splice(idx - 1, 1)
      // this.$set(this, 'quality', this.quality)
      this.expandItem(null)
      this.doChange(true)
    },
    addPurchase() {
      this.priceCharacteristics.push(EX_PRICE_CHARACTERISTICS())
      this.doChange(true)

      this.$nextTick(() => {
        const lg = this.priceCharacteristics?.length || 0
        this.expandItem(this.priceCharacteristics[lg - 1])
      })
    },
    expandItem(item = null) {
      this.expandedPurchase = item ? [item] : []
    },
  },
}
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
