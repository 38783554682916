import { copyObject, copyObjectBy, sameObject } from '@/lib/objects'
import { DEFAULT_KEI } from '@/components/expSummary/summaryAdditional/addConst'

export function getForScenario(arrScenario) {
  const count = Array.isArray(arrScenario) ? arrScenario.length : 1
  if (count < 2) return String(arrScenario || '')

  return arrScenario
    .reduce(
      (text, item, idx) =>
        `${text}${text ? '\n' : ''}- Сценарий ${idx + 1}:\n ${item}`,
      ''
    )
    .trim()
}

export function createSummaryKeiCopy(expKei, initKei) {
  if (!initKei) return copyObject(expKei)
  const kei = []
  initKei?.ka?.forEach(item => {
    kei.push(
      keiSummaryCopy(
        expKei?.find(d => d.shortName === item.shortName),
        item
      )
    )
  })
  return kei
}

export function keiSummaryCopy(kei, initKei) {
  const resKei = {
    ...copyObjectBy(kei, DEFAULT_KEI()),
  }
  resKei.shortName = initKei.shortName
  resKei.qLevels = initKei.qLevels
  resKei.condition = initKei.condition
  resKei.method = copyObject(initKei.resume?.method)
  resKei.analogsCompare = copyObject(initKei.resume?.analog)
  resKei.clinicalAdvantages = copyObject(initKei.resume?.clinicalAdvantages)
  resKei.econAdvantages = copyObject(initKei.resume?.econAdvantages)
  resKei.results = copyObject(initKei.resume?.results)

  return resKei
}

export function checkSummaryKeiLength(expKei, initKei) {
  return expKei?.length === initKei?.ka?.length
}

/***
 *Проверка всей экспертизы КЭИ на актуальность данных
 * @param expKei массив КЭИ с экспертизы сводного заключения
 * @param initKei массив КЭИ с экспретизы КЭИ
 * @returns Boolean
 */
export function checkSummaryKei(expKei, initKei) {
  if (!checkSummaryKeiLength(expKei, initKei)) {
    return false
  }
  for (let i = 0; i < expKei.length; i++) {
    if (!checkSummaryKeiCopy(expKei[i], initKei?.ka[i])) {
      return false
    }
  }
  return true
}

function checkSummaryKeiCopy(kei, initKei) {
  const resKei = keiSummaryCopy(null, initKei)
  for (const key of [
    'shortName',
    'qLevels',
    'condition',
    'analogsCompare',
    'method',
    'clinicalAdvantages',
    'econAdvantages',
    'results',
  ]) {
    if (!sameObject(resKei[key], kei[key])) return false
  }

  return true
}
