<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9">
        <v-text-field
          :label="`Короткое название (до ${shortNameLength} символов)`"
          v-model="expertise.shortName"
          :maxlength="shortNameLength"
          :counter="shortNameLength"
          :rules="rules"
          @input="doChange()"
          @blur="doBlur()"
        />
        <v-textarea
          rows="4"
          no-resize
          auto-grow
          label="Показание"
          v-model="expertise.condition"
          @input="doChange()"
          @blur="doBlur()"
        />
        <v-text-field
          label="МКБ-10 (коды)"
          readonly
          hint="Выбора кодов МКБ-10 через справочник, кнопка справа"
          v-model="expertise.mkb10_codes"
          @change="doChange()"
        >
          <template #append-outer>
            <v-btn :disabled="readonly" @click="showMkbTree" icon>
              <v-icon> mdi-store-search-outline </v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-textarea
          label="МКБ-10"
          rows="3"
          auto-grow
          v-model="expertise.mkb10"
          readonly
          hint="Выбора кодов МКБ-10 доступен через справочник выше"
          @input="doChange()"
          @blur="doBlur()"
        />
      </v-col>
      <v-col
        cols="3"
        class="d-flex flex-column justify-space-between text-center"
      >
        <div class="text-overline">
          Методологическое качество{{
            storedPlpVersion === 2 ? `: ${qWeight}` : ''
          }}
        </div>
        <div>
          <v-timeline>
            <v-timeline-item
              v-for="(level, i) in qLevels"
              :key="i"
              :small="!level.active"
              :color="level.active ? level.color : 'grey lighten-2'"
            >
              <template v-slot:opposite>
                <span
                  :class="
                    level.active ? 'font-weight-bold' : 'font-weight-thin'
                  "
                  >{{ level.title }}</span
                >
              </template>
            </v-timeline-item>
          </v-timeline>
        </div>
        <div>
          <v-btn
            text
            color="warning"
            @click="deleteDialog = !deleteDialog"
            v-if="!readonly"
            >Удалить</v-btn
          >
          <AvbKeiExpertiseDeleteDialog
            :name="expertise.shortName"
            :dialog="deleteDialog"
            @onClose="deleteDialog = !deleteDialog"
            @onAction="removeItem"
          ></AvbKeiExpertiseDeleteDialog>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="storedPlpVersion >= 2">
      <v-col>
        <v-radio-group
          class="mt-0 mb-4 pa-0"
          row
          v-model="withModel"
          @change="doChangeModel()"
        >
          <v-radio
            color="green"
            :value="true"
            label="Математическая модель представлена"
          />
          <v-radio
            color="red"
            :value="false"
            label="Математическая модель не представлена"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Критерии (доработка)</v-card-title>
          <v-card-text>
            <v-alert
              v-if="needRefresh && expElements && modExpElements"
              type="warning"
              dense
              text
            >
              Внимание! Информация по критериям для данной экспертизы
              изменилась, рекомендуется обновить данные
              <template #append>
                <v-btn
                  @click="refreshCriteria"
                  :disabled="!needRefresh"
                  text
                  color="green"
                  small
                  title="Обновить все данные по критериям"
                  ><v-icon>mdi-sync</v-icon></v-btn
                >
              </template>
            </v-alert>
          </v-card-text>
          <v-card-text>
            <v-tabs center-active show-arrows>
              <v-tab
                v-for="(element, elementIndex) in expertise.elements"
                :key="elementIndex"
                ><span
                  :class="someClass(element.correspond)"
                  :title="element.name"
                  >{{ elementIndex + 1 }}.
                  {{ element.name.split(' ')[0] || '' }}...</span
                >
              </v-tab>
              <v-tab-item
                v-for="(element, elementIndex) in expertise.elements"
                :key="elementIndex"
                style="min-height: 400px"
              >
                <v-card :key="elementIndex" class="my-2" elevation="0">
                  <v-card-title>
                    {{ element.order }}. {{ element.name }}
                  </v-card-title>
                  <v-card-text>
                    <CriteriaCompare
                      :init-obj="modExpElements[elementIndex]"
                      :exp-obj="element"
                      :element-index="elementIndex"
                      :readonly="readonly"
                      @change="
                        (item, force) =>
                          doChangeCompare(item, elementIndex, force)
                      "
                      @update="item => doChangeCorrespond(item, elementIndex)"
                      @blur="doBlur"
                    />
                  </v-card-text>

                  <v-card-actions>
                    <v-list-item class="grow">
                      <v-btn
                        text
                        @click="showToggle(elementIndex)"
                        v-if="hasTags(element.id)"
                        >теги
                        <v-icon>{{
                          show[elementIndex]
                            ? 'mdi-chevron-up'
                            : 'mdi-chevron-down'
                        }}</v-icon>
                      </v-btn>

                      <v-row align="center" justify="end" v-if="!readonly">
                        <v-tooltip bottom v-if="element.correspond === null">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" color="warning"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </template>
                          <span>Выберите соответствие критерия</span>
                        </v-tooltip>
                        <v-btn
                          color="primary"
                          text
                          @click="referenceToggle(element, elementIndex, 0)"
                          :disabled="element.correspond === null"
                        >
                          Шаблоны
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="referenceToggle(element, elementIndex, 1)"
                          text
                          :disabled="element.correspond === null"
                        >
                          Ответы
                        </v-btn>
                      </v-row>
                    </v-list-item>
                  </v-card-actions>

                  <!-- ТЭГи -->
                  <v-expand-transition v-if="hasTags(element.id)">
                    <div v-show="show[elementIndex]">
                      <v-divider />
                      <v-card-text>
                        <CriterionTags
                          :value="element.tagsSelected"
                          :tags="getTags(element.id)"
                          :clearable="!readonly"
                          :md="6"
                          flat
                          outlined
                          @change="data => doUpdate(data, elementIndex)"
                        />
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <!-- Тут все поля для "Информация для сводного заключения и представления об ЛП" -->
    <v-card>
      <v-card-title class="mp-0">
        <v-row dense>
          <v-col
            >Информация для сводного заключения и представления об ЛП</v-col
          >
          <v-col cols="3">
            <v-combobox
              label="Кол-во сценариев"
              :value="expertise.resume.scenario"
              class="mt-3 font-weight-bold"
              hide-details
              outlined
              dense
              :items="getResumeScenarioArr"
              @change="doChangeScenario"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- автор КЭИ и АВБ  -->
        <v-card class="my-2" outlined shaped>
          <v-card-title class="text-subtitle-2">
            Автор {{ expertise.shortName }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="expertise.resume.author"
              placeholder="ФИО автора, год"
              @input="doChange()"
              @blur="doBlur()"
              :hint="`Отчёт по ${expertise.shortName}, указывается автор и год`"
              class="mb-2"
            />
          </v-card-text>
        </v-card>
        <template v-if="kei">
          <!--Использованный метод, поля с галками-->
          <v-card class="my-2" outlined>
            <v-card-title class="text-subtitle-2">
              Использованный метод
            </v-card-title>
            <v-card-text>
              <KeiMethodEditor
                v-model="expertise.resume.method"
                @blur="doBlur()"
                @input="doChange(true)"
                :readonly="readonly"
              />
            </v-card-text>
          </v-card>
          <!-- Препараты сравнения -->
          <v-card class="my-2" outlined>
            <v-card-title class="text-subtitle-2">
              Препараты сравнения
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-for="(_, idx) in expertise.resume.analog"
                :key="idx"
                v-model="expertise.resume.analog[idx]"
                :label="
                  expertise.resume.scenario > 1 ? `Сценарий ${idx + 1}` : ''
                "
                class="mb-2"
                no-resize
                rows="1"
                auto-grow
                hint="Препараты сравнения, включенные/не включенные в перечень  [название предлагаемого перечня/перечней]: [названия ЛП]"
                @input="doChange()"
                @blur="doBlur()"
              />
            </v-card-text>
          </v-card>

          <!-- Клинические преимущества -->
          <v-card class="my-2" outlined>
            <v-card-title class="text-subtitle-2">
              Клинические преимущества
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-for="(_, idx) in expertise.resume.clinicalAdvantages"
                :key="idx"
                v-model="expertise.resume.clinicalAdvantages[idx]"
                :label="
                  expertise.resume.scenario > 1 ? `Сценарий ${idx + 1}` : ''
                "
                class="mb-2"
                no-resize
                auto-grow
                @input="doChange()"
                @blur="doBlur()"
              >
                <template #[`append`]>
                  <v-btn
                    color="primary"
                    icon
                    large
                    @click="
                      referenceAddKei(
                        clinicalAdvantagesId,
                        expertise.resume,
                        'clinicalAdvantages',
                        idx
                      )
                    "
                    :disabled="!clinicalAdvantagesId"
                    title="Выбрать из шаблона для клинических преимуществ"
                  >
                    <v-icon>mdi-playlist-plus</v-icon>
                  </v-btn>
                </template>
              </v-textarea>
            </v-card-text>
          </v-card>

          <!--только для КЭИ-->
          <v-card class="my-2" outlined>
            <v-card-title class="text-subtitle-2">
              Экономические преимущества
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-for="(_, idx) in expertise.resume.econAdvantages"
                :key="idx"
                v-model="expertise.resume.econAdvantages[idx]"
                :label="
                  expertise.resume.scenario > 1 ? `Сценарий ${idx + 1}` : ''
                "
                class="mb-2"
                hide-details
                no-resize
                auto-grow
                @input="doChange()"
                @blur="doBlur()"
              >
                <template #[`append`]>
                  <v-btn
                    color="primary"
                    icon
                    large
                    @click="
                      referenceAddKei(
                        econAdvantagesId,
                        expertise.resume,
                        'econAdvantages',
                        idx
                      )
                    "
                    :disabled="!econAdvantagesId"
                    title="Выбрать из шаблона для экономических преимуществ"
                  >
                    <v-icon>mdi-playlist-plus</v-icon>
                  </v-btn>
                </template>
              </v-textarea>
            </v-card-text>
          </v-card>
        </template>

        <!--только для АВБ-->
        <template v-if="!kei">
          <!-- Численность целевой популяции -->
          <v-card class="my-2" outlined>
            <v-card-title class="text-subtitle-2">
              Численность целевой популяции
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-for="(_, idx) in expertise.resume.epidemiology"
                :key="idx"
                v-model="expertise.resume.epidemiology[idx]"
                :label="
                  expertise.resume.scenario > 1 ? `Сценарий ${idx + 1}` : ''
                "
                class="mb-2"
                hide-details
                no-resize
                auto-grow
                @input="doChange()"
                @blur="doBlur()"
              />
            </v-card-text>
          </v-card>

          <!-- Текущая практика -->
          <v-card class="my-2" outlined>
            <v-card-title class="text-subtitle-2">
              Текущая практика
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-for="(_, idx) in expertise.resume.currentState"
                :key="idx"
                v-model="expertise.resume.currentState[idx]"
                :label="
                  expertise.resume.scenario > 1 ? `Сценарий ${idx + 1}` : ''
                "
                class="mb-2"
                hide-details
                no-resize
                auto-grow
                @input="doChange()"
                @blur="doBlur()"
              />
            </v-card-text>
          </v-card>

          <!-- Ожидаемая практика -->
          <v-card class="my-2" outlined>
            <v-card-title class="text-subtitle-2">
              Ожидаемая практика
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-for="(_, idx) in expertise.resume.expectedState"
                :key="idx"
                v-model="expertise.resume.expectedState[idx]"
                :label="
                  expertise.resume.scenario > 1 ? `Сценарий ${idx + 1}` : ''
                "
                class="mb-2"
                hide-details
                no-resize
                auto-grow
                @input="doChange()"
                @blur="doBlur()"
              />
            </v-card-text>
          </v-card>
        </template>

        <!--основные результаты подходит для доработок АВБ и для КЭИ тоже-->
        <v-card class="my-2" outlined>
          <v-card-title class="text-subtitle-2"
            >Основные результаты</v-card-title
          >
          <v-card-text>
            <v-textarea
              v-for="(_, idx) in expertise.resume.results"
              :key="idx"
              v-model="expertise.resume.results[idx]"
              :label="
                expertise.resume.scenario > 1 ? `Сценарий ${idx + 1}` : ''
              "
              class="mb-2"
              hide-details
              no-resize
              auto-grow
              @input="doChange()"
              @blur="doBlur()"
            >
              <template #[`append`] v-if="kei">
                <v-btn
                  color="primary"
                  icon
                  large
                  @click="
                    referenceAddKei(resultsId, expertise.resume, 'results', idx)
                  "
                  :disabled="!resultsId"
                  title="Выбрать из шаблона для основных результатов"
                >
                  <v-icon>mdi-playlist-plus</v-icon>
                </v-btn>
              </template>
            </v-textarea>
          </v-card-text>
        </v-card>

        <v-card class="my-2" outlined>
          <v-card-title class="text-subtitle-2"
            >Комментарий к {{ expertise.shortName }}</v-card-title
          >
          <v-card-text>
            <v-textarea
              v-model="expertise.resume.comment"
              :hint="`Коментарий к ${expertise.shortName}, отображается в сводном заключении. Заполняется при необходимости.`"
              placeholder="Внимание!!! Этот комментарий будет отражаться в сводном заключении"
              class="mb-2"
              no-resize
              auto-grow
              @input="doChange()"
              @blur="doBlur()"
            />
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!--комментарий подходит для АВБ и для КЭИ тоже-->
    <v-card class="my-2" shaped>
      <v-card-title>Мои заметки к {{ expertise.shortName }}</v-card-title>
      <v-card-text>
        <v-textarea
          v-model="expertise.note"
          hint="Внутренние комментарии. В отчёты не идёт!"
          class="mb-2"
          no-resize
          auto-grow
          @input="doChange()"
          @blur="doBlur()"
        />
      </v-card-text>
    </v-card>
    <MkbTreeSelectDialog
      v-if="mkbTree"
      :codes-string="expertise.mkb10_codes"
      @close-mkb-tree="closeMkbTree"
      @confirm-selection="confirmCodes"
    />
  </v-container>
</template>

<script>
import { SHORT_NAME_LENGTH } from '@/store/const/expDefaults'
import {
  checkQLevels,
  getQLevel,
  EX_QLEVELS_DEFAULT,
  EX_AVB_RESUME_SCENARIO_ARR,
  EX_KEI_RESUME_SCENARIO_ARR,
  doChangeAvbResumeScenario,
  doChangeKeiResumeScenario,
  EX_CORRESPOND,
  EX_NOT_CORRESPOND,
} from './const'
import AvbKeiExpertiseDeleteDialog from '@/components/dialogs/AvbKeiExpertiseDeleteDialog'
import KeiMethodEditor from '@/components/expModAvbKei/KeiMethodEditor'
import MkbTreeSelectDialog from '@/components/dict/indications/allForms/MkbTreeSelectDialog'
import CriterionTags from './tags/CriterionTags'
import { mapActions, mapGetters } from 'vuex'
import { sameObject } from '@/lib/objects'
import CriteriaCompare from '@/components/expModAvbKei/CriteriaCompare.vue'

export default {
  components: {
    CriteriaCompare,
    KeiMethodEditor,
    AvbKeiExpertiseDeleteDialog,
    MkbTreeSelectDialog,
    CriterionTags,
  },
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    kei: {
      type: Boolean,
      default: false,
    },
    initData: {
      type: Object,
    },
  },
  data: () => ({
    deleteDialog: false,

    qLevels: EX_QLEVELS_DEFAULT,
    qWeight: 0, //Весовой коэф метод качества
    withModel: false, //Была ли представлена математическая модель

    disabledTab: false,
    show: [],
    expertise: null,
    rules: [v => v.length > 0 || 'Это поле должно быть заполнено'],
    mkbTree: false, // показать диалог выбор дерева МКБ или нет
  }),
  computed: {
    ...mapGetters([
      'storedKeiCriteria',
      'storedAvbCriteria',
      'storedAddKeiCriteria',
      'storedPlpVersion',
    ]),
    EX_CORRESPOND: () => EX_CORRESPOND,
    EX_NOT_CORRESPOND: () => EX_NOT_CORRESPOND,
    getExpType() {
      return this.$route.meta.ex_type_sub
    },
    shortNameLength() {
      return SHORT_NAME_LENGTH
    },
    clinicalAdvantagesId() {
      return this.storedAddKeiCriteria?.find(
        ({ name }) => name === 'Клинические преимущества'
      )?.id
    },
    econAdvantagesId() {
      return this.storedAddKeiCriteria?.find(
        ({ name }) => name === 'Экономические преимущества'
      )?.id
    },
    resultsId() {
      return this.storedAddKeiCriteria?.find(
        ({ name }) => name === 'Основные результаты'
      )?.id
    },
    getResumeScenarioArr() {
      return this.kei
        ? EX_KEI_RESUME_SCENARIO_ARR()
        : EX_AVB_RESUME_SCENARIO_ARR()
    },
    storedCriteria() {
      return this.kei ? this.storedKeiCriteria : this.storedAvbCriteria
    },
    expElements() {
      return this.initData?.elements
    },
    modExpElements() {
      return this.expertise?.initElements
    },
    needRefresh() {
      return !sameObject(this.expElements, this.modExpElements)
    },
  },
  created() {
    this.expertise = this.value
    this.kei ? this.LOAD_KEI_CRITERIA() : this.LOAD_AVB_CRITERIA()
    if (!this.expertise.initElements) {
      this.refreshCriteria()
    }
    this.withModel = this.expertise?.withModel || false
    this.countLevels()
  },
  methods: {
    ...mapActions(['LOAD_KEI_CRITERIA', 'LOAD_AVB_CRITERIA']),
    refreshCriteria() {
      this.expertise.initElements = this.expElements
      this.doChange(true)
    },
    showMkbTree() {
      this.mkbTree = true
    },
    closeMkbTree() {
      this.mkbTree = false
    },
    // эмит из диалога с деревом: нам приходит строка кодов + строка кодов с расшифровками
    confirmCodes(codes, codesWithNames) {
      this.expertise.mkb10_codes = codes
      this.expertise.mkb10 = codesWithNames
      this.doChange(true)

      this.mkbTree = false // закрываем показ диалога
    },
    someClass(correspond) {
      if (correspond === false) return 'error--text'
      if (correspond === true) return 'success--text'
      return ''
    },
    removeItem() {
      this.$emit('removeAvbKeiExpertise')
      this.deleteDialog = false
    },
    updateCorrespond() {
      this.countLevels()
      this.doChange(true)
    },
    countLevels() {
      if (this.storedPlpVersion === 1) {
        this.qLevels = checkQLevels(
          this.getExpType,
          this.expertise.elements,
          this.storedPlpVersion || 1,
          this.withModel
        )
      } else {
        this.qLevels = checkQLevels(
          this.getExpType,
          this.expertise.elements,
          this.storedPlpVersion || 1,
          this.withModel
        ).qLevels
        this.qWeight = checkQLevels(
          this.getExpType,
          this.expertise.elements,
          this.storedPlpVersion || 1,
          this.withModel
        ).resultWeight
      }
    },
    showToggle(index) {
      this.$set(this.show, index, !this.show[index])
    },
    doBlur() {
      if (this.expertise.shortName.length === 0) return
      this.$emit('blur')
    },
    doUpdate(value, elementIndex) {
      this.expertise.elements[elementIndex].tagsSelected = value
      this.doChange(true)
    },
    doChangeModel() {
      this.expertise.withModel = this.withModel
      this.countLevels()
      this.doChange(true)
    },
    doChange(force = false) {
      const lock = this.expertise.shortName.length === 0
      this.disabledTab = lock
      if (!this.readonly) {
        // возвращаем расчитаное методологическое качество
        this.expertise.qLevels = getQLevel(this.qLevels)
        this.expertise.qWeight = this.qWeight
        this.$emit('change', force, lock)
      }
    },
    doChangeCompare(item, itemIndex, force) {
      this.expertise.elements[itemIndex] = item
      this.doChange(force)
    },
    doChangeCorrespond(item, itemIndex) {
      this.expertise.elements[itemIndex] = item
      this.updateCorrespond()
    },
    doChangeScenario(value) {
      if (this.kei) doChangeKeiResumeScenario(this.expertise.resume, value)
      else doChangeAvbResumeScenario(this.expertise.resume, value)
      // отправить обновление
      this.doChange(true)
    },
    referenceToggle(element, elementIndex, tabIndex) {
      this.$refs[`ele${elementIndex}`]?.[0].$el.classList.add('active-field')
      this.$emit('clickTemplate', element, tabIndex)
    },
    referenceAddKei(id, element, key, index = 0) {
      this.$emit('clickTemplateAdd', { id, element, key, index })
    },
    getTags(id) {
      return this.storedCriteria?.find(el => el.id === id)?.tags || []
    },
    hasTags(id) {
      return this.getTags(id)?.length > 0
    },
  },
}
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
.active-field {
  background-color: #ffeb3b;
}
.v-application .title {
  font-size: 16px !important;
}
.v-input .v-label {
  font-size: 14px;
}
/*.v-expansion-panel-content__wrap {
  padding: 0 !important;
}*/
</style>
