// данные plp из файла предложения
export const DEFAULT_PLP = {
  gnvlp: false, //1.1 перечень включения - ЖВНЛП
  vzn14: false, //1.1 перечень включения - 14ВЗН
  applicant: '', //2.1. Заявитель
  fullName: '', //2.2. ФИО должностного лица
  address: '', //2.3. адрес
  phone: '', //2.3. телефон
  email: '', //2.3. эл. Почта
  nameType: '', //сборник 3.1.2. группировочное наименование / 3.1.3. химическое наименование / 3.1.4. торговое наименование
  mnn: '', //3.1.1. МНН
  ath: '', //3.2. АТХ
  lForma: '', //3.7. ЛФ
  ruNumber: '', //3.4. номер РУ
  ruDate: '', //3.4. дата РУ
  ruDateConfirm: '', //3.4. дата подтверждения РУ
  ruFiles: {
    //3.4. РУ - ссылки на документ
    text: '',
    data: [],
  },
  lpCompareDrug: '', //3.5. юр. Лицо препарата сравнения
  localizationData: '', //3.6. данные о локализации
  reproducedLP: true, //3.8. Воспроизведенные препараты - часть одного целого
  reproducedLPtext: '', //REPRODUCED_DEFAULT_TEXT, //3.8. Воспроизведенные препараты - часть одного целого
  reproducedLPloadedText: '', //3.8. Воспроизведенные препараты - часть одного целого
  isOrphan: false, // орфанный статус - его нет в экселе но есть в ТЗ
  isOrphanByIndication: false, // орфанный статус
  condition: '', //3.3. Показания
  comment: '', // нет в экселе но есть в ТЗ
  epidNecessity: {
    //4.1. Эпид. необходимость - ссылки на документы
    text: '',
    data: [],
  },
  justification14vzn: {
    //4.2. 14ВЗН обоснование - ссылки на документы
    text: '',
    data: [],
  },
  advantages: {
    //4.3. Преимущества - ссылки на документы
    text: '',
    data: [],
  },
  region: {
    //4.4. Регионы - ссылки на документы
    text: '',
    data: [],
  },
  list: {
    //4.5. Страт. Перечень
    text: '',
    data: [],
  },
  localization: {
    //4.6. Локализация - ссылки на документы
    text: '',
    data: [],
  },
  epidData: {
    //5.1. Эпид. Данные - ссылки на документы
    text: '',
    data: [],
  },
  clinData: {
    //5.2. Клин. данные - ссылки на документы
    text: '',
    data: [],
  },
  equivalence: {
    //5.3. тер. Эквивалентность - ссылки на документы
    text: '',
    data: [],
  },
  economy: {
    //5.4. Экономика - ссылки на документы
    text: '',
    data: [],
  },
  DACdata: {
    //5.5. Дозировка (таблица цен, комментарии, файлы)
    data: [],
    text: '',
    table: [],
  },
  salesVolume: {
    //5.6. Объемы продаж - ссылки на документы
    text: '',
    data: [],
  },
  securityMonitoring: {
    //5.7. Монитор. Безопасн. - ссылки на документы
    text: '',
    data: [],
  },
  initiative: {
    //5.8. Инициатива - ссылки на документы
    text: '',
    data: [],
  },
  pVersion: '1.0', //версия парсера
  file: '', // имя файла
}

// минимальная длина короткого имени
export const SHORT_NAME_LENGTH = 20

// сколько позиций в фильтре годов для ответов от текущего года
export const EX_ANSWER_YEAR_QUANTITY = 5
