<template>
  <OverlayTemplate
    v-bind="$attrs"
    :config="templates"
    @add="addResult"
    @close="$emit('close', $event)"
  />
</template>

<script>
import { RESULTS_TEMPLATE } from './results'
import OverlayTemplate from './OverlayTemplate'

export default {
  name: 'OverlayResults',
  components: { OverlayTemplate },
  data: () => ({
    templates: [{ header: 'Результаты', templates: RESULTS_TEMPLATE }],
  }),
  methods: {
    addResult(idx, template) {
      this.$emit('add', template)
    },
  },
}
</script>
