<template>
  <VersionSelect v-if="plp_task_id">
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <ad-list
        :value="data"
        :readonly="readonly"
        :onCreate="onCreate"
        @change="onChange"
        @blur="onBlur"
      />
    </template>
    <template #prepend-bottom="{ readonly, is_analog_completed }">
      <btn-with-confirm
        v-if="is_analog_completed"
        v-show="!readonly"
        :loading="loading"
        left
        iconMain="mdi-check-bold"
        color="primary"
        @click="analogToRework"
        title="Этап экспертизы аналоги не готов к проверки - требуется доработка"
        >Аналоги - не готово!</btn-with-confirm
      >
      <btn-with-confirm
        v-else
        v-show="!readonly"
        :loading="loading"
        left
        iconMain="mdi-arrow-up-left-bold"
        color="success"
        @click="analogComplete"
        title="Этап экспертизы аналоги готовы к проверки"
        >Аналоги - готово</btn-with-confirm
      >
    </template>
  </VersionSelect>
</template>

<script>
import btnWithConfirm from '@/components/buttons/btnWithConfirm'
import { mapGetters, mapActions } from 'vuex'
import VersionSelect from '@/components/VersionSelect'
import adList from '@/components/expAnalogs/adList'

export default {
  data() {
    return {
      loading: false,
    }
  },
  components: { VersionSelect, adList, btnWithConfirm },
  computed: {
    ...mapGetters(['plp_task_id']),
  },
  methods: {
    ...mapActions(['UPDATE_PLP_TASK']),
    async analogToRework() {
      this.loading = true
      try {
        await this.UPDATE_PLP_TASK({
          is_analog_completed: false,
        })
      } finally {
        this.loading = false
      }
    },
    async analogComplete() {
      this.loading = true
      try {
        await this.UPDATE_PLP_TASK({
          is_analog_completed: true,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.container {
  width: 100% !important;
}
</style>
