<template>
  <v-card flat>
    <v-card-title>Оценка АВБ </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        :headers="avbHeaders"
        :items="AVB"
        hide-default-footer
        :items-per-page="100"
      >
        <template #[`item.percent`]="{ value }">
          <div v-for="item in value" :key="item.id">
            {{ item }}
            <hr />
          </div>
        </template>
        <template #[`item.score`]="{ value }">
          <div v-for="item in value" :key="item.id">
            <span class="font-weight-bold">{{ item }}</span>
            <hr />
          </div>
        </template>
        <template #[`item.result`]="{ value, item }">
          <span v-if="item.percent.length || item.score.length">
            {{ value }}
          </span>
          <span v-else class="font-weight-bold">
            {{ value }}
          </span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
const AVB = [
  {
    number: 1,
    result:
      'Применение предлагаемого лекарственного препарата приводит к снижению прямых медицинских затрат* по сравнению с текущей практикой терапии',
    percent: ['более 60', 'от 40 до 60', 'от 20 до 40', 'от 10 до 20'],
    score: [8, 6, 4, 2],
  },
  {
    number: 2,
    result:
      'Применение предлагаемого лекарственного препарата приводит к несущественному изменению прямых медицинских затрат* по сравнению с текущей практикой терапии',
    percent: ['до 10'],
    score: [1],
  },
  {
    number: 3,
    result:
      'Применение предлагаемого лекарственного препарата приводит к увеличению прямых медицинских затрат* по сравнению с текущей практикой терапии',
    percent: ['от 10 до 20', 'от 20 до 40', 'от 40 до 60', 'более 60'],
    score: [0, -1, -2, -3],
  },
]
export default {
  name: 'AvbScore',
  data: () => ({
    AVB,
    avbHeaders: [
      {
        text: '№ п/п',
        value: 'number',
        sortable: false,
      },
      {
        text: 'Результат оценки',
        value: 'result',
        sortable: false,
      },
      {
        text: 'Процент отклонения',
        value: 'percent',
        sortable: false,
        width: '200px',
      },
      {
        text: 'Балл',
        value: 'score',
        sortable: false,
      },
    ],
  }),
}
</script>

<style scoped></style>
