<template>
  <v-form v-if="data" :readonly="readonly" ref="form">
    <v-row>
      <v-col>
        <!-- справочная информация -->
        <HelpCard
          v-if="!logView"
          class="mt-2"
          caption="Показание препарата"
          open
        >
          <v-alert v-if="about.error" type="error" outlined dense>
            {{ about.error }}
          </v-alert>
          <v-card v-else :loading="about.loading" flat>
            <v-card-text v-if="about.data" class="pt-0">
              <v-textarea
                v-if="about.data.about_ready"
                :value="about.data.condition"
                readonly
                auto-grow
                rows="2"
              />
              <v-alert v-else type="warning" outlined dense>
                Ожидание сводной версии "Информации о препарате" (см. показание
                <b>ГРЛС</b> / <b>ЕАЭС</b>)
              </v-alert>
            </v-card-text>
          </v-card>
        </HelpCard>
      </v-col>
    </v-row>
    <!-- Экспертиза -->
    <v-row>
      <v-col>
        <v-tabs @change="doBlur()">
          <v-tab
            v-model="activeTab"
            v-for="(agency, agency_index) in data.data"
            :key="agency_index"
          >
            {{ agency.name }}
          </v-tab>
          <v-tab-item
            v-for="(agency, agency_index) in data.data"
            :key="agency_index"
            class="pa-2"
          >
            <!-- Карточка экспертизы "item.name"  -->
            <v-card
              v-for="(item, index) in agency.data"
              :key="index"
              class="my-2"
            >
              <v-card-title class="py-1">
                <v-container
                  class="pa-0"
                  v-if="agency.data && agency.data.length > 1"
                >
                  <v-row dense no-gutters
                    ><v-col class="font-weight-bold">{{ index + 1 }}.</v-col>
                    <v-col md="1" v-if="!readonly" class="text-right">
                      <v-btn
                        v-if="agency.data && agency.data.length > 1"
                        icon
                        small
                        elevation="0"
                        color="warning"
                        @click="showDeleteItemDlg(agency_index, index)"
                        :title="`Удалить заключение для агенства &quot;${agency.name}&quot;`"
                        ><v-icon>mdi-close </v-icon></v-btn
                      >
                    </v-col></v-row
                  >
                </v-container>
              </v-card-title>
              <v-card-text>
                <v-textarea
                  label="Оригинал заключения"
                  v-model="item.original"
                  auto-grow
                  @input="doChange()"
                  @blur="doBlur()"
                />
                <v-textarea
                  label="Перевод"
                  v-model="item.translate"
                  auto-grow
                  @input="doChange()"
                  @blur="doBlur()"
                />
                <!-- ссылка на заключение -->
                <ArrangeLink
                  v-model="item.link"
                  :link="readonly"
                  @change="doChange()"
                  @blur="doBlur()"
                />
              </v-card-text>
            </v-card>
            <!-- Кнопка добавления ещё карточки -->
            <div v-if="!readonly" class="text-right">
              <v-btn
                color="accent"
                fab
                small
                @click="addItem(agency_index)"
                :title="`Добавить новое заключение для агенства &quot;${agency.name}&quot;`"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </div>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <ExpAgencyDeleteDialog
      v-if="data && !readonly"
      title="Подтверждение удаления"
      :lb="`Вы действительно хотите удалить ${
        delete_dialog.index + 1
      } заключение агенства ${getAgencyName(data.data[delete_dialog.agency])}`"
      :name="delete_text"
      :dialog="delete_dialog.show"
      @onClose="delete_dialog.show = false"
      @onAction="deleteItem()"
    ></ExpAgencyDeleteDialog>
  </v-form>
</template>

<style scoped>
.v-card__title {
  font-size: 14px;
}
</style>

<script>
import { copyObject } from '@/lib/objects'
import { EX_AGENCIES_DEFAULT } from './const'
import { SUB_EX_ABOUT } from '@/store/const/expertise'
import ArrangeLink from '@/components/editors/ArrangeLink'
import HelpCard from '@/components/HelpCard'

import ExpAgencyDeleteDialog from '@/components/dialogs/ExpAgencyDeleteDialog'
import { mapActions } from 'vuex'

export default {
  name: 'Exp-Agency',
  components: { ArrangeLink, HelpCard, ExpAgencyDeleteDialog },
  props: {
    value: {
      type: Object,
      //required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
    logView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: null,
    activeTab: 0,
    delete_dialog: {
      show: false,
      agency: null,
      index: null,
    },
    // about
    about: {
      loading: false,
      error: '',
      data: null,
    },
  }),
  watch: {
    value: 'reInit',
    readonly: 'reInit',
  },
  async created() {
    if (this.readonly) this.data = this.value
    else this.data = copyObject(this.value)

    if (!this.logView) {
      await this.loadAbout()
    }

    if (!this.data && this.onCreate) {
      console.log('need create expertise - agency')
      this.data = await this.onCreate(this.initNewData())
    }
  },
  computed: {
    delete_text() {
      return (
        this.data.data[this.delete_dialog?.agency]?.data[
          this.delete_dialog?.index
        ]?.original.substring(0, 100) + '...' ?? ''
      )
    },
  },
  mounted() {
    this.reInit()
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),

    getAgencyName(name = '') {
      return name?.name || ''
    },
    reInit() {
      if (this.readonly) this.data = this.value
      if (!this.readonly)
        this.$nextTick().then(() => this.$refs.form?.validate())
    },
    // Генерируем новые данные для экспертизы в первый раз
    initNewData() {
      return copyObject(EX_AGENCIES_DEFAULT)
    },
    // данные поменялись кидаем наверх
    doChange(force = false) {
      if (!this.readonly) this.$emit('change', this.data, force)
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },

    // новыя экспертиза по типу
    addItem(agency_index) {
      this.data.data[agency_index].data.push(
        copyObject(EX_AGENCIES_DEFAULT.data[agency_index].data[0])
      )
      this.doChange()
    },
    showDeleteItemDlg(agency, index) {
      this.delete_dialog = { show: true, agency, index }
    },
    deleteItem() {
      const { agency, index } = this.delete_dialog
      this.data.data[agency].data.splice(index, 1)
      this.delete_dialog = { show: false }
      this.doChange(true)
    },
    async loadAbout() {
      this.about = { loading: true, data: null, error: '' }
      try {
        const { expertise } = await this.GET_PLP_REPORT([SUB_EX_ABOUT])
        const about_ready = !!expertise?.about
        const condition = expertise?.about?.condition || ''
        this.about.data = {
          about_ready,
          condition,
        }
      } catch (err) {
        this.about.error = err.message + (err.error ? ' ' + err.error : '')
        throw err
      } finally {
        this.about.loading = false
      }
    },
  },
}
</script>
