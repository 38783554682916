<template>
  <v-container>
    <v-form v-if="data" :readonly="readonly">
      <!--Опросник по критериям-->
      <v-card class="mb-2 pl-3">
        <v-card-title style="word-break: break-word">
          Шкала количественной оценки дополнительных данных о лекарственном
          препарате
        </v-card-title>
        <v-card-text>
          <div v-for="position in data.other" :key="position.id" class="mb-1">
            <div v-if="position.text === 'Преобладание заболеваемость'">
              <v-checkbox
                v-model="position.state"
                :label="position.text"
                color="primary"
                persistent-hint
                :hint="getComment(position.id) | capitalize"
                :title="`Добавляет ${ru_ending(position.weight, [
                  'балл',
                  'балла',
                  'баллов',
                ])}`"
                @click="setWeight(position)"
              >
              </v-checkbox>
              <v-text-field
                class="ml-10 my-5"
                dense
                outlined
                label="Например, заболеваемость по показанию Х 10 000 случаев в год (Данные Росстата)"
                v-model="data.comment"
                @input="doChange()"
                @blur="doBlur()"
                hide-details
              />
            </div>
            <v-checkbox
              v-else
              v-model="position.state"
              :label="position.text"
              color="primary"
              persistent-hint
              :hint="getComment(position.id) | capitalize"
              :title="`Добавляет ${ru_ending(position.weight, [
                'балл',
                'балла',
                'баллов',
              ])}`"
              @click="setWeight(position)"
            >
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-expansion-panels class="mb-2" flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="text-h6 text--secondary">
                <span>
                  Сумма баллов:
                  <span class="text-h5 text--primary">{{
                    data.sum
                  }}</span></span
                >
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="text-justify text-body-2 red-line"
              >
                {{ sumDescription }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-actions>
      </v-card>
    </v-form>

    <!-- справочная информация -->
    <HelpCard v-if="!logView" class="mt-2" open>
      <!--------------------------------------->
      <!--инфрормация о АВБ, об эпидемиологии-->
      <v-card :loading="avb.loading" shaped outlined>
        <v-card-title>Численность целевой популяции</v-card-title>
        <v-card-text>
          <v-alert v-if="avb.error" type="warning" outlined dense>
            Нет доступтной экспертизы "АВБ"
            <span class="font-italic cursor-help"
              >(возможно она ещё не была начата)</span
            >
          </v-alert>
          <v-tabs v-else>
            <v-tab v-for="(elem, index) in avb.data" :key="index">
              {{ elem.shortName }}
            </v-tab>
            <v-tab-item v-for="(elem, index) in avb.data" :key="index">
              <v-card flat>
                <v-card-text>
                  <AutoGrowTextEditor :value="getEpidemiology(elem)" rows="3"
                /></v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <!--вызов списка файлов из п.5.1 эпид данные-->
          <PlpFilesList plp-key="epidData" />
        </v-card-text>
      </v-card>
    </HelpCard>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PlpFilesList from '@/components/plp/PlpFilesList'
import HelpCard from '@/components/HelpCard'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import { EX_EXPERTISE, SUB_EX_AVB } from '@/store/const/expertise'
import { OTHER_MEDICINE_EXP, SUM_DESCRIPTION, resetStates } from './const'
import { copyObject } from '@/lib/objects'
import { ru_ending } from '@/lib/strUtils'

export default {
  name: 'Exp-Other',
  components: { PlpFilesList, HelpCard, AutoGrowTextEditor },
  props: {
    value: {
      type: Object,
      // required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
    logView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sumDescription: SUM_DESCRIPTION,
    data: null,
    options: {},
    // AVB
    avb: {
      loading: false,
      error: '',
      data: null,
    },
  }),

  async created() {
    // делаем подготовку сервисных данных
    OTHER_MEDICINE_EXP.forEach(({ id, group, commentary }) => {
      this.options[id] = { group, commentary }
    })

    if (this.readonly) this.data = this.value
    else this.data = copyObject(this.value)

    if (!this.data && this.onCreate) {
      console.log('need create expertise - OTHER')
      this.data = await this.onCreate(this.initNewData())
    }

    // загрузим АВБ
    await this.loadAVB()
  },

  watch: {
    value: 'reInit',
    readonly: 'reInit',
  },

  computed: {
    ...mapGetters(['storedPlpData']),
    files() {
      return this.storedPlpData?.epidData?.data ?? []
    },
  },

  methods: {
    ...mapActions(['GET_MY_LAST_EXPERTISE_DATA']),
    initNewData() {
      const other = copyObject(OTHER_MEDICINE_EXP)
      other.forEach(item => {
        delete item['group']
        delete item['commentary']
      })
      return { other, sum: 0, comment: '' }
    },
    reInit() {
      if (this.readonly) this.data = this.value
    },
    getComment(id) {
      // ищу комметарий в имходном объекте по ID
      return this.options[id]?.commentary || ''
    },
    ru_ending: (num, words) => ru_ending(num, words),
    calcSum() {
      this.data.sum = this.data
        ? Object.values(this.data.other)
            .filter(v => v.state)
            .reduce((a, b) => a + b.weight, 0)
        : 0
      this.doChange(true)
    },
    setWeight(position) {
      // сбрасываем галки у групп 1 и пересчитываем сумму
      const { id, state } = position
      resetStates(state, id, this.data, this.options)
      this.calcSum()
    },
    // данные поменялись кидаем наверх
    doChange(force = false) {
      if (!this.readonly) this.$emit('change', this.data, force)
    },
    doBlur() {
      this.$emit('blur')
    },

    async loadAVB() {
      // Получим данные из экспертизы About - для теста
      this.avb = { loading: true, data: null, error: '' }
      try {
        const { ka } = await this.GET_MY_LAST_EXPERTISE_DATA({
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_AVB,
        })
        this.avb.data = ka
      } catch (err) {
        this.avb.error = err.message + (err.error ? ' ' + err.error : '')
      } finally {
        this.avb.loading = false
      }
    },

    getEpidemiology(avbElement) {
      const epidemiologyArr = avbElement?.resume?.epidemiology || []
      const count = Array.isArray(epidemiologyArr) ? epidemiologyArr.length : 1
      if (count < 2) return String(epidemiologyArr || '')

      return epidemiologyArr
        .reduce(
          (text, item, idx) =>
            `${text}${text ? '\n' : ''}Сценарий ${idx + 1}: ${item}`,
          ''
        )
        .trim()
    },
  },
}
</script>
