var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"mainManual",attrs:{"height":"700","loading":_vm.loading,"flat":""}},[(_vm.isManager && !_vm.selectMode)?_c('div',{staticClass:"text-end"},[_c('span',{staticClass:"tip pointer",on:{"click":_vm.changeOpen}},[_vm._v(_vm._s(_vm.openText))])]):_vm._e(),(_vm.isManager && !_vm.selectMode)?_c('ReferenceTip',{staticClass:"mb-3 mt-1"},[_vm._v(" Выгрузка справочника КР в формате Excel доступна внизу таблицы, кнопка \"Выгрузить справочник\" ")]):_vm._e(),(_vm.isManager && !_vm.selectMode)?_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}]},[_c('ReferenceTip',[_vm._v("Для обновления справочника КР необходимо выбрать файл (*.xlsx), нажать \"Обновить справочник\""),_c('br'),_vm._v("В результате обновления произойдет полная замена текущего справочника")]),_c('UpdateCrManual')],1)]):_vm._e(),_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"loading":_vm.loading,"prepend-inner-icon":"mdi-magnify","label":"Поиск по названию","single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('v-data-table',{attrs:{"loading":_vm.loading,"dense":"","search":_vm.search,"item-key":"shortName","headers":_vm.headers,"items":_vm.changedKr,"items-per-page":10,"single-select":"","show-select":_vm.selectMode,"footerProps":_vm.table_footer_options,"loading-text":"Справочник загружается"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.search)}})]}},{key:"item.association",fn:function(ref){
var value = ref.value;
return [_c('expand-text',{attrs:{"value":value,"words-count":1}})]}},{key:"item.npc",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"title":value,"color":"primary"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.onRubricator",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"title":value,"color":"primary"}},[_vm._v("mdi-check")]):_vm._e()]}},(_vm.isManager)?{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex align-end justify-end"},[(_vm.dictCr && !_vm.loading && !_vm.selectMode)?_c('export-cr',{staticClass:"my-3",attrs:{"storedData":_vm.dictCr}}):_vm._e()],1)]},proxy:true}:null],null,true),model:{value:(_vm.selectItem),callback:function ($$v) {_vm.selectItem=$$v},expression:"selectItem"}})],1),(_vm.selectMode)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"disabled":!_vm.selectItem || !_vm.selectItem.length,"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Добавить ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }