<template>
  <action-dialog
    title="Удаление формы выпуска"
    :name="name"
    v-model="dialogState"
    @onAction="closeAndAction"
    @onClose="closeDialog"
    colorBtnAction="error"
    label="Вы действительно хотите удалить текущую форму выпуска ?"
    labelBtnClose="ОТМЕНА"
    labelBtnAction="УДАЛИТЬ"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text title="Удалить форму выпуска" v-bind="attrs" v-on="on">
        <v-icon>mdi-delete-forever-outline</v-icon>
        Удалить форму выпуска
      </v-btn>
    </template>
  </action-dialog>
</template>

<script>
import ActionDialog from '@/components/dialogs/ActionDialog'
export default {
  name: 'PricingDeleteDialog',
  components: { ActionDialog },
  data: () => ({
    dialogState: false,
  }),

  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    closeDialog() {
      this.dialogState = false
      this.$emit('onClose')
    },
    closeAndAction() {
      this.dialogState = false
      this.$emit('onAction')
    },
  },
}
</script>
