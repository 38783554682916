<template>
  <v-dialog v-model="showing" max-width="1200px" persistent scrollable>
    <v-card loading="updating">
      <v-card-title>
        Выбор лекарственной формы и данных об аналогах
      </v-card-title>
      <v-card-subtitle
        v-if="!updating"
        class="caption text-right text--secondary"
      >
        {{ dictMedicineDescription }}
      </v-card-subtitle>
      <v-card-text>
        <v-toolbar flat class="mt-2">
          <v-text-field
            v-model="search"
            label="МНН"
            class="mx-4"
            clearable
            :persistent-hint="!!lForma"
            :hint="lForma"
          />
          <v-spacer />
          <v-switch
            v-model="jvnlp"
            label="ЖВНЛП"
            title="Показывать только ЖВНЛП"
            hide-details
            class="mr-4"
          />
          <v-switch
            v-if="!onlyOneSelected"
            v-model="singleSelect"
            label="Выбрать несколько позиций"
            hint="Выбранные значения будут аггрегированы"
            :persistent-hint="singleSelect"
            @change="selectedRows = []"
            hide-details
          />
        </v-toolbar>
      </v-card-text>
      <v-divider />
      <v-card-text class="px-0">
        <v-data-table
          v-model="selectedRows"
          :headers="filteredHeaders"
          :items="filteredDictMedicine"
          :search="search"
          :custom-filter="customFilter"
          :loading="updating"
          :single-select="!singleSelect"
          loading-text="Загрузка справочника МНН... Пожалуйста ждите."
          item-key="id"
          show-select
          :group-by="['innInternal']"
          :show-expand="!hideDetails"
          :footerProps="table_footer_options"
          :items-per-page="15"
        >
          <template v-slot:top> </template>

          <template #[`group.header`]="{ group, headers, items }">
            <td :colspan="headers.length">
              <span v-if="items" class="text-overline font-weight-bold">
                {{ items[0].innInternal }}
              </span>
              <span v-else>{{ group }}</span>
            </td>
          </template>

          <template v-slot:[`item.isJVNLP`]="{ value }">
            {{ value ? 'Да' : '' }}
          </template>

          <template v-slot:[`item.regNr`]="{ value }">
            {{ value }}
          </template>

          <template v-slot:[`item.regDateFin`]="{ value }">
            {{ value ? value : unlimited_text }}
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container fluid class="my-4">
                <v-row>
                  <v-col class="text-subtitle-2">
                    Официальные данные (ГРЛС)
                  </v-col>
                </v-row>
                <v-row v-if="item.innOficialList.length > 0">
                  <v-col cols="1" class="text-right font-weight-bold">
                    Название
                  </v-col>
                  <v-col cols="11" class="text-left">
                    <span
                      v-for="(item, index) in item.innOficialList"
                      :key="index"
                      >{{ item }}</span
                    >
                  </v-col>
                </v-row>

                <v-row v-if="item.regNrList.length > 0">
                  <v-col cols="1" class="text-right font-weight-bold">
                    АТХ
                  </v-col>
                  <v-col cols="11" class="text-left">
                    <span>{{ item.atxGroupCode }}</span>
                  </v-col>
                </v-row>

                <v-row v-if="item.regNrList.length > 0">
                  <v-col cols="1" class="text-right font-weight-bold">
                    РУ
                  </v-col>
                  <v-col cols="11" class="text-left">
                    <span>{{ item.regNrList.join(', ') }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="item.dosageFormOficialList.length > 0">
                  <v-col cols="1" class="text-right font-weight-bold">
                    ЛФ
                  </v-col>
                  <v-col cols="11" class="text-left">
                    <span>{{ item.dosageFormOficialList.join(', ') }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-subtitle-2">
                    Обращение / госзакупки
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="11" offset="1">
                    <v-data-table
                      :headers="groupHeaders"
                      :items="getByYear(item)"
                      item-key="id"
                      disable-sort
                      class="elevation-1"
                      hide-default-footer
                      disable-pagination
                      dense
                    >
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider />
      <v-card-actions class="secondary lighten-5">
        <!-- Результаты -->
        <v-container class="pa-0 pt-2" style="min-height: 70px" fluid>
          <v-row v-if="showTotals">
            <v-col :cols="jvnlp ? 8 : 7" class="font-weight-medium">
              <div class="d-flex">
                <div style="min-width: 60px" v-if="singleSelect">
                  [
                  <strong title="Выбрано ЛФ">{{ selectedRows.length }}</strong>
                  ]
                </div>
                <v-select
                  v-if="!hideSelectLf"
                  label="Лекарственная форма"
                  v-model="innInternal"
                  :items="totals.lfs"
                  item-text="lf"
                  item-value="lf"
                  hide-details
                  dense
                  :readonly="totals.lfs.length <= 1"
                  ><template #append-outer v-if="totals.lfs.length > 1"
                    ><v-chip
                      small
                      title="Кол-во вариантов лекарственных форм"
                      >{{ totals.lfs.length }}</v-chip
                    >
                  </template>
                  <template #append v-if="totals.lfs.length <= 1"
                    ><v-icon>mdi-blanc</v-icon></template
                  ></v-select
                ><span class="text--disabled" v-else>
                  {{ lForma || innInternal }}</span
                >
              </div>
            </v-col>
            <v-col v-if="!jvnlp" cols="1" class="text-center text-caption">{{
              totals.isJVNLP ? 'ЖВНЛП' : ''
            }}</v-col>
            <v-col v-if="!hideDetails" cols="2" class="text-center"
              >РУ: <strong>{{ totals.regNr }}</strong></v-col
            >
            <v-col v-if="!hideDetails" cols="2" class="font-weight-bold">{{
              totals.regDateFin ? totals.regDateFin : unlimited_text
            }}</v-col>
          </v-row>
          <!-- Обращение / ГЗ -->
          <v-row v-if="!hideDetails && showTotals" class="text-body-2">
            <v-col class="text-right py-0"
              >Обращение:
              <strong v-if="totals.obr">{{
                totals.obr.replace('\n', ' \\ ')
              }}</strong>
            </v-col>
            <v-col class="text-center py-0"
              >Упаковки ГЗ: <strong>{{ totals.govPack }}</strong>
            </v-col>
            <v-col class="text-start py-0"
              >Стоимость ГЗ: <strong>{{ totals.govPrice }}</strong>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <slot />
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!showTotals || readonly"
          text
          @click="getSelected"
          class="mr-3"
        >
          Выбрать
        </v-btn>
        <v-btn text @click="closeDialog" class="mr-3"> Отмена </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  UNLIMITED_TEXT,
  ANALOG_AD_DATA_FIELDS,
  ANALOG_AD_DATA_DEFAULT,
  ANALOG_LOOKUP_DATA_FUNC,
} from './const'
import { ntl } from '@/lib/strUtils'
import { searchByWords } from '../../lib/strUtils'

function getMaxDate(d1, d2) {
  if (!d1 || !d2) return null
  const dateTime = /(\d{2}).(\d{2}).(\d{4})/
  const match1 = dateTime.exec(d1)
  const match2 = dateTime.exec(d2)

  if (!match2) return null

  const t1 = new Date(
    Number(match1[3]),
    Number(match1[2]) - 1,
    Number(match1[1])
  ).getTime()
  const t2 = new Date(
    Number(match2[3]),
    Number(match2[2]) - 1,
    Number(match2[1])
  ).getTime()

  return t1 > t2 ? d1 : d2
}

export default {
  name: 'AnalogLFList',
  props: {
    value: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    useZeroYear: {
      type: Boolean,
      default: false,
    },
    hideSelectLf: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    onlyOneSelected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    jvnlp: true,
    singleSelect: false,
    selectedRows: [],
    innInternal: '',
    updating: false,
    search: '',
    error: '',
    dialogShow: false,
    groupHeaders: [
      {
        text: 'Год',
        value: 'year',
        width: '75px',
        align: 'center',
        cellClass: 'text--disabled font-weight-bold',
      },
      { text: 'Обращение', value: 'q', align: 'center' },
      { text: 'Упаковки ГЗ', value: 'p', align: 'center' },
      { text: 'Стоимость ГЗ', value: 'pr', align: 'center' },
    ],
    headers: [
      // {
      //   text: 'АТХ',
      //   value: 'atxGroupCode',
      //   width: '50px',
      //   cellClass: 'text--disabled',
      // },
      { text: 'МНН', value: 'innInternal' },
      {
        text: 'ЛФ',
        value: 'dosageFormInternal',
        cellClass: 'font-weight-medium',
      },
      {
        text: 'ЖВНЛП',
        value: 'isJVNLP',
        sortable: false,
        align: 'center',
        cellClass: 'text-caption text--secondary',
        filterable: false,
        width: '100px',
      },
      {
        text: 'РУ',
        value: 'regNr',
        width: '130px',
        align: 'center',
        detail: true,
      },
      {
        text: 'Дата окончания РУ',
        value: 'regDateFin',
        width: '170px',
        align: 'center',
        detail: true,
      },
      { text: '', value: 'data-table-expand', filterable: false, detail: true },
    ],
    table_footer_options: {
      itemsPerPageOptions: [15, 25, 50, 100],
    },
  }),
  async created() {
    this.updating = true
    this.error = ''
    this.dialogShow = this.show
    try {
      this.search = this.value?.mnn ?? ''
      await this.GET_MEDICINE_DICT()
    } catch (error) {
      this.error = error
    } finally {
      this.updating = false
    }
  },
  watch: {
    show(val) {
      this.dialogShow = val
    },
    totals(val) {
      if (val.lfs?.indexOf(this.innInternal) === -1) {
        this.innInternal = val.lfs?.length ? val.lfs[0].lf : ''
      }
    },
  },
  computed: {
    ...mapGetters([
      'dictMedicine',
      'dictMedicineYear',
      'dictMedicineDescription',
    ]),
    lForma() {
      return this.value?.lForma
    },
    unlimited_text() {
      return UNLIMITED_TEXT
    },
    filteredHeaders() {
      return this.headers
        ?.filter(({ value }) => !this.jvnlp || value !== 'isJVNLP')
        .filter(({ detail }) => !this.hideDetails || !detail)
    },
    filteredDictMedicine() {
      return (
        this.dictMedicine?.filter(({ isJVNLP }) => !this.jvnlp || isJVNLP) ?? []
      )
    },
    showTotals() {
      return this.selectedRows?.length >= 1
    },
    totals() {
      const temp = new Set()
      const minDate = '01.01.1900'
      const lfs = new Map()

      const totals = this.selectedRows.reduce(
        (acc, item, i) => {
          // соберём уникальные ЛФ
          lfs.set(item.dosageFormInternal, 1)
          // а также все ЛФ по ГРЛС
          item.dosageFormOficialList?.forEach(lf => lfs.set(lf, 0))

          // накапливаем даты окончания регистрации РУ
          if (this.selectedRows.length === 1) {
            acc.regDateFin = item.regDateFin
            acc.regNr = item.regNr
          } else {
            acc.regDateFin = getMaxDate(
              item.regDateFin,
              i !== 0 ? acc.regDateFin : minDate
            )
            item.regNrList?.forEach(regNr => temp.add(regNr?.toUpperCase()))
            const val = [...temp.values()]
            acc.regNr = val.length === 1 ? val[0] : val.length
          }

          // Просуммирвоать все поля данных
          ANALOG_AD_DATA_FIELDS.forEach(key => {
            acc.dictData[key] += item[key] ?? 0
          })

          // для красоты
          acc.isJVNLP ||= item.isJVNLP
          return acc
        },
        {
          isSingleRow: true,
          innInternal: '',
          regDateFin: null,
          regNr: '',
          // данные из справочника
          // год берём по факту ( в теории они все одинаковые на весь справочник)
          dictData: ANALOG_AD_DATA_DEFAULT(this.dictMedicineYear),
          isJVNLP: false,
        }
      )
      //Достаём список всех ЛФ
      totals.lfs = [...lfs.entries()]
        .map(el => ({ lf: el[0], off: !el[1] }))
        .sort((a, b) => a.lf?.localeCompare(b.lf))

      if (!totals.regDateFin) totals.regDateFin = UNLIMITED_TEXT

      return {
        ...totals,
        ...ANALOG_LOOKUP_DATA_FUNC(totals.dictData, this.useZeroYear),
      }
    },

    showing: {
      get() {
        return this.dialogShow
      },
      set(value) {
        this.dialogShow = value
        this.closeDialog()
      },
    },
  },
  methods: {
    ...mapActions(['GET_MEDICINE_DICT']),
    getSelected() {
      const { totals, innInternal } = this
      this.$emit('updateLF', { ...totals, innInternal })
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    getByYear(item) {
      return [0, 1, 2].map(id => ({
        id,
        year: item.yearData - id ?? '-',
        q: ntl(item[`quantitybyYear${id}`], '-'),
        p: ntl(item[`countPackbyYear${id}`], '-'),
        pr: ntl(item[`contractCostbyYear${id}`], '-'),
      }))
    },
    customFilter(value, search) {
      if (!search) return true
      // сравниваем без учёта пунктуации и регистронезависимо
      return searchByWords(value, search)
    },
  },
}
</script>

<style scoped>
div >>> .v-toolbar__content {
  padding: 10px 0;
}
</style>
