<template>
  <v-form :readonly="readonly">
    <v-row>
      <v-col>
        <!-- Табы  -->
        <v-card class="mb-2">
          <v-card-title> Оценка экономических последствий </v-card-title>
          <v-card-text>
            <EconomicTable
              :value="value"
              :readonly="readonly"
              @change="doChangeEconomic"
              @blur="doBlur"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { SHORT_NAME_LENGTH } from '@/store/const/expDefaults'
import { copyObject } from '@/lib/objects'
import ls from '@/lib/localStorageUtils'
import EconomicTable from '@/components/expSummary/summaryScore/comp/EconomicTable.vue'
import { ASSESSMENT_ORGANIZATION_DEFAULT } from '@/components/expAssessment/const'

export default {
  components: { EconomicTable },
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
    dictType: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    renderComponent: true,
    economicAssessment: null,
    data: null,
    dt: undefined, //dictType
    newExpertiseName: '',
    activeTab: 0,
    disabledTab: false,
    answerYear: ls.load('answerYear'),
  }),
  mounted() {
    if (this.readonly) this.data = this.value
    else this.data = copyObject(this.value)

    if (!this.data && this.onCreate) {
      this.data = this.onCreate(this.initNewData())
    }
  },
  computed: {
    shortNameLength() {
      return SHORT_NAME_LENGTH
    },
  },
  methods: {
    ...mapActions(['LOAD_ANSWERS', 'GET_PLP_REPORT']),
    ...mapMutations(['SET_TEMPLATE_CRITERION']),
    forceRender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    initNewData() {
      return copyObject(ASSESSMENT_ORGANIZATION_DEFAULT())
    },
    doChangeEconomic(data, force = false) {
      this.data = data
      this.doChange(force)
    },
    doChange(force = false) {
      if (!this.readonly) {
        this.$emit('change', this.data, force)
      }
    },
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped></style>
