<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          label="Баллы ЭО"
          v-model="scorePrice"
          :outlined="!readonly"
          @input="doChangePrice()"
          @blur="doBlur()"
          hide-details
          type="number"
          :disabled="!isCalculate"
          :append-icon="!readonly ? 'mdi-pencil' : null"
        />
      </v-col>
      <v-col class="d-flex align-center">
        <span v-if="isCalculate">
          <ReferenceTip>
            Заполняется, если учитывается в конечных расчетах баллов
          </ReferenceTip>
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="isCalculate"
          @change="doChange(true)"
          @blur="doBlur()"
        >
          <template #label>
            Учитывается ли в расчетах?
            <span class="font-weight-bold ml-3">
              {{ isCalculate ? 'Да' : 'Нет' }}</span
            ></template
          >
        </v-switch>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReferenceTip from '@/components/ReferenceTip'
export default {
  name: 'LevelPriceCard',
  components: { ReferenceTip },
  data: () => ({ scorePrice: '', isCalculate: false }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
    },
  },
  watch: {
    value: 'init',
  },
  created() {
    this.init(this.value)
  },
  methods: {
    init(value) {
      this.scorePrice = value.scorePrice || ''
      this.isCalculate = value.isCalculate || false
    },
    doChangePrice() {
      this.$emit('update')
      this.doChange()
    },
    doChange(force = false) {
      this.$emit(
        'change',
        { scorePrice: this.scorePrice, isCalculate: this.isCalculate },
        force
      )
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped></style>
