export default class DateForPicker {
  constructor(date) {
    this.date = date ? new Date(date) : new Date()
  }

  get toISO8601date() {
    return this.date.toISOString().substring(0, 10)
  }

  addDays(daysToAdd) {
    this.date.setDate(this.date.getDate() + daysToAdd)
    return this
  }
}
