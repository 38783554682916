<template>
  <v-card flat>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Характеристика исследований</th>
            <th class="text-left">Уровень доказательности данных</th>
            <th class="text-left">Уровень убедительности доказательств</th>
            <th class="text-left">Общая оценка исследования (баллов)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in TRIALS" :key="item.id">
            <td class="font-weight-bold">{{ item.researchCharacter }}</td>
            <td>{{ item.levelEvidence }}</td>
            <td>{{ item.levelEvidenceDoc }}</td>
            <td>{{ item.scores }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
const TRIALS = [
  {
    researchCharacter:
      'Систематический обзор рандомизированных клинических исследований с мета-анализом с низкой или средней вероятностью ошибок',
    levelEvidence: 'I',
    levelEvidenceDoc: 'A',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Рандомизированные клинические исследования с низкой или средней вероятностью ошибок',
    levelEvidence: 'II',
    levelEvidenceDoc: 'A',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Сетевой мета-анализ (в том числе непрямые сравнения и смешанные сравнения) с низкой или средней вероятностью ошибок',
    levelEvidence: 'III',
    levelEvidenceDoc: 'A',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Когортные исследования с низкой или средней вероятностью ошибок',
    levelEvidence: 'IV',
    levelEvidenceDoc: 'B',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования «случай-контроль» с низкой или средней вероятностью ошибок',
    levelEvidence: 'V',
    levelEvidenceDoc: 'B',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter: 'Описание случаев, серий случаев',
    levelEvidence: 'VI',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter: 'Мнение экспертов',
    levelEvidence: 'VII',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования любого дизайна с высокой вероятностью ошибок (низкого методического качества)',
    levelEvidence: 'I',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования любого дизайна с высокой вероятностью ошибок (низкого методического качества)',
    levelEvidence: 'II',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования любого дизайна с высокой вероятностью ошибок (низкого методического качества)',
    levelEvidence: 'III',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования любого дизайна с высокой вероятностью ошибок (низкого методического качества)',
    levelEvidence: 'IV',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования любого дизайна с высокой вероятностью ошибок (низкого методического качества)',
    levelEvidence: 'V',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования любого дизайна с высокой вероятностью ошибок (низкого методического качества)',
    levelEvidence: 'VI',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
  {
    researchCharacter:
      'Исследования любого дизайна с высокой вероятностью ошибок (низкого методического качества)',
    levelEvidence: 'VII',
    levelEvidenceDoc: 'C',
    scores:
      'определяется как произведение оценки исследования (баллов) уровня доказательности данных и оценки исследования (баллов) уровня убедительности доказательств',
  },
]
export default {
  name: 'ClinicalAddTrials',
  data: () => ({
    TRIALS,
    headers: [
      {
        text: 'Характеристика исследований',
        value: 'researchCharacter',
      },
      {
        text: 'Уровень доказательности данных',
        value: 'levelEvidence',
      },
      {
        text: 'Уровень убедительности доказательств',
        value: 'levelEvidenceDoc',
      },
      {
        text: 'Общая оценка исследования (баллов)',
        value: 'scores',
      },
    ],
  }),
}
</script>
