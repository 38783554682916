<template>
  <v-progress-linear v-if="loading" indeterminate color="secondary" />
  <!-- Если не режим лога но ещё не готовы нужные экспертизы  -->
  <div v-else-if="!loading && expertiseNotReady">
    <v-alert type="warning" dense text>
      Не возможно создать "Сводное заключение", так как не готовы экспертизы:<br />
      <span v-if="initData && !initData.about" class="font-weight-bold ml-3">
        - Информация о препарате;</span
      ><br />
      <span v-if="initData && !initData.kei" class="font-weight-bold ml-3">
        - Экспертиза КЭИ;</span
      ><br />
      <span v-if="initData && !initData.avb" class="font-weight-bold ml-3">
        - Экспертиза АВБ;</span
      >
    </v-alert>
  </div>
  <!-- Остновная форма экспертизы -->
  <v-form v-else :readonly="readonly" ref="conclusionForm">
    <v-card elevation="1" class="mb-2" :loading="loading">
      <v-card-title> Информация по препарату </v-card-title>
      <v-card-subtitle v-if="editMode && needRefresh">
        <ReferenceTip>
          Обновить данные из экспертизы до актуальной версии можно кнопкой
          "обновить" ( <v-icon color="green" small>mdi-sync</v-icon> )
        </ReferenceTip>
      </v-card-subtitle>
      <v-card-text v-if="data && !loading">
        <v-alert v-if="needRefresh" type="warning" dense text>
          Внимание! Информация по экспертизе изменилась, рекомендуется обновить
          данные
          <template #append>
            <v-btn
              v-if="editMode && !loading"
              text
              color="green"
              small
              title="Обновить все данные по экспертизе"
              @click="refreshData"
              :disabled="!needRefresh"
              ><v-icon>mdi-sync</v-icon></v-btn
            >
          </template>
        </v-alert>
        <!-- Информация по препарату -->
        <v-timeline dense align-top>
          <!--МНН-->
          <v-timeline-item small>
            <check-alert
              label="МНН"
              v-model="data.mnn"
              :check-value="initMnn"
              :hide-check="logView"
              @change="doChange(true)"
            />
          </v-timeline-item>
          <!-- Перечень -->
          <v-timeline-item small>
            <check-alert
              label="Перечень"
              :value="listOfList"
              :check-value="initListOfList"
              :custom-check="checkList"
              :hide-check="logView"
              @change="listOfListChange"
              empty-value="-- не определён --"
            />
          </v-timeline-item>
          <!-- Компания-заявитель-->
          <v-timeline-item small>
            <check-alert
              label="Компания-заявитель"
              v-model="data.company"
              :check-value="initCompany"
              :hide-check="logView"
              @change="doChange(true)"
            />
          </v-timeline-item>
          <!-- Показание ЛП (ИМП) -->
          <v-timeline-item small>
            <check-alert
              label="Показание ЛП (ИМП)"
              v-model="data.condition"
              :check-value="initCondition"
              :hide-check="logView"
              pre
              @change="doChange(true)"
            />
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
    <!-- Экспертиза КЭИ-->
    <v-card v-if="data && !loading" elevation="1" class="mb-2">
      <v-card-title>Экспертиза КЭИ</v-card-title>
      <v-card-text>
        <v-alert v-if="!logView && numberKeiChanged" type="warning" dense text
          >Внимание! Информация о количестве экспертиз КЭИ изменилась
        </v-alert>
        <v-tabs v-if="keiExist" center-active show-arrows>
          <v-tab
            v-for="(element, elementIndex) in data.kei"
            :key="elementIndex"
            :title="element.shortName"
            :class="{
              'error--text': isErrorTitle(element.shortName),
            }"
            >{{ element.shortName }}</v-tab
          >
          <v-tab-item
            v-for="(_, elementIndex) in data.kei"
            :key="elementIndex"
            eager
            style="min-height: 400px"
          >
            <ExpKei
              :value="data.kei[elementIndex]"
              :init-element="initExpKeiEl(elementIndex)"
              :readonly="readonly"
              @change="(el, force) => change('kei', el, elementIndex, force)"
              @refreshData="refreshExp('kei', elementIndex)"
              @changeAlert="changeAlert"
              @blur="doBlur"
            />
          </v-tab-item>
        </v-tabs>
        <v-alert v-else type="info" outlined :text="editMode" dense>
          <b>Отчет по КЕИ</b>: заявлен, но не представлен в составе Предложения.
        </v-alert>
      </v-card-text>
    </v-card>
    <!-- Экспертиза АВБ-->
    <v-card v-if="data && !loading" elevation="1" class="mb-2">
      <v-card-title>Экспертиза АВБ</v-card-title>
      <v-card-text>
        <v-alert v-if="!logView && numberAvbChanged" type="warning" dense text>
          Внимание! Информация о количестве экспертиз АВБ изменилась
        </v-alert>
        <v-tabs v-if="avbExist" center-active show-arrows>
          <v-tab
            v-for="(element, elementIndex) in data.avb"
            :key="elementIndex"
            :title="element.shortName"
            :class="{
              'error--text': isErrorTitle(element.shortName),
            }"
            >{{ element.shortName }}</v-tab
          >
          <v-tab-item
            v-for="(_, elementIndex) in data.avb"
            :key="elementIndex"
            style="min-height: 400px"
            eager
          >
            <ExpAvb
              :value="data.avb[elementIndex]"
              :init-element="initExpAvbEl(elementIndex)"
              :readonly="readonly"
              @change="el => change('avb', el, elementIndex)"
              @refreshData="refreshExp('avb', elementIndex)"
              @changeAlert="changeAlert"
              @blur="doBlur"
            />
          </v-tab-item>
        </v-tabs>
        <v-alert v-else type="info" outlined :text="editMode" dense>
          <b>Отчет по АВБ</b>: заявлен, но не представлен в составе Предложения.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  SUB_EX_ABOUT,
  SUB_EX_AVB,
  SUB_EX_KEI,
  SUB_EX_KEI_MOD,
  SUB_EX_AVB_MOD,
} from '@/store/const/expertise'
import { EX_CONCLUSION_TEMPLATE } from '@/components/expConclusion/const'

import CheckAlert from '@/components/CheckAlert'
import ReferenceTip from '@/components/ReferenceTip'
import ExpKei from '@/components/expConclusion/expKeiAvb/ExpKei'
import ExpAvb from '@/components/expConclusion/expKeiAvb/ExpAvb'
import {
  checkKei,
  createKeiCopy,
} from '@/components/expConclusion/expKeiAvb/KeiUtils'
import {
  checkAvb,
  createAvbCopy,
} from '@/components/expConclusion/expKeiAvb/AvbUtils'

export default {
  name: 'ConclusionReport',
  components: {
    ExpKei,
    ExpAvb,
    ReferenceTip,
    CheckAlert,
  },
  data: () => ({
    loading: false,
    initData: null,
    data: null,
    errorNames: new Set(),
    errorArrNames: [],
  }),
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
    logView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['storedPlpData', 'storedKeiCriteria', 'storedAvbCriteria']),
    expertiseNotReady() {
      return (
        !this.logView &&
        (!this.initData?.about || !this.initData?.kei || !this.initData?.avb)
      )
    },

    editMode() {
      return !this.readonly && !this.logView
    },
    // МНН
    initMnn() {
      return this.initData?.about?.mnn ?? ''
    },
    checkMNN() {
      return !this.initData || this.initMnn === this.data?.mnn
    },
    // Перечень
    listOfList() {
      return this.makeListOfList(this.data)
    },
    initListOfList() {
      return this.makeListOfList(this.initData?.about)
    },
    checkList() {
      return !this.initData || this.listOfList === this.initListOfList
    },
    //Компания-заявитель
    initCompany() {
      return this.storedPlpData?.applicant ?? ''
    },
    checkCompany() {
      return !this.storedPlpData || this.initCompany === this.data?.company
    },
    //Показание
    initCondition() {
      return this.initData?.about?.condition ?? ''
    },
    checkCondition() {
      return !this.initData || this.initCondition === this.data?.condition
    },
    // Проверка на актуальность всех данных
    checkData() {
      return (
        this.checkMNN &&
        this.checkList &&
        this.checkCompany &&
        this.checkCondition &&
        !this.numberKeiChanged &&
        !this.numberAvbChanged
      )
    },
    showAlert() {
      return (
        !this.checkData ||
        !checkAvb(this.data?.avb, this.avbModification || this.initData?.avb) ||
        !checkKei(this.data?.kei, this.keiModification || this.initData?.kei)
      )
    },
    needRefresh() {
      return !this.logView && !this.loading && this.showAlert
    },
    //Изменилось ли количество экспертиз КЭИ или АВБ
    numberKeiChanged() {
      if (this.keiModification)
        return (
          !this.initData ||
          this.keiModification.ka?.length !== this.data?.kei?.length
        )
      else
        return (
          !this.initData ||
          this.initData.kei?.ka?.length !== this.data?.kei?.length
        )
    },
    numberAvbChanged() {
      if (this.avbModification)
        return (
          !this.initData ||
          this.avbModification.ka?.length !== this.data?.avb?.length
        )
      else {
        return (
          !this.initData ||
          this.initData.avb?.ka?.length !== this.data?.avb?.length
        )
      }
    },
    keiExist() {
      return this.data?.kei?.length > 0
    },
    avbExist() {
      return this.data?.avb?.length > 0
    },
    avbModification() {
      return this.initData['avb-mod']
    },
    keiModification() {
      return this.initData['kei-mod']
    },
  },
  watch: {
    value: 'reInit',
    readonly: 'reInit',
  },

  async created() {
    this.loading = true
    try {
      if (this.readonly) this.data = this.value
      else this.data = copyObject(this.value)

      if (!this.logView) {
        const [report] = await Promise.all([
          this.GET_PLP_REPORT([
            SUB_EX_ABOUT,
            SUB_EX_AVB,
            SUB_EX_KEI,
            SUB_EX_KEI_MOD,
            SUB_EX_AVB_MOD,
          ]),
          this.GET_PLP_DATA(),
          this.LOAD_KEI_CRITERIA(),
          this.LOAD_AVB_CRITERIA(),
        ])
        this.initData = report?.expertise ?? null
      }
      // для проверки сущестоввания данных по экспертизам about, kei, avb
      const { about, kei, avb } = this.initData || {}

      if (!this.data && this.onCreate && about && kei && avb) {
        this.data = await this.onCreate(await this.initNewData())
      }
    } finally {
      this.loading = false
    }
  },
  mounted() {
    this.reInit()
  },
  methods: {
    ...mapActions([
      'GET_PLP_REPORT',
      'GET_PLP_DATA',
      'LOAD_KEI_CRITERIA',
      'LOAD_AVB_CRITERIA',
      'COPY_EXPERTISE',
    ]),
    ...mapMutations(['SET_ERROR']),
    //Перечень
    makeListOfList({ gnvlp, vzn14 } = {}) {
      const resString = []
      if (gnvlp) resString.push('ЖВНЛП')
      if (vzn14) resString.push('14 ВЗН')
      return resString.join(', ')
    },
    listOfListChange() {
      this.data.gnvlp = this.initData?.about?.gnvlp || false
      this.data.vzn14 = this.initData?.about?.vzn14 || false

      this.doChange(true)
    },
    //обновляем данные
    reInit() {
      if (this.readonly) this.data = this.value
      if (!this.readonly && this.initData) {
        this.$nextTick().then(() => this.$refs.conclusionForm.validate())
      }
    },
    async refreshData() {
      if (!this.initData) return
      this.loading = true
      try {
        const { about, kei, avb } = this.initData || {}
        const data = this.data

        //Обновляем данные экспертизы кроме полей для редактирования
        data.mnn = about?.mnn || ''
        data.gnvlp = about?.gnvlp || false
        data.vzn14 = about?.vzn14 || false
        data.condition = about?.condition || false
        data.company = this.storedPlpData?.applicant || ''

        this.$set(
          data,
          'kei',
          createKeiCopy(
            data.kei,
            this.keiModification || kei,
            this.storedKeiCriteria
          )
        )
        this.$set(
          data,
          'avb',
          createAvbCopy(
            data.avb,
            this.avbModification || avb,
            this.storedAvbCriteria
          )
        )
        // создаём новую версию с обновлёнными данными
        await this.COPY_EXPERTISE(data)
        //this.doChange(true)

        this.errorNames.clear() //Очищаем поле с именами неактуальных экспертиз
        this.errorArrNames = []
      } finally {
        this.loading = false
      }
    },

    async getExpertiseData({ about, kei, avb } = {}) {
      try {
        const data = copyObject(EX_CONCLUSION_TEMPLATE)

        data.mnn = about?.mnn || ''
        data.gnvlp = about?.gnvlp || false
        data.vzn14 = about?.vzn14 || false
        data.condition = about?.condition || false
        data.company = this.storedPlpData?.applicant

        data.kei = createKeiCopy(null, kei, this.storedKeiCriteria)
        data.avb = createAvbCopy(null, avb, this.storedAvbCriteria)
        return data
      } catch (err) {
        this.SET_ERROR({
          head: 'Сводное заключение',
          text: 'Ошибка загрузки данных по экспертизам ',
          err,
        })
      }
    },
    // Генерируем новые данные для экспертизы в первый раз
    async initNewData() {
      try {
        const resData = this.getExpertiseData(this.initData)

        resData.company = this.storedPlpData?.applicant
        return resData
      } catch (err) {
        this.SET_ERROR({
          head: 'Сводное заключение',
          text: 'Ошибка загрузки данных',
          err,
        })
      }
    },
    doChange(force = false) {
      if (!this.readonly) this.$emit('change', this.data, force)
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    change(key, element, elementIndex, force) {
      this.$set(this.data[key], elementIndex, element)
      this.doChange(force)
    },
    refreshExp(key, elementIndex) {
      if (!this.initData) return

      const isKei = key === 'kei'
      const createExpCopy = isKei ? createKeiCopy : createAvbCopy

      // экспертиза Кэи или Авб
      const exp = createExpCopy(
        this.data[key],
        this.initData[key],
        this[`stored${isKei ? 'Kei' : 'Avb'}Criteria`]
      )

      this.change(key, exp[elementIndex], elementIndex, true)
    },
    // Заголовки табов актуальные или нет
    changeAlert(shortName, state) {
      if (state) this.errorNames.add(shortName)
      this.errorArrNames = [...this.errorNames.values()]
    },
    isErrorTitle(shortName) {
      return !this.logView && this.errorArrNames.includes(shortName)
    },
    initExpKeiEl(elementIndex) {
      if (this.keiModification)
        return this.initData ? this.keiModification.ka[elementIndex] : null
      else return this.initData ? this.initData.kei.ka[elementIndex] : null
    },
    initExpAvbEl(elementIndex) {
      if (this.avbModification)
        return this.initData ? this.avbModification.ka[elementIndex] : null
      else return this.initData ? this.initData.avb.ka[elementIndex] : null
    },
  },
}
</script>
