//Собираем лист клинических рекомендаций, (только используемые)
export function collectCRList(CR, AL) {
  let outString = ''
  const usingNum = prepareAnalogMap(AL, CR)

  CR?.forEach(({ name, crNumber, associations, year }, index) => {
    if (usingNum[index]) {
      outString += `"${name}" (КР ${crNumber}); ${associations}; ${year} г.`
    }
  })

  return outString
}

// аналоги  (сортировка по МНН - ЛФ)
function prepareAnalogMap(AL, CR) {
  let counter = 1
  const outMap = {}
  CR?.forEach((_, index) => {
    if (AL?.findIndex(({ CR }) => CR[index].use) > -1) {
      outMap[index] = counter++
    }
  })
  return outMap
}
