<template>
  <VersionSelect v-if="plp_task_id && expertiseReady" fluid>
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <ExpertiseTabsList
        :value="data"
        :readonly="readonly"
        :onCreate="onCreate"
        :dictType="dict"
        @change="onChange"
        @blur="onBlur"
      />
    </template>
  </VersionSelect>
  <v-container v-else>
    <!-- затенение и крутилка на проверку -->
    <v-progress-linear v-if="loading" indeterminate color="secondary" />
    <!-- алерты если экспертиза не готова -->
    <v-alert v-if="error" type="error" outlined dense>
      Ошибка получения экспертизы: {{ error }}
    </v-alert>
    <v-alert v-else-if="!loading" type="warning" dense text>
      Не возможно создать "Доработка КЭИ", так как не готовы экспертиза:<br />
      <ul class="font-weight-bold mt-3">
        <li v-if="!keiReady">Экспертиза КЭИ;</li>
      </ul>
    </v-alert>
  </v-container>
</template>

<script>
import VersionSelect from '@/components/VersionSelect'
import ExpertiseTabsList from '@/components/expModAvbKei/ExpertiseTabsList'
import { mapActions, mapGetters } from 'vuex'
import { SUB_EX_KEI } from '@/store/const/expertise'
export default {
  components: {
    VersionSelect,
    ExpertiseTabsList,
  },
  data: () => ({
    dict: 'kei-mod',
    loading: false,
    error: '',
    // данные из экспертизы
    initData: null,
  }),
  computed: {
    ...mapGetters(['plp_task_id']),
    keiReady() {
      return !!this.initData?.kei?.ka
    },
    avbReady() {
      return !!this.initData?.avb?.ka
    },
    expertiseReady() {
      return !this.loading && this.keiReady
    },
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),
  },
  async created() {
    this.loading = true
    try {
      const { expertise } = await this.GET_PLP_REPORT([SUB_EX_KEI])
      this.initData = expertise
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
  },
}
</script>
