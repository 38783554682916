<template>
  <v-container v-if="data" fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <v-progress-linear v-if="about.loading" indeterminate />
        <v-card v-if="!about.loading">
          <v-card-title> Основной препарат </v-card-title>
          <v-card-subtitle v-if="editMode && needRefresh">
            <ReferenceTip>
              Обновить данные из экспертизы до актуальной версии можно кнопкой
              "обновить" ( <v-icon color="green" small>mdi-sync</v-icon> )
            </ReferenceTip>
          </v-card-subtitle>
          <v-card-text>
            <v-alert v-if="about.error" type="error" dense text dismissible>
              {{ about.error }}
            </v-alert>
            <v-alert v-if="needRefresh" type="warning" dense text>
              Внимание! Информация по экспертизе изменилась, рекомендуется
              обновить данные
              <template #append>
                <v-btn
                  v-if="editMode && !about.loading"
                  text
                  color="green"
                  small
                  title="Обновить все данные по экспертизе"
                  @click="refreshData"
                  :disabled="!needRefresh"
                  ><v-icon>mdi-sync</v-icon></v-btn
                >
              </template>
            </v-alert>
            <!-- Основной препарат -->
            <v-timeline dense align-top>
              <v-timeline-item small>
                <check-alert
                  label="АТХ"
                  v-model="data.AL[0].ath"
                  :check-value="initAth"
                  :hide-check="!editMode"
                  :readonly="readonly"
                  @change="doChange(true)"
                />
              </v-timeline-item>
              <v-timeline-item small>
                <check-alert
                  label="МНН"
                  v-model="data.AL[0].mnn"
                  :check-value="initMnn"
                  :hide-check="!editMode"
                  :readonly="readonly"
                  @change="doChange(true)"
                />
              </v-timeline-item>
              <v-timeline-item small>
                <check-alert
                  label="Лекарственная форма"
                  v-model="data.AL[0].lForma"
                  :check-value="initLForma"
                  :hide-check="!editMode"
                  :readonly="readonly"
                  @change="doChange(true)"
                />
              </v-timeline-item>
              <v-timeline-item small>
                <check-alert
                  label="Показание"
                  v-model="data.AL[0].condition"
                  :check-value="initCondition"
                  :hide-check="!editMode"
                  :readonly="readonly"
                  pre
                  @change="doChange(true)"
                />
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div class="text-subtitle-2">
              Встречается в клинических рекомендациях:
            </div>
            <template v-for="(item, index) in data.CR">
              <div :key="index">
                <v-switch
                  v-model="data.AL[0].CR[index].use"
                  :label="data.CR[index].shortName"
                  :hint="data.CR[index].name"
                  persistent-hint
                  color="success"
                  :readonly="readonly"
                  inset
                  @change="doChange(true)"
                />
                <div v-if="data.AL[0].CR[index].use" class="mt-2">
                  <v-textarea
                    v-model.trim="data.AL[0].CR[index].description"
                    outlined
                    auto-grow
                    :clearable="!readonly"
                    hide-details
                    :readonly="readonly"
                    @input="doChange()"
                    @blur="doBlur()"
                  />
                </div>
              </div>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-checkbox
              disabled
              dense
              label="Использовать в качестве аналога"
              v-model="data.AL[0].anal"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- Аналоги -->
    <v-row>
      <v-col cols="6"><span class="text-h6">Список аналогов</span></v-col>
      <v-col cols="6">
        <v-select
          v-model="filterSelected"
          :items="preparedFilters"
          item-value="mnn"
          item-text="title"
          label="Фильтр аналогов"
          dense
          hide-details
          clearable
          @click:clear="panel = []"
          @change="forceRender"
          ><template #prepend v-if="filterSelected">
            <v-icon
              :color="!!filterSelected ? 'success' : null"
              class="mr-2"
              title="работает фильтр"
              >mdi-filter-outline</v-icon
            >
          </template></v-select
        >
      </v-col>
    </v-row>

    <v-alert
      v-if="data.AL && data.AL.length <= 1"
      border="bottom"
      colored-border
      type="warning"
      elevation="2"
      class="my-4"
      >Список аналогов пуст</v-alert
    >
    <v-expansion-panels
      class="my-4"
      focusable
      multiple
      v-if="renderComponent"
      v-model="panel"
    >
      <template v-for="(item, index) in filteredItems">
        <v-expansion-panel :key="index">
          <v-expansion-panel-header disable-icon-rotate>
            <template v-slot:default="{ open }">
              <v-row no-gutters>
                <v-col cols="12" class="text--secondary">
                  <span class="text--disabled mr-2">{{ index + 1 }}.</span>
                  <v-fade-transition leave-absolute>
                    <span v-if="open" key="0" class="font-weight-bold"
                      >Данные аналога
                    </span>
                    <span v-else key="1"
                      >{{ item.ath || noName.ath }} :
                      <span class="font-weight-bold">{{
                        item.mnn || noName.mnn
                      }}</span>
                      :
                      {{ item.lForma || noName.lf }}
                    </span>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
              <v-icon
                color="teal"
                v-if="item.anal"
                title="Используется в качестве аналога"
              >
                mdi-check
              </v-icon>
              <v-icon color="teal" v-else></v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-3">
            <ALCard
              :value="item"
              :cr="data.CR"
              :readonly="readonly"
              @input="doChange()"
              @change="doChange(true)"
              @blur="doBlur()"
              @deleteALItem="deleteALItem(index)"
              @copyALItem="copyALItem(index)"
              @changeMNN="changeMNN"
              :log-view="logView"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>

    <v-row>
      <v-col cols="12">
        <div v-if="!readonly" class="text-right">
          <v-btn
            color="accent"
            fab
            small
            @click="addItem()"
            title="Добавить новый аналог препарата"
            ><v-icon>mdi-plus</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
  <div v-else-if="createError">
    <v-alert type="error" outlined>{{ createError }}</v-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ANALOG_AL_DATA, DEFAULT_CR_ANALOG_AL } from './const'
import { copyObject } from '@/lib/objects'
import ALCard from './ALCard'
import AnalogMixin from './AnalogMixin'
import ReferenceTip from '@/components/ReferenceTip'
import CheckAlert from '@/components/CheckAlert'

export default {
  name: 'AnalogList',
  mixins: [AnalogMixin],
  components: { ALCard, ReferenceTip, CheckAlert },
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    alert: {
      icon: 'mdi-alert-circle-outline',
      text: 'Внимание! Информация о препарате изменилась',
      color: 'warning',
    },
    panel: [],
    noName: {
      ath: 'нет АТХ',
      mnn: 'нет МНН',
      lf: 'нет ЛФ',
    },
    renderComponent: true,
    filterSelected: null,
    activeFilter: false,
  }),
  created() {
    this.GET_ATC5MNN_DICT()
  },
  computed: {
    preparedFilters() {
      return (
        this.data?.AL.reduce((mnnList, item, index) => {
          if (index > 0 && !mnnList.includes(item.mnn)) {
            mnnList.push({ mnn: item.mnn, title: item.mnn || this.noName.mnn })
          }
          return mnnList
        }, []) || []
      )
    },
    filteredItems() {
      if (!this.filterSelected) {
        return this.data.AL.filter((_, index) => index > 0)
      } else {
        return this.data.AL.filter(
          (item, index) => index > 0 && item.mnn === this.filterSelected
        )
      }
    },
    showAlert() {
      return (
        !this.checkAth ||
        !this.checkMnn ||
        !this.checkLForma ||
        !this.checkCondition
      )
    },
    needRefresh() {
      return !this.logView && !this.about?.loading && this.showAlert
    },
  },
  methods: {
    ...mapActions(['GET_MY_LAST_EXPERTISE_DATA', 'GET_ATC5MNN_DICT']),
    refreshData() {
      let { mnn, ath, lForma, condition, ruQuantity, ruEndDate, ruNumber } =
        this.initNewData().AL[0]
      this.$set(this.data.AL, 0, {
        ...this.data.AL[0],
        mnn,
        ath,
        lForma,
        condition,
        ruQuantity,
        ruEndDate,
        ruNumber,
      })
      this.doChange(true)
    },
    changeMNN(mnn) {
      if (this.filterSelected && this.filterSelected !== mnn)
        this.filterSelected = mnn
      this.forceRender()
    },
    copyALItem(itemIndex) {
      const analog = copyObject(this.data.AL[itemIndex + 1])
      this.data.AL.splice(itemIndex + 1, 0, analog)
      this.panel = [itemIndex + 1]
      this.doChange(true)
    },
    deleteALItem(itemIndex) {
      this.data.AL.splice(itemIndex + 1, 1)
      this.panel = []
      this.doChange(true)
    },
    addItem() {
      // создаем объект аналога и заполняем список КР из того уже что есть
      const analog = ANALOG_AL_DATA()
      this.data.CR.forEach(() => {
        analog.CR.push(DEFAULT_CR_ANALOG_AL())
      })
      this.filterSelected = null
      this.data.AL.push(analog)
      this.panel = [this.data.AL.length - 2]
      this.doChange(true)
    },
    forceRender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
  },
}
</script>

<style scoped>
.b-black {
  color: black;
  font-weight: bold;
}
.v-timeline-item {
  padding-bottom: 5px;
}
.v-card {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 40%, #dfdfdf 100%);
}
</style>

<style>
textarea {
  margin-right: 15px;
}
</style>
