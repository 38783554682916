<template>
  <default-log v-bind="$attrs" :diff-keys="diffKeys">
    <template #default="{ message, code }">
      <span
        v-if="code === 'PLP_SUBEX_VER_COPIED'"
        class="brown--text font-weight-regular"
      >
        {{ message }}
      </span>
      <span
        v-else-if="code === 'PLP_SUBEX_VER_MODIFIED'"
        class="font-weight-medium"
      >
        {{ message }}
      </span>
      <span v-else class="font-italic brown--text">
        {{ message }}
      </span>
    </template>
    <template #detail="{ logInfo, data }">
      <v-list v-if="showLogInfo(data)" subheader flat>
        <v-tabs
          class="mb-4"
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
        >
          <v-tab> Подробная информация</v-tab>
          <v-tab> Экспертиза</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-if="logInfo && logInfo.data">
            <ul v-if="plp">
              <li><span class="font-weight-bold">МНН:</span>{{ mnnSubex }}</li>
              <li><span class="font-weight-bold">АТХ:</span>{{ athSubex }}</li>
              <li>
                <span class="font-weight-bold">Лекарственная форма: :</span
                >{{ lfSubex }}
              </li>
              <li>
                <span class="font-weight-bold">Отправлено в МЗ: </span
                >{{ sentMZ ? 'да' : 'нет' }}
              </li>
              <li>
                <span class="font-weight-bold">Проверена: </span
                >{{ checkedSubex ? 'да' : 'нет' }}
              </li>
              <li>
                <span class="font-weight-bold">Выполнена: </span
                >{{ completedSubex ? 'да' : 'нет' }}
              </li>
              <li>
                <span class="font-weight-bold">Квартал: </span>{{ quarterExp }}
              </li>
              <li><span class="font-weight-bold">Год: </span>{{ yearExp }}</li>
              <li>
                <span class="font-weight-bold"
                  >Крайний срок отправки в МЗ: </span
                >{{ timeMZ | filteredDatetime }}
              </li>
            </ul>
            <ul v-else>
              <li>Подробная информация по данному коду отсутствует</li>
            </ul>
          </v-tab-item>
          <v-tab-item>
            <component
              v-if="expData || expDataExist || subexData(data)"
              :is="exp_types[logInfo.data.ex_type_name]"
              :value="expData"
              readonly
              log-view
              :dictType="logInfo.data.ex_type_name"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-list>
    </template>
    <template #[`diff.object`]="{ obj }">
      {{ obj }}
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import AnalogForLog from '@/components/expAnalogs/AnalogForLog'
import SummaryForLog from '@/components/expSummary/SummaryForLog'
import ExpAbout from '@/components/expAbout/ExpAbout'
import ExpAgency from '@/components/expAgency/ExpAgency'
import ExpOther from '@/components/expOther/ExpOther'
import ConclusionReport from '@/components/expConclusion/ConclusionReport'
import RefPricing from '@/components/expPricing/RefPricing'
import ExpertiseTabsList from '@/components/expAvbKei/ExpertiseTabsList'
import ExpertiseTabsModList from '@/components/expModAvbKei/ExpertiseTabsList'
import CostCalc from '@/components/expCost/CostCalc'
import ExpAssessment from '@/components/expAssessment/expAssessment.vue'
import {
  SUB_EX_ABOUT,
  SUB_EX_AVB,
  SUB_EX_KEI,
  SUB_EX_OTHER,
  SUB_EX_AGE,
  SUB_EX_ANALOG,
  SUB_EX_CONCLUSION,
  sub_exp_migration,
  SUB_EX_SUMMARY,
  SUB_EX_PRICING,
  SUB_EX_COST,
  SUB_EX_ASSESSMENT,
  SUB_EX_KEI_MOD,
  SUB_EX_AVB_MOD,
} from '@/store/const/expertise'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'

export default {
  name: 'PlpSubexLog',
  inheritAttrs: false,
  components: {
    DefaultLog,
    ExpAbout,
    ExpAgency,
    ExpOther,
    ExpertiseTabsList,
    ExpertiseTabsModList,
    ExpAssessment,
    AnalogForLog,
    SummaryForLog,
    ConclusionReport,
    RefPricing,
    CostCalc,
  },
  mixins: [logInfoMixin],
  data: () => ({
    diffKeys: {
      ath: 'АТХ',
      mnn: 'МНН',
      shortName: 'Короткое название',
    },
    tab: null,
    exp_types: {
      [SUB_EX_ABOUT]: 'ExpAbout',
      [SUB_EX_AGE]: 'ExpAgency',
      [SUB_EX_OTHER]: 'ExpOther',
      [SUB_EX_KEI]: 'ExpertiseTabsList',
      [SUB_EX_AVB]: 'ExpertiseTabsList',
      [SUB_EX_ANALOG]: 'AnalogForLog',
      [SUB_EX_CONCLUSION]: 'ConclusionReport',
      [SUB_EX_SUMMARY]: 'SummaryForLog',
      [SUB_EX_PRICING]: 'RefPricing',
      [SUB_EX_COST]: 'CostCalc',
      [SUB_EX_ASSESSMENT]: 'ExpAssessment',
      [SUB_EX_KEI_MOD]: 'ExpertiseTabsList',
      [SUB_EX_AVB_MOD]: 'ExpertiseTabsList',
    },
    expData: null,
    expDataExist: 0,
  }),
  computed: {
    plp() {
      return this.logData?.data?.plpTask?.plp
    },
    timeMZ() {
      return this.plp?.time_deadline_mz
    },
    yearExp() {
      return this.plp?.expertise_year
    },
    quarterExp() {
      return this.plp?.expertise_quarter || '--пусто--'
    },
    completedSubex() {
      return this.plp?.is_completed
    },
    checkedSubex() {
      return this.plp?.is_checked
    },
    sentMZ() {
      return this.plp?.was_sent_to_mz
    },
    mnnSubex() {
      return this.plp?.mnn || '--пусто--'
    },
    athSubex() {
      return this.plp?.ath || '--пусто--'
    },
    lfSubex() {
      return this.plp?.lForma || '--пусто--'
    },
  },
  methods: {
    // Делаем миграцию версий data экспертизы!
    subexData(originalData) {
      this.expDataExist = 1
      const { data, ex_type_name, data_version } = originalData?.data || {}
      sub_exp_migration(ex_type_name, data_version, data).then(({ data }) => {
        this.expData = data
      })
    },
  },
}
</script>
