<template>
  <v-card v-if="detail">
    <v-card-title> Подробная информация: </v-card-title>
    <v-card-subtitle v-if="isAdmin" class="text--disabled pb-1">
      {{ code }}
    </v-card-subtitle>
    <v-card-text>
      <v-list-item>
        <v-list-item-content class="text-subtitle-1">
          <v-list-item-title>Сообщение</v-list-item-title>
          {{ message }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Дата</v-list-item-title>
          {{ timestamp }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Пользователь</v-list-item-title>
          {{ user_fio }}
        </v-list-item-content>
      </v-list-item>
      <!-- Если есть Diff объект -->
      <template v-if="logDiffExist">
        <v-list-group :value="false" class="mb-2" :disabled="!logDiff.length">
          <template v-slot:activator>
            <v-list-item-title
              :class="{
                'font-weight-bold': logDiff.length,
                'text--disabled': !logDiff.length,
              }"
            >
              Изменения:
            </v-list-item-title>
          </template>
          <v-list-item>
            <ul class="ml-3" style="width: 100%">
              <slot name="diff.header" :logInfo="logInfo" :diff="logDiff">
              </slot>
              <!-- один раз вызываем diff-label - отдаём diff = { changes: diff }  -->
              <diff-label
                tag-item="li"
                :diff="{ changes: logDiff }"
                :diff-keys="diffKeys"
                :diff-keys-exclude="new Set(diffKeysExclude)"
              >
                <!-- прокидываем все слоты [diff.*] -->
                <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                  <slot :name="slot" v-bind="scope" />
                </template>
              </diff-label>
            </ul>
          </v-list-item>
        </v-list-group>
      </template>

      <!-- слот для вывода деталей дополнительно -->
      <slot
        name="detail"
        :logInfo="logInfo"
        :data="logData"
        :diff="logDiff"
        :message="message"
      />

      <v-divider v-if="isAdmin" class="my-2" />
      <v-list-group v-if="isAdmin" :value="false">
        <template v-slot:activator>
          <v-list-item-title>
            Данные в формате JSON ( Админ ):
          </v-list-item-title>
        </template>

        <v-list-item class="text-pre-wrap">
          {{ logInfo }}
        </v-list-item>
      </v-list-group>
    </v-card-text>
  </v-card>
  <span v-else>
    <!-- слот по дефолту -->
    <slot :logInfo="logInfo" :message="message" :code="code">
      {{ message }}
    </slot>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import diffLabel from './diffLabel'
import { filteredDatetime } from './logsFunctions'

export default {
  name: 'DefaultLog',
  components: { diffLabel },
  data: () => ({
    tab: null,
  }),
  props: {
    logInfo: {
      type: Object,
      default: null,
    },
    detail: {
      type: Boolean,
      default: false,
    },
    diffKeys: {
      type: Object,
      default: null,
    },
    diffKeysExclude: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
    message() {
      return this.logInfo?.message
    },
    code() {
      return this.logInfo?.code
    },
    timestamp() {
      return filteredDatetime(this.logInfo?.timestamp)
    },
    user_fio() {
      return this.logInfo?.user_fio
    },
    logDiff() {
      return this.logInfo?.data?.data?.diff
    },
    logDiffExist() {
      try {
        return 'diff' in this.logInfo?.data?.data
      } catch (e) {
        return false
      }
    },
    logData() {
      return this.logDiffExist ? this.logInfo?.data?.data : this.logInfo?.data
    },
  },
}
</script>
