<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="blue--text">
        {{ message }}
      </span>
    </template>
    <template #detail="{ logInfo }">
      <v-list subheader flat>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="mb-2"
              >Данные по экспертизе</v-list-item-title
            >
            <ul>
              <li>Дата удаления: {{ filteredDatetime(dateDeleted) }}</li>
              <li>МНН: {{ mnnDeleted }}</li>
              <li>АТХ: {{ athDeleted }}</li>
              <li>Лекарственная форма: {{ lfDeleted }}</li>
            </ul>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'
export default {
  name: 'PlpDeletedLog',
  inheritAttrs: false,
  components: { DefaultLog },
  mixins: [logInfoMixin],
  computed: {
    dateDeleted() {
      return this.logData?.timestamp
    },
    mnnDeleted() {
      return this.logData?.data?.data?.mnn
    },
    athDeleted() {
      return this.logData?.data?.data?.ath
    },
    lfDeleted() {
      return this.logData?.data?.data?.lForma
    },
  },
}
</script>
