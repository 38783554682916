<template>
  <v-container v-if="data" fluid>
    <v-row class="mt-1">
      <v-col cols="12">
        <v-alert v-if="about.error" type="error" dismissible>{{
          about.error
        }}</v-alert>
        <check-alert
          label="Показание"
          v-model="data.AL[0].condition"
          :loading="about.loading"
          :check-value="initCondition"
          :hide-check="about.loading || !editMode"
          :readonly="readonly"
          pre
          @change="doChange(true)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="crItems"
          @click:row="openForEdit"
          hide-default-footer
          :items-per-page="30"
          sort-by="name"
        >
          <template #top v-if="!logView"
            ><v-btn
              color="success"
              @click="openForAdd"
              small
              :disabled="readonly"
            >
              <v-icon small left> mdi-plus </v-icon>Добавить КР</v-btn
            ></template
          >
          <template #[`item.index`]="{ item, index }">
            <v-badge
              :value="crItemsUsedCount[item.key]"
              color="grey lighten-1"
              :content="crItemsUsedCount[item.key]"
              :title="crItemsUsedHint(crItemsUsedCount[item.key])"
              offset-x="-5px"
              class="cursor-help"
              >{{ index + 1 }}.</v-badge
            >
          </template>

          <template v-slot:[`item.isOnRubricator`]="{ item }">
            <span v-if="item.isOnRubricator"> Да </span>
            <span v-else>Нет</span>
          </template>

          <template v-slot:[`item.isApprovedNPS`]="{ item }">
            <span v-if="item.isApprovedNPS"> Да </span>
            <span v-else>Нет</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <editCRForm
      v-if="show"
      :CR="data.CR"
      :item="item"
      @updateItem="updateItem"
      @removeItem="removeItem"
      @closeDialog="closeDialog"
    />
  </v-container>
  <div v-else-if="createError">
    <v-alert type="error" outlined>{{ createError }}</v-alert>
  </div>
</template>

<script>
import { ru_ending } from '@/lib/strUtils'
import editCRForm from './editCRForm.vue'
import { DEFAULT_CR_ANALOG_AL } from './const'
import AnalogMixin from './AnalogMixin'
import CheckAlert from '@/components/CheckAlert'

export default {
  name: 'CrList',
  mixins: [AnalogMixin],
  components: { editCRForm, CheckAlert },
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    show: false,
    item: null,
    headers: [
      {
        text: '№',
        value: 'index',
        width: '50px',
        sortable: false,
        cellClass: 'text--disabled',
        filterable: false,
      },
      {
        value: 'shortName',
        text: 'Краткое название',
        cellClass: 'font-weight-medium',
      },
      { value: 'name', text: 'Название' },
      {
        value: 'isOnRubricator',
        text: 'Размещены на рубрикаторе',
        width: '120px',
        align: 'center',
      },
      {
        value: 'isApprovedNPS',
        text: 'Одобрены НПС',
        width: '120px',
        align: 'center',
      },
      { value: 'crNumber', text: '№ КР' },
      { value: 'associations', text: 'Ассоциации', width: '35%' },
      { value: 'year', text: 'Год', width: '50px' },
    ],
  }),
  computed: {
    crItems() {
      //разворачиваем data в таблицу
      if (!this.data) return []
      const items = []
      for (const key in this.data.CR) {
        items.push({ key, ...this.data.CR[key] })
      }
      return items
    },
    /** Кол-во ссылок на КР из аналогов */
    crItemsUsedCount() {
      return this.crItems.map(
        ({ key }) =>
          this.data.AL?.reduce(
            (sum, analog) => (sum += analog.CR[key]?.use ? 1 : 0),
            0
          ) ?? 0
      )
    },
  },
  methods: {
    updateItem(item) {
      // сохраняем/добавляем выбранный элемент
      const { key, ...data } = item
      if (key) {
        this.$set(this.data.CR, key, data)
      } else {
        this.data.CR.push(data)
        this.data.AL.forEach(({ CR }) => {
          CR.push(DEFAULT_CR_ANALOG_AL())
        })
      }
      this.$emit('change', this.data, true)
      this.closeDialog()
    },
    removeItem(item) {
      const { key } = item
      if (!key) return
      this.data.CR.splice(key, 1)
      for (const k in this.data.AL) {
        this.data.AL[k].CR.splice(key, 1)
      }
      this.$emit('change', this.data, true)
      this.closeDialog()
    },
    closeDialog() {
      this.show = false
    },
    openDialog() {
      this.show = true
    },
    openForAdd() {
      this.item = null
      this.openDialog()
    },
    openForEdit(item) {
      if (this.readonly) return
      this.item = item
      this.openDialog()
    },
    crItemsUsedHint(count) {
      return count
        ? `КР упоминается в ${ru_ending(count, ['аналоге', 'аналогах'])} кр`
        : 'для КР нет упоминаний'
    },
  },
}
</script>

<style scoped>
.v-data-table >>> td {
  cursor: pointer;
}
</style>
