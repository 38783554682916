<template>
  <v-container fluid class="pb-4 pl-8">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          :outlined="!readonly"
          label="Форма выпуска"
          v-model="releaseForm"
          placeholder="Введите название КИ"
          prepend-icon="mdi-text-box-search-outline"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.number="numberActive"
          :outlined="!readonly"
          label="Количество действующего вещества"
          prepend-icon="mdi-pill"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.number="price"
          :outlined="!readonly"
          label="Цена в валюте"
          prepend-icon="mdi-cash-multiple"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DEFAULT_CUSTOM_FORM } from '@/components/expPricing/const'
import { copyObject } from '@/lib/objects'

export default {
  name: 'PricingEditor',
  data: () => ({
    outObj: null,
    releaseForm: '',
    numberActive: 0,
    price: 0,
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) {
        this.$emit(
          'change',
          {
            ...this.value,
            ...this.outObj,
            custom: {
              releaseForm: this.releaseForm, //Форма выпуска
              numberActive: this.numberActive, //Количество действующего вещества
              price: this.price, //Цена за упаковку в валюте
            },
          },
          force
        )
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments() {
      this.$emit('delete')
    },
    init() {
      if (this.value.custom) {
        this.releaseForm = this.value?.custom?.releaseForm || ''
        this.numberActive = this.value?.custom?.numberActive || 0
        this.price = this.value?.custom?.price || 0
      } else {
        this.outObj = copyObject(this.value)
        this.outObj.custom = DEFAULT_CUSTOM_FORM()
        this.releaseForm = this.outObj?.custom?.releaseForm || ''
        this.numberActive = this.outObj?.custom?.numberActive || 0
        this.price = this.outObj?.custom?.price || 0
      }
    },
  },
}
</script>

<style scoped></style>
