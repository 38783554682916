import {
  SUB_VERSION_MIGRATION,
  SUB_EX_CONCLUSION,
} from '@/store/const/expertise'
import {
  EX_KEI_ANNOTATIONS_DEFAULT,
  EX_KEI_METHOD_DEFAULT,
} from '../expAvbKei/const'

export const KEI = 'КЭИ'
export const AVB = 'АВБ'
export const EMPTY_DATA = '--пусто--'
export const EX_CONCLUSION_TEMPLATE_VERSION = 2

export const EX_CONCLUSION_KEI = {
  shortName: '',
  qLevels: '',
  resume: '', //Отчет по КЭИ
  condition: '', //показание
  analogsCompare: '', //препараты сравнения ред.
  method: {
    items: EX_KEI_METHOD_DEFAULT(),
    annotations: EX_KEI_ANNOTATIONS_DEFAULT(),
  },
  criterias: [], //массив несоотвутствующих критериев кэи
  comment: '', // комментарий для отчёта
  note: '', // внутренний комментарий
}

export const EX_CONCLUSION_AVB = {
  shortName: '',
  qLevels: '',
  resume: '', //Отчет по АВБ
  condition: '', //показание
  currentState: '', //текущая практика
  expectedState: '', //Ожидаемая практика
  criterias: [], //массив несоотвутствующих критериев авб
  comment: '', // комментарий для отчёта
  note: '', // внутренний комментарий
}

export const EX_CONCLUSION_TEMPLATE = {
  mnn: '',
  gnvlp: false,
  vzn14: false,
  company: '',
  condition: '',
  kei: [],
  avb: [],
}
//
// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_CONCLUSION].v = EX_CONCLUSION_TEMPLATE_VERSION
// Добавили миграции для перехода на версию 2
SUB_VERSION_MIGRATION[SUB_EX_CONCLUSION][1] = function (prev) {
  const func = el => {
    el.criterias = el.criteriasName
    delete el.criteriasName
  }
  prev?.avb?.forEach(func)
  prev?.kei?.forEach(func)
  return prev
}
