<template>
  <v-container fluid class="pb-4">
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Форма выпуска"
          v-model="releaseForm"
          :outlined="!readonly"
          :readonly="readonly"
          placeholder="Введите форму выпуска"
          hint="Например, 10 №10 (капсулы)"
          prepend-icon="mdi-text-box-search-outline"
          @input="doChange()"
          @blur="doBlur()"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.number="registerPrice"
          :outlined="!readonly"
          :readonly="readonly"
          label="Цена, планируемая к регистрирации, руб. (Без НДС)"
          hint="Источник: П. 5.5. Предложения"
          prepend-icon="mdi-cash"
          @input="doChange()"
          @blur="doBlur()"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.number="pricePurchase"
          :outlined="!readonly"
          :readonly="readonly"
          label="Цена гос. закупок, руб.(Средневзвешенная)"
          hint="Источник: БД Госзакупки"
          prepend-icon="mdi-cash-100"
          @input="doChange()"
          @blur="doBlur()"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.number="retailPrice"
          :outlined="!readonly"
          :readonly="readonly"
          label="Розничная цена, руб (Минимальная)"
          hint="Источник: Фарминдекс"
          prepend-icon="mdi-cash-multiple"
          @input="doChange()"
          @blur="doBlur()"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.number="registerPriceWithNdc"
          :outlined="!readonly"
          :readonly="readonly"
          label="Цена, планируемая к регистрирации, руб. (НДС)"
          hint="Источник: П. 5.5. Предложения"
          prepend-icon="mdi-cash"
          @input="doChange()"
          @blur="doBlur()"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model.number="pricePurchaseLast"
          :outlined="!readonly"
          :readonly="readonly"
          label="Цена гос. закупок, руб.(Последний контракт)"
          hint="Источник: БД Госзакупки"
          prepend-icon="mdi-cash-100"
          @input="doChange()"
          @blur="doBlur()"
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4"> </v-col>
      <v-col cols="12" md="4"> </v-col>
      <v-col
        ><SelectDate
          v-model="dateLastContract"
          :disabled="readonly"
          :maxDate="currentDate"
          label="Дата последнего контракта"
          ref="date"
          @input="doChange(true)"
      /></v-col>
    </v-row>
    <v-row v-if="!readonly">
      <v-col class="text-right">
        <btn-with-confirm
          text
          icon-main="mdi-delete-forever-outline"
          title="Удалить форму выпуска"
          @click="deleteAssessments"
        >
          Удалить
        </btn-with-confirm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import SelectDate from '@/components/editors/SelectDate.vue'

export default {
  name: 'PriceEditor',
  components: { BtnWithConfirm, SelectDate },
  data: () => ({
    releaseForm: '',
    registerPrice: 0,
    registerPriceWithNdc: 0,
    pricePurchase: 0,
    pricePurchaseLast: 0,
    retailPrice: 0,
    dateLastContract: null,
    scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  computed: {
    //Сегодняшняя дата(год-месяц-день)
    currentDate() {
      return String(new Date().toISOString().slice(0, 10))
    },
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) {
        const {
          releaseForm,
          registerPrice,
          pricePurchase,
          retailPrice,
          registerPriceWithNdc,
          pricePurchaseLast,
          dateLastContract,
        } = this
        const outObj = {
          ...this.value,
          releaseForm,
          registerPrice,
          pricePurchase,
          retailPrice,
          registerPriceWithNdc,
          pricePurchaseLast,
          dateLastContract,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments() {
      this.$emit('delete')
    },
    init() {
      this.releaseForm = this.value?.releaseForm || ''
      this.registerPrice = this.value?.registerPrice || 0
      this.registerPriceWithNdc = this.value?.registerPriceWithNdc || 0
      this.pricePurchase = this.value?.pricePurchase || 0
      this.pricePurchaseLast = this.value?.pricePurchaseLast || 0
      this.retailPrice = this.value?.retailPrice || 0
      this.dateLastContract = this.value?.dateLastContract || null
    },
  },
}
</script>

<style scoped></style>
