<template>
  <v-tabs v-model="activeTab">
    <v-tab
      v-for="(item, tabIndex) in pricing"
      :key="tabIndex"
      :title="item.releaseForm || '-пусто-'"
      class="font-weight-bold"
      ><span>
        {{ item.releaseForm.substring(0, 10) || '-пусто-' }}
      </span>
      <span v-if="item.releaseForm.length > 10">...</span>
    </v-tab>
    <v-tab :disabled="!editMode" class="font-weight-regular">Добавить</v-tab>

    <v-tab-item
      v-for="(item, itemIndex) in pricing"
      :key="itemIndex"
      class="pa-2"
      eager
    >
      <PricingForm
        :value="item"
        :currency="value.originCurrency"
        :editMode="editMode"
        @change="doChangePricing"
        @delete="deletePricing"
        @blur="doBlur"
      />
    </v-tab-item>

    <!-- Последняя вкладка с добавлением -->
    <v-tab-item>
      <v-form
        @submit.prevent="addPricing"
        class="my-5 pt-0 d-flex align-center"
        :readonly="!editMode"
      >
        <v-text-field
          label="Форма выпуска"
          v-model="newPricingName"
          class="mr-4 pa-2"
          style="max-width: 480px"
          autofocus
        ></v-text-field>
        <v-btn color="primary" type="submit" :disabled="!editMode"
          >Добавить</v-btn
        >
      </v-form>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { DEFAULT_PRICING_FORM } from '@/components/expPricing/const'
import PricingForm from '@/components/expPricing/comp/pricing/PricingForm'
import Vue from 'vue'

export default {
  name: 'PricingForms',
  components: { PricingForm },
  data: () => ({
    activeTab: 0,
    pricing: [],
    newPricingName: '',
  }),
  props: {
    value: { type: Object, default: () => {} },
    editMode: { type: Boolean, default: false },
  },
  created() {
    this.init(this.value)
  },
  watch: {
    value: 'init',
  },
  methods: {
    init(value) {
      this.pricing = copyObject(value?.pricing || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
    },
    addPricing() {
      const defaultForm = DEFAULT_PRICING_FORM()
      defaultForm.releaseForm = this.newPricingName
      this.pricing.push(defaultForm)
      this.newPricingName = ''
      this.activeTab = this.pricing.length
      this.doChange(true)
    },
    doChangePricing(data, force) {
      Vue.set(this.pricing, data.idx - 1, data)
      this.doChange(force)
    },
    deletePricing(item) {
      this.pricing.splice(item.idx - 1, 1)
      this.activeTab = 0
      this.doChange(true)
    },
    doChange(force) {
      this.$emit('change', this.pricing, force)
    },
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped></style>
