var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[(!_vm.loading)?_c('SelectDate',{attrs:{"maxDate":_vm.minDate,"min-date":"2022-06-00","label":"Фильтр по дате"},on:{"input":function (value) { return _vm.filterDateLogs(value); }},model:{value:(_vm.dateLogs),callback:function ($$v) {_vm.dateLogs=$$v},expression:"dateLogs"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.logs,"group-by":"group","loading":_vm.loading,"headers":_vm.headers,"search":_vm.search,"disable-pagination":"","hide-default-footer":"","custom-filter":_vm.filterOnlyFio},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(items[0].date))])])]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('LogItem',{attrs:{"log-data":item}})]}},{key:"item.user_fio",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(_vm._f("shortFIO")(value),_vm.search)}})]}},{key:"item.time",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-center",attrs:{"dense":""}},'span',attrs,false),on),[_c('span',[_vm._v(_vm._s(value))])])]}}],null,true)},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actionsLogs"},[_c('v-btn',{attrs:{"loading":_vm.loadInfo && _vm.logInfo.id === item.id,"icon":""},on:{"click":function($event){!_vm.showInfoLog && !_vm.loadInfo && _vm.openInfoDialog(item)}}},[_c('v-icon',[_vm._v(" mdi-information-outline ")])],1)],1)]}},{key:"footer",fn:function(){return [(_vm.errorText)?_c('v-alert',{staticClass:"ma-2",attrs:{"type":"error","outlined":""}},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e(),(_vm.needLoading && _vm.showLogBtn)?_c('v-btn',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: _vm.loadUserLogs,
              options: {
                threshold: [0, 0.5, 1.0],
              },
            }),expression:"{\n              handler: loadUserLogs,\n              options: {\n                threshold: [0, 0.5, 1.0],\n              },\n            }"}],attrs:{"loading":_vm.loading,"block":"","text":""},on:{"click":_vm.loadUserLogs}},[_vm._v(" Показать ещё...")]):_vm._e()]},proxy:true}],null,true)})],1)],1),(_vm.showInfoLog)?_c('v-dialog',{attrs:{"overlay":"","width":"1000"},model:{value:(_vm.showInfoLog),callback:function ($$v) {_vm.showInfoLog=$$v},expression:"showInfoLog"}},[_c('LogItem',{attrs:{"logData":_vm.logInfo,"detail":""}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }