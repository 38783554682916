<template>
  <!-- если в списке исключения - уходим сразу -->
  <span v-if="!isExclude">
    <!-- рекурсия если есть изменения в глубь -->
    <template v-if="hasChanges">
      <diff-label
        v-for="(subDiff, idx) in changes"
        :key="idx"
        :diff="subDiff"
        :parent="path"
        :embedded="!!embeddedKey"
        :embedded-idxs="myEmbeddedIdxs"
        :tag-item="tagItem"
        :diff-keys="diffKeys"
        :diff-keys-exclude="diffKeysExclude"
      >
        <!-- прокидываем все слоты -->
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </diff-label>
    </template>
    <!-- одиночный финальный элемент - путь: $.path -->
    <component v-else :is="tagItem" :bind="$attrs" :class="tagClass">
      <span v-if="keyLabel">{{ keyLabel }}: </span>

      <!-- type: UPDATE -->
      <table v-if="type === 'UPDATE'" width="100%">
        <tr>
          <td width="45%">
            <diff-label-value :name="key" :value="oldValue" color="orange">
              <!-- прокидываем все слоты + добавляем ключ -->
              <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                <slot :name="slot" v-bind="{ ...scope, key, type }" />
              </template>
            </diff-label-value>
          </td>
          <td class="px-2"><v-icon>mdi-arrow-right</v-icon></td>
          <td width="45%">
            <diff-label-value :name="key" :value="value" color="green">
              <!-- прокидываем все слоты + добавляем ключ-->
              <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
                <slot :name="slot" v-bind="{ ...scope, key, type }" />
              </template>
            </diff-label-value>
          </td>
        </tr>
      </table>

      <!-- type: ADD и REMOVE-->
      <diff-label-value v-else :name="key" :value="value" color="green">
        <!-- прокидываем все слоты + добавляем ключ-->
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="{ ...scope, key, type }" />
        </template>
      </diff-label-value>
    </component>
  </span>
</template>

<script>
import DiffLabelValue from './diffLabelValue'

const diffKeysExclude = new Set(['id'])

export default {
  name: 'diffLabel',
  components: { DiffLabelValue },
  inheritAttrs: false,
  props: {
    diff: {
      type: Object,
      default: null,
    },
    parent: {
      type: String,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    embeddedIdxs: {
      type: Array,
      default: () => [],
    },
    tagItem: {
      type: String,
      validator(value) {
        return ['span', 'li', 'div'].includes(value)
      },
      default: 'div',
    },
    diffKeys: {
      type: Object,
      default: null,
    },
    diffKeysExclude: {
      type: Set,
      default: null,
    },
  },

  computed: {
    hasChanges() {
      return this.changes?.length > 0
    },
    changes() {
      // перебрать массив changes, если в нём подряд идёт удаление потом добавление одного и того же ключа, объединяем в UPDATE
      let magicFlag = 1
      return this.diff.changes?.reduce((out, el, i, arr) => {
        const nx = arr[i + 1]
        if (!el.embeddedKey && nx) {
          if (el.type === 'REMOVE' && nx.type === 'ADD' && el.key === nx.key) {
            const oldValue = el.value
            magicFlag = 0
            return [...out, { ...nx, type: 'UPDATE', oldValue }]
          }
        }
        if (magicFlag) return [...out, el]
        magicFlag = 1
        return out
      }, [])
    },
    type() {
      return this.diff.type
    },
    embeddedKey() {
      return this.diff.embeddedKey
    },
    key() {
      return this.diff.key?.trim()
    },
    path() {
      if (this.embedded) {
        return this.parent
      }
      // собираем путь <parent>.<key>[embeddedKey]
      let path = this.parent ? `${this.parent}.${this.key}` : this.key
      if (this.embeddedKey) {
        path += `[${this.embeddedKey}]`
      }
      return path?.trim()
    },
    keyLabel() {
      const diffKeys = this.diffKeys ?? {}
      const label = diffKeys[this.path] || diffKeys[this.key] || this.path

      // расставляем индексы
      return this.myEmbeddedIdxs.reduce(
        (label, key) => label.replace('$index', key),
        label.trim()
      )
    },
    value() {
      return this.diff.value
    },
    valueIsEmpty() {
      return !this.value
    },
    oldValue() {
      return this.diff.oldValue
    },
    oldValueIsEmpty() {
      return !this.oldValue
    },
    isExclude() {
      return (
        diffKeysExclude?.has(this.key) || this.diffKeysExclude?.has(this.key)
      )
    },
    myEmbeddedIdxs() {
      return (
        (this.embedded
          ? [...this.embeddedIdxs, this.key]
          : this.embeddedIdxs) || []
      )
    },
    tagClass() {
      // если remove - перечёркиываем
      return this.type === 'REMOVE'
        ? 'text--primary text-decoration-line-through'
        : ''
    },
  },
}
</script>
