<template>
  <v-row dense>
    <v-col cols="12" lg="2" class="d-flex align-center pb-7"
      ><div class="text-caption font-weight-bold">{{ label }}</div></v-col
    >
    <v-col cols="2">
      <v-text-field
        readonly
        :label="prop_1_label"
        type="number"
        v-model.number="value_1"
        @input="doChange"
        @blur="doBlur"
      />
    </v-col>
    <v-col class="align-center text-center"
      ><same-icon class="mt-4" :same="isCompare_1" />
    </v-col>
    <v-col cols="3">
      <v-text-field
        :readonly="!editMode"
        :outlined="editMode"
        :label="ownLabel"
        type="number"
        v-model.number="value_own"
        @input="doChange"
        @blur="doBlur"
      />
    </v-col>
    <v-col class="align-center text-center"
      ><same-icon class="mt-4" :same="isCompare_2" />
    </v-col>
    <v-col cols="2">
      <v-text-field readonly :label="prop_2_label" v-model="value_2" />
    </v-col>
  </v-row>
</template>

<script>
import { sameObject } from '@/lib/objects'
import SameIcon from '@/components/compare/SameIcon'

export default {
  name: 'ScoreRow',
  components: { SameIcon },
  data: () => ({
    value_1: 0,
    value_2: 0,
    value_own: 0,
  }),
  methods: {
    init() {
      this.value_1 = Number(this.prop_1)
      this.value_2 = Number(this.prop_2)
      this.value_own = Number(this.prop_own)
    },
    doChange() {
      this.$emit('change', this.value_own)
    },
    doBlur() {
      this.$emit('blur')
    },
  },
  watch: {
    prop_own: 'init',
  },
  props: {
    prop_1: {
      type: [String, Number],
      default: '',
    },
    prop_2: {
      type: [String, Number],
      default: '',
    },
    //Собственное значение
    prop_own: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    ownLabel: {
      type: String,
      default: 'Баллы ГВС (кор. Баллы ЭО)',
    },
    prop_1_label: {
      type: String,
      default: 'Собственный расчет',
    },
    prop_2_label: {
      type: String,
      default: 'Баллы ЭО',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  computed: {
    isCompare_1() {
      return sameObject(this.value_1, this.value_own)
    },
    isCompare_2() {
      return sameObject(this.value_own, this.value_2)
    },
  },
}
</script>

<style scoped></style>
