<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="purple--text text-decoration-underline">
        {{ message }}
      </span>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'

export default {
  name: 'DictUpdatedLog',
  inheritAttrs: false,
  components: { DefaultLog },
}
</script>

<style scoped></style>
