var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.priceCharacteristics,"headers":_vm.priceCharacterHeaders,"show-expand":"","single-select":"","item-key":"idx","single-expand":"","expanded":_vm.expandedPurchase,"disable-pagination":"","hide-default-footer":"","no-data-text":"Информация о ценовой характеристики ЛП отсутствует"},on:{"update:expanded":function($event){_vm.expandedPurchase=$event}},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(item.idx)+".")])]}},{key:"item.releaseForm",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(value))]):_c('span',{staticClass:"warning--text"},[_vm._v(" ( пусто ) ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"td-expanded pa-2",attrs:{"colspan":headers.length}},[_c('PriceEditor',{attrs:{"value":item,"readonly":_vm.readonly},on:{"change":_vm.doChangeItem,"blur":_vm.doBlur,"delete":function($event){return _vm.deleteAssessments(item.idx)}}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('td',{staticClass:"text-start"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-pencil-circle-outline")])],1)],1)]}},(!_vm.readonly)?{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex pt-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!!_vm.readonly,"title":"Добавить форму выпуска","color":"accent","fab":"","small":""},on:{"click":function($event){return _vm.addPurchase()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }