<script>
import { filteredDatetime } from '@/components/viewLogs/logsFunctions'
//Миксин для того, чтобы вытащить из слота logInfo и для каждого компонента прописывать свои computed
export default {
  data: () => ({
    logData: null,
  }),
  methods: {
    filteredDatetime,
    showLogInfo(data) {
      this.logData = data
      return !!data
    },
  },
  filters: {
    filteredDatetime,
  },
}
</script>
