<template>
  <v-form v-if="!loading && additionalInfo" :readonly="readonly" ref="form">
    <v-alert v-if="needRefresh" type="warning" dense text>
      Внимание! Информация по экспертизе изменилась, рекомендуется обновить
      данные
      <template #append>
        <v-btn
          v-if="editMode && !loading"
          text
          color="green"
          small
          title="Обновить данные по экспертизе"
          @click="refreshData"
          :disabled="!needRefresh"
          ><v-icon>mdi-sync</v-icon></v-btn
        >
      </template>
    </v-alert>
    <v-card class="mb-3">
      <v-card-title>
        Данные о сравнительной клинической эффективности(исследования из п. 5.2.
        Предложения)
      </v-card-title>
      <v-card-text>
        <AdditionalTable
          :value="additionalInfo.crEffects"
          :readonly="readonly"
          @change="doChangeCrEffects"
        />
      </v-card-text>
    </v-card>
    <!-- Экспертиза КЭИ-->
    <v-card v-if="additionalInfo && !loading" elevation="1" class="mb-2">
      <v-card-title>Данные о клинико-экономической эффетивновсти</v-card-title>
      <v-card-text>
        <v-alert v-if="!logView && numberKeiChanged" type="warning" dense text
          >Внимание! Информация о количестве экспертиз КЭИ изменилась
        </v-alert>
        <v-tabs v-if="keiExist" center-active show-arrows>
          <v-tab
            v-for="(element, elementIndex) in additionalInfo.kei"
            :key="elementIndex"
            :title="element.shortName"
            :class="{
              'error--text': isErrorTitle(element.shortName),
            }"
            >{{ element.shortName }}</v-tab
          >
          <v-tab-item
            v-for="(_, elementIndex) in additionalInfo.kei"
            :key="elementIndex"
            eager
            style="min-height: 400px"
          >
            <AddKei
              :value="additionalInfo.kei[elementIndex]"
              :init-element="initData ? initData.kei.ka[elementIndex] : null"
              :readonly="readonly"
              @change="(el, force) => change('kei', el, elementIndex, force)"
              @refreshData="refreshExp('kei', elementIndex)"
              @changeAlert="changeAlert"
              @blur="doBlur"
            />
          </v-tab-item>
        </v-tabs>
        <v-alert v-else type="info" outlined :text="!readonly" dense>
          <b>Отчет по КЕИ</b>: заявлен, но не представлен в составе Предложения.
        </v-alert>
      </v-card-text>
    </v-card>
    <!-- Экспертиза АВБ-->
    <v-card v-if="additionalInfo && !loading" elevation="1" class="mb-2">
      <v-card-title>Данные о влиянии на бюджет</v-card-title>
      <v-card-text>
        <v-alert v-if="!logView && numberAvbChanged" type="warning" dense text
          >Внимание! Информация о количестве экспертиз АВБ изменилась
        </v-alert>
        <v-tabs v-if="avbExist" center-active show-arrows>
          <v-tab
            v-for="(element, elementIndex) in additionalInfo.avb"
            :key="elementIndex"
            :title="element.shortName"
            :class="{
              'error--text': isErrorTitle(element.shortName),
            }"
            >{{ element.shortName }}</v-tab
          >
          <v-tab-item
            v-for="(_, elementIndex) in additionalInfo.avb"
            :key="elementIndex"
            eager
            style="min-height: 400px"
          >
            <AddAvb
              :value="additionalInfo.avb[elementIndex]"
              :init-element="initData ? initData.avb.ka[elementIndex] : null"
              :readonly="readonly"
              @change="(el, force) => change('avb', el, elementIndex, force)"
              @refreshData="refreshExp('avb', elementIndex)"
              @changeAlert="changeAlert"
              @blur="doBlur"
            />
          </v-tab-item>
        </v-tabs>
        <v-alert v-else type="info" outlined :text="!readonly" dense>
          <b>Отчет по АВБ</b>: заявлен, но не представлен в составе Предложения.
        </v-alert>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import ExpSummaryMixin from '../ExpSummaryMixin'
import AddKei from './AddKei'
import AddAvb from './AddAvb'
import KeiAvbMixin from './KeiAvbMixin'
import { checkSummaryKei, createSummaryKeiCopy } from './keiUtils'
import { checkSummaryAvb, createSummaryAvbCopy } from './avbUtils'
import AdditionalTable from './comp/AdditionalTable'

export default {
  components: { AdditionalTable, AddKei, AddAvb },
  data: () => ({
    errorArrNames: [],
    errorNames: new Set(),
  }),
  name: 'SummaryAdditional',
  mixins: [ExpSummaryMixin, KeiAvbMixin],
  computed: {
    numberKeiChanged() {
      return (
        !this.initData ||
        this.initData.kei?.ka?.length !== this.data?.additionalInfo?.kei?.length
      )
    },
    numberAvbChanged() {
      return (
        !this.initData ||
        this.initData.avb?.ka?.length !== this.data?.additionalInfo?.avb?.length
      )
    },
    keiExist() {
      return this.additionalInfo?.kei?.length > 0
    },
    avbExist() {
      return this.additionalInfo?.avb?.length > 0
    },
    additionalInfo() {
      return this.data?.additionalInfo || null
    },
    showAlert() {
      return (
        !this.checkData ||
        !checkSummaryAvb(this.additionalInfo?.avb, this.initData?.avb) ||
        !checkSummaryKei(this.additionalInfo?.kei, this.initData?.kei)
      )
    },

    checkData() {
      return !this.numberKeiChanged && !this.numberAvbChanged
    },
  },
  methods: {
    doChangeCrEffects(data, force = false) {
      this.additionalInfo.crEffects = data
      this.doChangeCr(force)
    },
    doChangeCr(force = false) {
      if (!this.readonly) this.$emit('change', this.data, force)
    },
    change(key, element, elementIndex, force) {
      this.$set(this.additionalInfo[key], elementIndex, element)
      this.doChange(force)
    },
    isErrorTitle(shortName) {
      return !this.logView && this.errorArrNames.includes(shortName)
    },
    changeAlert(shortName, state) {
      if (state) this.errorNames.add(shortName)
      this.errorArrNames = [...this.errorNames.values()]
    },
    refreshExp(key, elementIndex) {
      if (!this.initData) return

      const isKei = key === 'kei'
      const createExpCopy = isKei ? createSummaryKeiCopy : createSummaryAvbCopy

      // экспертиза Кэи или Авб
      const exp = createExpCopy(this.additionalInfo[key], this.initData[key])

      this.change(key, exp[elementIndex], elementIndex, true)
    },
  },
}
</script>
