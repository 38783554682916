<template>
  <v-card flat>
    <v-data-table
      dense
      :headers="safetyHeaders"
      :items="SAFETY"
      :group-by="['safetyLevel']"
      hide-default-footer
      :items-per-page="100"
    >
      <template #[`group.header`]="{ headers, items }">
        <td :colspan="headers.length">
          <span v-if="items" class="text-overline"
            >{{ items[0].safetyLevel }}.
          </span>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
const SAFETY = [
  {
    safetyLevel: 'Побочные действия отсутствуют',
    numberPE: '0',
    scores: 0,
    weightFactor: '0',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 10',
    scores: -1,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 20',
    scores: -1,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 30',
    scores: -1,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 40',
    scores: -2,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 50',
    scores: -2,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 60',
    scores: -3,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 70',
    scores: -3,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 80',
    scores: -3,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 90',
    scores: -4,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия легкой степени',
    numberPE: '≥ 100',
    scores: -4,
    weightFactor: '0,25',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 10',
    scores: -2,
    weightFactor: '0',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 20',
    scores: -2,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 30',
    scores: -3,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 40',
    scores: -3,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 50',
    scores: -4,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 60',
    scores: -4,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 70',
    scores: -5,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 80',
    scores: -5,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '≥ 90',
    scores: -6,
    weightFactor: '0,5',
  },
  {
    safetyLevel: 'Побочные действия средней степени',
    numberPE: '100',
    scores: -6,
    weightFactor: '0,5',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 10',
    scores: -4,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 20',
    scores: -4,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 30',
    scores: -5,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 40',
    scores: -5,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 50',
    scores: -6,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 60',
    scores: -6,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 70',
    scores: -7,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 80',
    scores: -8,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 90',
    scores: -9,
    weightFactor: '1',
  },
  {
    safetyLevel:
      'Побочные действия тяжелой степени - состояния, продлевающие нахождение пациента в стационаре или представляющие угрозу для жизни',
    numberPE: '≥ 100',
    scores: -10,
    weightFactor: '1',
  },
]
export default {
  name: 'SafetyAssessment',
  data: () => ({
    SAFETY,
    safetyHeaders: [
      {
        text: 'Оценка уровня безопасности ЛП',
        value: 'safetyLevel',
        sortable: false,
      },
      {
        text: 'Число случае ПЭ, %',
        value: 'numberPE',
        sortable: false,
      },
      {
        text: 'Баллы',
        value: 'scores',
        sortable: false,
      },
      {
        text: 'Весовой коэффициент',
        value: 'weightFactor',
        sortable: false,
        cellClass: 'text--primary font-weight-bold',
      },
      { text: '', value: 'data-table-expand' },
    ],
  }),
}
</script>

<style scoped></style>
