<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" sm="10">
        <v-text-field
          label="Форма выпуска"
          v-model="item.releaseForm"
          :outlined="editMode"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          :append-icon="editMode ? 'mdi-pencil' : null"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-text-field
          label="Кол-во действующего вещества, в упаковке"
          v-model.number="item.numberActive"
          type="number"
          :outlined="editMode"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          :append-icon="editMode ? 'mdi-pencil' : null"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <PricingTable
          :value="item.currencyPrice"
          :currency="currency"
          :editMode="editMode"
          :numberActive="Number(item.numberActive)"
          @change="doChangeCurrency"
          @blur="doBlur()"
        />
      </v-col>
    </v-row>
    <hr class="my-2" />
    <v-row>
      <v-col>
        <v-simple-table hide-default-footer>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Мнн, форма выпуска</th>
                <th width="200px" class="text-left">
                  Цена в Российской Федерации (руб.)
                </th>
                <th width="190px" class="text-left">
                  Минимальная цена в референтной стране, руб.
                </th>
                <th width="170px" v-if="differentPercent" class="text-left">
                  Разница с ценой в Референтной стране (разница в %)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="font-weight-bold"
                    >{{ loading ? 'мнн...' : mnn }},
                  </span>
                  <span v-if="item.releaseForm">{{ item.releaseForm }}</span>
                  <span class="warning--text" v-else>(пусто)</span>
                </td>
                <td>
                  <v-text-field
                    dense
                    v-model="item.registerPrice"
                    type="number"
                    :outlined="editMode"
                    @input="doChange()"
                    @blur="doBlur()"
                    hide-details
                    :append-icon="editMode ? 'mdi-pencil' : null"
                  />
                </td>
                <td>
                  <span class="font-weight-bold">{{ minPrice | toMoney }}</span>
                  - <span class="">{{ minCountry }}</span>
                  <span class="ml-2 font-weight-bold">{{
                    getFlag(minCountry)
                  }}</span>
                </td>
                <td v-if="differentPercent" class="font-weight-bold">
                  <span
                    v-if="minPrice > item.registerPrice"
                    class="green--text font-weight-bold"
                    >меньше на {{ differentPercent }} %</span
                  >
                  <span v-else class="red--text font-weight-bold"
                    >больше на {{ differentPercent }} %</span
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row v-if="editMode">
      <v-col class="text-right">
        <PricingDeleteDialog
          v-if="editMode"
          :name="item.releaseForm"
          @onAction="deletePricing"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { getFlag } from '@/components/expPricing/const'
import PricingTable from '@/components/expPricing/comp/pricing/PricingTable'
import PricingDeleteDialog from '@/components/expPricing/comp/pricing/PricingDeleteDialog'
import { toMoney } from '@/lib/strUtils'
import { mapActions, mapMutations } from 'vuex'
import { SUB_EX_ABOUT } from '@/store/const/expertise'

export default {
  name: 'PricingForm',
  components: { PricingDeleteDialog, PricingTable },
  data: () => ({
    item: null,
    loading: false,
    mnn: '',
  }),
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Array,
    },
  },
  watch: {
    value: 'init',
  },
  created() {
    this.init()
  },
  async mounted() {
    await this.loadMnn()
  },
  filters: {
    toMoney,
  },
  methods: {
    ...mapMutations(['SET_ERROR']),
    ...mapActions(['GET_PLP_REPORT']),
    getFlag,
    doChange(force) {
      this.$emit(
        'change',
        {
          ...this.item,
          minPrice: this.minPrice,
          differentPrice: this.differentPercent,
        },
        force
      )
    },
    async loadMnn() {
      this.loading = true
      try {
        const { expertise } = await this.GET_PLP_REPORT([SUB_EX_ABOUT])
        this.mnn = expertise?.about?.mnn || ''
      } catch (err) {
        this.SET_ERROR({
          head: 'МНН',
          text: 'Ошибка загрузки МНН.',
          err,
        })
      } finally {
        this.loading = false
      }
    },
    init() {
      this.item = copyObject(this.value)
    },
    doChangeCurrency(data, force) {
      this.item.currencyPrice = data
      this.doChange(force)
    },
    doBlur() {
      this.$emit('blur')
    },
    deletePricing() {
      this.$emit('delete', this.item)
    },
  },
  computed: {
    minPrice() {
      const min = Math.min(
        ...this.item?.currencyPrice
          .map(item => Number(item.priceRUB))
          .filter(price => price > 0)
      )
      if (min === Number.POSITIVE_INFINITY) return 0
      return min
    },
    minCountry() {
      const itemCur = this.item?.currencyPrice?.find(
        item => Number(item.priceRUB) === this.minPrice && item.price > 0
      )
      return itemCur?.country || 'ПУСТО'
    },
    differentPercent() {
      const regPrice = Number(this.item?.registerPrice) || 0
      const minPrice = Number(this.minPrice) || 0
      if (!regPrice || !minPrice) return 0
      return Math.abs(Math.round(100 * (1 - regPrice / minPrice)))
    },
  },
}
</script>
