<template>
  <v-data-table
    :items="crEffects"
    :headers="crEffectsHeaders"
    show-expand
    disable-pagination
    hide-default-footer
    disable-sort
    single-select
    single-expand
    item-key="idx"
    :expanded.sync="expandedCrEffects"
    no-data-text="Информация о assessmentsData отсутствует"
  >
    <template #[`item.idx`]="{ item }">
      <span class="text--disabled">{{ item.idx }}.</span>
    </template>
    <template #[`item.crScience`]="{ value }">
      <expand-text
        v-if="value"
        separator=" "
        :value="String(value)"
        :words-count="5"
      />
      <span v-else class="warning--text"> ( пусто ) </span>
    </template>
    <template #[`item.analogCompare`]="{ value }">
      <span v-if="value" class="font-weight-medium">{{ value }}</span>
      <span v-else class="warning--text"> ( пусто ) </span>
    </template>
    <template #[`item.population`]="{ value }">
      <span v-if="value" class="font-weight-medium">{{ value }}</span>
      <span v-else class="warning--text"> ( пусто ) </span>
    </template>
    <template #[`item.result`]="{ value }">
      <expand-text
        v-if="value"
        separator=" "
        :value="String(value)"
        :words-count="5"
      />
      <span v-else class="warning--text"> ( пусто ) </span>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="td-expanded pa-2">
        <AdditionalEditor
          :value="item"
          @change="doChangeItem"
          @delete="deleteAssessments(item.idx)"
          :readonly="readonly"
        />
      </td>
    </template>
    <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
      <td class="text-start">
        <v-btn
          icon
          @click="expand(!isExpanded)"
          class="v-data-table__expand-icon"
          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
        >
          <v-icon>mdi-pencil-circle-outline</v-icon>
        </v-btn>
      </td>
    </template>
    <template #footer v-if="!readonly">
      <div class="d-flex pt-3">
        <v-spacer />
        <v-btn
          color="accent"
          fab
          small
          :disabled="!!readonly"
          @click="addAssessment()"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { copyObject } from '@/lib/objects'
import SafetyEditor from '@/components/expSummary/summaryScore/comp/SafetyEditor'
import { DEFAULT_CR_EFFECT } from '@/components/expSummary/summaryAdditional/addConst'
import AdditionalEditor from '@/components/expSummary/summaryAdditional/comp/AdditionalEditor'
import ExpandText from '@/components/editors/expandText'

export default {
  name: 'AdditionalTable',
  components: { ExpandText, AdditionalEditor },
  data: function () {
    return {
      crEffects: [],
      expandedCrEffects: [],
      scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      crEffectsHeaders: [
        {
          text: '№',
          value: 'idx',
          width: '10px',
          sortable: false,
        },
        {
          text: 'Клиническое исследование',
          value: 'crScience',
          sortable: false,
        },
        {
          text: 'Препарат сравнения',
          value: 'analogCompare',
          sortable: false,
        },
        {
          text: 'Популяция',
          value: 'population',
          sortable: false,
        },
        {
          text: 'Результат',
          value: 'result',
          sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
    },
  },
  watch: {
    value: 'init',
  },
  created() {
    this.init(this.value)
  },
  mounted() {
    const firstItem = this.crEffects?.[0]
    if (!firstItem?.crScience) {
      this.expand(firstItem)
    }
  },
  methods: {
    init(value) {
      this.crEffects = copyObject(value || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
    },
    doChangeItem(item, force = false) {
      const idx = item.idx - 1
      this.crEffects[idx] = item
      this.doChange(force)
    },
    doChange(force = false) {
      if (!this.readonly) {
        this.$emit('change', this.crEffects, force)
      }
    },
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments(idx) {
      this.crEffects.splice(idx - 1, 1)
      this.$set(this, 'crEffects', this.crEffects)
      this.expand(null)
      this.doChange(true)
    },
    expand(item = null) {
      this.expandedCrEffects = item ? [item] : []
    },
    addAssessment() {
      this.crEffects.push(DEFAULT_CR_EFFECT())
      this.doChange(true)

      this.$nextTick(() => {
        const lg = this.crEffects?.length || 0
        this.expand(this.crEffects[lg - 1])
      })
    },
  },
}
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
