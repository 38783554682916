<template>
  <v-combobox
    v-model="model"
    v-bind="$attrs"
    v-on="$listeners"
    :items="getAD"
    item-text="mnn"
    item-value="mnn"
    @change="val => updateMNN(val)"
    label="МНН"
  >
    <template v-slot:no-data><div class="pa-3">нет данных</div></template>
    <template v-slot:item="{ item }">
      {{ item.mnn }}<v-spacer /><span class="text--disabled">{{
        item.ath
      }}</span>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    isOpenForAdd: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array || null,
    },
  },
  data: () => ({
    model: null,
  }),
  created() {
    if (!this.isOpenForAdd) this.model = this.value.mnn
  },
  methods: {
    updateMNN(val) {
      this.$emit('updateMNN', val)
    },
  },
  computed: {
    getAD() {
      if (this.model) {
        return (
          this.items?.filter(
            item =>
              String(item.mnn).localeCompare(
                this.model?.mnn ?? this.model,
                undefined,
                { sensitivity: 'base', ignorePunctuation: true }
              ) === 0
          ) || []
        )
      } else {
        return this.items || []
      }
    },
  },
}
</script>
