<template>
  <td
    v-if="item.mergeObj || !item.merged"
    :rowspan="item.mergeObj ? item.mergeObj.bottom + 1 : 1"
    :colspan="item.mergeObj ? item.mergeObj.right + 1 : 1"
  >
    <div v-if="item.mergeObj" class="align-center text-center">
      <span>{{ formatter(item.value) }}</span>
    </div>
    <div v-else class="text-center">
      <span>{{ formatter(item.value) }}</span>
    </div>
  </td>
</template>

<script>
import { toMoney } from '@/lib/strUtils'
export default {
  name: 'CostItem',
  filters: { toMoney },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    formatter: {
      type: Function,
      default: val => {
        return val
      },
    },
  },
}
</script>
