<template>
  <span :bind="$attrs">
    <!-- общий слот [custom] - рисуем все ключи сами  -->
    <slot name="diff" v-bind="bind">
      <!-- частный слот [custom.<name>] - рисуем сами только ключ <name>  -->
      <slot :name="`diff.${name}`" v-bind="bind">
        <!-- Если value is Bool -->
        <div v-if="isBoolean" class="text-center">
          <v-icon :color="color">{{ valueIcon }}</v-icon>
        </div>
        <!-- Если value is Null -->
        <span v-else-if="isNull" class="text--disabled"> - пусто - </span>
        <!-- Если value is Объект то сначала общий слот объекта [object] для всех -->
        <slot v-else-if="isObject" name="diff.object" v-bind="bind">
          <!-- частный слот Объекта [object.<name>] для ключей <name> -->
          <slot :name="`diff.object.${name}`" v-bind="bind">
            <span class="text--disabled">[ Объект ]</span>
          </slot>
        </slot>
        <!-- иначе value - как текст -->
        <span v-else :class="textClass">{{ String(value) }}</span>
      </slot>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'diffLabelValue',
  props: {
    name: { type: String },
    value: { default: null },
    color: {},
  },
  inheritAttrs: false,

  computed: {
    isNull() {
      return this.value === null
    },
    isBoolean() {
      return typeof this.value === 'boolean'
    },
    valueIcon() {
      if (this.value) return `mdi-checkbox-marked-outline`
      else return 'mdi-checkbox-blank-outline'
    },
    isObject() {
      return typeof this.value === 'object'
    },
    textClass() {
      return this.color ? `${this.color}--text` : ''
    },
    bind() {
      return {
        value: this.value,
        color: this.color,
        textClass: this.textClass,
      }
    },
  },
}
</script>
