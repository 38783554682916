<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span class="text-decoration-underline teal--text font-weight-bold">
        {{ message }}
      </span>
    </template>
    <template #detail="{ logInfo }">
      <ul v-if="showLogInfo(logInfo)">
        <li>
          <span class="font-weight-bold">Выполнена: </span
          >{{ taskCompleted ? 'да' : 'нет' }}
        </li>
        <li>
          <span class="font-weight-bold">Проверена: </span
          >{{ taskChecked ? 'да' : 'нет' }}
        </li>
        <li>
          <span class="font-weight-bold">В работе: </span
          >{{ taskWork ? 'да' : 'нет' }}
        </li>
        <li>
          <span class="font-weight-bold">Дата создания: </span
          >{{ filteredDatetime(dateCreate) }}
        </li>
      </ul>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'

export default {
  name: 'PlpTaskLog',
  components: { DefaultLog },
  inheritAttrs: false,
  mixins: [logInfoMixin],
  computed: {
    taskCompleted() {
      return this.logData?.data?.data?.is_completed
    },
    taskChecked() {
      return this.logData?.data?.data?.is_checked
    },
    taskWork() {
      return this.logData?.data?.data?.is_in_work
    },
    dateCreate() {
      return this.logData?.data?.data?.time_edit
    },
  },
}
</script>
