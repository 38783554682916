export const SCORE_KI_TEMPLATES = [
  'Оценены не все исследования, представленные заявителем в п. 5.2 Предложения ([расшифровка несоотстветствия])',
  'Некорректно оценено качество [Дизайн КИ, Автор, год] ([расшифровка несоотстветствия])',
  'Невозможно идентифицировать КИ, оцененные по шкале оценки уровней доказательности результатов клинических исследований, так как отсутствует название КИ, инициалы автора и год, литературная ссылка',
  'Некорректно оценено качество мнения экспертов [Автор, год] ([расшифровка несоотстветствия])',
  'Некорректно рассчитан итоговый балл по клинической оценке Предложения ([расшифровка несоотстветствия])',
  'Некорректно выставлена итоговая оценка клинической оценки предложения ([расшифровка несоотстветствия])',
  'Некорректно оценен уровень убедительности / доказательности доказательств для [Дизайн КИ, Автор, год] ([расшифровка несоотстветствия])',
  'Не проведена интегральная количественная оценка качества клинических исследований ([расшифровка несоотстветствия])',
]

export const SCORE_SAFETY_TEMPLATES = [
  'Некорректно рассчитан балл оценки побочных действий тяжелой степени ([расшифровка несоотстветствия])',
  'Некорректно рассчитан балл оценки побочных действий легкой и тяжелой степени ([расшифровка несоотстветствия])',
  'Некорректно рассчитан балл оценки побочных действий: частота побочных действий, извлеченная из клинических исследований, не соответствует баллам по шкале оценки безопасности применения ЛП',
  'Некорректно рассчитан балл оценки побочных действий: частота побочных действий не соответствует баллам по шкале оценки безопасности применения ЛП, использованы некорректные весовые коэффициенты для побочных действий средней и тяжелой степени',
  'Некорректно рассчитан балл итоговой оценки уровня безопасности ([расшифровка несоотстветствия])',
  'Итоговый балл оценки побочных действий рассчитан по некорректной методике ([расшифровка несоотстветствия])',
]

export const SCORE_KEIAVB_TEMPLATES = [
  'Некорректно оценены результаты клинико-экономического исследования и анализа влияния на бюджет ([расшифровка несоотстветствия])',
  'Некорректно оценены результаты клинико-экономического исследования ([расшифровка несоотстветствия])',
  'Некорректно оценены результаты анализа влияния на бюджет ([расшифровка несоотстветствия])',
  'Некорректно выставлен балл по клинико-экономической оценки ([расшифровка несоотстветствия])',
  'Некорректно выставлен балл за анализ влияния на бюджет ([расшифровка несоотстветствия])',
  'Не оценены результаты клинико-экономического исследования и анализа влияния на бюджет ([расшифровка несоотстветствия])',
  'Не оценены результаты клинико-экономического исследования ([расшифровка несоотстветствия])',
  'Не оценены результаты анализа влияния на бюджет ([расшифровка несоотстветствия])',
  'Некорректно учтены баллы по шкале экономических последствий: в итоговых баллах учтен результат оценки стоимости курса (года) лечения ЛП ([расшифровка несоотстветствия])',
]

export const SCORE_OTHER_TEMPLATES = [
  'Некорректно проведена оценка прочих данных по предложению (баллы выставляются ФГБУ ЦЭККМП)',
  'Отсутствует вывод о целесообразности/нецелесообразности включения препарата в перечень [ЖНВЛП / 14 ВЗН / ЖНВЛП и 14 ВЗН] ',
  'Некорректно рассчитана сумма баллов оценки Предложения Некорректно выставлена итоговая сумма оценки Предложения ([расшифровка несоотстветствия])',
  'Некорректно выставлен балл по критерию «[Название критерия]», который отсутствует в Постановлении Правительства 871',
]
