<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    :label="lb"
    colorBtnAction="error"
    labelBtnClose="ОТМЕНА"
    labelBtnAction="УДАЛИТЬ"
  />
</template>

<script>
import ActionDialog from '@/components/dialogs/ActionDialog.vue'
export default {
  name: 'ExpAgencyDeleteDialog',
  props: {
    lb: {
      type: String,
      default: 'Удалить',
    },
  },
  components: { ActionDialog },
}
</script>
