<template>
  <v-dialog v-model="dialogState" width="500" persistent>
    <!-- прокидываем все слоты -->
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <v-card v-if="dialogStateKiller">
      <v-card-title class="text-h5 text-center">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <p>
          {{ label }}
          <br />
          <b v-if="name"> "{{ name }}" </b>
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="colorBtnClose" text @click="closeDialog"
          >{{ labelBtnClose }}
        </v-btn>
        <v-btn :color="colorBtnAction" text @click="closeAndAction">
          {{ labelBtnAction }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ActionDialog',
  data: () => ({
    dialogState: false,
    dialogStateKiller: false,
    killTimer: 0,
  }),
  model: {
    prop: 'dialog',
    event: 'change',
  },

  props: {
    name: {
      type: String,
      required: false,
      //default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: 'Предупреждение системы',
    },
    labelBtnClose: {
      type: String,
      required: false,
      default: 'ОТМЕНА',
    },
    colorBtnClose: {
      type: String,
      required: false,
      //default: 'error',
    },
    labelBtnAction: {
      type: String,
      required: false,
      default: 'ПРИМЕНИТЬ',
    },
    colorBtnAction: {
      type: String,
      required: false,
      default: 'primary',
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  watch: {
    dialog(val) {
      this.dialogState = val
    },
    dialogState() {
      this.changeState()
      if (this.dialogState) {
        clearTimeout(this.killTimer)
        this.dialogStateKiller = false
        this.$nextTick(() => {
          this.dialogStateKiller = true
        })
      } else
        this.killTimer = setTimeout(() => {
          this.dialogStateKiller = false
        }, 1000)
    },
  },
  beforeDestroy() {
    clearTimeout(this.killTimer)
  },
  methods: {
    closeDialog() {
      this.$emit('onClose')
    },
    closeAndAction() {
      this.$emit('onAction')
    },
    changeState() {
      this.$emit('change', this.dialogState)
    },
  },
}
</script>
