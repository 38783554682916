var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"items":_vm.safety,"headers":_vm.assessmentHeaders,"show-expand":"","single-select":"","single-expand":"","item-key":"idx","expanded":_vm.expandedAssessments,"disable-pagination":"","hide-default-footer":"","no-data-text":"Информация о assessmentsData отсутствует"},on:{"update:expanded":function($event){_vm.expandedAssessments=$event}},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(item.idx)+".")])]}},{key:"item.sideEffect",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(value))]):_c('span',{staticClass:"warning--text"},[_vm._v(" ( пусто ) ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"td-expanded pa-2",attrs:{"colspan":headers.length}},[_c('SafetyEditor',{attrs:{"value":item,"readonly":_vm.readonly},on:{"change":_vm.doChangeItem,"blur":_vm.doBlur,"delete":function($event){return _vm.deleteAssessments(item.idx)}}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('td',{staticClass:"text-right"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-pencil-circle-outline")])],1)],1)]}},(!_vm.readonly)?{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex pt-3"},[_c('div',{staticClass:"text-body-1"},[_c('span',[_vm._v("Суммарная оценка: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.averageValue))])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","fab":"","small":"","disabled":!!_vm.readonly},on:{"click":function($event){return _vm.addAssessment()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true}:null],null,true)})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Баллы ЭО","outlined":!_vm.readonly,"hide-details":"","type":"number","append-icon":!_vm.readonly ? 'mdi-pencil' : null},on:{"input":function($event){return _vm.doChangeEO()},"blur":function($event){return _vm.doBlur()}},model:{value:(_vm.scoreEO),callback:function ($$v) {_vm.scoreEO=$$v},expression:"scoreEO"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('HelpCard',{staticClass:"my-2",attrs:{"caption":"СПРАВОЧНАЯ ИНФОРМАЦИЯ – ШКАЛА БЕЗОПАСНОСТИ","outlined":"","border":""}},[_c('SafetyAssessment')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }