export const DEFAULT_RELEASE_FORM = () => ({
  lf: '',
  min: 0,
  max: 0,
})
export const EX_PRICE_CHARACTERISTICS = () => ({
  releaseForm: '', //Форма выпуска
  registerPrice: 0, //Цена, планируемая к регистрирации, руб.
  registerPriceWithNdc: 0, //Цена, планируемая к регистрирации, руб. с НДС
  pricePurchase: 0, //цена гос закупок средневзвешенная
  pricePurchaseLast: 0, //цена гос закупок средневзвешенная (Последняя)
  retailPrice: 0, //Розничная цена, руб.
  dateLastContract: null, //Дата последнего контракта
})
