import { SUB_VERSION_MIGRATION, SUB_EX_PRICING } from '@/store/const/expertise'

export const EX_PRICING_TEMPLATE_VERSION = 1

export const countryFlagMap = new Map([
  ['Королевство Нидерланды', '🇳🇱'],
  ['Республика Польша', '🇵🇱'],
  ['Румыния', '🇷🇴 '],
  ['Словацкая Республика', '🇸🇰'],
  ['Королевство Испания', '🇪🇸'],
  ['Турецкая Республика', '🇹🇷'],
  ['Королевство Бельгия', '🇧🇪'],
  ['Чешская Республика', '🇨🇿'],
  ['Французская Республика', '🇫🇷'],
  ['Греческая Республика', '🇬🇷'],
  ['Венгрия', '🇭🇺'],
  ['Швейцария', '🇨🇭'],
  ['Швеция', '🇸🇪'],
  ['Китай', '🇨🇳'],
  ['Япония', '🇯🇵'],
])
export function getFlag(country) {
  return countryFlagMap.get(country) || '🏳️'
}

export const DEFAULT_CUSTOM_FORM = () => ({
  releaseForm: '', //Форма выпуска
  numberActive: 0, //Количество действующего вещества
  price: 0, //Цена за упаковку в валюте
})

export const CUSTOM_COUNTRY_FORM = () => ({
  country: '',
  price: '',
  priceRUB: '',
  currency: '',
  custom: null,
  isEdit: true,
})

export const DEFAULT_CURRENCY_PRICE = () => [
  {
    country: 'Королевство Нидерланды',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
  {
    country: 'Республика Польша',
    price: '',
    priceRUB: '',
    currency: 'PLN',
    custom: null,
  },
  {
    country: 'Румыния',
    price: '',
    priceRUB: '',
    currency: 'RON',
    custom: null,
  },
  {
    country: 'Словацкая Республика',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
  {
    country: 'Королевство Испания',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
  {
    country: 'Турецкая Республика',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
  {
    country: 'Королевство Бельгия',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
  {
    country: 'Чешская Республика',
    price: '',
    priceRUB: '',
    currency: 'CZK',
    custom: null,
  },
  {
    country: 'Французская Республика',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
  {
    country: 'Греческая Республика',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
  {
    country: 'Венгрия',
    price: '',
    priceRUB: '',
    currency: 'EUR',
    custom: null,
  },
]
export const DEFAULT_ORIGIN = () => ({
  nameCurrency: '', //название валюты
  valueCurrency: '', //значение
  fullNameCurrency: '', //Полное имя валюты
  isEdit: true,
})

export const DEFAULT_PRICING_FORM = () => ({
  releaseForm: '',
  numberActive: 0, //Количетсво действующего вещества
  registerPrice: '', //Цена, предлагаемая к регистрации
  minPrice: '', //Минимальная цена в референтной стране
  differentPrice: '', //Разница с ценой в референтной стране (разница в %)
  currencyPrice: DEFAULT_CURRENCY_PRICE(),
})

export const EX_PRICING_TEMPLATE = () => ({
  datePricing: '',
  // stateDate: '00-00-0000',
  pricing: [DEFAULT_PRICING_FORM()],
  originCurrency: [
    {
      nameCurrency: 'EUR',
      fullNameCurrency: '', //Полное имя валюты
      valueCurrency: '',
    },
    {
      nameCurrency: 'PLN',
      fullNameCurrency: '', //Полное имя валюты
      valueCurrency: '',
    },
    {
      nameCurrency: 'RON',
      fullNameCurrency: '', //Полное имя валюты
      valueCurrency: '',
    },
    {
      nameCurrency: 'CZK',
      fullNameCurrency: '', //Полное имя валюты
      valueCurrency: '',
    },
  ],
})
// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_PRICING].v = EX_PRICING_TEMPLATE_VERSION
