<template>
  <v-dialog width="1100px" persistent :value="true">
    <v-card class="pa-3">
      <v-form @submit.prevent="submitHandler">
        <v-card-title>{{ actionTitle }}</v-card-title>
        <v-card-title>
          <v-text-field
            :loading="loadingLf"
            dense
            v-model="formData.lf"
            label="ЛФ"
            placeholder="Лекарственная форма препарата"
            clearable
            :append-icon="
              expand ? 'mdi-store-search' : 'mdi-store-search-outline'
            "
            @click:append="showLfs()"
          />
        </v-card-title>
        <v-expand-transition>
          <v-card flat v-show="expand">
            <v-card-subtitle> Поиск по справочнику ЛП </v-card-subtitle>

            <v-card-text>
              <div class="d-flex flex-row">
                <v-text-field
                  v-model="search"
                  label="МНН"
                  clearable
                  class="mr-6"
                />
                <v-switch
                  v-model="grls"
                  label="Показать ГРЛС"
                  title="Показывать еще ГРЛС"
                  hide-details
                  class="mr-4 mb-5"
                />
                <v-switch
                  v-model="jvnlp"
                  label="ЖВНЛП"
                  title="Показывать только ЖВНЛП"
                  hide-details
                  class="mr-4 mb-5"
                />
              </div>
              <v-data-table
                :headers="filteredHeaders"
                :items="filteredDictMedicine"
                v-model="selectedRow"
                :search="search"
                single-select
                loading-text="Загрузка справочника МНН... Пожалуйста ждите."
                item-key="id"
                show-select
                :group-by="['innInternal']"
                :footerProps="table_footer_options"
                :items-per-page="5"
              >
                <template v-slot:top> </template>
                <template #[`group.header`]="{ group, headers, items }">
                  <td :colspan="headers.length">
                    <span v-if="items" class="text-overline font-weight-bold">
                      {{ items[0].innInternal }}
                    </span>
                    <span v-else>{{ group }}</span>
                  </td>
                </template>
                <template v-slot:[`item.isJVNLP`]="{ value }">
                  {{ value ? 'Да' : '' }}
                </template>
                <template v-slot:[`item.isOfficial`]="{ value }">
                  {{ value ? 'Да' : '' }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expand-transition>
        <v-card-actions>
          <v-btn
            type="submit"
            color="primary"
            class="mr-4"
            small
            :loading="saveInProgress"
            :disabled="saveInProgress"
            >Сохранить</v-btn
          >
          <v-btn @click="closeDialog" class="mr-4" small>Отменить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { copyObject } from '@/lib/objects'

const TEMPLATE_FORM_DATA = {
  id: '',
  lf: '',
  reg_num: '',
}

export default {
  name: 'LfsDialog',
  data: () => ({
    expand: false,
    jvnlp: true,
    grls: false,
    loadingLf: true,
    search: '',
    selectedRow: [],
    formData: TEMPLATE_FORM_DATA,
    saveInProgress: false,
    filteredLfDict: null,
    table_footer_options: {
      itemsPerPageOptions: [5, 10, 15, 20],
    },
    headers: [
      { text: 'МНН', value: 'innInternal' },
      {
        text: 'Лекарственная форма',
        value: 'dosageFormInternal',
        cellClass: 'font-weight-medium',
      },
      {
        text: 'ЖВНЛП',
        value: 'isJVNLP',
        sortable: false,
        align: 'center',
        cellClass: 'text-caption text--secondary',
        filterable: false,
        width: '100px',
      },
    ],
  }),
  computed: {
    ...mapGetters([
      'dictMedicine',
      'dictMedicineYear',
      'dictMedicineDescription',
    ]),
    actionTitle() {
      return this.isOpenForAdd
        ? 'Добавить лекарственную форму'
        : 'Выбор лекарственной формы'
    },
    lForma() {
      return this.lfs?.lf
    },
    filteredHeaders() {
      const outArr = this.headers?.filter(
        ({ value }) => !this.jvnlp || value !== 'isJVNLP'
      )

      if (this.grls)
        outArr.push({
          text: 'ГРЛС',
          value: 'isOfficial',
          sortable: false,
          align: 'center',
          cellClass: 'text-caption text--secondary',
          filterable: false,
          width: '100px',
        })
      return outArr
    },
    filteredDictMedicine() {
      if (this.grls) {
        const dict = this.createFilteredLfDict()
        const outArr =
          dict?.filter(
            ({ isJVNLP, isOfficial }) => (!this.jvnlp || isJVNLP) && !isOfficial
          ) ?? []
        dict.forEach(item => {
          if (item.isOfficial && this.grls) outArr.push(item)
        })
        return outArr
      } else {
        return (
          this.dictMedicine?.filter(({ isJVNLP }) => !this.jvnlp || isJVNLP) ??
          []
        )
      }
    },
  },
  watch: {
    selectedRow(newVal) {
      newVal.length > 0
        ? (this.formData.lf = newVal[0].dosageFormInternal)
        : null
    },
  },
  created() {
    if (!this.lfs) return
    this.formData = copyObject(this.lfs)
  },
  async mounted() {
    this.loadingLf = true
    try {
      await this.GET_MEDICINE_DICT()
    } finally {
      this.search = this.medication?.mnn ?? ''
      if (this.isOpenForAdd) this.formData.lf = ''
      this.loadingLf = false
    }
  },
  props: {
    isOpenForAdd: {
      type: Boolean,
      default: false,
    },
    lfs: {
      type: Object,
      required: false,
    },
    medication: {
      type: Object,
      required: false,
    },
  },
  methods: {
    ...mapActions([
      'GET_MEDICINE_DICT',
      'EDIT_GNVLP_DICT',
      'ADD_LECFORM_TO_GNVLP_DICT',
    ]),
    closeDialog() {
      this.$emit('close-form')
      this.expand = false
    },
    submitHandler() {
      if (this.isOpenForAdd) this.add()
      else this.save()
    },
    showLfs() {
      this.expand = !this.expand
    },
    createFilteredLfDict() {
      if (!this.filteredLfDict) {
        const filteredLfDict = []
        let idx = 1
        this.dictMedicine.forEach(item => {
          item.dosageFormOficialList.forEach(lf => {
            filteredLfDict.push({
              id: idx++,
              innInternal: item.innInternal,
              dosageFormInternal: lf,
              isJVNLP: item.isJVNLP,
              isOfficial: true,
            })
          })
          filteredLfDict.push({
            id: idx++,
            innInternal: item.innInternal,
            dosageFormInternal: item.dosageFormInternal,
            isJVNLP: item.isJVNLP,
            isOfficial: false,
          })
        })
        this.filteredLfDict = filteredLfDict
      }
      return this.filteredLfDict
    },
    async save() {
      this.error_message = ''
      this.saveInProgress = true
      try {
        const medication = this.medication
        const lecform = this.formData
        await this.EDIT_GNVLP_DICT({ medication, lecform })
        this.closeDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.saveInProgress = false
      }
    },
    async add() {
      if (!this.formData.lf) return
      this.error_message = ''
      this.saveInProgress = true
      try {
        this.formData.dict_gnvlp_medicine_id = this.medication.id // id на уровень выше, самого препарата
        await this.ADD_LECFORM_TO_GNVLP_DICT(this.formData)
        this.closeDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.saveInProgress = false
      }
    },
  },
}
</script>

<style scoped></style>
