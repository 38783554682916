<template>
  <v-container fluid class="pb-4">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="nameKi"
          :outlined="!readonly"
          label="Название КИ (дизайн + автор + год)"
          hint="Например, мета-анализ Rosi A. et al., 2019"
          placeholder="Введите название КИ"
          prepend-icon="mdi-text-box-search-outline"
          @input="doChange()"
          @blur="doBlur()"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model.number="evidencesScore"
          :items="scoresEvidence"
          :outlined="!readonly"
          label="Оценка по уровню доказательности"
          prepend-icon="mdi-book-open-page-variant-outline"
          @input="doChange(true)"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model.number="convictionScore"
          :items="scoresConviction"
          :outlined="!readonly"
          label="Оценка по уровню убедительности"
          prepend-icon="mdi-book-open-blank-variant"
          @input="doChange(true)"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          readonly
          @input="doChange(true)"
          :value="resultScore"
          label="Итоговая оценка"
          hide-details
          class="font-weight-bold"
          type="number"
        />
      </v-col>
    </v-row>
    <!-- Удалить текущую строку -->
    <v-row v-if="!readonly">
      <v-col class="text-right">
        <btn-with-confirm
          text
          icon-main="mdi-delete-forever-outline"
          title="Удалить препарат"
          @click="deleteAssessments"
        >
          Удалить
        </btn-with-confirm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'

export default {
  name: 'QualityEditor',
  components: { BtnWithConfirm },
  data: () => ({
    nameKi: '',
    evidencesScore: 0,
    convictionScore: 0,
    scoresEvidence: [3, 4, 5, 6, 7, 8, 9, 10],
    scoresConviction: [1, 2, 3],
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) {
        const { nameKi, evidencesScore, convictionScore } = this
        const resultScore = this.resultScore
        const outObj = {
          ...this.value,
          resultScore,
          nameKi,
          evidencesScore,
          convictionScore,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments() {
      this.$emit('delete')
    },
    init() {
      this.nameKi = this.value?.nameKi || ''
      this.evidencesScore = this.value?.evidencesScore || 0
      this.convictionScore = this.value?.convictionScore || 0
      this.idx = this.value?.idx || 0
    },
  },
  computed: {
    resultScore() {
      const convictionScore = Number(this.convictionScore || 0)
      const evidencesScore = Number(this.evidencesScore || 0)
      return convictionScore * evidencesScore
    },
  },
}
</script>

<style scoped></style>
