<template>
  <default-log v-bind="$attrs">
    <!--    Слот для короткого сообщения-->
    <template #default="{ message }">
      <span class="purple--gray--text font-italic font-weight-bold">
        {{ message }}
      </span>
    </template>
    <!--    Слот для подробного сообщения-->
    <template #detail="{ logInfo }">
      <ul v-if="showLogInfo(logInfo)">
        <li><span class="font-weight-bold">МНН: </span>{{ mnnTask }}</li>
        <li><span class="font-weight-bold">АТХ: </span>{{ athTask }}</li>
        <li>
          <span class="font-weight-bold">Лекарственная форма: </span
          >{{ lfTask }}
        </li>
        <li>
          <span class="font-weight-bold">Отправлено в МЗ: </span
          >{{ mzTask ? 'да' : 'нет' }}
        </li>
        <li>
          <span class="font-weight-bold">Проверено: </span
          >{{ checkedTask ? 'да' : 'нет' }}
        </li>
        <li>
          <span class="font-weight-bold">Выполнено: </span
          >{{ completeTask ? 'да' : 'нет' }}
        </li>
        <li><span class="font-weight-bold">Год: </span>{{ yearTask }}</li>
        <li>
          <span class="font-weight-bold">Квартал: </span>{{ quarterTask }}
        </li>
      </ul>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
import logInfoMixin from '@/components/viewLogs/logInfoMixin'

export default {
  name: 'PlpTaskModifiedLog',
  inheritAttrs: false,
  components: { DefaultLog },
  data: () => ({
    tab: null,
  }),
  mixins: [logInfoMixin],
  computed: {
    plpTask() {
      return (
        this.logData?.data?.data?.data?.plp ?? this.logData?.data?.data?.plp
      )
    },
    mnnTask() {
      return this.plpTask?.mnn || '--пусто--'
    },
    athTask() {
      return this.plpTask?.ath || '--пусто--'
    },
    lfTask() {
      return this.plpTask?.lForma || '--пусто--'
    },
    mzTask() {
      return this.plpTask?.was_sent_to_mz
    },
    checkedTask() {
      return this.plpTask?.is_checked
    },
    completeTask() {
      return this.plpTask?.is_completed
    },
    yearTask() {
      return this.plpTask?.expertise_year
    },
    quarterTask() {
      return this.plpTask?.expertise_quarter || '--пусто--'
    },
  },
}
</script>
