import ExcelJS from 'exceljs'

export class ExcelException extends Error {
  constructor(message) {
    super(message)
    this.name = 'ExcelException'
  }
}

export class Excel {
  constructor({ data, name, headers, fileName, creatorName, rowIterator }) {
    this.fileName = fileName || 'Безымянный'
    if (creatorName) this.creatorName = creatorName
    this.workbook = null
    this.sheet = null
    this.headers = headers || []
    this.data = data
    this.name = name || 'Лист 1'
    this.TABLE_HEADERS_AT_ROW = 1
    // функция итератор
    if (typeof rowIterator === 'function') {
      this.rowIterator = rowIterator
    }
  }

  async getData() {
    if (typeof this.data === 'function')
      try {
        // хитрость если передать не данные а функцию, то мы их получим по требованию
        this.data = await this.data()
      } catch (error) {
        throw new ExcelException(`Ошибка получения данных ${error}`)
      }
  }

  create() {
    try {
      this.workbook = new ExcelJS.Workbook()
      this.workbook.creator = this.creatorName
      this.workbook.created = new Date()
      this.sheet = this.workbook.addWorksheet(this.name)
    } catch (error) {
      throw new ExcelException(`Ошибка создания документа Excel  ${error}`)
    }
  }

  async download() {
    try {
      const data = await this.workbook.xlsx.writeBuffer()
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const url = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = this.fileName
      anchor.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      throw new ExcelException(
        `Ошибка подготовки к сохранению документа Excel  ${error}`
      )
    }
  }

  async downloadXLSX() {
    this.create()
    await this.getData()

    // рисуем таблицу с данными
    this.createTableHeaders()
    this.createRows()

    await this.download()
  }

  static async downloadXLSX(options) {
    const excel = new this(options)
    await excel.downloadXLSX()
  }

  createTableHeaders() {
    try {
      this.sheet.columns = this.headers.map(column => column.options)

      const headers = this.sheet.getRow(this.TABLE_HEADERS_AT_ROW)
      headers.style = {
        font: { bold: true },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        border: {
          bottom: { style: 'thin' },
        },
      }
      headers.values = this.headers.map(
        column => column.label || column.name || ''
      )
    } catch (error) {
      throw new ExcelException(`Ошибка подготовки столбцов  ${error}`)
    }
  }

  createRows() {
    try {
      let rowIndex = this.TABLE_HEADERS_AT_ROW + 1

      this.data?.forEach(dataRow => {
        const row = this.sheet.getRow(rowIndex)
        row.values = this.headers.map(column => dataRow[column.name])

        if (this.rowIterator) {
          // если есть итератор, вызываем
          this.rowIterator(rowIndex, row, dataRow)
        }

        rowIndex += 1
      })
    } catch (error) {
      throw new ExcelException(`Ошибка формирования таблицы с данными ${error}`)
    }
  }
}

export default Excel
