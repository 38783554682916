var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-2"},[(!_vm.error_message)?_c('v-data-table',{attrs:{"items":_vm.getFilterItems,"headers":_vm.getHeaders,"search":_vm.searchLF,"custom-filter":_vm.customFilter,"hide-default-footer":"","disable-pagination":"","item-key":"id","no-data-text":"Нет данных","show-expand":"","disable-sort":"","no-results-text":"Лек. форма не найдена, проверьте правильность написания"},on:{"item-expanded":_vm.expandedEvent},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1)+".")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('IndicationsTable',{attrs:{"medication":_vm.medication,"item":item,"select-mode":_vm.selectMode,"readonly":_vm.readonly,"editMode":_vm.editMode},on:{"updateIndications":_vm.updateIndications}})],1)]}},(!_vm.selectMode)?{key:"item.lf",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._f("highlight")(value,_vm.searchLF)}})]}}:null,(_vm.editMode)?{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-end mt-6 mb-6 mr-2"},[(!_vm.expandedOpen)?_c('v-btn',{attrs:{"color":"accent","fab":"","small":"","title":"Добавить лекарственную форму"},on:{"click":_vm.openForAdd}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)]},proxy:true}:null,(_vm.editMode)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actionsGNVLPInd"},[_c('v-btn',{staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","title":"Редактировать","color":"transparent"},on:{"click":function($event){return _vm.openForEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('btn-with-confirm',{attrs:{"icon":"","icon-main":"mdi-delete-forever-outline","title":"Удалить лекарственную форму","disabled":_vm.deleteLfInProgress},on:{"click":function($event){return _vm.showDeleteConfirmation(item)}}})],1)]}}:null],null,true)}):_vm._e(),(_vm.error_message)?_c('v-alert',{staticClass:"mt-2",attrs:{"type":"error","outlined":"","dismissible":""},model:{value:(_vm.error_message),callback:function ($$v) {_vm.error_message=$$v},expression:"error_message"}},[_c('div',[_vm._v("Произошла ошибка при работе со справочниками")]),_c('div',[_vm._v(" "+_vm._s(_vm.error_message)+" ")])]):_vm._e(),(_vm.editMode && _vm.showForm)?_c('lfs-dialog',{attrs:{"lfs":_vm.lfs,"medication":_vm.medication,"is-open-for-add":_vm.isOpenForAdd},on:{"close-form":_vm.closeForm}}):_vm._e(),(_vm.editMode)?_c('ExpAgencyDeleteDialog',{attrs:{"dialog":_vm.showDeleteDialog,"name":_vm.getName,"lb":"Лекарственная форма:","title":"Удалить лекарственную форму","label":"Подтвердить удаление лекарственной формы"},on:{"onClose":_vm.cancelDelete,"onAction":_vm.confirmDelete}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }