<template>
  <div v-if="element" class="pa-2">
    <v-text-field
      label="Методологическое качество АВБ"
      :value="element.qLevels"
      :error="qLevelsError"
      :error-messages="qLevelsError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Показание по которому выполнено АВБ"
      :value="element.condition"
      :error="conditionError"
      :error-messages="conditionError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Численность целевой популяций"
      :value="element.epidemiology | getForScenario"
      :error="epidemiologyError"
      :error-messages="epidemiologyError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Текущая практика"
      :value="element.currentState | getForScenario"
      :error="currentError"
      :error-messages="currentError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Ожидаемая практика"
      :value="element.expectedState | getForScenario"
      :error="expectedError"
      :error-messages="expectedError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Основные результаты"
      :value="element.results | getForScenario"
      :error="resultError"
      :error-messages="resultError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
  </div>
</template>

<script>
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import KeiAvbMixin from '@/components/expSummary/summaryAdditional/KeiAvbMixin'
import { avbSummaryCopy } from '@/components/expSummary/summaryAdditional/avbUtils'

export default {
  name: 'AddAvb',
  components: { AutoGrowTextEditor },
  mixins: [KeiAvbMixin],

  computed: {
    qLevelsError() {
      return this.editMode && !this.checkProperty('qLevels')
    },
    conditionError() {
      return this.editMode && !this.checkProperty('condition')
    },
    epidemiologyError() {
      return this.editMode && !this.checkProperty('epidemiology')
    },
    currentError() {
      return this.editMode && !this.checkProperty('currentState')
    },
    expectedError() {
      return this.editMode && !this.checkProperty('expectedState')
    },
    resultError() {
      return this.editMode && !this.checkProperty('results')
    },
    initExp() {
      if (!this.initElement) return null
      return avbSummaryCopy(null, this.initElement)
    },
  },
}
</script>
