<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    label="Подтвердите удаление экспертизы"
    color-btn-action="error"
    title="Удаление экспертизы"
    labelBtnClose="ОТМЕНА"
    labelBtnAction="УДАЛИТЬ"
  />
</template>

<script>
import ActionDialog from '@/components/dialogs/ActionDialog.vue'
export default {
  name: 'AvbKeiExpertiseDeleteDialog',
  components: { ActionDialog },
}
</script>
