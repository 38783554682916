import { copyObject, sameObject, copyObjectBy } from '@/lib/objects'
import { EX_CONCLUSION_AVB } from '@/components/expConclusion/const'

/***
 *Создание копии Массива АВБ
 * @param expAvb массив КЭИ с экспертизы сводного заключения
 * @param initAvb массив КЭИ с экспретизы КЭИ
 * @param storedAvbCriteria
 * @returns []
 */
export function createAvbCopy(expAvb, initAvb, storedAvbCriteria) {
  if (!initAvb) return copyObject(expAvb)
  const avb = []
  initAvb.ka.forEach(item => {
    avb.push(
      AvbCopy(
        expAvb?.find(d => d.shortName === item.shortName),
        item,
        storedAvbCriteria
      )
    )
  })
  return avb
}

/***
 *Проверка всей экспертизы АВБ на актуальность данных
 * @param expAvb массив КЭИ с экспертизы сводного заключения
 * @param initAvb массив КЭИ с экспретизы КЭИ
 * @returns Boolean
 */
export function checkAvb(expAvb, initAvb) {
  if (!checkAvbLength(expAvb, initAvb)) {
    return false
  }
  for (let i = 0; i < expAvb?.length; i++) {
    if (!checkCopy(expAvb[i], initAvb?.ka[i])) {
      return false
    }
  }
  return true
}

export function checkAvbLength(expAvb, initAvb) {
  return expAvb?.length === initAvb?.ka?.length
}

/***
 *Создание копии элемента АВБ
 * @param avb элемент КЭИ с экспертизы сводного заключения
 * @param initAvb Элемент КЭИ с экспретизы КЭИ
 * @returns {{}}
 */
export function AvbCopy(avb, initAvb, storedAvbCriteria) {
  const resAvb = {
    ...copyObjectBy(avb, EX_CONCLUSION_AVB),
  }
  resAvb.shortName = initAvb.shortName
  resAvb.qLevels = initAvb.qLevels
  // автор, год
  resAvb.condition = initAvb.condition
  resAvb.criterias = createAvbCriterias(
    initAvb.elements,
    storedAvbCriteria,
    resAvb.criterias
  )
  // коментарий к экспертизе АВБ
  resAvb.comment = initAvb.resume?.comment
  return resAvb
}

/***
 *Проверка свойств элемента на актуальность данных
 * @param avb Элемент КЭИ с экспертизы сводного заключения
 * @param initAvb Элемент КЭИ с экспретизы КЭИ
 * @returns Boolean
 */
function checkCopy(avb, initAvb) {
  const resAvb = AvbCopy(null, initAvb)

  // критерии
  if (avb.criterias?.length !== resAvb.criterias?.length) return false
  for (let i = 0; i < avb.criterias?.length; i++) {
    const src = avb.criterias[i] || {}
    const res = resAvb.criterias?.[i] || {}

    if (src.id !== res.id) return false
    if (
      !src.manual &&
      (src.description !== res.description || src.comment !== res.comment)
    )
      return false
  }

  for (const key of ['shortName', 'qLevels', 'condition', 'comment']) {
    if (!sameObject(resAvb[key], avb[key])) return false
  }

  return true
}

function createAvbCriterias(elements, storedAvbCriteria, criterias) {
  const resRequirements = new Map()
  storedAvbCriteria?.forEach(item => {
    resRequirements.set(item.id, item.data?.itemRequirements)
  })
  return elements
    .filter(({ correspond }) => !correspond)
    .map((item, idx) => ({
      id: item.id,
      name: item.name,
      order: item.order,
      itemRequirements: copyObject(resRequirements.get(item.id) || []),
      description: criterias?.[idx]?.manual
        ? criterias?.[idx].description
        : item.criteria,
      comment: criterias?.[idx]?.manual
        ? criterias?.[idx].comment
        : item.comment,
      // ручтая коррекция
      manual: criterias?.[idx]?.manual || false,
    }))
}
