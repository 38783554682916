import { SUB_VERSION_MIGRATION, SUB_EX_COST } from '@/store/const/expertise'

export const EX_COST_TEMPLATE_VERSION = 1

export const DEFAULT_INDICATION = () => ({
  groupName: { value: '', merged: false }, //Наименование групп
  mnn: { value: '', merged: false }, //МНН предлагаемого препарата, а также лекарственных препаратов, включенных в перечень ЖНВЛП
  patientPopulation: { value: '-', merged: false }, //Численность целевой популяции пациентов
  EI: { value: '', merged: false }, //Единица измерения (ЕИ)
  requiredEiNumber: { value: 0, merged: false }, //Необходимое количество ЕИ лекарственного препарата согласно инструкции по медицинскому применению (ИМП)
  priceEiNumber: { value: 0, merged: false }, //Цена за ЕИ
  priceTherapy: { value: 0, merged: false }, //Стоимость терапии одного пациента согласно ИМП в год (курс) без  НДС (руб.)
})

export const DEFAULT_COMBINE_INDICATION = () => ({
  groupName: { value: '', merged: false }, //Наименование групп
  combineName: { value: '', merged: false }, //Наименование комбинации
  mnn: { value: '', merged: false }, //МНН предлагаемого препарата, а также лекарственных препаратов, включенных в перечень ЖНВЛП
  patientPopulation: { value: '-', merged: false }, //Численность целевой популяции пациентов
  EI: { value: '', merged: false }, //Единица измерения (ЕИ)
  requiredEiNumber: { value: 0, merged: false }, //Необходимое количество ЕИ лекарственного препарата согласно инструкции по медицинскому применению (ИМП)
  priceEiNumber: { value: 0, merged: false }, //Цена за ЕИ
  priceTherapy: { value: 0, merged: false }, //Стоимость терапии одного пациента согласно ИМП в год (курс) без  НДС (руб.)
})

export const DEFAULT_CALCULATION_COST = () => ({
  dosageMedicine: '', //Режим дозирования препарата
  durationTherapy: '', //Продолжительность терапии в год
  costOneMg: '', //Стоимость 1 мг препарата
  costOnePatient: [{ formula: '' }], //Расчет стоимости курса терапии одного пациента ( n- год) Если были расчеты на 2-й год [n,1n,...]
  offerLp: '', //Предлагаемый ЛП
})

export const DEFAULT_CALCULATIONS = () => ({
  name: '',
  indication: '',
  calculation: '',
  combinations: [DEFAULT_COMBINATION()],
})

export const DEFAULT_COMBINATION = () => ({
  isCombine: false,
  costs: [DEFAULT_CALCULATION_COST()],
  calculation: '',
  combinePrice: 0,
})

export const DEFAULT_LOCAL_FILE = () => ({
  uuid: '',
  filename: '',
  size: 0,
  data: {},
  time_create: '',
})

export const EX_COST_TEMPLATE = () => ({
  mnn: '',
  condition: '',
  vzn14: '',
  gnvlp: '',
  countIndications: {
    gnvlp: 0, //для ЖНВЛП
    vzn14: 0, //для ВЗН 14
  }, //Сколько показаний у предлагаемого лекарственного препарата?
  indicationsData: {
    gnvlp: [],
    gnvlpCalculations: [], //расчёты для ЖНВЛП
    vzn14: [],
    vznCalculations: [], //расчёты для ВЗН
  }, //Данные о показаниях
  file: DEFAULT_LOCAL_FILE(), // файл
})
// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_COST].v = EX_COST_TEMPLATE_VERSION

// // Добавили миграции для перехода на версию 2
// SUB_VERSION_MIGRATION[SUB_EX_COST][1] = function (prev) {
//   console.log(prev, 'prevvvvv')
//   if (prev?.indicationsData?.gnvlpCalculations) {
//     prev.indicationsData.gnvlpCalculations.forEach(element => {
//       element.manualCalculations = [element]
//     })
//   }
//   if (prev?.indicationsData?.vznCalculations) {
//     prev.indicationsData.vznCalculations.forEach(element => {
//       element.manualCalculations = [element]
//     })
//   }
//   return prev
// }
