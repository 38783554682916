<template>
  <v-data-table
    :items="purchaseRelease"
    :headers="purchaseDataHeaders"
    show-expand
    single-select
    single-expand
    item-key="idx"
    :expanded.sync="expandedAssessments"
    disable-pagination
    hide-default-footer
    no-data-text="Информация о форме выпуска отсутствует"
  >
    <template #[`item.idx`]="{ item }">
      <span class="text--disabled">{{ item.idx }}.</span>
    </template>
    <template #[`item.lf`]="{ value }">
      <span v-if="value" class="font-weight-medium">{{ value }}</span>
      <span v-else class="warning--text"> ( пусто ) </span>
    </template>
    <template #[`item.min`]="{ value }">
      <span class="text-lg-subtitle-1">{{ value }}</span>
    </template>
    <template #[`item.max`]="{ value }">
      <span class="text-lg-subtitle-1">{{ value }}</span>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="td-expanded pa-2">
        <ReleaseEditor
          :value="item"
          :about="about"
          :readonly="readonly"
          @change="doChangeItem"
          @delete="deleteAssessments(item.idx)"
          @blur="doBlur"
        />
      </td>
    </template>
    <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
      <td class="text-start">
        <v-btn
          icon
          @click="expand(!isExpanded)"
          class="v-data-table__expand-icon"
          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
        >
          <v-icon>mdi-pencil-circle-outline</v-icon>
        </v-btn>
      </td>
    </template>
    <template #footer v-if="!readonly">
      <div class="d-flex pt-3">
        <v-spacer />
        <v-btn
          :disabled="!!readonly"
          @click="addPurchase"
          title="Добавить лекарственную форму"
          color="accent"
          fab
          small
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { DEFAULT_RELEASE_FORM } from '../purchaseConst'
import ReleaseEditor from './ReleaseEditor'

export default {
  name: 'ReleaseTable',
  components: { ReleaseEditor },
  data: function () {
    return {
      purchaseRelease: [],
      expandedAssessments: [],
      scores: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      purchaseDataHeaders: [
        {
          text: '№',
          value: 'idx',
          width: '10px',
          sortable: false,
        },
        {
          text: 'Форма выпуска',
          value: 'lf',
          sortable: false,
        },
        {
          text: 'Мин.',
          value: 'min',
          sortable: false,
          align: 'end',
        },
        {
          text: 'Макс.',
          value: 'max',
          sortable: false,
          align: 'end',
        },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    about: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.init(this.value)
  },
  mounted() {
    const firstItem = this.purchaseRelease?.[0]
    if (!firstItem?.lf) {
      this.expandItem(firstItem)
    }
  },
  watch: {
    value: 'init',
  },
  methods: {
    init(value) {
      this.purchaseRelease = copyObject(value || []).map((item, idx) => ({
        ...item,
        idx: idx + 1,
      }))
    },
    doChangeItem(item, force = false) {
      const idx = item.idx - 1
      this.purchaseRelease[idx] = item
      this.doChange(force)
    },
    doChange(force = false) {
      if (!this.readonly) {
        this.$emit('change', this.purchaseRelease, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments(idx) {
      this.purchaseRelease.splice(idx - 1, 1)
      // this.$set(this, 'quality', this.quality)
      this.expandItem(null)
      this.doChange(true)
    },
    addPurchase() {
      this.purchaseRelease.push(DEFAULT_RELEASE_FORM())
      this.doChange(true)

      this.$nextTick(() => {
        const lg = this.purchaseRelease?.length || 0
        this.expandItem(this.purchaseRelease[lg - 1])
      })
    },
    expandItem(item = null) {
      this.expandedAssessments = item ? [item] : []
    },
  },
}
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
