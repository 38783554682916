export const DEFAULT_GVS_SCORES = () => ({
  scoreGVS: 0,
  ki: 0,
  safety: 0,
  economic: 0,
  sum: 0,
})

export const DEFAULT_GVS_FORM = () => ({
  gvsFio: '',
  gvsSpec: '',
  inclusionStandart: 0, //Целесообразность включения(Наличие в стандартах)
  scoreFGBU: 0, //Баллы ФГБУ (подтягиваются с экспертизы доп. Данные)
  scoreGVS: 0, //Баллы ГВС (скорректированные баллы ЭО)
  scoreEO: 0, //Баллы экспертной организаций
  ownScores: DEFAULT_GVS_SCORES(), //Собственные рассчеты
  conclusionExpediency: false, //Вывод о целесообразности (да/нет)
  comment: '', //Комментарии
  lpPoints: '', // ЛП набрал рекомендованное количество баллов?
  notDidPoints: '', //По каким шкалам не набрал?
  initEO: 0, //знач. экспертизы ЭП
  initFEO: 0, //макс знач. экспертизы ЭП
})
