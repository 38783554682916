<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          label="Баллы ЭО"
          v-model="scoreLevel"
          :outlined="!readonly"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          type="number"
          :append-icon="!readonly ? 'mdi-pencil' : null"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LevelQualityCard',
  data: () => ({ scoreLevel: '' }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
    },
  },
  watch: {
    value: 'init',
  },
  created() {
    this.init(this.value)
  },
  methods: {
    init(value) {
      this.scoreLevel = value.scoreLevel || ''
    },
    doChange(force = false) {
      this.$emit('update')
      this.$emit('change', { scoreLevel: this.scoreLevel }, force)
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped></style>
