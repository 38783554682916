<template>
  <v-data-table
    :items="currencyPrice"
    :headers="currencyHeaders"
    disable-pagination
    hide-default-footer
    single-select
    show-expand
    item-key="idx"
    :expanded.sync="expandedPricing"
    no-data-text='Информация о таблице "Референтные страны" отсутствует'
  >
    <template #[`item.price`]="{ item, index }">
      <v-text-field
        :readonly="priceEdit(item)"
        @input="val => doChangePrice(index, val)"
        @blur="doBlur()"
        :value="item.price"
        :outlined="editMode && !priceEdit(item)"
        :append-icon="editMode ? 'mdi-pencil' : null"
        hint="Цена"
        type="number"
        hide-details
        class="my-1 py-0"
      />
    </template>
    <template #[`item.priceRUB`]="{ item }">
      <span>
        <span class="font-weight-bold">{{ item.priceRUB | toMoney }}</span>
      </span>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td v-if="item" :colspan="headers.length - 1" class="td-expanded pa-2">
        <PricingEditor
          :value="item"
          :readonly="!editMode"
          @change="doChangeItem"
          @blur="doBlur"
        />
      </td>
      <td>
        <btn-with-confirm
          v-if="editMode"
          text
          icon-main="mdi-delete-forever-outline"
          title="Удалить"
          @click="deleteCustom(item)"
        >
        </btn-with-confirm>
      </td>
    </template>
    <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
      <td class="text-right">
        <v-btn
          v-if="!item.custom && editMode"
          class="action"
          color="blue lighten-2"
          title="Добавить альтернативную форму выпуска"
          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
          @click="expand(!isExpanded)"
          icon
        >
          <v-icon>mdi-plus-circle</v-icon>
        </v-btn>
      </td>
    </template>
    <template #[`item.flag`]="{ item }">
      <v-badge
        :value="priceEdit(item)"
        color="blue lighten-3"
        content="Альтернативная форма выпуска"
      >
        <span class="text-h5">{{ getFlag(item.country) }}</span>
      </v-badge>
    </template>
    <template #[`item.currency`]="{ item, value, index }">
      <div style="max-width: 70px" v-if="item.isEdit">
        <v-select
          label="Код"
          :items="namesCurrency"
          v-model="item.currency"
          @input="doChangePrice(index, item.price, true)"
        ></v-select>
      </div>
      <span v-else class="font-weight-bold">{{ value }}</span>
    </template>
    <template #[`item.country`]="{ value, item }">
      <v-text-field
        @input="doChange()"
        @blur="doBlur()"
        hint="Страна"
        v-if="item.isEdit"
        v-model="item.country"
      >
      </v-text-field>
      <span v-else>{{ value }}</span>
    </template>
    <template #footer v-if="editMode">
      <div class="d-flex pt-3">
        <v-spacer />
        <v-btn
          title="Добавить референтную страну"
          color="accent"
          fab
          small
          @click="addRefCountry"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { CUSTOM_COUNTRY_FORM, getFlag } from '@/components/expPricing/const'
import PricingEditor from '@/components/expPricing/comp/pricing/PricingEditor'
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import { sameObject } from '@/lib/objects'
import { toMoney } from '@/lib/strUtils'

export default {
  name: 'PricingTable',
  components: { BtnWithConfirm, PricingEditor },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Array,
    },
    numberActive: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    currencyMap: new Map(),
    expandedPricing: [],
    currencyHeaders: [
      {
        text: '',
        value: 'flag',
        width: '50px',
        sortable: false,
      },
      {
        text: 'Референтная страна',
        value: 'country',
        sortable: false,
      },
      {
        text: 'Валюта',
        value: 'currency',
        sortable: false,
      },
      {
        text: 'Цена в валюте',
        value: 'price',
        sortable: false,
      },
      {
        text: 'Цена в рублях',
        value: 'priceRUB',
        sortable: false,
        cellClass: '',
      },
      { text: '', value: 'data-table-expand', width: '70px' },
    ],
  }),
  watch: {
    currency: 'initCurrency',
  },
  filters: {
    toMoney,
  },
  methods: {
    getFlag,
    //Инициилизируем коды валют с их значениями
    initCurrency(value, oldValue) {
      this.currencyMap = new Map(
        this.currency?.map(({ nameCurrency, valueCurrency }) => [
          nameCurrency,
          valueCurrency,
        ])
      )

      if (!sameObject(value, oldValue)) {
        this.updateCurrencyPrice()
      }
    },
    //Добавление кастомной референтной страны
    addRefCountry() {
      this.currencyPrice.push(CUSTOM_COUNTRY_FORM())
      this.doChange(true)
    },
    //Перерасчет цены при использований альтернативной формы выпуска
    doChangeItem(item, force) {
      const idx = item.idx - 1
      this.currencyPrice[idx] = item

      item.price = Number(
        (
          (this.numberActive / (item.custom.numberActive || 1)) *
          item.custom.price
        ).toFixed(2)
      )
      if (item.price === Number.POSITIVE_INFINITY) item.price = 0
      this.doChangePrice(idx, item.price, force)
    },
    //Перерасчет цены в рублях с учетом текущего курса выбранной валюты
    doChangePrice(idx, price, force) {
      const item = this.currencyPrice[idx]

      const newItem = this.calcPrice(item, price)
      const newCurrencyPrice = this.currencyPrice.map((item, index) =>
        index === idx ? newItem : item
      )

      this.$emit('change', newCurrencyPrice, force)
    },
    // Обновление текущих реф. стран с учетом текущего курса(Перерасчет всех реф. стран)
    updateCurrencyPrice(force = true) {
      const newCurrencyPrice = this.currencyPrice.map(item =>
        this.calcPrice(item, item.price)
      )
      this.$emit('change', newCurrencyPrice, force)
    },
    doChange(force) {
      this.$emit('change', this.currencyPrice, force)
    },
    doBlur() {
      this.$emit('blur')
    },
    //Перерасчет цены в рублях для айтема
    calcPrice(item, price) {
      const priceRUB = this.calcCurrency(price, item.currency)

      if (price === item.price && priceRUB === item.priceRUB) return item

      return {
        ...item,
        price,
        priceRUB,
      }
    },
    //Перерасчет в рублях
    calcCurrency(price, currency) {
      return Number(
        Number(price * (this.currencyMap.get(currency) || 0)).toFixed(2)
      )
    },
    expandItem(item = null) {
      this.expandedPricing = item ? [item] : []
    },
    //Альтернативная ли форма выпуска
    priceEdit(item) {
      return this.expandedPricing.findIndex(exp => exp.idx === item.idx) > -1
    },
    //Удаление альтернативной формы выпуска
    deleteCustom(item) {
      item.custom = null
      const idx = this.expandedPricing.findIndex(el => el.idx === item.idx)
      if (idx >= 0) this.expandedPricing.splice(idx, 1)

      this.$emit('change', this.currencyPrice, true)
    },
  },
  computed: {
    //Референтные страны(добавляем индексы для expanded)
    currencyPrice() {
      return [...this.value.map((item, idx) => ({ ...item, idx: idx + 1 }))]
    },
    //Коды текущих валют
    namesCurrency() {
      return [
        ...this.currency.map(({ nameCurrency }) => nameCurrency || '--пусто--'),
      ]
    },
  },
  created() {
    this.initCurrency()
  },
  mounted() {
    this.currencyPrice.forEach(item =>
      item.custom ? this.expandedPricing.push(item) : null
    )
  },
}
</script>

<style scoped>
.v-data-table >>> tr .action {
  display: none !important;
}
.v-data-table >>> tr:hover .action {
  display: flex !important;
}
</style>
