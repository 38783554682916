<template>
  <v-form v-if="data" class="my-2" :readonly="!editMode">
    <v-card class="mb-3">
      <v-card-title>Курсы</v-card-title>
      <v-card-subtitle class="d-flex align-center">
        <SelectDate
          v-if="editMode"
          v-model="data.datePricing"
          :maxDate="currentDate"
          label="Дата подачи предложения"
          ref="date"
          @input="doChange(true)"
        />
        <span class="font-weight-bold text-decoration-underline" v-else>{{
          data.datePricing
        }}</span>
        <ReferenceTip class="ml-3">
          Курс валют к рублю за 3 предыдущих месяца
        </ReferenceTip>
        <v-spacer />
      </v-card-subtitle>
      <v-card-text>
        <OriginTable
          :value="data.originCurrency"
          :editMode="editMode"
          :date-pricing="data.datePricing"
          @change="doChangeCurrency"
          @blur="doBlur"
          class="mb-4"
        />
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Сравнения</v-card-title>
      <v-card-text
        ><PricingForms
          :value="data"
          :editMode="editMode"
          @change="doChangePricing"
          @blur="doBlur"
      /></v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { EX_PRICING_TEMPLATE } from '@/components/expPricing/const'

import OriginTable from '@/components/expPricing/comp/origin/OriginTable'
import PricingForms from '@/components/expPricing/comp/pricing/PricingForms'
import SelectDate from '@/components/editors/SelectDate'
import ReferenceTip from '@/components/ReferenceTip'

export default {
  name: 'RefPricing',
  components: { ReferenceTip, SelectDate, PricingForms, OriginTable },
  data: () => ({
    data: null,
    loading: false,
  }),
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    logView: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
  },
  watch: {
    value(newElement) {
      if (this.readonly) this.data = this.value
      else this.data = copyObject(newElement)
    },
  },
  async created() {
    this.loading = true
    try {
      if (this.readonly) this.data = this.value
      else this.data = copyObject(this.value)
      if (!this.data && this.onCreate) {
        console.log('need create expertise - pricing')
        this.data = await this.onCreate(await this.initNewData())
      }
    } finally {
      this.loading = false
    }
  },
  mounted() {
    this.reInit()
  },
  computed: {
    editMode() {
      return this.value && !this.readonly && !this.logView
    },
    //Сегодняшняя дата(год-месяц-день)
    currentDate() {
      return String(new Date().toISOString().slice(0, 10))
    },
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) this.$emit('change', this.data, force)
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    reInit() {
      if (this.readonly) this.data = this.value
    },
    async initNewData() {
      try {
        const resData = this.getExpertiseData()

        return resData
      } catch (err) {
        this.SET_ERROR({
          head: 'Референтные страны',
          text: 'Ошибка загрузки данных',
          err,
        })
      }
    },
    async getExpertiseData() {
      try {
        const data = copyObject(EX_PRICING_TEMPLATE())
        data.datePricing = this.currentDate
        return data
      } catch (err) {
        this.SET_ERROR({
          head: 'Референтные страны',
          text: 'Ошибка загрузки данных по референтным странам ',
          err,
        })
      }
    },
    doChangeCurrency(data, force) {
      this.data.originCurrency = data
      this.doChange(force)
    },
    doChangePricing(data, force) {
      this.data.pricing = data
      this.doChange(force)
    },
  },
}
</script>
