var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.crEffects,"headers":_vm.crEffectsHeaders,"show-expand":"","disable-pagination":"","hide-default-footer":"","disable-sort":"","single-select":"","single-expand":"","item-key":"idx","expanded":_vm.expandedCrEffects,"no-data-text":"Информация о assessmentsData отсутствует"},on:{"update:expanded":function($event){_vm.expandedCrEffects=$event}},scopedSlots:_vm._u([{key:"item.idx",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(item.idx)+".")])]}},{key:"item.crScience",fn:function(ref){
var value = ref.value;
return [(value)?_c('expand-text',{attrs:{"separator":" ","value":String(value),"words-count":5}}):_c('span',{staticClass:"warning--text"},[_vm._v(" ( пусто ) ")])]}},{key:"item.analogCompare",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(value))]):_c('span',{staticClass:"warning--text"},[_vm._v(" ( пусто ) ")])]}},{key:"item.population",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(value))]):_c('span',{staticClass:"warning--text"},[_vm._v(" ( пусто ) ")])]}},{key:"item.result",fn:function(ref){
var value = ref.value;
return [(value)?_c('expand-text',{attrs:{"separator":" ","value":String(value),"words-count":5}}):_c('span',{staticClass:"warning--text"},[_vm._v(" ( пусто ) ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"td-expanded pa-2",attrs:{"colspan":headers.length}},[_c('AdditionalEditor',{attrs:{"value":item,"readonly":_vm.readonly},on:{"change":_vm.doChangeItem,"delete":function($event){return _vm.deleteAssessments(item.idx)}}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('td',{staticClass:"text-start"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-pencil-circle-outline")])],1)],1)]}},(!_vm.readonly)?{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex pt-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","fab":"","small":"","disabled":!!_vm.readonly},on:{"click":function($event){return _vm.addAssessment()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }