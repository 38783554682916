<template>
  <div v-if="element" class="pa-2">
    <v-text-field
      label="Методологическое качество КЭИ"
      :value="element.qLevels"
      :error="qLevelsError"
      :error-messages="qLevelsError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Показание по которому выполнено КЭИ"
      :value="element.condition"
      :error="conditionError"
      :error-messages="conditionError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <v-card v-if="element" elevation="0" class="mb-2">
      <v-card-title
        class="caption pb-0 pl-0"
        :class="{ [`success--text`]: editMode }"
      >
        Использованный метод
      </v-card-title>
      <v-card-text class="pl-0">
        <KeiMethodEditor
          v-model="element.method"
          readonly
          :color="editMode ? 'success' : null"
          :error="methodError"
          :error-text="textAlert.avb_kei"
        />
      </v-card-text>
    </v-card>
    <AutoGrowTextEditor
      label="Препараты сравнения, включенные в предлагаемый перечень"
      :value="element.analogsCompare | getForScenario"
      :error="analogsError"
      :error-messages="analogsError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Клинические преимущества"
      :value="element.clinicalAdvantages | getForScenario"
      :error="clinicalError"
      :error-messages="clinicalError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Экономические преимущества"
      :value="element.econAdvantages | getForScenario"
      :error="econError"
      :error-messages="econError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Основные результаты"
      :value="element.results | getForScenario"
      :error="resultError"
      :error-messages="resultError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
  </div>
</template>

<script>
import KeiMethodEditor from '@/components/expAvbKei/KeiMethodEditor'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import KeiAvbMixin from '@/components/expSummary/summaryAdditional/KeiAvbMixin'
import { keiSummaryCopy } from '@/components/expSummary/summaryAdditional/keiUtils'

export default {
  name: 'AddKei',
  components: { KeiMethodEditor, AutoGrowTextEditor },
  mixins: [KeiAvbMixin],

  computed: {
    qLevelsError() {
      return this.editMode && !this.checkProperty('qLevels')
    },
    conditionError() {
      return this.editMode && !this.checkProperty('condition')
    },
    methodError() {
      return this.editMode && !this.checkProperty('method')
    },
    criteriasError() {
      return this.editMode && !this.checkCriterias(this.element, this.initExp)
    },
    analogsError() {
      return this.editMode && !this.checkProperty('analogsCompare')
    },
    clinicalError() {
      return this.editMode && !this.checkProperty('clinicalAdvantages')
    },
    econError() {
      return this.editMode && !this.checkProperty('econAdvantages')
    },
    resultError() {
      return this.editMode && !this.checkProperty('results')
    },
    commentKeiError() {
      return this.editMode && !this.checkProperty('comment')
    },
    initExp() {
      if (!this.initElement) return null
      return keiSummaryCopy(null, this.initElement)
    },
  },
}
</script>
