<template>
  <div>
    <div class="text-justify pt-2 red-line text-pre-wrap">
      <span>{{ template }}</span>
    </div>
    <div class="text-right">
      <v-btn text x-small color="success" class="mb-1" @click="add"
        >добавить</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'OverlayTemplate',
  props: {
    template: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
    },
  },
  // computed: {
  //   disable() {
  //     return this.comment.includes(this.template)
  //   },
  // },
  methods: {
    add() {
      this.$emit('add', this.template)
    },
  },
}
</script>
