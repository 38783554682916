export function filteredDatetime(data) {
  return data
    ? new Date(data).toLocaleTimeString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timezone: 'UTC',
      })
    : undefined
}

export function filteredDate(data) {
  return data
    ? new Date(data).toLocaleDateString('ru', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })
    : undefined
}
