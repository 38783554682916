<template>
  <default-log v-bind="$attrs">
    <template #default="{ message, code }">
      <span :class="code === 'LOGIN' ? 'green--text' : 'red--text'">
        {{ message }}
      </span>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'

export default {
  name: 'AuthLog',
  inheritAttrs: false,
  components: { DefaultLog },
}
</script>
