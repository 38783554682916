<template>
  <v-container class="pa-5">
    <v-row>
      <v-col></v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="ownScores.scoreGVS"
          :outlined="editMode"
          @input="doChange"
          @blur="doBlur"
          persistent-hint
          hint="Сумма баллов из заключения ГВС"
          label="Баллы ГВС (кор. Баллы ЭО"
          type="number"
        />
      </v-col>
      <v-col></v-col>
    </v-row>
    <ScoreRow
      :prop_1="scoreOwnKi"
      :prop_2="scoreKi"
      :prop_own="ki"
      :edit-mode="editMode"
      @blur="doBlur"
      @change="doChangeKi"
      label="Оценка КИ"
    />
    <ScoreRow
      :prop_1="scoreOwnSafety"
      :prop_2="scoreSafety"
      :prop_own="safety"
      :edit-mode="editMode"
      @change="doChangeSafety"
      @blur="doBlur"
      label="Оценка безопасности"
    />
    <ScoreRow
      :prop_1="scoreOwnEconomic"
      :prop_2="scoreEconomic"
      :prop_own="economic"
      :edit-mode="editMode"
      @change="doChangeEconomic"
      @blur="doBlur"
      ownLabel="Баллы ГВС (кор. балл. ФГБУ)"
      prop_2_label="Баллы ФГБУ"
      label="Оценка экономических последствий"
    />
    <ScoreRow
      :prop_1="scoreOwnSum"
      :prop_2="scoreSum"
      :prop_own="sum"
      readonly
      @change="doChangeSum"
      @blur="doBlur"
      label="Суммарные баллы"
      own-label="Суммарные баллы ГВС (кор. Баллы ЭО)"
      prop_1_label="Суммарные баллы (собственный расчет)"
      prop_2_label="Суммарные баллы ЭО"
    />
  </v-container>
</template>

<script>
import ScoreRow from './ScoreRow'
import { copyObject } from '@/lib/objects'
import { DEFAULT_GVS_SCORES } from '@/components/expSummary/summaryGVS/gvsConst'
export default {
  name: 'compareScores',
  components: { ScoreRow },
  data: () => ({
    assessment: null,
    ownScores: null,
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    item: {
      handler() {
        this.init()
      },
    },
    ['item.initEO'](val) {
      this.item.initEO = val
      this.init()
    },
    ['item.initFEO'](val) {
      this.item.initFEO = val
      this.init()
    },
  },
  methods: {
    init() {
      this.assessment = copyObject(this.value.assessmentOrganization)
      this.ownScores = copyObject(this.item?.ownScores || DEFAULT_GVS_SCORES())
    },
    doChange() {
      this.$emit('change', this.ownScores)
    },
    doChangeKi(data) {
      this.ownScores.ki = data
      this.doChange()
    },
    doChangeSafety(data) {
      this.ownScores.safety = data
      this.doChange()
    },
    doChangeEconomic(data) {
      this.ownScores.economic = data
      this.doChange()
    },
    doChangeSum(data) {
      this.ownScores.sum = data
      this.doChange()
    },
    doBlur() {
      this.$emit('blur')
    },
  },
  computed: {
    //Оценка КИ
    scoreOwnKi() {
      return this.assessment?.qualityAssessment?.averageValue || 0
    },
    scoreKi() {
      return this.assessment?.qualityAssessment?.scoreEO || 0
    },
    ki() {
      return this.ownScores.ki || 0
    },
    //Оценка безопасности
    scoreOwnSafety() {
      return this.assessment?.safetyAssessment?.averageValue || 0
    },
    scoreSafety() {
      return this.assessment?.safetyAssessment?.scoreEO || 0
    },
    safety() {
      return this.ownScores?.safety || 0
    },
    //Оценка экономических последствий
    scoreOwnEconomic() {
      return this.item?.initFEO || 0
    },
    scoreEconomic() {
      return this.item?.initFEO || 0
    },
    economic() {
      return this.ownScores?.economic || 0
    },
    //Суммарные баллы (Баллы подтягиваются с учетом оценки эффективности, стоимости курса и дополнительной терапевтической ценности)
    scoreOwnSum() {
      return (
        Number(this.scoreOwnKi) +
        Number(this.scoreOwnSafety) +
        Number(this.scoreOwnEconomic) +
        this.joinScore +
        this.addData
      )
    },
    scoreSum() {
      return (
        Number(this.scoreKi) +
        Number(this.scoreSafety) +
        Number(this.scoreEconomic) +
        this.joinScore +
        this.addData
      )
    },
    sum() {
      return Number(this.ownScores.scoreGVS || 0) + this.addData
    },
    joinScore() {
      return (
        Number(this.assessment?.levelQuality?.scoreLevel || 0) +
        Number(this.assessment?.economicAssessment?.scoreEconomic || 0) +
        Number(this.assessment?.levelPrice?.scorePrice || 0)
      )
    },
    addData() {
      return (
        Number(this.item.scoreFGBU || 0) +
        Number(this.item.inclusionStandart || 0)
      )
    },
  },
}
</script>

<style scoped></style>
