<template>
  <ActionDialog
    v-bind="$attrs"
    v-on="$listeners"
    colorBtnAction="error"
    label="Вы действительно хотите удалить этот аналог ?"
    labelBtnClose="ОТМЕНА"
    labelBtnAction="УДАЛИТЬ"
  />
</template>

<script>
import ActionDialog from '@/components/dialogs/ActionDialog.vue'
export default {
  name: 'AnalogListItemDeleteDialog',
  components: { ActionDialog },
}
</script>
