<template>
  <VersionSelect v-if="plp_task_id" fluid>
    <template #content="{ data, readonly, onChange, onBlur, onCreate }">
      <ExpAbout
        :value="data"
        @change="onChange"
        :readonly="readonly"
        :onCreate="onCreate"
        @blur="onBlur"
      />
    </template>
    <!-- <template #bottom="{}"> Может своя кнопка </template> -->
  </VersionSelect>
</template>

<script>
import VersionSelect from '@/components/VersionSelect'
import ExpAbout from '@/components/expAbout/ExpAbout'

import { mapGetters } from 'vuex'

export default {
  components: { VersionSelect, ExpAbout },
  data: () => ({}),
  computed: {
    ...mapGetters(['plp_task_id']),
  },
}
</script>
