<template>
  <div v-if="data">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="getItems"
          :expanded.sync="expanded"
          :single-expand="singleExpand"
          item-key="index"
          show-expand
          hide-default-footer
          disable-pagination
          disable-sort
          @click:row="rowClick"
          single-select
        >
          <template v-for="(h, index) in headers" v-slot:[`header.${h.value}`]>
            <v-tooltip bottom :key="index">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ h.text }}</span>
              </template>
              <span>{{ h.title || h.text }}</span>
            </v-tooltip>
          </template>
          <template
            v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
          >
            <td class="text-start">
              <v-btn
                icon
                @click="expand(!isExpanded)"
                :color="item.AD[0].manual ? `success` : null"
                class="v-data-table__expand-icon"
                :class="{ 'v-data-table__expand-icon--active': isExpanded }"
              >
                <v-icon>mdi-pencil-circle-outline</v-icon>
              </v-btn>
            </td>
          </template>
          <template v-slot:[`item.mnn`]="{ item }">
            <div>{{ item.mnn }}</div>
            <div class="text--disabled">{{ item.lForma }}</div>
          </template>
          <template v-slot:[`item.ru`]="{ item }">
            {{ item.AD[0].ru }}
          </template>
          <template v-slot:[`item.ruEndDate`]="{ item }">
            {{ item.AD[0].ruEndDate }}
          </template>
          <template v-slot:[`item.govPack`]="{ item }">
            {{ item.AD[0].govPack }}
          </template>
          <template v-slot:[`item.govPrice`]="{ item }">
            {{ item.AD[0].govPrice }}
          </template>
          <template v-slot:[`item.obr`]="{ item }">
            <span class="text-pre-wrap">{{ item.AD[0].obr }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="td-expanded">
              <v-form class="py-4" :readonly="readonly">
                <v-row>
                  <v-col md="4">
                    <v-text-field
                      v-model.trim="item.AD[0].ru"
                      prepend-icon="mdi-text-box-check-outline"
                      label="РУ"
                      @input="doChange()"
                      @blur="doBlur()"
                      :success="item.AD[0].manual"
                      :readonly="!item.AD[0].manual"
                    />
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      label="Дата РУ"
                      v-model.trim="item.AD[0].ruEndDate"
                      prepend-icon="mdi-calendar"
                      @input="doChange()"
                      @blur="doBlur()"
                      :success="item.AD[0].manual"
                      :readonly="!item.AD[0].manual"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="4">
                    <v-textarea
                      v-model.trim="item.AD[0].obr"
                      prepend-icon="mdi-recycle-variant"
                      label="Обращение"
                      @input="doChange()"
                      @blur="doBlur()"
                      :success="item.AD[0].manual"
                      :readonly="!item.AD[0].manual"
                      auto-grow
                      rows="1"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model.trim="item.AD[0].govPack"
                      prepend-icon="mdi-package-variant-closed"
                      label="Упаковки ГЗ"
                      @input="doChange()"
                      @blur="doBlur()"
                      :success="item.AD[0].manual"
                      :readonly="!item.AD[0].manual"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model.trim="item.AD[0].govPrice"
                      prepend-icon="mdi-cash-100"
                      label="Стоимость ГЗ"
                      @input="doChange()"
                      @blur="doBlur()"
                      :success="item.AD[0].manual"
                      :readonly="!item.AD[0].manual"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-switch
                      class="mt-0"
                      v-model="item.AD[0].manual"
                      label="Ручная корректировка данных"
                      title="Выполнить ручную корректировку данных (отменить ручную корректирвку можно только выполнив замену данных из справочника ЛП)"
                      color="success"
                      @change="doChange()"
                      :disabled="item.AD[0].manual"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="8" class="text-right">
                    <v-btn
                      v-if="!logView"
                      fab
                      small
                      icon
                      :disabled="readonly"
                      @click="getLF(item, isMain(item))"
                      title="Открыть справочник ЛП"
                      ><v-icon>mdi-store-search-outline</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <AnalogLFList
      v-if="show"
      :value="item"
      :readonly="readonly"
      :use-zero-year="showMain"
      hide-select-lf
      @closeDialog="closeDialog"
      @updateLF="updateLF"
      ><v-alert v-if="item.AD[0].manual" type="warning" dense outlined
        >Включён ручной ввод данных об аналогах, после "Выбора" данные будут
        перезаписаны!</v-alert
      >
    </AnalogLFList>
  </div>
  <div v-else-if="createError">
    <v-alert type="error" outlined>{{ createError }}</v-alert>
  </div>
</template>

<script>
import AnalogMixin from './AnalogMixin'
import { UNLIMITED_TEXT } from './const'
import AnalogLFList from './AnalogLFList'
import { copyObject } from '@/lib/objects'

export default {
  name: 'AdList',
  components: { AnalogLFList },
  mixins: [AnalogMixin],
  props: {
    value: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    item: null,
    show: false,
    showMain: false,
    minDate: '',
    singleExpand: true,
    expanded: [],
    headers: [
      {
        text: 'АТХ',
        value: 'ath',
        title: 'Анатомо-терапевтическо-химическая классификация',
        cellClass: 'text--disabled',
      },
      {
        text: 'МНН / ЛФ',
        value: 'mnn',
        title: 'Международное непатентованное наименование',
      },
      {
        text: 'РУ',
        value: 'ru',
        width: '130px',
        title:
          'Номер регистрационного удостоверения (в случае наличия одного регистрационного удостоверения) или количество регистрационных удостоверений в случае наличия нескольких регистрационных удостоверений',
        align: 'center',
      },
      {
        text: 'Дата окончания РУ',
        value: 'ruEndDate',
        width: '140px',
        title: 'Дата окончания РУ',
        align: 'center',
      },
      { text: 'Обращение', value: 'obr', width: '140px', align: 'right' },
      { text: 'Упаковки ГЗ', value: 'govPack', width: '120px', align: 'right' },
      {
        text: 'Стоимость ГЗ',
        value: 'govPrice',
        width: '140px',
        align: 'right',
      },
      { text: '', value: 'data-table-expand' },
    ],
  }),
  computed: {
    getItems() {
      return this.data.AL
        ? this.data.AL.reduce((itm, item, index) => {
            if (item.anal === true) {
              itm.push({ index, ...item })
            }
            return itm
          }, [])
        : []
    },
  },
  methods: {
    getLF(item, main) {
      this.item = copyObject(item)
      this.openDialog(main)
    },
    isMain(item) {
      return item.index === 0
    },
    updateLF(lf) {
      const ad = this.data.AL[this.item.index].AD[0]
      // алгоритм и данные
      ad.algVer = lf.algVer
      ad.dictData = lf.dictData
      // обращение и ГЗ
      ad.obr = lf.obr
      ad.govPack = lf.govPack
      ad.govPrice = lf.govPrice
      // сброс ручного режима
      ad.manual = false
      // кол-во РУ и дата окончания РУ
      ad.ru = lf.regNr
      ad.ruEndDate = lf.regDateFin || UNLIMITED_TEXT

      this.doChange(true)
      this.closeDialog()
    },
    closeDialog() {
      this.show = false
      this.showMain = false
    },
    openDialog(showMain) {
      this.show = true
      this.showMain = showMain
    },
    rowClick: function (item, row) {
      row.select(true)
    },
    doBlur() {
      this.$emit('blur')
    },
    doChange(force = false) {
      this.$emit('change', this.data, force)
    },
  },
}
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
.td-expanded {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 10%, #dfdfdf 100%);
  border-radius: 0 0 15px 15px;
}
.v-data-table__expand-icon--active {
  background-color: lightgreen;
}
</style>
