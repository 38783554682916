<template>
  <div class="my-2">
    <v-data-table
      :headers="getHeaders"
      :items="getFilteredItems"
      :loading="item.loading"
      disable-pagination
      hide-default-footer
      disable-sort
      :show-select="selectMode && !readonly"
      :single-select="!singleSelect"
      v-model="selectedRows"
    >
      <template #[`item.index`]="{ index }">
        <span>{{ index + 1 }}.</span>
      </template>
      <template #[`item.mkb10_codes`]="{ value }">
        <expand-text :value="value" :words-count="5" />
      </template>
      <template #no-data v-if="item.error">
        <v-alert type="error" outlined class="mt-2">
          Ошибка загрузки показаний
        </v-alert>
      </template>
      <template v-slot:top v-if="selectMode">
        <v-toolbar flat v-if="!readonly">
          <v-spacer />
          <v-switch
            v-model="singleSelect"
            label="Выбрать несколько позиций"
            title="Выбранные значения будут аггрегированы"
            @change="selectedRows = []"
            :disabled="readonly"
          ></v-switch>
          <v-switch
            v-model="withMKB10"
            label="Выводить с МКБ-10"
            title="Вывод показаний вместе с мкб-10"
            class="ml-8"
            :disabled="readonly"
          ></v-switch>
        </v-toolbar>
      </template>
      <template v-if="editMode" v-slot:footer>
        <div class="d-flex justify-end mt-6 mb-6 mr-2">
          <v-btn
            color="accent"
            fab
            small
            @click="openForAdd"
            title="Добавить показание"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-if="editMode" v-slot:[`item.actions`]="{ item }">
        <div class="actionsIndication">
          <v-btn
            @click="openForEdit(item)"
            fab
            small
            depressed
            title="Редактировать"
            color="transparent"
            class="mr-1"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <btn-with-confirm
            icon
            icon-main="mdi-delete-forever-outline"
            title="Удалить показание"
            @click="showDeleteConfirmation(item)"
            :disabled="deleteIndicationInProgress"
          >
          </btn-with-confirm>
        </div>
      </template>
    </v-data-table>
    <v-btn
      class="my-2"
      v-if="selectMode && !readonly"
      color="primary"
      :disabled="!selectedRows.length || readonly"
      @click="getSelected"
    >
      Выбрать
    </v-btn>
    <v-alert type="error" v-if="error_message" class="mt-2" outlined>
      <div>Произошла ошибка при работе со справочниками</div>
      <div>
        {{ error_message }}
      </div>
    </v-alert>
    <IndicationsForm
      v-if="editMode && showForm"
      :indication="indication"
      :lf="item"
      :medication="medication"
      :is-open-for-add="isOpenForAdd"
      @close-form="closeForm"
    />
    <ExpAgencyDeleteDialog
      v-if="editMode"
      :dialog="showDeleteDialog"
      :name="getName"
      title="Удалить показание"
      lb="Показание: "
      label="Подтвердить удаление показания"
      @onClose="cancelDelete"
      @onAction="confirmDelete"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import IndicationsForm from '@/components/dict/indications/allForms/IndicationsForm'
import ExpAgencyDeleteDialog from '@/components/dialogs/ExpAgencyDeleteDialog'
import ExpandText from '@/components/editors/expandText'
export default {
  name: 'IndicationsTable',
  components: {
    ExpandText,
    ExpAgencyDeleteDialog,
    IndicationsForm,
    BtnWithConfirm,
  },
  props: {
    // весь препарат, АТХ, МНН, lfs
    medication: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    // одна выбранная лекформа из списка
    item: {
      required: true,
    },
    // Флаг для выбора показаний
    selectMode: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    singleSelect: false,
    selectedRows: [],
    withMKB10: true,
    indicationHeaders: [
      { text: '№', value: 'index', width: '10px', cellClass: 'text--disabled' },
      {
        text: 'Коды мкб10',
        value: 'mkb10_codes',
        cellClass: 'text-pre-wrap pb-1',
      },
      {
        text: 'Показание',
        value: 'indications',
        sortable: false,
        width: '400px',
      },
      {
        text: 'Возраст',
        value: 'age',
        sortable: false,
        width: '200px',
      },
      { text: 'Действия', value: 'actions', width: '150px', sortable: false },
    ],
    error_message: '',
    deleteIndicationInProgress: false,
    indication: null,
    showForm: false,
    isOpenForAdd: false,
    showDeleteDialog: false,
  }),
  watch: {
    withMKB10(val) {
      try {
        localStorage.setItem('withMKB10', val)
      } catch {
        localStorage.removeItem('withMKB10')
      }
    },
  },
  created() {
    // загружаем список показаний конректного препарата (medication) по лекформе (item)
    this.GET_INDICATIONS({
      lecformId: this.item.id,
      medicationId: this.medication.id,
    })
    this.withMKB10 = JSON.parse(localStorage.getItem('withMKB10')) ?? true
  },
  methods: {
    ...mapActions(['GET_INDICATIONS', 'DELETE_MEDICINE_FROM_GNVLP']),
    resultIndications(selectedRows) {
      return selectedRows.reduce((acc, item) => {
        const property = item.mkb10_codes
        acc[property] = acc[property] || []
        acc[property].push({
          indications: item.indications,
        })
        return acc
      }, {})
    },
    getSelected() {
      const res = this.resultIndications(this.selectedRows)
      let condition = ''
      for (let key of Object.keys(res)) {
        if (this.withMKB10) condition += '\n' + key + ':'
        res[key].forEach(item => {
          condition += '\n-' + item.indications + ';'
        })
      }
      this.$emit('updateIndications', condition.trim())
    },
    openForAdd() {
      this.isOpenForAdd = true
      this.showForm = true
    },
    openForEdit(item) {
      this.isOpenForAdd = false
      this.indication = item
      this.showForm = true
    },
    closeForm() {
      this.indication = null
      this.showForm = false
    },
    showDeleteConfirmation(item) {
      this.showDeleteDialog = true
      this.indication = item
    },
    cancelDelete() {
      this.showDeleteDialog = false
      this.indication = null
    },
    async confirmDelete() {
      this.deleteIndicationInProgress = true
      this.error_message = ''
      try {
        await this.DELETE_MEDICINE_FROM_GNVLP({
          med_id: this.medication.id,
          lf_id: this.indication.dict_gnvlp_lf_id,
          ind_id: this.indication.id,
        })
      } catch (err) {
        this.error_message = err
      } finally {
        this.indication = null
        this.showDeleteDialog = false
        this.deleteIndicationInProgress = false
      }
    },
  },
  computed: {
    getName() {
      return this.indication?.indications ?? ''
    },
    getHeaders() {
      return this.editMode
        ? this.indicationHeaders
        : this.indicationHeaders.filter(({ value }) => value != 'actions')
    },
    getFilteredItems() {
      if (this.medication?.filterMKB) {
        return this.item?.indications?.reduce((out, item) => {
          const indication = this.item?.filterMKB.find(
            ({ id }) => item.id === id
          )
          return indication ? [...out, { ...item }] : out
        }, [])
      } else {
        return this.item.indications
      }
    },
  },
}
</script>
<style scoped>
.v-data-table >>> tr .actionsIndication {
  display: none !important;
}
.v-data-table >>> tr:hover .actionsIndication {
  display: flex !important;
}
</style>
