<template>
  <div v-if="element" class="pa-2">
    <v-text-field
      label="Методологическое качество КЭИ"
      :value="element.qLevels"
      :error="qLevelsError"
      :error-messages="qLevelsError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <v-text-field
      label="ФИО автора, год"
      v-model="element.resume"
      hide-details
      @input="doChange()"
      @blur="doBlur()"
      :append-icon="editMode ? 'mdi-pencil' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Показание по которому выполнено КЭИ"
      :value="element.condition"
      :error="conditionError"
      :error-messages="conditionError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <AutoGrowTextEditor
      label="Препараты сравнения, включенные в предлагаемый перечень"
      v-model="element.analogsCompare"
      @input="doChange()"
      @blur="doBlur()"
      :append-icon="editMode ? 'mdi-pencil' : null"
      class="mb-4"
    />
    <v-card v-if="element" elevation="0" class="mb-2">
      <v-card-title
        class="caption pb-0 pl-0"
        :class="{ [`success--text`]: editMode }"
      >
        Использованный метод
      </v-card-title>
      <v-card-text class="pl-0">
        <KeiMethodEditor
          v-model="element.method"
          readonly
          :color="editMode ? 'success' : null"
          :error="methodError"
          :error-text="textAlert.avb_kei"
        />
      </v-card-text>
    </v-card>
    <!-- Критерии несоответствия КЭИ с возможностью корректировки данных -->
    <v-card :outlined="editMode" elevation="0" class="mb-4">
      <v-card-title
        class="text-caption pb-0"
        :class="{ [`success--text`]: editMode, [`pl-0`]: !editMode }"
      >
        Критерии несоответствия КЭИ в соответствии с пунктами требований
        Постановления Правительства РФ № 871 (ред. от 20.11.2018)
      </v-card-title>
      <v-card-text :class="{ [`pl-0`]: !editMode }">
        <v-alert v-if="criteriasError" type="warning" dense text class="mt-2">
          {{ textAlert.criteria }}
        </v-alert>
        <v-data-table
          :headers="headers"
          :items="element.criterias"
          show-expand
          :expanded="element.criterias"
          disable-pagination
          hide-default-footer
          no-data-text="Критерии несоответствия КЭИ отсутствуют"
        >
          <template #[`item.name`]="{ item }">
            <span
              :class="{
                [`error--text font-weight-bold`]:
                  commentError(item) || descriptionError(item),
              }"
            >
              {{ item.order }}. {{ item.name }}
            </span>
          </template>
          <template #[`item.itemRequirements`]="{ value }">
            {{ value && value.join(', ') }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div>
                <div class="d-flex align-center my-2">
                  <v-spacer />
                  <btn-with-confirm
                    v-if="editMode"
                    icon
                    class="float-right"
                    icon-main="mdi-sync-alert"
                    title="Выйти из режима ручной корректировки данных, обновить до актуальных данных"
                    @click="updateCriteria(item)"
                    :disabled="!item.manual"
                  />
                </div>
                <AutoGrowTextEditor
                  label="Описание"
                  v-model="item.description"
                  @input="doChange()"
                  @blur="doBlur()"
                  :error="descriptionError(item)"
                  :readonly="!item.manual"
                  hide-details
                  class="mb-4"
                  :append-icon="
                    editMode
                      ? item.manual
                        ? 'mdi-pencil'
                        : 'mdi-lock-outline'
                      : null
                  "
                  :success="editMode && !item.manual"
                />
                <AutoGrowTextEditor
                  v-if="item.manual || item.comment"
                  label="Комментарий"
                  v-model="item.comment"
                  @input="doChange()"
                  @blur="doBlur()"
                  :error="commentError(item)"
                  :readonly="!item.manual"
                  hide-details
                  :append-icon="
                    editMode
                      ? item.manual
                        ? 'mdi-pencil'
                        : 'mdi-lock-outline'
                      : null
                  "
                  :success="editMode && !item.manual"
                />
                <div class="d-flex align-center">
                  <v-spacer />
                  <v-switch
                    v-model="item.manual"
                    label="Ручная корректировка данных"
                    color="primary"
                    class="align-start"
                    :disabled="item.manual"
                    dense
                    @change="doChange(true)"
                  />
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
        <v-divider />
      </v-card-text>
    </v-card>
    <AutoGrowTextEditor
      v-if="element.comment || commentKeiError"
      :label="`Комментарий к ${element.shortName} (сводное заключение)`"
      :value="element.comment"
      :error="commentKeiError"
      :error-messages="commentKeiError ? textAlert.avb_kei : ''"
      readonly
      :success="editMode"
      :append-icon="editMode ? 'mdi-lock-outline' : null"
      class="mb-4"
    />
    <v-divider />
    <v-card flat>
      <v-card-title class="text-subtitle-2">
        Мои заметки к {{ element.shortName }}
      </v-card-title>
      <v-card-text>
        <AutoGrowTextEditor
          v-model="element.note"
          hint="Внутренние комментарии. В отчёты не идёт!"
          @input="doChange()"
          @blur="doBlur()"
          class="mb-4"
          :append-icon="editMode ? 'mdi-pencil' : null"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import KeiMethodEditor from '@/components/expAvbKei/KeiMethodEditor'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import KeiAvbMixin from '@/components/expConclusion/expKeiAvb/KeiAvbMixin'
import btnWithConfirm from '@/components/buttons/btnWithConfirm'
import { mapGetters } from 'vuex'
import { keiCopy } from '@/components/expConclusion/expKeiAvb/KeiUtils'

export default {
  name: 'ExpKei',
  components: { KeiMethodEditor, AutoGrowTextEditor, btnWithConfirm },
  mixins: [KeiAvbMixin],

  computed: {
    ...mapGetters(['storedKeiCriteria']),
    qLevelsError() {
      return this.editMode && !this.checkProperty('qLevels')
    },
    conditionError() {
      return this.editMode && !this.checkProperty('condition')
    },
    resumeError() {
      return this.editMode && !this.checkProperty('resume')
    },
    methodError() {
      return this.editMode && !this.checkProperty('method')
    },
    criteriasError() {
      return this.editMode && !this.checkCriterias(this.element, this.initExp)
    },
    analogsError() {
      return this.editMode && !this.checkProperty('analogsCompare')
    },
    commentKeiError() {
      return this.editMode && !this.checkProperty('comment')
    },
    initExp() {
      if (!this.initElement) return null
      return keiCopy(null, this.initElement, this.storedKeiCriteria)
    },
  },
}
</script>
