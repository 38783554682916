var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.currencyPrice,"headers":_vm.currencyHeaders,"disable-pagination":"","hide-default-footer":"","single-select":"","show-expand":"","item-key":"idx","expanded":_vm.expandedPricing,"no-data-text":"Информация о таблице \"Референтные страны\" отсутствует"},on:{"update:expanded":function($event){_vm.expandedPricing=$event}},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-text-field',{staticClass:"my-1 py-0",attrs:{"readonly":_vm.priceEdit(item),"value":item.price,"outlined":_vm.editMode && !_vm.priceEdit(item),"append-icon":_vm.editMode ? 'mdi-pencil' : null,"hint":"Цена","type":"number","hide-details":""},on:{"input":function (val) { return _vm.doChangePrice(index, val); },"blur":function($event){return _vm.doBlur()}}})]}},{key:"item.priceRUB",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toMoney")(item.priceRUB)))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item)?_c('td',{staticClass:"td-expanded pa-2",attrs:{"colspan":headers.length - 1}},[_c('PricingEditor',{attrs:{"value":item,"readonly":!_vm.editMode},on:{"change":_vm.doChangeItem,"blur":_vm.doBlur}})],1):_vm._e(),_c('td',[(_vm.editMode)?_c('btn-with-confirm',{attrs:{"text":"","icon-main":"mdi-delete-forever-outline","title":"Удалить"},on:{"click":function($event){return _vm.deleteCustom(item)}}}):_vm._e()],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('td',{staticClass:"text-right"},[(!item.custom && _vm.editMode)?_c('v-btn',{staticClass:"action",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"color":"blue lighten-2","title":"Добавить альтернативную форму выпуска","icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1):_vm._e()],1)]}},{key:"item.flag",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"value":_vm.priceEdit(item),"color":"blue lighten-3","content":"Альтернативная форма выпуска"}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.getFlag(item.country)))])])]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
var value = ref.value;
var index = ref.index;
return [(item.isEdit)?_c('div',{staticStyle:{"max-width":"70px"}},[_c('v-select',{attrs:{"label":"Код","items":_vm.namesCurrency},on:{"input":function($event){return _vm.doChangePrice(index, item.price, true)}},model:{value:(item.currency),callback:function ($$v) {_vm.$set(item, "currency", $$v)},expression:"item.currency"}})],1):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(value))])]}},{key:"item.country",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.isEdit)?_c('v-text-field',{attrs:{"hint":"Страна"},on:{"input":function($event){return _vm.doChange()},"blur":function($event){return _vm.doBlur()}},model:{value:(item.country),callback:function ($$v) {_vm.$set(item, "country", $$v)},expression:"item.country"}}):_c('span',[_vm._v(_vm._s(value))])]}},(_vm.editMode)?{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex pt-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"title":"Добавить референтную страну","color":"accent","fab":"","small":""},on:{"click":_vm.addRefCountry}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }