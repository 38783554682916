<template>
  <v-row v-if="exps.length" dense>
    <v-col>
      <v-combobox
        label="Название экспертной организаций"
        :value="internalExp"
        :outlined="!readonly"
        :items="exps.map(item => item.name)"
        :loading="loading"
        prepend-inner-icon="mdi-subtitles-outline"
        @input="changeExp"
        @input.native="changeNativeExp"
        @blur="doBlur()"
      >
      </v-combobox>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ScoreEditor',
  data: () => ({
    internalExp: '',
    exps: [],
    loading: false,
  }),
  props: {
    exp: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getExps()
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['GET_ORG_DICT', 'SET_ERROR']),
    init() {
      this.internalExp = this.exp
    },
    async getExps() {
      this.loading = true
      try {
        const exps = await this.GET_ORG_DICT()
        this.exps = exps || []
      } catch (e) {
        console.log(e)
        this.SET_ERROR({
          head: 'Текущие списки ЭО',
          text: 'Ошибка загрузки текущих списков ЭО (экспертных организаций)',
          e,
        })
        throw e
      } finally {
        this.loading = false
      }
    },
    doChange(val1, force = false) {
      this.$emit('change', val1, force)
    },
    getExp(val) {
      return typeof val === 'string' ? val : val?.name
    },
    changeNativeExp(val) {
      this.changeExp(val, true)
    },
    changeExp(val, native = false) {
      if (!native) {
        this.internalExp = this.getExp(val)
      }

      this.$emit('update:exp', this.internalExp)
      this.doChange(this.internalExp, !native)
    },
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>
