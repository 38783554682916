<template>
  <component :logInfo="logData" :detail="detail" :is="specificInfoLog" />
</template>

<script>
import PlpReportLog from '@/components/viewLogs/plpLogs/PlpReportLog'
import PlpModifiedLog from '@/components/viewLogs/plpLogs/PlpModifiedLog'
import PlpSentLog from '@/components/viewLogs/plpLogs/PlpSentLog'
import DefaultLog from '@/components/viewLogs/DefaultLog'
import AuthLog from '@/components/viewLogs/authLogs/AuthLog'
import PlpTaskModifiedLog from '@/components/viewLogs/plpTaskLogs/PlpTaskModifiedLog'
import PlpSubexMergedLog from '@/components/viewLogs/plpSubexVerLogs/PlpSubexMergedLog'
import SentToReworkLog from '@/components/viewLogs/plpTaskLogs/SentToReworkLog'
import PlpTaskLog from '@/components/viewLogs/plpTaskLogs/PlpTaskLog'
import UserLog from '@/components/viewLogs/userLogs/UserLog'
import PlpSubexLog from '@/components/viewLogs/plpSubexVerLogs/PlpSubexLog'
import PlpTaskUpdLog from '@/components/viewLogs/plpTaskLogs/PlpTaskUpdLog'
import PlpCreatedLog from '@/components/viewLogs/plpLogs/PlpCreatedLog'
import PlpDeletedLog from '@/components/viewLogs/plpLogs/PlpDeletedLog'
import DictUpdatedLog from '@/components/viewLogs/dictLogs/DictUpdatedLog'
import DictImportedLog from '@/components/viewLogs/dictLogs/DictImportedLog'
import DictExportedLog from '@/components/viewLogs/dictLogs/DictExportedLog'
import EmptyLog from '@/components/viewLogs/EmptyLog'
import DeadlinesUpdatedLog from '@/components/viewLogs/deadlinesLogs/DeadlinesUpdatedLog.vue'
import PlpFilesCreatedLog from '@/components/viewLogs/plpFilesCreatedLog/PlpFilesCreatedLog.vue'

//Все коды и связанные с ними компоненты, которые отрисовываются динамически исходя из кода лога.
const logItem = {
  //
  LOGIN: 'AuthLog',
  LOGOUT: 'AuthLog',
  //
  DICT_IMPORTED: 'DictImportedLog',
  DICT_UPDATED: 'DictUpdatedLog',
  DICT_EXPORTED: 'DictExportedLog',
  //
  PLP_REPORT: 'PlpReportLog',
  PLP_CREATED: 'PlpCreatedLog',
  PLP_MODIFIED: 'PlpModifiedLog',
  PLP_SENT: 'PlpSentLog',
  PLP_REVOKED: 'PlpSentLog',
  PLP_DELETED: 'PlpDeletedLog',
  //
  PLP_TASK_MASS_UPDATED: 'PlpTaskUpdLog',
  PLP_TASK_CREATED: 'PlpTaskLog',
  PLP_TASK_MODIFIED: 'PlpTaskModifiedLog',
  PLP_TASK_SENT_TO_REWORK: 'SentToReworkLog',
  PLP_TASK_FORCE_COMPLETED: 'PlpTaskLog',
  PLP_TASK_CHECKED: 'PlpTaskLog',
  PLP_TASK_UNCHECKED: 'PlpTaskLog',
  PLP_TASK_DELETED: 'PlpTaskLog',
  //
  PLP_SUBEX_VER_CREATED: 'PlpSubexLog',
  PLP_SUBEX_VER_UPDATED: 'PlpSubexLog',
  PLP_SUBEX_VER_MERGED: 'PlpSubexMergedLog',
  PLP_SUBEX_VER_MODIFIED: 'PlpSubexLog',
  PLP_SUBEX_VER_SET_MAIN: 'PlpSubexLog',
  PLP_SUBEX_VER_COPIED: 'PlpSubexLog',
  PLP_SUBEX_VER_DELETED: 'PlpSubexLog',
  //
  USER_CREATED: 'UserLog',
  USER_MODIFIED: 'UserLog',
  USER_UPDATED: 'UserLog',
  USER_DELETED: 'UserLog',
  USER_RESET_TOKEN_GENERATED: 'UserLog',
  USER_RESET_TOKEN_DELETED: 'UserLog',
  //
  PLP_DEADLINES_UPDATED: 'DeadlinesUpdatedLog',
  //
  PLP_FILES_CREATED: 'PlpFilesCreatedLog',
}
const defaultItem = 'EmptyLog'

export default {
  name: 'LogItem',
  data() {
    return {}
  },
  components: {
    PlpReportLog,
    PlpModifiedLog,
    PlpSentLog,
    DefaultLog,
    AuthLog,
    PlpTaskModifiedLog,
    PlpSubexLog,
    PlpSubexMergedLog,
    SentToReworkLog,
    PlpTaskLog,
    UserLog,
    PlpTaskUpdLog,
    PlpCreatedLog,
    PlpDeletedLog,
    DictImportedLog,
    DictUpdatedLog,
    DictExportedLog,
    EmptyLog,
    DeadlinesUpdatedLog,
    PlpFilesCreatedLog,
  },
  props: {
    logData: {
      type: Object,
      default: () => ({}),
    },
    detail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logCode() {
      return this.logData?.code
    },
    specificInfoLog() {
      return logItem[this.logCode] ?? defaultItem
    },
  },
}
</script>
