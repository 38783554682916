<template>
  <v-card flat>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Уровень убедительности КИ</th>
            <th class="text-left">Уровень убедительности доказательств</th>
            <th class="text-left">Баллы</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in EVIDENCES" :key="item.id">
            <td class="font-weight-bold">{{ item.levelPersuasiveness }}</td>
            <td>{{ item.levelEvidence }}</td>
            <td>{{ item.scores }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
const EVIDENCES = [
  {
    levelPersuasiveness:
      'Доказательства убедительны: \n' +
      'имеются веские доказательства предлагаемого утверждения',
    levelEvidence: 'A',
    scores: 3,
  },
  {
    levelPersuasiveness:
      'Относительная убедительность доказательств: \n' +
      'имеются достаточные доказательства в пользу того, чтобы рекомендовать предложенный лекарственный препарат для включения (исключения) в соответствующий перечень лекарственных препаратов',
    levelEvidence: 'B',
    scores: 2,
  },
  {
    levelPersuasiveness:
      'Достаточных доказательств нет: \n' +
      'имеющихся доказательств недостаточно для вынесения рекомендации, но рекомендации могут быть даны с учетом иных обстоятельств',
    levelEvidence: 'C',
    scores: 1,
  },
]
export default {
  name: 'LevelEvidence',
  data: () => ({
    EVIDENCES,
  }),
}
</script>

<style scoped></style>
