<template>
  <v-row dense>
    <v-col cols="5" lg="3" offset-lg="2">
      <v-text-field
        readonly
        hint="Собственный расчет"
        :label="label"
        type="number"
        v-model.number="value_1"
      />
    </v-col>
    <v-col cols="2" lg="2" class="align-center text-center"
      ><same-icon class="mt-4" :same="isCompare" />
    </v-col>
    <v-col cols="5" lg="3">
      <v-text-field readonly hint="Баллы ЭО" :label="label" v-model="value_2" />
    </v-col>
  </v-row>
</template>

<script>
import { sameObject } from '@/lib/objects'
import SameIcon from '@/components/compare/SameIcon'

export default {
  name: 'ScoreRow',
  components: { SameIcon },
  data: () => ({
    value_1: 0,
    value_2: 0,
  }),
  methods: {
    init() {
      this.value_1 = Number(this.prop_1)
      this.value_2 = Number(this.prop_2)
    },
  },
  watch: {
    prop_2: 'init',
    prop_1: 'init',
  },
  props: {
    prop_1: {
      type: [String, Number],
      default: '',
    },
    prop_2: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  computed: {
    isCompare() {
      return sameObject(this.value_1, this.value_2)
    },
  },
}
</script>

<style scoped></style>
