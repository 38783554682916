<template>
  <v-container>
    <v-row>
      <v-col cols="4"> </v-col>
      <v-spacer />
      <v-col cols="6">
        <SelectDate
          v-if="!loading"
          v-model="dateLogs"
          :maxDate="minDate"
          min-date="2022-06-00"
          label="Фильтр по дате"
          @input="value => filterDateLogs(value)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="logs"
          group-by="group"
          :loading="loading"
          :headers="headers"
          :search="search"
          disable-pagination
          hide-default-footer
          :custom-filter="filterOnlyFio"
        >
          <template #[`group.header`]="{ items, headers }">
            <td :colspan="headers.length">
              <span class="font-weight-bold">{{ items[0].date }}</span>
            </td>
          </template>
          <template #[`item.message`]="{ item }">
            <LogItem :log-data="item" />
          </template>
          <template #[`item.user_fio`]="{ value }">
            <span :inner-html.prop="value | shortFIO | highlight(search)" />
          </template>
          <template #[`item.time`]="{ value, item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span dense v-bind="attrs" v-on="on" class="text-center">
                  <span>{{ value }}</span>
                </span>
              </template>
              {{ item.date }}
            </v-tooltip>
          </template>
          <template #[`item.info`]="{ item }">
            <div class="actionsLogs">
              <v-btn
                :loading="loadInfo && logInfo.id === item.id"
                icon
                @click="!showInfoLog && !loadInfo && openInfoDialog(item)"
              >
                <v-icon> mdi-information-outline </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:footer>
            <v-alert type="error" v-if="errorText" outlined class="ma-2">
              {{ errorText }}
            </v-alert>
            <v-btn
              v-if="needLoading && showLogBtn"
              v-intersect="{
                handler: loadUserLogs,
                options: {
                  threshold: [0, 0.5, 1.0],
                },
              }"
              :loading="loading"
              block
              text
              @click="loadUserLogs"
            >
              Показать ещё...</v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="showInfoLog" v-model="showInfoLog" overlay width="1000">
      <LogItem :logData="logInfo" detail />
    </v-dialog>
  </v-container>
</template>

<script>
import SelectDate from '@/components/editors/SelectDate'
import { Api } from '@/lib/axiosApi'
import LogItem from '@/components/viewLogs/LogItem'
import DateForPicker from '@/lib/datetime'

export default {
  name: 'userActions',
  components: { LogItem, SelectDate },
  data() {
    return {
      loading: false,
      logs: [],
      logId: null,
      count: 0,
      search: '',
      showInfoLog: false,
      errorText: '',
      groupIdx: 0,
      showLogBtn: true,
      groupLastDate: '',
      logInfo: null,
      loadInfo: false,
      dateLogs: '',
      minDate: new DateForPicker().toISO8601date,
      headers: [
        {
          text: 'Время',
          align: 'center',
          value: 'time',
          width: '100px',
          cellClass: 'px-2',
          sortable: false,
        },
        {
          text: 'Сообщение',
          value: 'message',
          cellClass: 'text--secondary font-weight-medium px-0',
          sortable: false,
        },
        {
          text: 'Пользователь',
          value: 'user_fio',
          cellClass: 'text--primary font-weight-medium',
          width: '150px',
          sortable: false,
        },
        {
          text: '',
          value: 'info',
          width: '80px',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    getGroup(timestamp) {
      const dateStamp = this.filteredDate(timestamp)
      if (dateStamp !== this.groupLastDate) {
        this.groupLastDate = dateStamp
        this.groupIdx++
      }
      return this.groupIdx
    },
    async openInfoDialog(value) {
      this.logInfo = value
      this.loadInfo = true
      try {
        const data = await Api.get(`actions-log/${value?.id || -1}`)
        this.logInfo = data
      } catch (e) {
        console.log('error log info')
      } finally {
        this.loadInfo = false
        this.showInfoLog = true
      }
    },
    filterDateLogs(value) {
      //корректная дата, не захватывающая еще +3 часа потому что new Date работает кривовато
      const newDate = new Date(value)
      newDate.setMinutes(newDate.getTimezoneOffset())
      this.allSearchLog['date_till'] = newDate.toISOString()
      //обновляем список логов в таблице.
      if (this.logs.length) {
        this.logs = []
      } else {
        this.loadUserLogs()
      }
    },
    async loadUserLogs() {
      if (this.loading || !this.needLoading) return
      this.errorText = ''
      this.loading = true
      try {
        const last_id = this.lastId ? `last_id=${this.lastId}&` : ''
        const { data, count } = await Api.post(
          `actions-log/search?${last_id}limit=${this.limitCount}`,
          this.allSearchLog
        )
        this.logs.push(
          ...data.map(({ timestamp, ...d }) => ({
            ...d,
            time: this.filteredTime(timestamp),
            date: this.filteredDate(timestamp),
            group: this.getGroup(timestamp),
          }))
        )
        this.count = count
        this.showLogBtn = count > 0
      } catch (error) {
        this.errorText = error.message
        console.log('it s user actions error')
      } finally {
        this.loading = false
      }
    },
    filterOnlyFio(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        item.user_fio.toUpperCase().includes(search.toUpperCase())
      )
    },
    filteredDate(data) {
      return new Date(data).toLocaleDateString('ru', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    filteredTime(data) {
      return new Date(data).toLocaleTimeString('ru', {
        timezone: 'UTC',
      })
    },
  },
  created() {
    this.loadUserLogs()
  },
  computed: {
    needLoading() {
      return !this.lastId || this.count > this.limitCount
    },
    lastId() {
      return this.logs?.[this.logs?.length - 1]?.id ?? 0
    },
    // Тело запроса для фильтраций логов
    allSearchLog() {
      return this.searchLog
    },
  },
  props: {
    limitCount: {
      type: Number,
      default: 20,
    },
    searchLog: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style scoped>
.v-data-table >>> tr .actionsLogs {
  display: none !important;
}
.v-data-table >>> tr:hover .actionsLogs {
  display: flex !important;
}
</style>
