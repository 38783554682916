<template>
  <v-form v-if="data" :readonly="readonly" ref="form">
    <v-card elevation="1" class="mb-2">
      <v-card-title> Информация по препарату </v-card-title>

      <v-card-subtitle v-if="editMode && needRefresh">
        <ReferenceTip>
          Обновить данные из экспертизы до актуальной версии можно кнопкой
          "обновить" ( <v-icon color="green" small>mdi-sync</v-icon> )
        </ReferenceTip>
      </v-card-subtitle>

      <v-card-text>
        <v-alert v-if="needRefresh" type="warning" dense text>
          Внимание! Информация по экспертизе изменилась, рекомендуется обновить
          данные
          <template #append>
            <v-btn
              v-if="editMode"
              text
              color="green"
              small
              title="Обновить все данные по экспертизе"
              @click="refreshData"
              :disabled="!needRefresh"
              ><v-icon>mdi-sync</v-icon></v-btn
            >
          </template>
        </v-alert>
        <!-- Информация по препарату -->
        <v-timeline dense align-top>
          <!-- МНН -->
          <v-timeline-item small>
            <check-alert
              label="МНН"
              v-model="data.mnn"
              :check-value="initMnn"
              :hide-check="logView"
              @change="doChange(true)"
            />
          </v-timeline-item>
          <!-- Перечень -->
          <v-timeline-item small>
            <check-alert
              label="Перечень"
              :value="listOfList"
              :check-value="initListOfList"
              :custom-check="checkList"
              :hide-check="logView"
              @change="listOfListChange"
              empty-value="-- не определён --"
            />
          </v-timeline-item>
          <!-- Лекарственная форма -->
          <v-timeline-item small>
            <check-alert
              label="Лекарственная форма"
              v-model="data.lForma"
              :check-value="initLf"
              :hide-check="logView"
              @change="doChange(true)"
            />
          </v-timeline-item>
          <!-- Дата регистрации ЛП -->
          <v-timeline-item small>
            <check-alert
              label="Дата регистрации ЛП"
              v-model="data.ruDate"
              :check-value="initRuDate"
              :hide-check="logView"
              @change="doChange(true)"
            />
          </v-timeline-item>
          <!-- Орфанный статус -->
          <v-timeline-item small>
            <check-alert
              label="Орфанный статус"
              v-model="data.isOrphan"
              :check-value="initOrphan"
              :hide-check="logView"
              @change="doChange(true)"
            >
              <template #default="{ value }">
                {{ value ? 'Да' : 'Нет' }}
              </template>
            </check-alert>
          </v-timeline-item>
          <!-- Наличие в КР -->
          <v-timeline-item small>
            <check-alert
              label="Наличие в КР"
              :value="listOfCr"
              :check-value="initListOfCr"
              :custom-check="checkCR"
              :hide-check="logView"
              @change="listOfCrChange"
              empty-value="-- не определён --"
            />
          </v-timeline-item>
          <!-- Показание ЛП (ИМП) -->
          <v-timeline-item small>
            <check-alert
              label="Показание ЛП (ИМП)"
              v-model="data.condition"
              :check-value="initCondition"
              :hide-check="logView"
              pre
              @change="doChange(true)"
            />
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import ReferenceTip from '@/components/ReferenceTip'
import CheckAlert from '@/components/CheckAlert'
import ExpSummaryMixin from '../ExpSummaryMixin'
import { collectCRList } from '@/components/expSummary/summaryFunctions'

const textAlert = {
  plp: 'Информация о препарате изменилась',
}

export default {
  name: 'SummaryInfo',
  components: {
    CheckAlert,
    ReferenceTip,
  },
  mixins: [ExpSummaryMixin],
  data: () => ({
    textAlert,
  }),
  computed: {
    skeletonType: () => Array(7).fill('list-item-two-line').join(','),
    // Проверка на актуальность всех данных
    checkData() {
      return (
        this.checkMnn &&
        this.checkList &&
        this.checkCondition &&
        this.checkLf &&
        this.checkRuDate &&
        this.checkOrphan &&
        this.checkCR
      )
    },
    initMnn() {
      return this.initData?.about?.mnn ?? ''
    },
    checkMnn() {
      return !this.initData || this.initMnn === this.data?.mnn
    },
    initLf() {
      return this.initData?.about?.lForma ?? ''
    },
    checkLf() {
      return !this.initData || this.initLf === this.data?.lForma
    },
    initRuDate() {
      return this.initData?.about?.ruDate ?? ''
    },
    checkRuDate() {
      return !this.initData || this.initRuDate === this.data?.ruDate
    },
    initOrphan() {
      return this.initData?.about?.isOrphan ?? false
    },
    checkOrphan() {
      return !this.initData || this.initOrphan === this.data?.isOrphan
    },
    listOfCr() {
      return this.data?.crNames || ''
    },
    initListOfCr() {
      return (
        this.collectCRList(
          this.initData?.analog?.CR,
          this.initData?.analog?.AL
        ) || ''
      )
    },
    checkCR() {
      return !this.initData || this.initListOfCr === this.listOfCr
    },
    listOfList() {
      return this.makeListOfList(this.data)
    },
    initListOfList() {
      return this.makeListOfList(this.initData?.about)
    },
    checkList() {
      return !this.initData || this.listOfList === this.initListOfList
    },
    initCondition() {
      return this.initData?.about?.condition ?? ''
    },
    checkCondition() {
      return !this.initData || this.initCondition === this.data?.condition
    },
    showAlert() {
      return !this.checkData
    },
  },
  methods: {
    collectCRList,
    makeListOfList({ gnvlp, vzn14 } = {}) {
      const resString = []
      if (gnvlp) resString.push('ЖВНЛП')
      if (vzn14) resString.push('14 ВЗН')
      return resString.join(', ')
    },
    listOfListChange() {
      this.data.gnvlp = this.initData?.about?.gnvlp || false
      this.data.vzn14 = this.initData?.about?.vzn14 || false

      this.doChange(true)
    },
    initUsingCR() {
      this.data.crNames = this.collectCRList(
        this.initData?.analog?.CR,
        this.initData?.analog?.AL
      )
    },
    listOfCrChange() {
      this.initUsingCR()
      this.doChange(true)
    },
  },
}
</script>
