<template>
  <v-row v-if="gvs.length" dense>
    <v-col cols="12" lg="6">
      <v-combobox
        label="ГВС, ФИО"
        :value="internalFio"
        :outlined="!readonly"
        :items="gvs.map(item => item.fio)"
        :loading="loading"
        :search-input.sync="searchNative"
        @input="changeFio"
        @input.native="changeNativeFio"
        @blur="doBlur()"
      >
        <template #item="{ item }">
          <div class="mb-2">
            <span :inner-html.prop="item | highlight(searchNative)" />
            <div>
              <span class="text-caption text--disabled">{{
                getSpec(item)
              }}</span>
            </div>
          </div>
        </template>
      </v-combobox>
    </v-col>
    <v-col cols="12" lg="6">
      <v-text-field
        label="ГВС, специальность"
        :value="internalSpec"
        :outlined="!readonly"
        :loading="loading"
        @input="changeSpec"
        @blur="doBlur()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GvsEditor',
  data: () => ({
    internalFio: '',
    internalSpec: '',
    searchNative: '',
    gvs: [],
    loading: false,
  }),
  props: {
    fio: {
      type: String,
      default: '',
    },
    spec: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getGvs()
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['GET_GVS_DICT', 'SET_ERROR']),
    getSpec(newVal) {
      return this.gvs.find(item => item.fio === newVal)?.post
    },
    init() {
      this.internalFio = this.fio
      this.internalSpec = this.spec
      this.searchNative = this.internalFio
    },
    async getGvs() {
      this.loading = true
      try {
        const gvs = await this.GET_GVS_DICT()
        this.gvs = gvs || []
      } catch (e) {
        console.log(e)
        this.SET_ERROR({
          head: 'Текущие списки ГВС',
          text: 'Ошибка загрузки текущих списков ГВС',
          e,
        })
        throw e
      } finally {
        this.loading = false
      }
    },
    doChange(val1, val2, force = false) {
      this.$emit('change', val1, val2, force)
    },
    getFio(val) {
      return typeof val === 'string' ? val : val?.fio
    },
    changeNativeFio() {
      this.changeFio(null, true)
    },
    changeFio(val, native = false) {
      if (!native) {
        this.internalFio = this.getFio(val)
        this.internalSpec = this.getSpec(val) || this.internalSpec
      }

      this.$emit('update:fio', this.getFioNative(native))

      if (!native) this.changeSpec(this.internalSpec)
      this.doChange(this.getFioNative(native), this.internalSpec, !native)
    },
    getFioNative(native) {
      return native ? this.searchNative : this.internalFio
    },
    changeSpec(val) {
      this.$emit('update:spec', val)
      this.doChange(this.internalFio, val, false)
    },
    doBlur() {
      this.$emit('blur')
    },
  },
}
</script>
