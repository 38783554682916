<script>
import { copyObject, sameObject } from '@/lib/objects'
import { getForScenario } from './keiUtils'

export default {
  data: () => {
    return {
      textAlert: {
        plp: 'Информация о препарате изменилась',
        avb_kei: 'Внимание! Информация в экспертизе изменилась',
        criteria: 'Внимание! Информация по количеству критериев изменилась',
      },
      element: null,
      headers: [
        {
          text: 'Критерий',
          align: 'start',
          sortable: false,
          cellClass: 'text-body-1 font-weight-bold',
          value: 'name',
        },
        {
          text: 'Пункты требований',
          value: 'itemRequirements',
          width: '35%',
          cellClass: 'font-weight-bold',
          sortable: false,
        },
        {
          text: '',
          value: 'data-table-expand',
          filterable: false,
          sortable: false,
          width: '50px',
        },
      ],
    }
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
    },
    initElement: {
      type: Object,
      default: null,
    },
    initLength: {
      type: Number,
    },
    logView: {
      type: Boolean,
    },
  },
  created() {
    if (this.readonly) this.element = this.value
    else this.element = copyObject(this.value)
  },
  watch: {
    value(newElement) {
      if (this.readonly) this.element = this.value
      else this.element = copyObject(newElement)
    },
  },
  computed: {
    //null потому что в expKei и expAvb данное вычисляемое initExp перезаписывается
    initExp() {
      return null
    },
    editMode() {
      return this.value && !this.readonly && !this.logView
    },
  },
  filters: {
    getForScenario,
  },
  methods: {
    checkProperty(property) {
      if (!this.initExp) return true //если длина data больше чем initData

      const check = sameObject(this.element[property], this.initExp[property])
      return !this.changeAlert(!check)
    },
    doChange(force = false) {
      if (this.editMode) {
        this.$emit('change', this.element, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    descriptionError(item) {
      return (
        this.criteriaError(item, 'description') ||
        this.criteriaError(item, 'itemRequirements')
      )
    },
    commentError(item) {
      return this.criteriaError(item, 'comment')
    },
    changeAlert(state) {
      if (this.element?.shortName) {
        this.$emit('changeAlert', this.element.shortName, state)
      }
      return state
    },
  },
}
</script>
