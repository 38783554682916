<template>
  <v-container class="pb-4">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="sideEffect"
          :outlined="!readonly"
          label="Побочный эффект"
          placeholder="Введите название побочного эффекта"
          prepend-icon="mdi-text-box-search-outline"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
        />
      </v-col>

      <v-col cols="12" md="4">
        <v-select
          v-model.number="pointsScore"
          :items="scores"
          :outlined="!readonly"
          label="Оценка в баллах"
          prepend-icon="mdi-book-open-page-variant-outline"
          @input="doChange(true)"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model.number="weightFactor"
          :items="weightScores"
          :outlined="!readonly"
          label="Весовой коэффициент"
          prepend-icon="mdi-book-open-blank-variant"
          @input="doChange(true)"
          @blur="doBlur()"
          hide-details
          type="number"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          readonly
          @input="doChange(true)"
          :value="resultScore"
          label="Итоговая оценка"
          hide-details
          type="number"
        />
      </v-col>
    </v-row>
    <!-- Удалить текущую строку -->
    <v-row v-if="!readonly">
      <v-col class="text-end">
        <btn-with-confirm
          text
          icon-main="mdi-delete-forever-outline"
          title="Удалить препарат"
          @click="deleteAssessments"
        >
          Удалить
        </btn-with-confirm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'

export default {
  name: 'SafetyEditor',
  components: { BtnWithConfirm },
  data: () => ({
    sideEffect: '',
    pointsScore: 0,
    weightFactor: 0,
    idx: '',
    scores: [0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10],
    weightScores: [0, 0.25, 0.5, 1],
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  methods: {
    doChange(force = false) {
      if (!this.readonly) {
        const { sideEffect, pointsScore, weightFactor, idx } = this
        const resultScore = this.resultScore
        const outObj = {
          ...this.value,
          sideEffect,
          pointsScore,
          weightFactor,
          resultScore,
        }
        this.$emit('change', outObj, force)
      }
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    deleteAssessments() {
      this.$emit('delete')
    },
    init() {
      this.sideEffect = this.value?.sideEffect || ''
      this.pointsScore = this.value?.pointsScore || 0
      this.weightFactor = this.value?.weightFactor || 0
    },
  },
  computed: {
    resultScore() {
      const pointsScore = Number(this.pointsScore || 0)
      const weightFactor = Number(this.weightFactor || 0)
      return pointsScore * weightFactor
    },
  },
}
</script>

<style scoped></style>
