<script>
import { EX_EXPERTISE, SUB_EX_ABOUT } from '@/store/const/expertise'
import { mapActions } from 'vuex'
import { EX_ANALOG_DEFAULT } from './const'

function compare(key, obj1, obj2) {
  return obj1?.[key] === obj2?.[key]
}

export default {
  props: {
    logView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    createError: '',
    about: {
      loading: false,
      error: '',
      data: null,
    },
    data: null,
  }),
  computed: {
    editMode() {
      return this.data && !this.readonly && !this.logView
    },
    initAth() {
      return this.about.data?.ath ?? ''
    },
    checkAth() {
      return (
        !this.about.data || compare('ath', this.about.data, this.data.AL[0])
      )
    },
    initMnn() {
      return this.about.data?.mnn ?? ''
    },
    checkMnn() {
      return (
        !this.about.data || compare('mnn', this.about.data, this.data.AL[0])
      )
    },
    initLForma() {
      return this.about.data?.lForma ?? ''
    },
    checkLForma() {
      return (
        !this.about.data || compare('lForma', this.about.data, this.data.AL[0])
      )
    },
    initCondition() {
      return this.about.data?.condition ?? ''
    },
    checkCondition() {
      return (
        !this.about.data ||
        compare('condition', this.about.data, this.data.AL[0])
      )
    },
  },
  async created() {
    try {
      this.data = this.value

      if (!this.logView) {
        await this.loadAbout()

        if (!this.data && this.onCreate) {
          this.data = this.onCreate(this.initNewData())
        }
      }
    } catch (error) {
      this.createError = this.about.data
        ? 'Ошибка создания экспертизы'
        : 'Сначала начните экспертизу "Информация о препарате"'
      this.data = null
    }
  },
  methods: {
    ...mapActions(['GET_MY_LAST_EXPERTISE_DATA']),
    async loadAbout() {
      this.about = { loading: true, data: null, error: '' }
      try {
        this.about.data = await this.GET_MY_LAST_EXPERTISE_DATA({
          ex_type: EX_EXPERTISE,
          ex_type_sub: SUB_EX_ABOUT,
        })
      } catch (err) {
        this.about.error = err.message + (err.error ? ' ' + err.error : '')
        throw err
      } finally {
        this.about.loading = false
      }
    },
    initNewData() {
      const d = EX_ANALOG_DEFAULT()
      let { mnn, ath, lForma, condition, ruQuantity, ruEndDate, ruNumber } =
        this.about.data

      d.AL[0].ath = ath
      d.AL[0].mnn = mnn
      d.AL[0].lForma = lForma
      d.AL[0].condition = condition
      if (parseInt(ruQuantity) === 1) {
        d.AL[0].AD[0].ru = ruNumber
        d.AL[0].AD[0].ruEndDate = ruEndDate
      }
      return d
    },
    doBlur() {
      this.$emit('blur')
    },
    doChange(force = false) {
      this.$emit('change', this.data, force)
    },
  },
}
</script>
