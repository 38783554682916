<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <v-icon small class="red--text">mdi-exclamation-thick</v-icon>
      <v-icon small class="red--text">mdi-exclamation-thick</v-icon>
      <v-icon small class="red--text">mdi-exclamation-thick</v-icon
      ><span class="font-weight-bold deep-orange--text"> {{ message }} </span
      ><v-icon small class="red--text">mdi-exclamation-thick</v-icon>
      <v-icon small class="red--text">mdi-exclamation-thick</v-icon>
      <v-icon small class="red--text">mdi-exclamation-thick</v-icon>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
export default {
  name: 'EmptyLog',
  components: { DefaultLog },
}
</script>
