<template>
  <default-log v-bind="$attrs">
    <template #default="{ message, code }">
      <span
        :class="
          code === 'USER_DELETED'
            ? 'red--text text-decoration-underline'
            : 'green--text text-decoration-underline'
        "
      >
        {{ message }}
      </span>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
export default {
  name: 'UserLog',
  inheritAttrs: false,
  components: { DefaultLog },
}
</script>
