<template>
  <default-log v-bind="$attrs">
    <template #default="{ message }">
      <span
        class="dark-purple--text text-decoration-underline font-weight-bold font-italic"
      >
        {{ message.toUpperCase() }}
      </span>
    </template>
  </default-log>
</template>

<script>
import DefaultLog from '@/components/viewLogs/DefaultLog'
export default {
  name: 'PlpSentLog',
  inheritAttrs: false,
  components: { DefaultLog },
}
</script>
