import { copyObject, copyObjectBy, sameObject } from '@/lib/objects'
import { DEFAULT_AVB } from '@/components/expSummary/summaryAdditional/addConst'

export function createSummaryAvbCopy(expAvb, initAvb) {
  if (!initAvb) return copyObject(expAvb)
  const avb = []
  initAvb?.ka?.forEach(item => {
    avb.push(
      avbSummaryCopy(
        expAvb?.find(d => d.shortName === item.shortName),
        item
      )
    )
  })
  return avb
}

export function avbSummaryCopy(avb, initAvb) {
  const resAvb = {
    ...copyObjectBy(avb, DEFAULT_AVB()),
  }
  resAvb.shortName = initAvb.shortName
  resAvb.qLevels = initAvb.qLevels
  resAvb.condition = initAvb.condition
  resAvb.epidemiology = copyObject(initAvb.resume?.epidemiology)
  resAvb.currentState = copyObject(initAvb.resume?.currentState)
  resAvb.expectedState = copyObject(initAvb.resume?.expectedState)
  resAvb.results = copyObject(initAvb.resume?.results)

  return resAvb
}

export function checkSummaryAvbLength(expAvb, initAvb) {
  return expAvb?.length === initAvb?.ka?.length
}

/***
 *Проверка всей экспертизы КЭИ на актуальность данных
 * @param expAvb массив КЭИ с экспертизы сводного заключения
 * @param initAvb массив КЭИ с экспретизы КЭИ
 * @returns Boolean
 */
export function checkSummaryAvb(expAvb, initAvb) {
  if (!checkSummaryAvbLength(expAvb, initAvb)) {
    return false
  }
  for (let i = 0; i < expAvb.length; i++) {
    if (!checkSummaryAvbCopy(expAvb[i], initAvb?.ka[i])) {
      return false
    }
  }
  return true
}

function checkSummaryAvbCopy(avb, initAvb) {
  const resAvb = avbSummaryCopy(null, initAvb)
  for (const key of [
    'shortName',
    'qLevels',
    'condition',
    'epidemiology',
    'currentState',
    'expectedState',
    'results',
  ]) {
    if (!sameObject(resAvb[key], avb[key])) return false
  }

  return true
}
