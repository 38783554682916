<template>
  <div class="my-2">
    <v-data-table
      :items="getFilterItems"
      :headers="getHeaders"
      :search="searchLF"
      :custom-filter="customFilter"
      hide-default-footer
      disable-pagination
      item-key="id"
      no-data-text="Нет данных"
      show-expand
      disable-sort
      no-results-text="Лек. форма не найдена, проверьте правильность написания"
      @item-expanded="expandedEvent"
      v-if="!error_message"
    >
      <template #[`item.index`]="{ index }">
        <span>{{ index + 1 }}.</span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <IndicationsTable
            :medication="medication"
            :item="item"
            :select-mode="selectMode"
            @updateIndications="updateIndications"
            :readonly="readonly"
            :editMode="editMode"
          />
        </td>
      </template>
      <template v-if="!selectMode" #[`item.lf`]="{ value }">
        <span :inner-html.prop="value | highlight(searchLF)" />
      </template>
      <template v-if="editMode" v-slot:footer>
        <div class="d-flex justify-end mt-6 mb-6 mr-2">
          <v-btn
            v-if="!expandedOpen"
            color="accent"
            fab
            small
            @click="openForAdd"
            title="Добавить лекарственную форму"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-if="editMode" v-slot:[`item.actions`]="{ item }">
        <div class="actionsGNVLPInd">
          <v-btn
            @click="openForEdit(item)"
            fab
            small
            depressed
            title="Редактировать"
            color="transparent"
            class="mr-2"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <btn-with-confirm
            icon
            icon-main="mdi-delete-forever-outline"
            title="Удалить лекарственную форму"
            @click="showDeleteConfirmation(item)"
            :disabled="deleteLfInProgress"
          >
          </btn-with-confirm>
        </div>
      </template>
    </v-data-table>
    <v-alert
      type="error"
      v-if="error_message"
      class="mt-2"
      outlined
      dismissible
      v-model="error_message"
    >
      <div>Произошла ошибка при работе со справочниками</div>
      <div>
        {{ error_message }}
      </div>
    </v-alert>
    <lfs-dialog
      v-if="editMode && showForm"
      :lfs="lfs"
      :medication="medication"
      :is-open-for-add="isOpenForAdd"
      @close-form="closeForm"
    />
    <ExpAgencyDeleteDialog
      v-if="editMode"
      :dialog="showDeleteDialog"
      :name="getName"
      lb="Лекарственная форма:"
      title="Удалить лекарственную форму"
      label="Подтвердить удаление лекарственной формы"
      @onClose="cancelDelete"
      @onAction="confirmDelete"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import IndicationsTable from '@/components/dict/indications/IndicationsTable'
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import ExpAgencyDeleteDialog from '@/components/dialogs/ExpAgencyDeleteDialog'
import LfsDialog from '@/components/dict/indications/allForms/LfsDialog'
import { searchByWords } from '../../../lib/strUtils'
export default {
  name: 'IndicationsLecformsTable',
  components: {
    LfsDialog,
    ExpAgencyDeleteDialog,
    BtnWithConfirm,
    IndicationsTable,
  },
  props: {
    // весь объект препарата, АТХ, МНН, лекформы lfs, итп
    medication: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    selectMode: {
      type: Boolean,
      default: false,
    },
    searchLF: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    medicationHeaders: [
      { text: '№', value: 'index', width: '20px', cellClass: 'text--disabled' },
      { text: 'Лекарственная форма', value: 'lf' },
      { text: 'Действия', value: 'actions', width: '150px', sortable: false },
    ],
    error_message: '',
    expanded: [],
    expandedOpen: false, // флажок - если открыли экспанд показаний, скрыть кнопку добавить лекформу и наоборот
    deleteLfInProgress: false,
    showForm: false,
    lfs: null,
    isOpenForAdd: false,
    showDeleteDialog: false,
    item: null, // объект лекформы, которую будем удалять
  }),
  methods: {
    ...mapActions(['DELETE_MEDICINE_FROM_GNVLP']),
    updateIndications(res) {
      this.$emit('updateIndications', res)
    },
    openForAdd() {
      this.isOpenForAdd = true
      this.showForm = true
    },
    openForEdit(item) {
      this.isOpenForAdd = false
      this.lfs = item
      this.showForm = true
    },
    closeForm() {
      this.lfs = null
      this.showForm = false
    },
    cancelDelete() {
      this.showDeleteDialog = false
      this.item = null
    },
    showDeleteConfirmation(item) {
      this.showDeleteDialog = true
      this.item = item
    },
    async confirmDelete() {
      this.deleteLfInProgress = true
      this.error_message = ''
      try {
        const lf_id = this.item.id
        const med_id = this.medication.id
        await this.DELETE_MEDICINE_FROM_GNVLP({ med_id, lf_id })
      } catch (err) {
        this.error_message = err
      } finally {
        this.item = null
        this.showDeleteDialog = false
        this.deleteLfInProgress = false
      }
    },
    // флажок - если открыли экспанд показаний, скрыть кнопку добавить лекформу и наоборот
    expandedEvent({ value }) {
      this.expandedOpen = value
    },
    customFilter(value, search) {
      if (!search) return true
      // сравниваем без учёта пунктуации и регистронезависимо
      return searchByWords(value, search)
    },
  },
  computed: {
    getName() {
      return this.item?.lf ?? ''
    },
    getHeaders() {
      return this.editMode
        ? this.medicationHeaders
        : this.medicationHeaders.filter(({ value }) => value != 'actions')
    },
    getFilterItems() {
      if (this.medication?.filterMKB) {
        return this.medication?.lfs?.reduce((out, item) => {
          const lf = this.medication?.filterMKB.find(({ id }) => item.id === id)
          return lf ? [...out, { ...item, filterMKB: lf.indications }] : out
        }, [])
      } else {
        return this.medication.lfs
      }
    },
  },
}
</script>
<style scoped>
.v-data-table >>> tr .actionsGNVLPInd {
  display: none !important;
}
.v-data-table >>> tr:hover .actionsGNVLPInd {
  display: flex !important;
}
</style>
