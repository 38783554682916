<template>
  <default-log
    v-bind="$attrs"
    :diff-keys="diffKeys"
    :diff-keys-exclude="excludeKeys"
  >
    <template #default="{ message }">
      <span class="text-decoration-underline font-weight-bold">
        {{ message.toUpperCase() }}
      </span>
    </template>
    <template #[`diff.object`]="{ value: obj }">
      <span class="primary--text">
        {{ expTypeById(obj.expertise_type_id) }}_{{ obj.section + 1 }}
      </span>
      - {{ userById(obj.user_id) }}
    </template>

    <!-- подписант -->
    <template #[`diff.user_signer_id`]="{ value, textClass }">
      <span v-if="value" :class="textClass">{{ userById(value) }}</span>
    </template>

    <!-- юзер -->
    <template #[`diff.user_id`]="{ value, textClass }">
      <span v-if="value" :class="textClass">{{ userById(value) }}</span>
    </template>
  </default-log>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DefaultLog from '../DefaultLog'
import { filteredDatetime, filteredDate } from '../logsFunctions'

export default {
  name: 'PlpTaskUpdLog',
  components: { DefaultLog },
  inheritAttrs: false,
  data: () => ({
    diffKeys: {
      user_signer_id: 'подписант',
      'tasks[$index]': ' ',
      'tasks[$index].expertise_type_id': 'Задача ($index)',
      'tasks[$index].user_id': 'Задача ($index) эксперт',
    },
    excludeKeys: ['section', 'is_in_work', 'is_completed', 'time_create'],
  }),
  filters: {
    filteredDate,
    filteredDatetime,
  },
  computed: {
    ...mapGetters(['usersList', 'storeExpTypes']),
  },
  mounted() {
    this.get_users()
  },
  methods: {
    ...mapActions(['get_users']),
    // получаем пользователя по ID
    userById(userId) {
      const user = this.usersList?.find(({ id }) => id === userId)
      return user?.fio ?? `Пользователь id: ${userId} не найден`
    },
    // получаем тип экспертизы по id
    expTypeById(expTypeId) {
      const expType = this.storeExpTypes?.find(({ id }) => id === expTypeId)
      return expType?.setup_name ?? `Это что за покемон? O_o ${expTypeId}`
    },
  },
}
</script>
