<template>
  <v-card flat>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Характеристика исследований</th>
            <th class="text-left">Уровень доказательств результатов</th>
            <th class="text-left">Баллы</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in TRIALS" :key="item.id">
            <td class="font-weight-bold">{{ item.researchCharacter }}</td>
            <td>{{ item.levelEvidence }}</td>
            <td>{{ item.scores }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
const TRIALS = [
  {
    researchCharacter: 'Систематические обзоры и мета-анализы',
    levelEvidence: 'I',
    scores: 10,
  },
  {
    researchCharacter: 'Рандомизированные слепые клинические исследования',
    levelEvidence: 'II',
    scores: 9,
  },
  {
    researchCharacter: 'Рандомизированные открытые клинические исследования',
    levelEvidence: 'III',
    scores: 8,
  },
  {
    researchCharacter:
      'Сетевые мета-анализы (в т.ч. Непрямые сравннеия и смешанные сравнения) ',
    levelEvidence: 'IV',
    scores: 7,
  },
  {
    researchCharacter: 'Когортные исследования',
    levelEvidence: 'V',
    scores: 6,
  },
  {
    researchCharacter: 'Исследования "случай-контроль"',
    levelEvidence: 'VI',
    scores: 5,
  },
  {
    researchCharacter: 'Описание случаев и серии случаев',
    levelEvidence: 'VII',
    scores: 4,
  },
  {
    researchCharacter: 'Мнение экпертов',
    levelEvidence: 'VIII',
    scores: 3,
  },
]
export default {
  name: 'ClinicalTrials',
  data: () => ({
    TRIALS,
  }),
}
</script>
