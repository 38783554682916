<template>
  <v-form v-if="data" :readonly="readonly" ref="form">
    <v-row dense>
      <v-col cols="6">
        <v-checkbox
          label="Перечень ЖНВЛП"
          v-model="data.gnvlp"
          hide-details
          class="ma-0 pa-0 mb-2"
          @click="doChange(true)"
        />
      </v-col>

      <v-col cols="6">
        <v-checkbox
          label="Орфанный статус на ГРЛС"
          v-model="data.isOrphan"
          hide-details
          class="ma-0 pa-0 mb-2"
          @click="doChange(true)"
        />
      </v-col>

      <v-col cols="6">
        <v-checkbox
          label="Перечень 14ВЗН"
          v-model="data.vzn14"
          hide-details
          class="ma-0 pa-0 mb-2"
          @click="doChange(true)"
        />
      </v-col>

      <v-col cols="6">
        <v-checkbox
          label="ЛП предназначен для лечения заболеваний, включенных в перечень «17 нозологий»"
          v-model="data.isOrphanByIndication"
          hide-details
          class="ma-0 pa-0 mb-2"
          @click="doChange(true)"
        />
      </v-col>

      <v-col cols="12">
        <v-select
          label="Тип наименования"
          v-model="data.nameType"
          :items="nameTypes"
          @change="doChange(true)"
          hide-details
          class="my-4"
        />
        <!-- МНН -->
        <v-text-field
          :label="labelMNN | capitalize"
          v-model.trim="data.mnn"
          @input="doChange()"
          @blur="doBlur()"
          hide-details
          :error="!data.mnn"
          class="mb-4"
        />
        <AtcTextEditor
          title="Код анатомо-терапевтическо-химической классификации лекарственного препарата"
          v-model.trim="data.ath"
          @input="doChange()"
          @blur="doBlur()"
          :maxlength="maxLengthAth"
        />
        <v-card class="my-2">
          <v-card-title class="py-1">Лекарственная Форма</v-card-title>
          <v-card-text>
            <v-textarea
              auto-grow
              v-model.trim="data.lForma"
              @input="doChange()"
              @blur="doBlur()"
              rows="4"
              class="pb-3"
          /></v-card-text>
        </v-card>
        <v-card class="my-2">
          <v-card-title class="py-1"
            >Государственная регистрация лекарственного препарата в Российской
            Федерации</v-card-title
          ><v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  label="Номер РУ"
                  v-model.trim="data.ruNumber"
                  @input="doChange()"
                  @blur="doBlur()"
                  hide-details
                  class="my-4" /></v-col
              ><v-col>
                <v-text-field
                  label="Дата окончания РУ"
                  v-model.trim="data.ruEndDate"
                  @input="doChange()"
                  @blur="doBlur()"
                  hide-details
                  class="my-4"
                />
                <!--<v-text-field
                  label="Дата РУ"
                  v-model.trim="data.ruDate"
                  @input="doChange()"
                  @blur="doBlur()"
                  hide-details
                  class="my-4" />--></v-col
              ><!--<v-col>
                <v-text-field
                  label="Дата подтверждения РУ"
                  v-model.trim="data.ruDateConfirm"
                  @input="doChange()"
                  @blur="doBlur()"
                  hide-details
                  class="my-4" />
              </v-col>-->
            </v-row>
            <!--<v-textarea
              label="Регистрационное удостоверение"
              v-model.trim="data.ruFiles.text"
              auto-grow
              @input="doChange()"
              @blur="doBlur()"
              rows="5"
            />-->
            <!-- Файлы из plpData - ruFiles -->
            <PlpFilesList v-if="!logView" plpKey="ruFiles" open />
          </v-card-text>
        </v-card>
        <v-card class="my-2">
          <v-card-title class="py-1"
            >Сведения о воспроизведённых лекарственных препаратах</v-card-title
          ><v-card-text>
            <v-row no-gutters>
              <v-col cols="6">
                <v-switch
                  v-model="data.reproducedLP"
                  label="Воспроизведенные ЛП"
                  color="success"
                  @change="
                    reproducedLPTextChange(data.reproducedLPtext)
                    doBlur()
                  "
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  :disabled="!data.reproducedLP"
                  rows="5"
                  :value="reproducedLPTextInit()"
                  @input="val => reproducedLPTextChange(val)"
                  @blur="doBlur()"
                  class="my-4"
                  hide-details
                />
              </v-col>
              <v-col md="6">
                <v-text-field
                  :readonly="!data.reproducedLP"
                  type="number"
                  label="Количество РУ"
                  v-model.trim="data.ruQuantity"
                  hide-details
                  @input="doChange()"
                  @blur="doBlur()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="my-2">
          <v-card-title class="py-1">Показание препарата</v-card-title>
          <v-card-text>
            <v-textarea
              auto-grow
              v-model.trim="data.condition"
              @input="doChange()"
              @blur="doBlur()"
              rows="5"
              class="pb-3"
          /></v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title class="py-1">Комментарий</v-card-title>
          <v-card-text>
            <v-textarea
              auto-grow
              v-model.trim="data.comment"
              @input="doChange()"
              @blur="doBlur()"
              hide-details
              rows="5"
              class="pb-3"
          /></v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { copyObject, copyObjectBy } from '@/lib/objects'
import { capitalize } from '@/lib/strUtils'
import { EX_ABOUT_TEMPLATE, MAX_ATH_LENGTH } from './const'
import { MNN_PARSE, REPRODUCED_DEFAULT_TEXT } from '@/store/const/lpParse'
import PlpFilesList from '@/components/plp/PlpFilesList'
import AtcTextEditor from '@/components/editors/AtcTextEditor'
import { mapGetters } from 'vuex'

export default {
  name: 'Exp-About',
  components: { PlpFilesList, AtcTextEditor },
  props: {
    value: {
      type: Object,
      // required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    logView: {
      type: Boolean,
      default: false,
    },
    onCreate: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    nameTypes: [],
    maxLengthAth: MAX_ATH_LENGTH,
    data: null,
  }),
  watch: {
    value: 'reInit',
    readonly: 'reInit',
  },
  computed: {
    ...mapGetters(['storedPlpData']),
    // блокировка для сохранения
    isNotValid() {
      if (!this.data) return false
      return !this.data.mnn || !this.data.ath
    },
    labelMNN() {
      return this.data?.nameType ?? 'МНН'
    },
  },
  filters: {
    capitalize,
  },
  async created() {
    this.nameTypes = []
    for (let key in MNN_PARSE) {
      this.nameTypes.push(MNN_PARSE[key].name)
    }

    if (this.readonly) this.data = this.value
    else this.data = copyObject(this.value)

    if (!this.data && this.onCreate) {
      console.log('need create expertise - about')
      this.data = await this.onCreate(this.initNewData())
    }
  },
  mounted() {
    this.reInit()
  },
  methods: {
    reInit() {
      if (this.readonly) this.data = this.value
      if (!this.readonly) this.$refs.form.validate()
    },
    // Генерируем новые данные для экспертизы в первый раз
    initNewData() {
      let data =
        copyObjectBy(EX_ABOUT_TEMPLATE, this.storedPlpData) ??
        copyObject(EX_ABOUT_TEMPLATE)

      data.ath = (data.ath ?? '').substring(0, MAX_ATH_LENGTH)
      data.ruEndDate = ''
      data.ruQuantity = data.reproducedLP ? '' : 1
      return data
    },
    // данные поменялись кидаем наверх
    doChange(force = false) {
      if (!this.readonly)
        this.$emit('change', this.data, force, this.isNotValid)
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    reproducedLPTextInit() {
      return this.data?.reproducedLP
        ? this.data.reproducedLPtext
        : REPRODUCED_DEFAULT_TEXT
    },
    reproducedLPTextChange(value) {
      this.data.reproducedLPtext = this.data.reproducedLP
        ? value
        : REPRODUCED_DEFAULT_TEXT
      this.data.ruQuantity = this.data.reproducedLP ? '' : 1
      this.doChange()
    },
  },
}
</script>
