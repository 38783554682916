<template>
  <div>
    <v-card elevation="0">
      <v-card-text class="px-0">
        <!-- Форма карточки аналога -->
        <v-form :readonly="readonly">
          <v-row>
            <v-col md="3">
              <AtcTextEditor
                title="Код анатомо-терапевтическо-химической классификации лекарственного препарата"
                v-model.trim="data.ath"
                @input="doInput()"
                @blur="doBlur()"
                :maxlength="maxLengthAth"
              />
            </v-col>
            <v-col md="9">
              <ComboboxMNN
                :clearable="!readonly"
                :value="data"
                :items="dictAtc5Mnn"
                @updateMNN="val => updateMNN(val)"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                label="ЛФ"
                title="Лекарственная форма препарата"
                v-model.trim="data.lForma"
                dense
                :clearable="!readonly"
                @input="doInput()"
                @blur="doBlur()"
                :append-outer-icon="!logView ? 'mdi-store-search-outline' : ''"
                @click:append-outer="getLF()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Показание"
                title="Показание препарата"
                v-model.trim="data.condition"
                dense
                auto-grow
                :clearable="!readonly"
                :rows="3"
                @input="doInput()"
                @click:append-outer="getIndications()"
                :append-outer-icon="!logView ? 'mdi-store-search' : ''"
                @blur="doBlur()"
              ></v-textarea>
            </v-col>
          </v-row>

          <div class="text-subtitle-2 my-4">
            Встречается в клинических рекомендациях:
          </div>
          <template v-for="(item, index) in data.CR">
            <div :key="index">
              <v-switch
                v-model="item.use"
                :label="dataCR[index].shortName"
                :hint="dataCR[index].name"
                persistent-hint
                color="success"
                inset
                @change="doChange(true)"
              />
              <div v-if="item.use" class="mt-2">
                <v-textarea
                  v-model.trim="item.description"
                  outlined
                  auto-grow
                  :clearable="!readonly"
                  hide-details
                  @input="doInput()"
                  @blur="doBlur()"
                />
              </div>
            </div>
          </template>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions class="px-0">
        <v-row no-gutters dense align="center" justify="center">
          <v-col md="6" class="text-left">
            <v-checkbox
              label="Использовать в качестве аналога"
              v-model="data.anal"
              hide-details
              dense
              :disabled="readonly"
              @change="doAnalog()"
            />
          </v-col>
          <v-col md="3" class="text-center">
            <v-btn
              v-if="!logView"
              text
              color="success"
              @click="copyItem"
              title="Скопировать аналог"
              :disabled="readonly"
              >Копировать</v-btn
            >
          </v-col>
          <v-col md="3" class="text-right">
            <!-- Кнопка удаления аналога -->
            <v-btn
              v-if="!logView"
              text
              elevation="0"
              color="warning"
              @click="deleteDialog = !deleteDialog"
              title="Удаление аналога"
              :disabled="readonly"
              >Удалить</v-btn
            >
            <AnalogListItemDeleteDialog
              v-if="!logView"
              title="Удаление аналога"
              :name="`${data.ath} : ${data.mnn}`"
              :dialog="deleteDialog"
              @onClose="deleteDialog = !deleteDialog"
              @onAction="removeItem"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <AnalogLFList
      v-if="show"
      :value="data"
      :readonly="readonly"
      @closeDialog="closeDialog"
      @updateLF="updateLF"
    />

    <v-dialog
      v-if="showIndication"
      v-model="showIndication"
      width="90%"
      persistent
    >
      <v-card>
        <v-card-title>
          <span>
            <span class="text--secondary">Выбор показаний: </span>
            <span class="text--primary">{{ data.mnn }}</span>
          </span>
        </v-card-title>
        <v-card-subtitle
          ><span>{{ data.lForma }}</span>
        </v-card-subtitle>
        <v-card-text>
          <MedicineIndicationsTable
            :medication="medication"
            select-mode
            :readonly="readonly"
            @updateIndications="updateIndications"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="closeIndicationsDialog" class="mr-3">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ATH_NAME_LENGTH } from './const'
import AtcTextEditor from '@/components/editors/AtcTextEditor'
import AnalogListItemDeleteDialog from '@/components/dialogs/AnalogListItemDeleteDialog'
import ComboboxMNN from '@/components/expAnalogs/ComboboxMNN'
import AnalogLFList from './AnalogLFList'
import { UNLIMITED_TEXT } from './const'
import { mapActions, mapGetters } from 'vuex'
import MedicineIndicationsTable from '@/components/dict/indications/MedicineIndicationsTable'

const maxAth = 5

export default {
  name: 'AL-Card',
  components: {
    MedicineIndicationsTable,
    AtcTextEditor,
    AnalogListItemDeleteDialog,
    ComboboxMNN,
    AnalogLFList,
  },
  props: {
    value: {
      type: Object,
    },
    cr: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    logView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    maxLengthAth: maxAth,
    data: null,

    dataCR: null,
    deleteDialog: false,
    searchFilter: null,
    focused: false,
    show: false,

    showIndication: false,
    showing: true,
    medication: null,
    withMKB10: true,
  }),
  async created() {
    this.data = this.value
    this.dataCR = this.cr
    this.withMKB10 = JSON.parse(localStorage.getItem('withMKB10')) ?? true
    try {
      await this.GET_INDICATIONS_DICT()
    } finally {
      this.loading = false
    }
  },
  watch: {
    value(val) {
      this.data = val
    },
    cr(val) {
      this.dataCR = val
    },
  },
  methods: {
    ...mapActions(['GET_INDICATIONS_DICT']),
    updateLF(lf) {
      this.data.lForma = lf.innInternal ?? ''
      // сразу добавим данные по аналогу если ещё не было если нет ручной блокировки
      const ad = this.data.AD[0]
      if (!ad.manual) {
        // алгоритм и данные
        ad.algVer = lf.algVer
        ad.dictData = lf.dictData
        // обращение и ГЗ
        ad.obr = lf.obr
        ad.govPack = lf.govPack
        ad.govPrice = lf.govPrice
        // кол-во РУ и дата окончания РУ
        ad.ru = lf.regNr
        ad.ruEndDate = lf.regDateFin || UNLIMITED_TEXT
      }
      this.doChange()
      this.closeDialog()
    },
    updateIndications(condition) {
      this.data.condition = condition
      this.doChange()
      this.closeIndicationsDialog()
    },
    getLF() {
      this.openDialog()
    },
    getIndications() {
      this.medication = {
        mnn: this.data.mnn,
        lf: this.data.lForma,
      }
      this.openIndicationDialog()
    },
    closeIndicationsDialog() {
      this.showIndication = false
    },
    openIndicationDialog() {
      this.showIndication = true
    },
    closeDialog() {
      this.show = false
    },
    openDialog() {
      this.show = true
    },
    updateMNN(data = '') {
      this.data.mnn = data?.mnn ?? data
      this.data.ath = data?.ath ?? ''
      this.doChange()
    },
    doAnalog() {
      this.$emit('change')
    },
    doChange() {
      this.$emit('change')
    },
    doInput() {
      this.$emit('input')
    },
    doBlur() {
      this.$emit('blur')
    },
    copyItem() {
      this.$emit('copyALItem')
    },
    removeItem() {
      this.$emit('deleteALItem')
      this.deleteDialog = false
    },
  },
  computed: {
    ...mapGetters(['dictIndications', 'dictAtc5Mnn']),
    athNameLength() {
      return ATH_NAME_LENGTH
    },
  },
}
</script>

<style scoped>
.v-card-footer {
  background: linear-gradient(to bottom, rgb(255, 255, 255) 40%, #dfdfdf 100%);
}
</style>
