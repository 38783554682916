<template>
  <v-container class="pa-5">
    <ScoreRow
      :prop_1="scoreOwnKi"
      :prop_2="scoreKi"
      :edit-mode="editMode"
      label="Оценка КИ"
    />
    <ScoreRow
      :prop_1="scoreOwnSafety"
      :prop_2="scoreSafety"
      :edit-mode="editMode"
      label="Оценка безопасности"
    />
    <ScoreRow
      :prop_1="scoreOwnSum"
      :prop_2="scoreSum"
      :edit-mode="editMode"
      label="Суммарные баллы"
    />
  </v-container>
</template>

<script>
import ScoreRow from '@/components/expSummary/summaryScore/comp/scores/ScoreRow'
import { copyObject } from '@/lib/objects'
export default {
  name: 'compareScores',
  components: { ScoreRow },
  data: () => ({
    assessment: null,
  }),
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.init()
  },
  watch: {
    value: 'init',
  },
  methods: {
    init() {
      this.assessment = copyObject(this.value)
    },
  },
  computed: {
    //Оценка КИ
    scoreOwnKi() {
      return this.assessment?.qualityAssessment?.averageValue || 0
    },
    scoreKi() {
      return this.assessment?.qualityAssessment?.scoreEO || 0
    },
    //Оценка безопасности
    scoreOwnSafety() {
      return this.assessment?.safetyAssessment?.averageValue || 0
    },
    scoreSafety() {
      return this.assessment?.safetyAssessment?.scoreEO || 0
    },
    //Оценка экономических последствий
    // scoreOwnEconomic() {
    //   return this.assessment?.economicAssessment?.maxValue || 0
    // },
    // scoreEconomic() {
    //   return this.assessment?.economicAssessment?.scoreEO || 0
    // },
    //Суммарные баллы (Баллы подтягиваются с учетом оценки эффективности, стоимости курса и дополнительной терапевтической ценности)
    scoreOwnSum() {
      return (
        Number(this.scoreOwnKi) +
        Number(this.scoreOwnSafety) +
        Number(this.scoreOwnEconomic) +
        this.joinScore
      )
    },
    scoreSum() {
      return Number(this.scoreKi) + Number(this.scoreSafety) + this.joinScore
    },
    joinScore() {
      return (
        Number(this.assessment?.levelQuality?.scoreLevel || 0) +
        Number(this.assessment?.levelPrice?.scorePrice || 0)
      )
    },
  },
}
</script>
