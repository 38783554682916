<template>
  <div v-bind="$attrs">
    <div class="text-caption d-flex flex-wrap">
      <strong v-if="label" class="mr-3">{{ label }}:</strong>
      <div
        v-if="!isSame && !hideCheck"
        :title="hideCheckValue ? `Новое значение: ${checkValue}` : ''"
        :class="{ ['text--disabled']: isDisabled }"
      >
        <v-icon
          small
          :color="color"
          left
          style="top: -1px"
          :class="{ ['text--disabled']: isDisabled }"
          >{{ icon }}</v-icon
        >
        <span
          :style="
            !isDisabled && isRGBColor ? `color: ${color} !important;` : ''
          "
          :class="{ [`${color}--text`]: !isDisabled && !isRGBColor }"
          >Внимание значение изменилось</span
        >
      </div>
    </div>
    <v-progress-linear v-if="loading" color="secondary" indeterminate rounded />
    <div class="d-flex flex-column flex-sm-row">
      <div class="d-flex flex-column flex-md-row flex-grow-1">
        <!-- Значение -->
        <div>
          <slot :value="value" :original="true"
            ><span v-if="value" :class="pre ? 'text-pre-wrap' : null">{{
              value
            }}</span
            ><span v-else class="text--disabled font-weight-light">{{
              emptyValue
            }}</span></slot
          >
        </div>
        <!-- Стрелка -->
        <div
          v-if="showChange"
          class="mx-md-3 my-2 my-md-0 align-self-start align-self-md-center"
        >
          <v-icon
            small
            :color="color"
            :class="{
              ['text--disabled']: isDisabled,
              ['shift-top-3']: !isDisplaySmall,
            }"
            >{{
              isDisplaySmall
                ? 'mdi-arrow-down-bold-outline'
                : 'mdi-arrow-right-bold-outline'
            }}</v-icon
          >
        </div>
        <!-- Новое значение -->
        <div
          v-if="showChange"
          class="font-weight-medium"
          :class="{ ['text--disabled']: isDisabled }"
        >
          <slot :value="checkValue" :original="false"
            ><span v-if="checkValue" :class="pre ? 'text-pre-wrap' : null">{{
              checkValue
            }}</span
            ><span v-else class="text--disabled font-weight-light">{{
              emptyValue
            }}</span></slot
          >
        </div>
      </div>
      <!-- Кнопка -->
      <div v-if="showChangeBtn" class="ml-sm-3 d-flex flex-column flex-sm-row">
        <v-divider :vertical="!isDisplayUltraSmall" />
        <v-btn
          icon
          small
          title="Применить изменение значения"
          class="shift-top-2 ml-sm-1 align-self-end align-self-sm-start"
          @click="change"
          :disabled="isDisabled"
        >
          <v-icon small :color="colorSync">{{ iconSync }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Validatable from 'vuetify/lib/mixins/validatable'

function sameObject(obj1, obj2) {
  if (typeof obj1 === 'object' && typeof obj2 === 'object')
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  return obj1 === obj2
}

export default {
  name: 'CheckAlert',
  inheritAttrs: false,
  data: () => ({}),
  mixins: [Validatable],
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    // Что пришло
    value: {},
    // С чем сравнить
    checkValue: {},
    emptyValue: {
      type: String,
      default: '( пусто )',
    },
    icon: {
      type: String,
      default: 'mdi-alert-circle-outline',
    },
    customCheck: {
      type: Boolean || null,
      default: null,
    },
    hideCheck: {
      type: Boolean,
      default: false,
    },
    hideCheckValue: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'warning',
    },
    iconSync: {
      type: String,
      default: 'mdi-sync-alert',
    },
    colorSync: {
      type: String,
      default: 'green',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pre: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSame() {
      return this.customCheck ?? sameObject(this.value, this.checkValue)
    },
    showChange() {
      return !this.hideCheck && !this.hideCheckValue && !this.isSame
    },
    showChangeBtn() {
      return this.showChange && !this.isReadonly
    },
    isRGBColor() {
      return this.color?.startsWith('#')
    },
    isDisplaySmall() {
      return ['xs', 'sm'].indexOf(this.$vuetify.breakpoint.name) > -1
    },
    isDisplayUltraSmall() {
      return ['xs'].indexOf(this.$vuetify.breakpoint.name) > -1
    },
  },
  methods: {
    change() {
      if (this.isInteractive && !this.isSame) {
        this.$emit('change', this.checkValue)
      }
    },
  },
}
</script>

<style scoped>
.shift-top-3 {
  top: -3px;
  bottom: -3px;
}
.shift-top-2 {
  top: -2px;
  bottom: -2px;
}
</style>
