var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[(_vm.showAlert)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ReferenceTip',[_vm._v(" Обновить курсы валют до актуальных значений можно кнопкой \"обновить\" ( "),_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-sync")]),_vm._v(" ) ")],1)],1)],1):_vm._e(),(_vm.showAlert)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-alert',{attrs:{"type":"warning","dense":"","text":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.editMode)?_c('v-btn',{attrs:{"text":"","small":"","color":_vm.showValues ? 'green' : 'secondary',"title":"Показать изменения","disabled":!_vm.needRefresh,"icon":""},on:{"click":function($event){_vm.showValues = !_vm.showValues}}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.showValues ? 'mdi-eye-outline' : 'mdi-eye-off-outline')}})],1):_vm._e(),(_vm.editMode)?_c('v-btn',{attrs:{"text":"","color":"green","small":"","title":"Обновить значения ЦБ","disabled":!_vm.needRefresh},on:{"click":_vm.setCurrencyPrice}},[_c('v-icon',[_vm._v("mdi-sync")])],1):_vm._e()]},proxy:true}],null,false,3687180645)},[_vm._v(" Внимание! Информация о текущих курсах валют неактуальна, рекомендуется обновить данные ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"8","offset-lg":"2"}},[_c('v-data-table',{attrs:{"items":_vm.originCurrency,"headers":_vm.originHeaders,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.nameCurrency",fn:function(ref){
var item = ref.item;
return [(item.isEdit)?_c('v-badge',{attrs:{"value":item.isEdit,"color":"blue lighten-3","content":"Редактируемая валюта","offset-x":180,"left":""}},[_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"dense":"","readonly":"","maxLength":"3","hide-details":""},on:{"input":function($event){return _vm.doChange()},"blur":function($event){return _vm.doBlur()}},model:{value:(item.nameCurrency),callback:function ($$v) {_vm.$set(item, "nameCurrency", $$v)},expression:"item.nameCurrency"}})],1):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.nameCurrency)+" ")])]}},{key:"item.fullNameCurrency",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.isEdit)?_c('v-text-field',{attrs:{"dense":"","readonly":"","value":item.fullNameCurrency,"hide-details":""},on:{"input":function($event){return _vm.doChange()},"blur":function($event){return _vm.doBlur()}}}):_c('span',[_vm._v(_vm._s(item.fullNameCurrency))])]}},{key:"item.valueCurrency",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.isEdit)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toMoney")(item.valueCurrency)))]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm._f("toMoney")(item.valueCurrency))+" "),(_vm.showValues && _vm.editMode)?_c('span',[(item.valueCurrency < _vm.getInitValue(item, index))?_c('span',{staticClass:"green--text"},[_vm._v(" ➚ "+_vm._s(_vm._f("toMoney")(_vm.getInitValue(item, index)))+" ")]):(item.valueCurrency > _vm.getInitValue(item, index))?_c('span',{staticClass:"error--text"},[_vm._v(" ➘"+_vm._s(_vm._f("toMoney")(_vm.getInitValue(item, index)))+" ")]):_vm._e()]):_vm._e()])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var index = ref.index;
return [_c('td',{staticClass:"text-right"},[_c('div',{staticClass:"action",class:{ 'v-data-table__expand-icon--active': isExpanded }},[(item.isEdit && _vm.editMode)?_c('OriginDialog',{attrs:{"value":item,"edit-mode":_vm.editMode},on:{"change":function (item) { return _vm.editCurrency(item, index); },"delete":function($event){return _vm.deleteCurrency(index)}}}):_vm._e()],1)])]}},{key:"footer",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.editMode)?_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('OriginDialog',{on:{"change":_vm.addCurrency}})],1):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }