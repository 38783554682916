<template>
  <v-form v-if="data" :readonly="readonly" ref="form">
    <v-card class="mb-2">
      <v-card-title>Данные по закупке</v-card-title>
      <v-card-text v-if="isReadyPurchaseData" class="pb-6">
        <v-container fluid>
          <v-row dense>
            <v-col cols="6" sm="3" md="2">
              <v-text-field
                label="Год"
                v-model="data.purchaseData.year"
                :outlined="editMode"
                placeholder="Год"
                prepend-inner-icon="mdi-calendar"
                :append-icon="editIcon"
                :rules="[rules_year]"
                maxlength="4"
                @input="doChange()"
                @blur="doBlur()"
              />
            </v-col>
            <v-col cols="12" sm="9" md="10">
              <v-textarea
                label="Субъекты"
                v-model="data.purchaseData.subjects"
                :hint="
                  !readonly ? 'Если написано более 10, то только цифра' : ''
                "
                :outlined="editMode"
                placeholder="Год"
                prepend-inner-icon="mdi-text-long"
                rows="1"
                auto-grow
                :append-icon="editIcon"
                @input="doChange()"
                @blur="doBlur()"
              />
            </v-col>
          </v-row>
        </v-container>
        <ReleaseTable
          :value="data.purchaseData.releaseForms"
          :about="data"
          :readonly="readonly"
          @change="doReleaseChange"
          @blur="doBlur()"
        />
      </v-card-text>
    </v-card>
    <v-card class="mb-2">
      <!-- Ценовая характеристика ЛП -->
      <v-card-title>Ценовая характеристика ЛП</v-card-title>
      <v-card-text v-if="isReadyReleaseForms" class="pb-6">
        <PriceTable
          :value="data.priceCharacteristics"
          :readonly="readonly"
          @change="doPriceChange"
          @blur="doBlur()"
        />
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import ExpSummaryMixin from '../ExpSummaryMixin'
import ReleaseTable from './comp/ReleaseTable'
import PriceTable from './comp/PriceTable'

export default {
  name: 'SummaryPurchase',
  components: { PriceTable, ReleaseTable },
  mixins: [ExpSummaryMixin],

  data: () => ({}),

  computed: {
    isReadyPurchaseData() {
      return !!this.data?.purchaseData
    },
    isReadyReleaseForms() {
      return !!this.data?.purchaseData
    },
  },
  methods: {
    doReleaseChange(data, force = false) {
      this.data.purchaseData.releaseForms = data
      this.doChange(force)
    },
    doPriceChange(data, force = false) {
      this.data.priceCharacteristics = data
      this.doChange(force)
    },
    //Т. к в textField нельзя использовать type=number и maxLength одновременно, то такой костыль-проверка на наличие символов
    rules_year(v) {
      const y = Number(v)
      if (isNaN(y)) return 'Недопустимые символы'
      if (y?.length > 4) return `Год должен быть не более 4 символов`
      else if (y !== 0 && y < 1900) return 'Год не может быть меньше 1900'
      else if (y > 2100) return 'Год не может превышать 2100'
      else return true
    },
  },
}
</script>
