export function getForScenario(arrScenario) {
  const count = Array.isArray(arrScenario) ? arrScenario.length : 1
  if (count < 2) return String(arrScenario || '')

  return arrScenario
    .reduce(
      (text, item, idx) =>
        `${text}${text ? '\n' : ''}- Сценарий ${idx + 1}:\n ${item}`,
      ''
    )
    .trim()
}
