<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-text-field
          v-if="typeSearch === 'mnn'"
          v-model="searchMNN"
          :prepend-inner-icon="!selectMode ? 'mdi-magnify' : ''"
          label="МНН или АТХ"
          clearable
          @keypress="searchTextKeyPress"
          @click:clear="searchMNN = ''"
        >
          <template v-slot:[`append-outer`] v-if="!selectMode">
            <v-btn small text :disabled="!searchMNN" @click="search = searchMNN"
              ><v-icon left>mdi-magnify</v-icon>Искать</v-btn
            >
          </template>
        </v-text-field>
        <v-text-field
          v-else-if="typeSearch === 'mkb'"
          readonly
          v-model="searchMKB"
          label="Код МКБ"
          hint="Нажмите на кнопку поиска справа для выбора кодов МКБ из справочника"
          persistent-hint
          clearable
          :loading="searchLoading"
          :error-messages="searchError"
          :prepend-inner-icon="!selectMode ? 'mdi-magnify' : ''"
          @click:clear="searchMKB = ''"
        >
          <template v-slot:[`append-outer`]>
            <v-btn small text :loading="searchLoading" @click="showMkbTree"
              ><v-icon left>mdi-book-education-outline</v-icon>МКБ-10</v-btn
            >
          </template>
        </v-text-field>
        <v-text-field
          v-else-if="typeSearch === 'indication'"
          v-model="searchIndication"
          label="Показания"
          clearable
          hint="Поиск по части слова встречающегося в показаниях ( можно указать несколько слов через пробел )"
          persistent-hint
          :loading="searchLoading"
          :error-messages="searchError"
          :prepend-inner-icon="!selectMode ? 'mdi-magnify' : ''"
          @click:clear="searchIndication = ''"
          @keypress="searchTextKeyPress"
        >
          <template v-slot:[`append-outer`]>
            <v-btn
              small
              text
              :disabled="!searchIndication"
              :loading="searchLoading"
              @click="searchByIndications"
              ><v-icon left>mdi-magnify</v-icon>Искать</v-btn
            >
          </template>
        </v-text-field>
      </v-col>
      <v-col v-if="selectMode && typeSearch === 'mnn'">
        <!-- Лекформа как дополнение в режиме выбора  selectMode-->
        <v-text-field v-model="searchLF" label="ЛФ" clearable />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- Таблица с данными -->
        <v-data-table
          :search="search"
          :custom-filter="customFilter"
          :headers="getHeaders"
          :items="filteredIndication"
          :expanded.sync="expanded"
          :items-per-page="selectMode ? 10 : 100"
          show-expand
          :footerProps="table_footer_options"
          :loading="loading"
          :group-by="['ath_group', 'ath_group_name']"
          loading-text="Справочник загружается"
          no-results-text="МНН не найдено, проверьте правильность написания"
        >
          <!--Кнопка добавить препарат, только для режима редактирования-->
          <template v-slot:top>
            <div class="d-flex">
              <v-radio-group
                v-if="!selectMode"
                v-model="typeSearch"
                row
                class="mt-0"
              >
                <v-radio label="МНН / АТХ" value="mnn" />
                <v-radio label="МКБ-10" value="mkb" />
                <v-radio label="Показания" value="indication" />
              </v-radio-group>
              <v-spacer />
              <div v-if="canEdit" class="d-flex justify-end">
                <v-btn @click="openForAdd" color="success">
                  <v-icon class="mr-2">mdi-plus</v-icon> Добавить препарат
                </v-btn>
              </div>
            </div>
          </template>
          <!--ГРУППИРОВКА-->
          <template #[`group.header`]="{ headers, items }">
            <td :colspan="headers.length">
              <span class="text-overline">
                {{ items[0].ath_group }}. {{ items[0].ath_group_name }}
              </span>
            </td>
          </template>
          <template #[`item.index`]="{ value }">
            <span>{{ value }}.</span>
          </template>
          <template v-if="!selectMode" #[`item.ath`]="{ value }">
            <span :inner-html.prop="value | highlight(search)" />
          </template>
          <template v-if="!selectMode" #[`item.mnn`]="{ value }">
            <span :inner-html.prop="value | highlight(search)" />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <IndicationsLecformsTable
                :medication="item"
                :searchLF="searchLF"
                :select-mode="selectMode"
                @updateIndications="updateIndications"
                :readonly="readonly"
                :editMode="canEdit"
              />
            </td>
          </template>
          <template v-if="canEdit" v-slot:[`item.actions`]="{ item }">
            <div class="actions">
              <v-btn
                @click="openForEdit(item)"
                fab
                small
                depressed
                title="Редактировать"
                color="transparent"
                class="mr-2"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <btn-with-confirm
                icon
                icon-main="mdi-delete-forever-outline"
                title="Удалить препарат"
                @click="showDeleteConfirmation(item)"
                :disabled="deleteItemInProgress"
              >
              </btn-with-confirm>
            </div>
          </template>
          <template v-slot:footer v-if="canEdit">
            <div class="d-flex my-2">
              <v-btn
                title="Показать историю справочника показаний"
                icon
                @click="showActionHistory = true"
                ><v-icon>mdi-history</v-icon></v-btn
              >
              <v-spacer />
              <v-btn
                small
                title="Выгрузить полный справочник в Excel"
                :loading="loading || savedProcess"
                @click="loadManual"
              >
                <v-icon left color="green darken-4">mdi-microsoft-excel</v-icon>
                Экспорт в Excel
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      width="1000px"
      v-model="showActionHistory"
      v-if="showActionHistory"
    >
      <v-card>
        <user-actions :search-log="{ dict_name: 'dict_gnvlp' }" />
      </v-card>
    </v-dialog>
    <MedicineIndicationsForm
      v-if="canEdit && showForm"
      :gnvlp="gnvlp"
      :is-open-for-add="isOpenForAdd"
      @close-form="closeForm"
    />
    <ExpAgencyDeleteDialog
      v-if="canEdit"
      :dialog="showDeleteDialog"
      :name="getName"
      lb="Международное непатентованное наименование: "
      title="Удалить препарат"
      label="Подтвердить удаление препарата"
      @onClose="cancelDelete"
      @onAction="confirmDelete"
    />
    <MkbTreeSelectDialog
      v-if="mkbTree"
      :codes-string="searchMKB"
      @close-mkb-tree="closeMkbTree"
      @confirm-selection="confirmCodes"
      single-select
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IndicationsLecformsTable from '@/components/dict/indications/IndicationsLecformsTable'
import MedicineIndicationsForm from '@/components/dict/indications/allForms/MedicineIndicationsForm'
import ExpAgencyDeleteDialog from '@/components/dialogs/ExpAgencyDeleteDialog'
import BtnWithConfirm from '@/components/buttons/btnWithConfirm'
import MkbTreeSelectDialog from '@/components/dict/indications/allForms/MkbTreeSelectDialog'
import userActions from '@/components/viewLogs/userActions'
import { Api } from '@/lib/axiosApi'
import { searchByWords } from '../../../lib/strUtils'

export default {
  name: 'MedicineIndicationsTable',
  components: {
    BtnWithConfirm,
    ExpAgencyDeleteDialog,
    MedicineIndicationsForm,
    IndicationsLecformsTable,
    MkbTreeSelectDialog,
    userActions,
  },
  props: {
    // весь объект препарата, АТХ, МНН, лекформы lfs, итп
    medication: {
      type: Object,
      default() {
        return {}
      },
    },
    selectMode: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      typeSearch: 'mnn',
      search: '',
      searchLF: '',
      searchMKB: '',
      searchMNN: '',
      searchIndication: '',
      searchLoading: false,
      mkbTree: false,
      filterDict: null,
      isOpen: false,
      expanded: [],
      showActionHistory: false,
      headers: [
        {
          text: '№',
          value: 'index',
          width: '5px',
          sortable: false,
          cellClass: 'text--disabled',
          filterable: false,
        },
        {
          text: 'АТХ',
          value: 'ath',
          width: '75px',
          align: 'end',
          cellClass: 'text--disabled font-weight-medium',
        },
        {
          text: 'Международное непатентованное наименование (МНН)',
          align: 'start',
          value: 'mnn',
          cellClass: 'text-body-1',
        },
        {
          text: 'Показание',
          align: 'start',
          value: 'ath_group_name',
        },
        {
          text: 'Действия',
          value: 'actions',
          width: '150px',
          sortable: false,
        },
      ],
      table_footer_options: {
        itemsPerPageOptions: [10, 100, 200, 300, 500, -1],
      },
      error_message: '',
      searchError: '',
      deleteItemInProgress: false,
      showForm: false,
      showDeleteDialog: false,
      item: null, // запоминаем объект для удаления
      gnvlp: null,
      isOpenForAdd: false,
      savedProcess: false,
    }
  },
  computed: {
    ...mapGetters(['dictIndications', 'dictIndicationsDescription']),
    openText() {
      return this.isOpen ? 'Скрыть' : 'Обновить справочник'
    },
    getName() {
      return this.item?.mnn ?? ''
    },
    canEdit() {
      return this.editMode && !this.selectMode
    },

    getHeaders() {
      return this.canEdit
        ? this.headers
        : this.headers.filter(({ value }) => value != 'actions')
    },
    filteredIndication() {
      if (this.filterDict) {
        return this.dictIndications?.reduce((out, item) => {
          const mnn = this.filterDict.find(({ id }) => item.id === id)
          return mnn ? [...out, { ...item, filterMKB: mnn.lfs }] : out
        }, [])
      } else {
        return this.dictIndications ?? []
      }
    },
  },
  async mounted() {
    this.loading = true
    this.clearSearch()
    try {
      await this.GET_INDICATIONS_DICT()
      if (this.selectMode) {
        this.expanded = [...this.dictIndications]
      }
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions([
      'GET_INDICATIONS_DICT',
      'DELETE_MEDICINE_FROM_GNVLP',
      'DOWNLOAD_FILE',
      'CREATE_ACTION_LOG',
    ]),
    searchTextKeyPress(event) {
      if (!event || event.charCode === 13) {
        if (this.typeSearch === 'mnn' && this.searchMNN)
          this.search = this.searchMNN
        if (this.typeSearch === 'indication' && this.searchIndication)
          this.searchByIndications()
      }
    },
    changeOpen() {
      this.isOpen = !this.isOpen
    },
    updateIndications(res) {
      this.$emit('updateIndications', res)
    },
    showMkbTree() {
      this.mkbTree = true
    },
    closeMkbTree() {
      this.mkbTree = false
    },
    async confirmCodes(code) {
      this.searchLoading = true
      this.mkbTree = false // закрываем показ диалога
      this.searchMKB = code
      try {
        this.filterDict = await Api.post(
          `dict/gnvlp/indications/search-by-mkb10/${this.searchMKB}?id_tree`
        )
      } catch (err) {
        this.searchError = err.message
      } finally {
        this.searchLoading = false
      }
    },
    async searchByIndications() {
      this.searchLoading = true
      this.searchError = ''
      try {
        this.filterDict = await Api.post(
          `dict/gnvlp/indications/search-by-indication?id_tree&t=${this.searchIndication}`
        )
      } catch (err) {
        this.searchError = err.message
      } finally {
        this.searchLoading = false
      }
    },
    openForAdd() {
      this.isOpenForAdd = true
      this.showForm = true
    },
    openForEdit(item) {
      this.isOpenForAdd = false
      this.gnvlp = item
      this.showForm = true
    },
    closeForm() {
      this.gnvlp = null
      this.showForm = false
    },
    cancelDelete() {
      this.showDeleteDialog = false
      this.itemId = null
    },
    showDeleteConfirmation(item) {
      this.showDeleteDialog = true
      this.item = item
    },
    async confirmDelete() {
      this.deleteItemInProgress = true
      this.error_message = ''
      const med_id = this.item.id
      try {
        await this.DELETE_MEDICINE_FROM_GNVLP({ med_id })
      } catch (err) {
        this.error_message = err
      } finally {
        this.item = null
        this.showDeleteDialog = false
        this.deleteItemInProgress = false
      }
    },
    async loadManual() {
      this.savedProcess = true
      try {
        const url = 'dict/gnvlp/export-file'
        const fileName = `Справочник_показаний_${this.getTimestamp()}`
        await this.DOWNLOAD_FILE({ url, fileName })
        // Пошлём сигнал в лог
        this.CREATE_ACTION_LOG({
          code: 'DICT_EXPORTED',
          message: `Выгружен справочник показаний "${fileName}"`,
          data: {
            dict: { dict_name: 'dict_gnvlp' },
            fileName,
          },
        })
      } finally {
        this.savedProcess = false
      }
    },
    getTimestamp() {
      return new Date().toISOString().split('T')[0].replaceAll('-', '_')
    },
    clearSearch() {
      this.searchError = ''
      this.search = ''
      if (this.selectMode && this.typeSearch === 'mnn') {
        this.searchMNN = this.medication.mnn || ''
        this.searchLF = this.medication.lf || ''
        // с задержкой выставим МНН в поиск
        this.$nextTick(() => (this.search = this.searchMNN))
      } else {
        this.searchMNN = ''
        this.searchLF = ''
      }
      this.searchMKB = ''
      this.searchIndication = ''
    },
    customFilter(value, search) {
      if (!search) return true
      // сравниваем без учёта пунктуации и регистронезависимо
      return searchByWords(value, search)
    },
  },
  watch: {
    searchMNN(newVal, oldVal) {
      if (this.selectMode) this.search = newVal
      else if (newVal !== oldVal) this.search = ''
    },
    searchMKB(newVal) {
      if (!newVal) this.filterDict = null
    },
    searchIndication(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchError = ''
        this.filterDict = null
      }
    },
    typeSearch: 'clearSearch',
  },
}
</script>
<style scoped>
.v-data-table >>> tr .actions {
  display: none !important;
}
.v-data-table >>> tr:hover .actions {
  display: flex !important;
}
</style>
