<template>
  <div>
    <v-tabs
      class="mb-4"
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab>Общая информация об ЛП</v-tab>
      <v-tab>Данные по закупке</v-tab>
      <v-tab>Оценка экспертной организаций</v-tab>
      <v-tab>Оценка ГВС</v-tab>
      <v-tab>Дополнительная информация</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SummaryInfo :value="value" log-view readonly />
      </v-tab-item>
      <v-tab-item>
        <SummaryPurchase :value="value" log-view readonly />
      </v-tab-item>
      <v-tab-item>
        <SummaryScore :value="value" log-view readonly />
      </v-tab-item>
      <v-tab-item>
        <SummaryGVS :value="value" log-view readonly />
      </v-tab-item>
      <v-tab-item>
        <SummaryAdditional :value="value" log-view readonly />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
export default {
  name: 'SummaryForLog',
  components: {
    SummaryAdditional: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expSummary/summaryAdditional/SummaryAdditional'
      ),
    SummaryGVS: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expSummary/summaryGVS/SummaryGVS'
      ),
    SummaryInfo: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expSummary/summaryInfo/SummaryInfo'
      ),
    SummaryPurchase: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expSummary/summaryPurchase/SummaryPurchase'
      ),
    SummaryScore: () =>
      import(
        /* webpackChunkName: "main-expertise" */ '@/components/expSummary/summaryScore/SummaryScore'
      ),
  },

  props: ['value'],
  data: () => ({
    tab: null,
  }),
}
</script>
